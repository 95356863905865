@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
title
------------------------------------------------------------*/
.nk23_c-title01 {
	font-size: 2rem;
	line-height: 1.5;
	font-weight: 700;
	text-align: center;

	&.is-style1 {
		@include r.pc {
			font-size: 2.4rem;
		}
	}

	&.is-style2 {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	&.is-style3 {
		font-size: 1.8rem;
		line-height: 2.2rem;
		text-align: left;
	}

	// .nk23_c-title01.has-icon:after .
	&.has-icon {
		&:after {
			top: 1px;
			position: relative;
			margin-left: 8px;
			background: url(p.$path + 'images/common/icon/icon_help.svg') no-repeat
				center / 18px auto;
			content: '';
			width: 18px;
			height: 18px;
			display: inline-block;
		}
	}

	&.title01 {
		@include r.sp {
			text-align: left;
			font-size: 1.8rem;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-title02 {
	background: transparent;
	font-size: 1.4rem;
	line-height: 1.6;
	color: v.$color0;
	font-weight: bold;
	$root: &;

	&.tl-h3 {
		@extend #{$root};
		padding: 0;
	}
}
