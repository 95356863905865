@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

@media print {
  @page {
    size: A4 portrait;
    margin: 0.25in;
  }

  body {
    -webkit-print-color-adjust: exact;
  }
  html,
  body {
    height: 100%;
    min-width: 1100px;
    background: #fff;
    margin: 0 !important;
    padding: 0 !important;
  }

  .nk23_c-header,
  .nk23_c-footer,
  .display_sp {
    display: none;
  }
  .nk23_l-container2,
  .nk23_l-container1,
  .nk23_l-container {
    padding: 0;
    max-width: none;
  }
  .nk23_c-title01 {
    margin-top: -16px;
  }

  table {
    page-break-after: auto;

    tr,
    td {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    thead,
    tfoot {
      display: table-header-group;
    }

    th,
    td {
      padding: 2px !important;
    }
  }

  table.nk23_c-table22__table {
    th,
    td {
      width: auto !important;
    }
  }

  table.nk23_c-table01__table {
    min-width: 100% !important;
  }

  .sp,
  .scrollbar-track {
    display: none !important;
  }

  .nk23_c-title01 {
    font-size: 2.8rem;
  }
  #contents170,
  #breadcrumbs {
    display: none;
  }
  #contents770 {
    width: 100%;
    margin-left: 0 !important;
  }
  #contents {
    padding: 20px 0 0 0;
  }
  #contents-nw {
    padding: 16px 0 0 0 !important;
    max-width: none !important;
  }
  .tb01,
  .table-wrapper .tb01,
  .table-wrapper .tb02 .tb02 {
    border-radius: 0;
  }

  .tb01 tr th:first-child,
  .tb01 tr td:first-child,
  .tb02 tr th:first-child,
  .tb02 tr td:first-child {
    border-left: 1px solid v.$color19;
  }
  .tb01 tr th:last-child,
  .tb01 tr td:last-child,
  .tb02 tr th:last-child,
  .tb02 tr td:last-child {
    border-right: 1px solid v.$color19;
  }
  .tb01 tr:last-child td,
  .tb02 tr:last-child td {
    border-bottom: 1px solid v.$color19;
  }
  #boxProf-jockey,
  #boxProf-horse {
    border-radius: 0;
    border-top: 3px solid #999;
    border-left: 1px solid v.$color19;
    border-right: 1px solid v.$color19;
    border-bottom: 1px solid v.$color19;
  }
}
