@use "../settings/variable" as v;
@use "../settings/mixin" as r;

/*------------------------------------------------------------
for develop
------------------------------------------------------------*/
#__bs_notify__ {
  opacity: 0.3 !important;
  font-size: 5px !important;
  line-height: 100% !important;
  padding: 0 5px 0 5px !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}

.c-dev-title1 {
  background: #000;
  color: #fff;
  line-height: 1;
  padding: 6px 0 6px 15px;
  font-size: 1.6rem;
  margin: 60px 0 20px 0;
}

.c-dev-title2 {
  background: #888;
  color: #fff;
  line-height: 1;
  padding: 6px 0 6px 30px;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    display: flex;
    flex-wrap: wrap;
  }
  &__design {
    margin-left: 20px;
    font-weight: bold;
    @include r.sp {
      margin-top: 5px;
      margin-left: 0;
      display: block;
      width: 100%;
    }
  }
  &__status {
    margin-left: 20px;
    @include r.sp {
      margin-top: 5px;
      margin-left: 0;
      display: inline-block;
    }
  }
  &__date {
    font-weight: bold;
    margin-right: 16px;
  }
}

.c-dev-message1 {
  top: 0;
  left: 0;
  background: #f00;
  color: #fff;
  z-index: 9999;
  position: fixed;
  line-height: 1;
  padding: 3px 6px;
  opacity: 0.6;
  font-size: 8px;
}

.c-dev-title3 {
  margin-bottom: 48px;
  padding-bottom: 16px;
  border-bottom: 3px solid v.$color0;
  font-weight: 700;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .is-en {
    font-size: 4rem;
    margin-right: 16px;
  }

  .is-jp {
    font-size: 2.4rem;
  }
}

.c-dev-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 32px;

  @include r.sp {
    margin-bottom: 16px;
  }
}

.c-dev-title4 {
  margin-bottom: 32px;
  padding-bottom: 8px;
  border-bottom: 1px solid v.$color0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .is-large {
    font-weight: 700;
    font-size: 2rem;
    margin-right: 16px;
		word-break: break-all;

    @include r.sp {
      margin-bottom: 8px;
    }
  }

  .is-small {
    font-size: 1rem;
  }

  &.is-style1 {
    margin-bottom: 16px;
    padding-bottom: 4px;

    .is-large {
      font-size: 1.2rem;
    }
  }

  & + .c-dev-text {
    margin-top: -24px;
  }
}

.c-dev-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 32px;

  @include r.sp {
    margin: 0 -4px 16px;
  }

  .nk23_c-block08__btn{
    width: 100%;
    margin: 0;
  }

	&.is-icons {
		.c-dev-group__item {
			@include r.max-w(767px) {
				width: 100%;
			}
		}
	}

	// .c-dev-group__item .
  &__item {
    margin-bottom: 16px;
    padding: 0 8px;

    @include r.sp {
      padding: 0 4px;
      margin-bottom: 8px;
    }
  }

  &.is-col2 {
    .c-dev-group__item {
      @include r.pc {
        width: 50%;
      }
    }
  }

  &.is-col3 {
    .c-dev-group__item {
      @include r.pc {
        width: 33.3333%;
      }
    }
  }

  &.is-col4 {
		// .c-dev-group.is-col4 .c-dev-group__item .
    .c-dev-group__item {
      @include r.pc {
        width: 25%;
      }
    }
  }

  &.is-col5 {
    .c-dev-group__item {
      @include r.pc {
        width: 20%;
      }
    }
  }

  &.is-col12 {
    .c-dev-group__item {
      @include r.pc {
        width: 8.3333%;
      }
    }
  }

	&.is-col2,
	&.is-col3,
	&.is-col4,
	&.is-col5 {
		.c-dev-group__item {
			@include r.max-w(767px) {
				width: 100%;
			}
		}
	}

	// dev-group__box .
  &__box {
    padding: 16px 16px 8px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include r.sp {
      padding: 8px 8px 4px;
      border-radius: 4px;
    }

		&.u-style1 {
			display: inline-block;

			// .c-dev-group__box.u-style1 .c-gdl-icon-list-box .
			.c-gdl-icon-list-box {
				width: max-content;
				height: max-content;
				max-width: 100px;
				max-height: 100px;

				@include r.pc {
					padding: 0;
				}
			}

			.c-dev-group__box--text {
				@include r.pc {
					margin-top: 5px;
				}

				@include r.sp {
					margin-bottom: 8px;
				}
			}
		}

		&--items {
			display: flex;
		}

    & + .c-dev-group__text {
      margin-top: 16px;

      @include r.sp {
        margin-top: 8px;
      }
    }

		&--icons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;

			li {
				&:not(:last-child) {
					margin-right: 16px;
				}
			}
		}

		&--text {
			margin-top: 8px;
			text-align: center;
			width: 100%;
			font-size: 1.2rem;

			@include r.sp {
				margin-top: 4px;
			}
		}
  }

  &__text {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .nk23_c-btn01,
  .nk23_c-btn02,
  .nk23_c-btn03,
  .nk23_c-btn04,
  .nk23_c-btn05 {
    margin-right: 8px;
    margin-bottom: 8px;

    @include r.sp {
      margin-right: 4px;
      margin-bottom: 6px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.c-dev-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  margin-bottom: 8px;

  @include r.sp {
    margin-right: 4px;
    margin-bottom: 6px;
  }

  &:last-child {
    margin-right: 0;
  }

  // .c-dev-status__text .
  &__text {
    margin-top: 8px;
    font-size: 1rem;
    text-align: center;

    @include r.sp {
      margin-top: 4px;
    }

		&.is-style1 {
			width: 100%;
			margin-top: 0;
			margin-bottom: 8px;
			display: block;

			@include r.sp {
				margin-bottom: 4px;
			}
		}

		&.is-style2 {
			width: 100%;
			margin-top: 0;
			margin-bottom: 8px;
			display: block;
			text-align: left;

			@include r.sp {
				margin-bottom: 4px;
			}
		}
  }

  .nk23_c-btn01,
  .nk23_c-btn02,
  .nk23_c-btn03,
  .nk23_c-btn04,
  .nk23_c-btn05 {
    margin-right: 0;

    @include r.sp {
      margin-right: 0;
    }
  }

	&--center {
		align-items: center;
	}
}


.c-dev-table {
	width: 100%;
	margin-bottom: 4px;

	th {
		background-color: #404040;
		color: #fff;

		&:first-child {
			min-width: 30px;
		}

		&:nth-child(2) {
			min-width: 85px;
		}
	}

	th,
	td {
		font-size: 1.2rem;
		text-align: center;
		border: 1px solid #c9c9c9;
		padding: 4px;

		@include r.max-w(374px) {
			font-size: 1.1rem;
		}
	}
}

.c-gdl-icon-list-box{
  word-break: break-all;

  &.w-150{
    width: 150px;
  }
}

.p-gdl-block{
  .p-my_index2{
    .nk23_l-container{
      width: 341px;

      @include r.sp{
        width: 100%;
      }
    }
  }
}

.p-gdl-w960 {
	@include r.pc {
		max-width: 960px;
		width: 100%;
	}
}
