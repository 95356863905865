@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
text
------------------------------------------------------------*/
.nk23_c-text01 {
	font-size: 1.4rem;
	line-height: 2.2rem;

	&.is-style1 {
		padding-left: 10px;
		text-indent: -10px;
		font-size: 1.2rem;
		line-height: 1.9rem;

		@include r.sp {
			padding-left: 12px;
			text-indent: -12px;
		}
	}

	&.is-style2 {
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	&.is-style3 {
		font-size: 1.8rem;
		line-height: 1.5;
	}

	&.is-style4 {
		font-size: 1.8rem;
		line-height: 2.4rem;

		@include r.w374 {
			font-size: 1.4rem;
			line-height: 1.3;
		}
	}

	&.is-style5 {
		font-size: 1.2rem;
		line-height: 1.5;
	}

	&.is-style6 {
		font-size: 1.5rem;
	}

	&.is-large {
		font-size: 1.6rem;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-text02 {
	font-size: 1.6rem;
	line-height: 2.2rem;
	font-weight: 700;
	text-align: center;

	&--style1 {
		padding: 16px 0;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-grtext1 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.is-small {
		font-size: 1.4rem;
		line-height: 2rem;

		@include r.sp {
			font-size: 1.2rem;
		}
	}

	// .nk23_c-grtext1 .is-large .
	.is-large {
		font-size: 1.6rem;
		line-height: 1.75;
		font-weight: 700;

		@include r.sp {
			font-size: 1.4rem;
		}
	}

	.is-icon {
		width: 10px;
		height: 10px;
		background: url(p.$path + 'images/common/icon/icon_plus.svg') no-repeat
			center / 10px auto;
	}

	.is-small,
	.is-large,
	.is-icon {
		margin-right: 8px;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-text03 {
	font-size: 1.3rem;
	line-height: 1.6;
	color: v.$color0;
	@include r.sp {
		font-size: 1.6rem;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-txtNote {
	max-width: 640px;
	margin: 0 auto;
	font-size: 1.2rem;
	line-height: 19px;
	@include r.sp {
		padding: 0 8px;
	}
	.nk23_c-list-txt {
		margin-left: 12px;
		li {
			margin: 2px 0;
		}
	}
}
