@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
table
------------------------------------------------------------*/

th[data-column].sort-desc,
th[data-column].sort-asc {
	background-color: v.$color0;
}

td[data-column].sort-desc,
td[data-column].sort-asc {
	background-color: v.$color19;
}

/*
------------------------------------------------------------*/

@mixin tablestyle02 {
	font-size: 1.4rem;
	line-height: 1.36;

	@include r.sp {
		min-width: 100%;
	}

	&__table {
		width: 100%;
		background-color: #fff;
	}

	&__wrap {
		@include r.pc {
			border-radius: 0 0 8px 8px;
			overflow: hidden;
		}
	}

	th,
	td {
		border: 1px solid v.$color23;
		padding: 4px 8px;
		text-align: center;

		@include r.sp {
			padding: 4px;
		}

		&.is-right {
			text-align: right;
		}

		&.is-left {
			text-align: left;
		}

		&.border-x-none {
			border-right: none;
			border-left: none;
		}

		&:first-child {
			border-left: none;
		}

		&:last-child {
			border-right: none;
		}
	}

	&__title {
		padding: 12px 16px;
		background-color: #fff;
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 700;
		width: 100%;

		@include r.pc {
			border-radius: 8px 8px 0 0;
		}

		@include r.sp {
			padding: 7.5px 16px;
		}
	}

	[class^="nk23_u-bg-color"] {
		color: #fff;
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}
}

.nk23_c-table01 {
	@include r.tablestyle;
	$root: &;

	&.w-1200 {
		.nk23_c-table01__table {
			@include r.table(1200, 52, 83, 1200, 52, 83);

			@include r.sp {
				min-width: 1200px;
			}
		}
	}

	&.w-960 {
		.nk23_c-table01__table {
			@include r.table(960, 102, 132, 437, 37, 65);
		}
	}

	&.w-640 {
		.nk23_c-table01__table {
			@include r.table(640, 68, 99, 437, 37, 65);
		}
	}

	&.w-472 {
		.nk23_c-table01__table {
			@include r.table(472, 52, 83, 437, 37, 65);
		}
	}

	&.w-437 {
		.nk23_c-table01__table {
			@include r.table(437, 35, 35, 437, 35, 35);
		}
	}

	.nowrap {
		white-space: nowrap;
		word-break: keep-all;
	}

	// .nk23_c-table01__title .
	&__title {
		padding: 12px 16px;
		background-color: #fff;
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 700;

		@include r.pc {
			border-radius: 8px 8px 0 0;
		}

		@include r.sp {
			padding: 8px 16px;
		}

		& + .nk23_c-table01__wrap {
			// .nk23_c-table01__title + .nk23_c-table01__wrap .nk23_c-table01__table .
			.nk23_c-table01__table {
				@include r.pc {
					border-radius: 0 0 8px 8px;
				}
			}
		}

		&.is-style1 {
			@include r.sp {
				border-radius: 8px 8px 0 0;
			}
		}

		// .nk23_c-table01__title__label
		&__label {
			padding: 14px 0 8px;
			width: 40px;
			height: max-content;
			position: absolute;
			top: 0;
			left: 16px;
			background-color: v.$color0;
			color: #fff;
			text-align: center;
			font-size: 1.4rem;
			line-height: 1;

			@include r.sp {
				padding: 16px 0 0;
				height: 36px;
				font-size: 1.4rem;
				left: 8px;
			}

			// .nk23_c-table01__title__label:after .
			&:after {
				bottom: -12px;
				border-width: 12px 20px 0 20px;
				left: 0;
				pointer-events: none;
				position: absolute;
				content: "";
				border-style: solid;
				border-color: transparent;
				border-top-color: v.$color0;
			}
		}

		// .nk23_c-table01__title__txt
		&__txt {
			padding-left: 56px;
			color: v.$color13;
			font-size: 1.4rem;

			@include r.sp {
				padding-left: 0;
				font-weight: bold;
				padding-bottom: 8px;
			}
		}
	}

	// .nk23_c-table01__note
	&__note {
		font-size: 1.2rem;
		line-height: 1.9rem;
		margin-top: 8px;

		@include r.sp {
			padding: 0 4px;
			box-sizing: border-box;
		}
	}

	// .nk23_c-table01__table .
	&__table {
		table-layout: fixed;
	}

	// .nk23_c-table01 th .
	th {
		text-align: center;

		// .nk23_c-table01 th.is-w-auto .
		&.is-w-auto {
			width: auto !important;
		}
	}

	th,
	td {
		padding: 4px 8px;
		border: 1px solid v.$color19;

		@include r.sp {
			padding-left: 4px;
			padding-right: 4px;
		}
	}

	td {
		border-bottom: 0;

		&:nth-child(1),
		&:nth-child(2),
		&:last-child {
			text-align: center !important;
		}

		&:last-child {
			border-right: 0;
		}

		&:first-child {
			border-left: 0;
		}

		&:not([class*="text-"]) {
			text-align: right;
		}
	}

	// .nk23_c-table01 thead .
	thead {
		background-color: v.$color29;
		font-weight: 700;
		color: #fff;
		line-height: 1.35;

		th {
			border-top: 0;

			&:first-child {
				@include r.sp {
					border-left: none;
				}
			}

			&:last-child {
				@include r.sp {
					border-right: none;
				}
			}
		}
	}

	// .nk23_c-table01 tbody .
	tbody {
		line-height: 1.42;
		background-color: #fff;
	}

	&__tabbtns {
		padding: 16px 8px;
		display: flex;
		background-color: #fff;
		border-top: 1px solid v.$color19;
		border-bottom: 1px solid v.$color19;

		// .nk23_c-table01__tabbtns .nk23_c-btn01 .
		.nk23_c-btn01 {
			margin-right: 8px;
			width: auto;
			min-width: 60px;
			padding: 0 15px;
		}
	}

	&--style1 {
		&.w-960 {
			.nk23_c-table01__table {
				min-width: 960px;

				@include r.sp {
					min-width: 600px;
				}

				@include r.table(960, 78, 82, 437, 32, 35);

				th {
					word-break: keep-all;

					&:nth-last-of-type(5),
					&:nth-last-of-type(6),
					&:nth-last-of-type(7) {
						width: 92px;

						@include r.sp {
							width: 70px;
						}
					}
				}

				td {
					text-align: center;

					a {
						color: v.$color-link;

						@include r.hover {
							text-decoration: underline;
							opacity: 1;
						}
					}
				}
			}

			.nk23_c-table01__inner {
				// padding-bottom: 8px;
				@include r.sp {
					// padding-bottom: 4px;
				}
			}

			.dataTables_paginate {
				display: none;
			}

			.nk23_c-selectbox--style1 {
				display: flex;
				justify-content: flex-end;

				select {
					margin-left: 4px;
					color: v.$color0;
				}
			}
		}

		th {
			@include r.pc {
				line-height: 1.5;
			}
		}

		td {
			@include r.pc {
				padding: 8px;
			}
		}

		thead {
			th {
				&.is-w-auto {
					@include r.sp {
						width: 1% !important;
						min-width: 130px;
					}
				}
			}
		}
	}

	&--style2 {
		.nk23_c-table01 {
			&__table {
				table-layout: initial;
				width: 100%;

				@include r.sp {
					min-width: 100%;
				}

				&.is-td-w01 {
					th {
						// .nk23_c-table01--style2 .nk23_c-table01__table.is-td-w01 th:not(:last-child) .
						&:not(:last-child) {
							width: 7.9%;
							white-space: nowrap;
							word-break: keep-all;

							@include r.sp {
								width: 17%;
							}
						}
					}
				}

				&.is-td-w02 {
					td {
						// .nk23_c-table01--style2 .nk23_c-table01__table.is-td-w02 td:not(:last-child) .
						&:not(:last-child) {
							width: 8em;
							white-space: nowrap;

							@include r.sp {
								width: 16%;
							}
						}
					}
				}

				&.is-td-w03 {
					td {
						// .nk23_c-table01--style2 .nk23_c-table01__table.is-td-w02 td:not(:last-child) .
						&:nth-child(1) {
							width: 100px;
							white-space: nowrap;

							@include r.sp {
								width: 16%;
							}
						}

						&:nth-child(2) {
							width: 100px;
							white-space: nowrap;

							@include r.sp {
								width: 16%;
							}
						}

						&:nth-child(3) {
							width: 100px;
							white-space: nowrap;

							@include r.sp {
								width: 16%;
							}
						}

						&:last-child {
							width: 620px;
							text-align: left !important;
							padding-left: 32px;

							@include r.sp {
								text-align: right !important;
							}
						}
					}
				}

				td {
					padding: 8px;

					&.nk23_u-left {
						text-align: left !important;
					}

					@include r.sp {
						border-bottom: 1px solid #c9c9c9;
					}
				}
			}
		}

		thead {
			th {
				@include r.sp {
					border-top: 1px solid v.$color19;
				}

				&:first-child {
					@include r.sp {
						border-left: none;
					}
				}

				&:last-child {
					@include r.sp {
						border-right: none;
					}
				}
			}
		}

		&.is-style1 {
			[data-tab-content] {
				@include r.pc {
					display: block !important;
				}
			}

			tbody {
				// .nk23_c-table01--style2.is-style1 tbody td .
				td {
					border-bottom: 1px solid v.$color19;

					@include r.pc {
						height: 38px;
					}

					// .nk23_c-table01--style2.is-style1 tbody td:nth-child(1) .
					&:nth-child(1) {
						width: 22%;
						padding-right: 0;
					}

					&:nth-child(2) {
						width: 22%;
						text-align: center !important;
					}

					// .nk23_c-table01--style2.is-style1 tbody td:nth-child(3) .
					&:nth-child(3) {
						width: 22%;
						padding-left: 0;
						padding-right: 0;
						text-align: right !important;
					}

					&.is-title {
						background-color: v.$color34;
						text-align: center !important;
					}

					// .nk23_c-table01--style2.is-style1 tbody td.is-full .
					&.is-full {
						min-width: 100%;
						width: 100%;
						text-align: left !important;

						@include r.pc {
							padding-top: 2px;
							padding-bottom: 2px;
						}
					}
				}

				tr {
					&:last-child {
						td {
							border-bottom: none;
						}
					}
				}
			}

			thead {
				// .nk23_c-table01--style2.is-style1 thead th .
				th {
					line-height: 1.59;
					border-top: 1px solid v.$color19;
					border-bottom: 1px solid v.$color19;
				}
			}

			th,
			td {
				border: none;

				@include r.w374 {
					font-size: 1.2rem;
				}
			}

			.nk23_c-table01 {
				&__wrap {
					&.is-col2 {
						.nk23_c-table01 {
							&__wraps {
								@include r.pc {
									display: flex;
								}
							}

							&__table {
								min-width: 100% !important;
							}

							&__inner {
								padding-bottom: 0;

								@include r.pc {
									width: 50%;
								}

								&:nth-child(1) {
									@include r.pc {
										border-right: 1px solid v.$color19;
										border-bottom-right-radius: 0;
									}

									.nk23_c-table01__table {
										@include r.pc {
											border-bottom-right-radius: 0;
										}
									}
								}

								&:nth-child(2) {
									@include r.pc {
										border-bottom-left-radius: 0;
									}

									.nk23_c-table01__table {
										@include r.pc {
											border-bottom-left-radius: 0;
										}
									}
								}
							}
						}
					}
				}

				&__table {
					@include r.sp {
						border-radius: 0 0 8px 8px !important;
					}

					&.is-col3 {
						tbody {
							td {
								&:last-child {
									min-width: unset;
									width: unset;
									padding-right: 8px;

									@include r.sp {
										padding-right: 4px;
									}
								}
							}
						}
					}
				}

				&__title {
					@include r.sp {
						padding-top: 12px;
						padding-bottom: 12px;
						border-radius: 8px 8px 0 0 !important;
					}
				}
			}

			td {
				@include r.pc {
					text-align: left !important;
				}

				@include r.sp {
					padding: 4px;
					line-height: 1.6;
				}

				&:not(:first-child) {
					@include r.sp {
						text-align: right !important;
					}
				}

				&:nth-child(2) {
					@include r.sp {
						text-align: center !important;
					}
				}

				&:last-child {
					text-align: right !important;
					width: 34%;
					@include r.sp {
						min-width: max-content;
					}
				}
			}

			tr {
				&.border-bottom-0 {
					td {
						border-bottom: none;
					}

					& + tr {
						&:not(.border-bottom-0) {
							td {
								border-top: 1px solid v.$color19;
							}
						}
					}
				}
			}
		}
	}

	&--style3 {
		.nk23_c-table01 {
			&__table {
				width: 100%;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-top: 1px solid #c9c9c9;

				@include r.pc {
					table-layout: initial;
				}

				@include r.sp {
					min-width: 100%;
				}

				&:first-child:not(:only-child) {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				thead {
					th {
						white-space: nowrap;
						word-break: keep-all;

						&.is-empty {
							border: none;
							background: #ffffff;

							@include r.sp {
								border-top: 1px solid v.$color19;
							}
						}
					}

					tr {
						&:not(:first-child) {
							th {
								padding: 8px 4px;
							}
						}
					}
				}

				td {
					padding: 8px;

					@include r.sp {
						padding: 4px;
					}

					&.nk23_u-right {
						text-align: right !important;
					}

					&.is-empty {
						border: none;

						@include r.sp {
							border-top: 1px solid v.$color19;
							border-bottom: 1px solid v.$color19;
						}
					}
				}
			}

			// .nk23_c-table01--style3 .nk23_c-table01__top .
			&__top {
				padding: 16px;
				display: flex;
				justify-content: space-between;
				background-color: #fff;
				align-items: center;

				@include r.pc {
					padding: 16px;
					border-radius: 8px 8px 0 0;
				}

				@include r.sp {
					padding: 8px 8px 8px 16px;
				}

				.nk23_c-table01__title {
					background-color: transparent;
					border-radius: 0;
					padding: 0;
				}

				.nk23_c-link {
					min-width: max-content;
					font-weight: 700;
				}
			}
		}

		thead {
			tr {
				&:not(:first-child) {
					// .nk23_c-table01--style3 thead tr:not(:first-child) th .
					th {
						@include r.pc {
							padding: 4px;
							width: 1%;
							min-width: 38px;
							background-color: v.$color34;
							color: v.$color0;
						}

						&.is-empty {
							border: none;
						}

						&:nth-child(2) {
							@include r.pc {
								width: 1%;
								min-width: 70px;
							}
						}

						// .nk23_c-table01--style3 thead tr:not(:first-child) th:nth-child(3n+2) .
						&:nth-child(3n + 2) {
							@include r.pc {
								width: 1%;
								min-width: 70px;
							}
						}

						&:first-child {
							border-left: none;
						}

						&:last-child {
							border-right: none;
						}
					}
				}
			}

			th {
				@include r.sp {
					background-color: v.$color34;
					color: v.$color0;
				}

				// .nk23_c-table01--style3 thead th:first-child .
				&:first-child {
					@include r.sp {
						width: 18.2%;
						border-left: none;
					}
				}

				&:last-child {
					@include r.sp {
						border-right: none;
					}
				}
			}
		}

		tbody {
			th {
				word-break: keep-all;

				@include r.sp {
					font-weight: 700;
					background-color: v.$color29;
					color: #fff;
				}

				&:first-child {
					border-left: none;
				}

				&:last-child {
					border-right: none;
				}
			}

			// .nk23_c-table01--style3 tbody td .
			td {
				word-break: keep-all;
				white-space: nowrap;

				@include r.pc {
					text-align: center !important;
				}
			}
		}
	}

	&--style4 {
		.nk23_c-table01 {
			&__table {
				table-layout: initial;
				width: 100%;

				@include r.sp {
					min-width: 100%;
				}

				tr[class^="nk23_u-bg-color"] {
					td {
						color: v.$color0;
					}

					td[class^="nk23_u-bg-color"] {
						color: #ffffff !important;
					}
					td.nk23_u-bg-color15 {
						color: v.$color0 !important;
					}
				}

				th {
					word-break: keep-all;
					padding: 4px;

					&:nth-of-type(4) {
						width: 200px;
					}
				}

				td {
					text-align: center;
					padding: 8px 4px;
					word-break: keep-all;
					white-space: nowrap;

					@include r.sp {
						border-bottom: 1px solid #c9c9c9;
					}

					a {
						color: v.$color-link;

						@include r.hover {
							text-decoration: underline;
							opacity: 1;
						}
					}
				}
			}

			.nk23_c-table01__inner {
				padding-bottom: 8px;

				@include r.sp {
					padding-bottom: 4px;
				}
			}

			.dataTables_paginate {
				display: none;
			}
		}
	}

	&--style5 {
		@include r.sp {
			margin-top: -40px;
		}

		#{$root}__wrap {
			border-radius: 0;
		}

		#{$root}__inner {
			padding-top: 40px;
			padding-bottom: 0 !important;
		}

		#{$root}__table {
			@include r.pc {
				border-radius: 0 0 8px 8px;
			}

			thead {
				font-weight: 700;

				th {
					width: calc(100% / 8);
					font-size: 1.6rem;
					line-height: 1.25;
					background-color: v.$color29;
					font-weight: 700;
					color: #fff;

					@include r.sp {
						min-width: 93px;
					}
				}
			}

			tbody {
				tr {
					&:last-child {
						th,
						td {
							border-bottom: none;
						}
					}

					th {
						&:first-child {
							border-left: none;
						}
					}
				}

				th {
					width: 4.167%;
					min-width: 40px;
					line-height: 1.36;

					@include r.sp {
						width: 6%;
						min-width: 24px;
					}

					&:not([class^="nk23_u-bg-color"]) {
						background-color: v.$color28;
					}

					&[class^="nk23_u-bg-color"] {
						color: #fff;
						font-weight: 700;
					}

					&[class^="nk23_u-bg-color"] {
						color: #fff;
						font-weight: 700;
					}

					&[class^="nk23_u-bg-color0"] {
						background: v.$color29;
					}
				}

				td {
					text-align: right;
					line-height: 1.36;

					span {
						display: block;

						&:first-child {
							margin-bottom: 2px;
						}
					}
				}
			}
		}

		.nk23_c-table00__title {
			background: #ffffff;
			border-radius: 8px 8px 0 0;
			display: flex;
			justify-content: space-between;
			font-weight: 400;
			align-items: center;
			padding: 0 16px;
			height: 40px;
			position: relative;
			z-index: 0;
			bottom: -40px;

			@include r.sp {
				padding: 6px 8px 4px;
				border-radius: 0;
			}
		}

		.nk23_c-selectbox {
			color: v.$color-link;
			font-size: 1.4rem;

			select {
				margin-left: 14px;

				@include r.sp {
					margin-left: 8px;
					margin-right: 8px;
				}
			}

			@include r.sp {
				label {
					font-size: 0;
				}
			}

			label {
				a {
					@include r.hover {
						text-decoration: underline;
					}
				}
			}
		}

		.datatable-scroll {
			.dataTables_wrapper {
				bottom: inherit;
				top: 7px;
				right: 16px;

				@include r.sp {
					right: 0;
				}
			}
		}
	}

	&.is-style12 {
		#{$root}__table {
			@include r.sp {
				min-width: 100%;
				table-layout: fixed;
			}

			thead {
				th {
					&:first-child {
						width: 41px;
						min-width: auto;
						padding: 8px 4px;
					}
				}
			}

			tbody {
				th {
					font-size: 1.2rem;
					border-left: 0;
					background: #f7f7f7;
					display: flex;
					justify-content: center;

					.is-text-ver {
						text-align: center;
						letter-spacing: 2px;
						display: flex;
						writing-mode: tb;
						align-items: center;
						flex-direction: column-reverse;
					}

					span {
						display: block;
						word-break: keep-all;

						&.is-bold {
							font-weight: 700;
							font-size: 1.4rem;
						}
					}
				}

				td {
					text-align: right !important;
				}

				tr {
					&:first-child {
						td {
							font-weight: 700;
						}
					}

					&:last-child {
						th {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}

	&__foot {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.2rem;

		@include r.sp {
			padding-top: 8px;
		}

		.nk23_c-selectbox {
			&__text {
				font-size: 1.4rem;
			}
		}

		// .nk23_c-table01__foot.is--style1 .
		&.is--style1 {
			bottom: 3px;
			position: absolute;
			z-index: 0;
		}
	}

	&__bottom {
		border-top: 1px solid v.$color19;
		padding: 16px;
		background-color: #fff;
		border-radius: 0 0 8px 8px;

		@include r.sp {
			padding: 8px 16px;
		}

		.nk23_c-text01 {
			@include r.sp {
				font-size: 1.3rem;
				font-weight: 700;
			}
		}
	}

	&.is-noData {
		.nk23_c-table01__top {
			border-radius: 8px;

			@include r.sp {
				.nk23_c-table01__title__txt {
					padding-left: 48px;
					padding-bottom: 0;
				}
			}
		}
	}

	// Custom table has datatable and scrollbar
	&.has-datatable {
		@for $i from 1 through 9 {
			.nk23_c-table0#{$i} {
				&__foot {
					margin-top: -23px;

					@include r.sp {
						padding-top: 0;
						padding-right: 0;
					}
				}
			}
		}
	}

	.datatable-scroll {
		@for $i from 1 through 9 {
			.nk23_c-table0#{$i} {
				&__inner {
					overflow: unset;
					padding-bottom: 50px !important;

					@include r.sp {
						padding-bottom: 42px !important;
					}
				}
			}

			&--sp {
				.nk23_c-table0#{$i} {
					&__inner {
						padding-bottom: 38px !important;

						@include r.sp {
							padding-bottom: 42px !important;
						}
					}
				}
			}
		}

		@for $i from 10 through 30 {
			.nk23_c-table#{$i} {
				&__inner {
					overflow: unset;
					padding-bottom: 50px !important;

					@include r.sp {
						padding-bottom: 42px !important;
					}
				}
			}

			&--sp {
				.nk23_c-table0#{$i} {
					&__inner {
						padding-bottom: 38px !important;

						@include r.sp {
							padding-bottom: 42px !important;
						}
					}
				}
			}
		}
	}

	&.is-style16 {
		.nk23_c-link {
			font-weight: 700;

			@include r.max-w(320px) {
				padding-left: 4px;
			}
		}

		th,
		td {
			padding: 8px;

			@include r.sp {
				padding: 4px;
			}
		}

		.nk23_c-table01__top {
			display: flex;
			justify-content: space-between;
			background-color: #fff;
			align-items: center;
			padding: 16px;
			border-radius: 8px 8px 0 0;

			@include r.sp {
				border-radius: 0;
				padding: 12px 16px;
			}

			@include r.max-w(320px) {
				padding: 16px 8px;
			}
		}

		.nk23_c-table01__title {
			padding: 0;
		}

		.nk23_c-table01__table {
			table-layout: initial;
			width: 960px;
			border-radius: 0 0 8px 8px;

			@include r.sp {
				border-radius: 0;
			}

			td {
				text-align: center;

				&:nth-child(1) {
					@include r.sp {
						min-width: 85px;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					text-align: right !important;

					@include r.sp {
						width: 106px;
					}
				}

				&:nth-child(4),
				&:nth-child(8) {
					width: 58px;
					text-align: right;
				}

				&:nth-child(5),
				&:nth-child(9) {
					width: 157px;
					text-align: left;
				}

				.nk23_c-chart1__item {
					.is-bar {
						background-color: transparent;
					}
				}
			}
		}
	}

	&__wrap {
		& + .nk23_c-text01 {
			@include r.sp {
				margin-top: 8px;
			}
		}
	}

	.has-after {
		.is-after {
			@include r.sp {
				display: none !important;
			}
		}
	}

	&:has(.nk23_c-btn01.js-toggle-show.is-modal) {
		.has-after {
			.is-after {
				@include r.pc {
					display: none !important;
				}
			}
		}
	}

	&:has(.nk23_c-btn01.js-toggle-show.is-modal.is-active) {
		.has-after {
			.is-after {
				@include r.pc {
					display: block !important;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table02 {
	@include r.tablestyle;
	font-size: 1.4rem;
	line-height: 2rem;
	@include tablestyle02;

	&__wrap {
		border-radius: 0;
	}

	&__table {
		@include r.pc {
			border-radius: 0 0 8px 8px;
		}
	}

	thead {
		font-weight: 700;

		th {
			width: calc(100% / 8);
			font-size: 1.6rem;
			line-height: 1.25;
			background-color: v.$color29;
			font-weight: 700;
			color: #fff;

			@include r.sp {
				min-width: 93px;
			}
		}
	}

	tbody {
		tr {
			&:last-child {
				th,
				td {
					border-bottom: none;
				}
			}
		}

		th {
			width: 4.167%;
			min-width: 40px;
			line-height: 1.36;

			@include r.sp {
				width: 6%;
				min-width: 24px;
			}

			&:not([class^="nk23_u-bg-color"]) {
				background-color: v.$color28;
			}

			&[class^="nk23_u-bg-color"] {
				color: #fff;
				font-weight: 700;
			}

			&[class^="nk23_u-bg-color"] {
				color: #fff;
				font-weight: 700;
			}

			&[class^="nk23_u-bg-color0"] {
				background: v.$color29;
			}
		}

		td {
			text-align: right;
			line-height: 1.36;

			span {
				display: block;

				&:first-child {
					margin-bottom: 2px;
				}
			}
		}

		.nk23_c-table02__thead {
			background: #000;

			th {
				font-size: 1.6rem;
				line-height: 1.25;
				font-weight: 700;
				color: #fff;
				background: v.$color29;
			}
		}
	}

	&--style1 {
		thead {
			th {
				min-height: 44px;
				line-height: 44px;
			}
		}

		tbody {
			td {
				padding: 6px 8px 5px;
			}
		}
	}

	&--selectbox {
		.nk23_c-table02__title {
			display: flex;
			justify-content: space-between;
			font-weight: 400;
			align-items: center;
			padding: 7px 16px 5px;

			@include r.sp {
				padding: 6px 8px 4px;
			}
		}

		.nk23_c-selectbox {
			&__text {
				color: v.$color-link;
				margin-right: 13px;
				font-size: 1.4rem;
				cursor: pointer;

				@include r.hover {
					text-decoration: underline;
				}

				@include r.sp {
					display: none;
				}
			}
		}
	}

	&.has-datatable {
		.nk23_c-table02__title {
			display: flex;
			justify-content: space-between;
			font-weight: 400;
			align-items: center;
			padding: 0 16px;
			height: 40px;
			position: relative;
			z-index: 0;
			bottom: -40px;

			@include r.sp {
				padding: 6px 8px 4px;
			}
		}

		.nk23_c-selectbox {
			color: v.$color-link;
			font-size: 1.4rem;

			select {
				margin-left: 14px;
			}
		}

		.datatable-scroll {
			.dataTables_wrapper {
				bottom: inherit;
				top: 7px;
				right: 16px;
			}
		}

		.nk23_c-table02__inner {
			padding-top: 40px;
		}
	}

	&.is-style1 {
		.nk23_c-table02__table {
			min-width: 960px;
		}

		tbody {
			td {
				line-height: 2.77rem;

				@include r.sp {
					line-height: 1.5rem;
				}

				&:not(.nk23_u-left) {
					text-align: center;
				}

				&[class^="nk23_u-bg-"] {
					color: v.$color0;
				}
			}
		}

		thead {
			th {
				font-size: 1.4rem;
				line-height: 2.2rem;
				word-break: keep-all;
				white-space: nowrap;

				@include r.sp {
					font-size: 1.3rem;
					line-height: 2.1rem;
				}

				&:first-child {
					width: 0.01%;
					min-width: 90px;
				}

				&:nth-child(2) {
					width: 0.01%;
					min-width: 59px;
				}

				&:nth-child(3) {
					width: 0.01%;
					min-width: 33px;
				}

				// .nk23_c-table02.is-style1 thead th:last-child .
				&:last-child {
					width: 0.01%;
					min-width: 45px;
				}

				// .nk23_c-table02.is-style1 thead th:nth-last-child(2) .
				&:nth-last-child(2) {
					width: 0.01%;
					min-width: 73px;
				}

				// .nk23_c-table02.is-style1 thead th:nth-last-child(3) .
				&:nth-last-child(3) {
					width: 0.01%;
					min-width: 46px;
				}

				// .nk23_c-table02.is-style1 thead th:nth-last-child(4) .
				&:nth-last-child(4) {
					width: 0.01%;
					min-width: 59px;
				}
			}
		}
	}

	.is-h-limit {
		@include r.sp {
			.scroll-content {
				height: 60vh;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table03 {
	@include r.tablestyle;
	font-size: 1.4rem;
	line-height: 2rem;

	&.w-960 {
		.nk23_c-table03__table {
			min-width: 960px;
		}
	}

	&__table {
		background-color: #fff;
		width: 100%;
	}

	th,
	td {
		border: 1px solid v.$color19;
		line-height: 1.95;
	}

	td {
		padding: 4px 16px;
		color: v.$color-link;
		width: 22%;

		&:last-child {
			border-right: none;
		}

		&:not(:first-child) {
			border-left: none;
		}
	}

	th {
		padding: 4px 8px;
		font-weight: 700;
		width: 3%;
		min-width: 36px;

		@include r.sp {
			padding: 4px;
		}

		&:first-child {
			border-left: none;
		}

		&:not(:first-child) {
			border-right: none;
		}
	}

	tr {
		&:first-child {
			th,
			td {
				border-top: none;
			}
		}

		&:last-child {
			th,
			td {
				border-bottom: none;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table04 {
	max-width: 472px;
	$r: &;
	@include r.tablestyle02;

	tr {
		&:last-child {
			th,
			td {
				border-bottom: none;
			}
		}

		&:nth-last-of-type(2) {
			th,
			td {
				border-bottom: none;
			}
		}
	}

	// .nk23_c-table04 thead .
	thead {
		font-size: 1.2rem;
		line-height: 1.65;

		th {
			// .nk23_c-table04 thead th:nth-child(1) .
			&:nth-child(1) {
				width: 8%;
				min-width: 40px;

				@include r.sp {
					min-width: 32px;
				}
			}

			// .nk23_c-table04 thead th:nth-child(2) .
			&:nth-child(2) {
				width: 8%;
				min-width: 40px;

				@include r.sp {
					min-width: 32px;
				}
			}

			// .nk23_c-table04 thead th:nth-child(3) .
			&:nth-child(3) {
				width: 40%;

				@include r.pc {
					min-width: 210px;
				}

				@include r.sp {
					width: 41.5%;
				}
			}

			&:nth-child(4) {
				width: 15%;

				@include r.pc {
					min-width: 75px;
				}
			}
		}
	}

	tbody {
		th {
			&:first-child {
				font-weight: 700;
			}
		}
	}

	.is-small {
		font-size: 1.2rem;
	}

	&.nk23_c-table04__thead--style1 {
		thead {
			th {
				background-color: v.$color29;
				color: #fff;
				font-weight: 700;
				font-size: 1.4rem;
				padding: 5px 8px;

				@include r.sp {
					padding: 5px 2px;
					font-size: 1.3rem;
				}

				&:nth-child(1),
				&:nth-child(2) {
					@include r.pc {
						min-width: 45px;
					}
				}
			}
		}

		#{$r}__title {
			@include r.sp {
				display: block;
			}
		}
	}

	&__title {
		@include r.sp {
			padding: 12px 16px;
		}
	}

	&.is-style1 {
		margin-bottom: 32px;

		@include r.sp {
			margin-bottom: 16px;
		}

		thead {
			@include r.sp {
				font-size: 1.3rem;
			}

			th {
				background: #404040;
				color: #fff;
				font-weight: 700;

				&:nth-child(1),
				&:nth-child(2) {
					width: 9.33%;

					@include r.sp {
						width: auto;
					}
				}

				&:last-child {
					@include r.max-w(375px) {
						width: 33%;
					}
				}
			}
		}

		tbody {
			th {
				&:first-child {
					font-weight: 400;
				}
			}
		}

		td {
			padding: 8px;

			@include r.sp {
				padding: 4px;
			}
		}

		#{$r}__table {
			@include r.sp {
				font-size: 1.3rem;
			}
		}
	}

	#{$r}__title {
		@include r.sp {
			display: none;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-grtable {
	@include r.pc {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.nk23_c-table04 {
		@include r.pc {
			width: calc(50% - 8px);
			max-width: 472px;
		}

		@include r.sp {
			margin-bottom: 16px;
		}
	}

	.nk23_c-grtable05,
	.nk23_c-table06 {
		margin-top: 16px;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-grtable05 {
	width: 100%;

	&__wrap {
		@include r.pc {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.nk23_c-table05 {
		max-width: 50%;

		@include r.sp {
			max-width: 100%;
		}

		&__wrap {
			display: flex;
		}

		&:nth-child(even) {
			thead {
				th {
					&:first-child {
						@include r.pc {
							border-left: 1px solid v.$color19;
						}
					}
				}
			}

			tbody {
				th {
					&:first-child {
						@include r.pc {
							border-left: 1px solid v.$color19;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table05 {
	width: 100%;
	max-width: 472px;
	@include r.tablestyle02;

	&__wrap {
		border-radius: 0;
	}

	thead {
		font-weight: 700;

		th {
			width: 25%;
			font-size: 1.6rem;
			line-height: 1.2;
		}
	}

	tbody {
		tr {
			&:last-child {
				th,
				td {
					border-bottom: none;
				}
			}
		}

		th {
			width: 8%;
			min-width: 40px;

			@include r.sp {
				width: 6%;
				min-width: 24px;
			}

			&:not([class^="nk23_u-bg-color"]) {
				background-color: v.$color28;
			}

			&[class^="nk23_u-bg-color"] {
				color: #fff;
				font-weight: 700;
			}
		}

		td {
			text-align: right;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table06 {
	width: 100%;
	max-width: 472px;
	$r: &;
	@include r.tablestyle02;

	td {
		text-align: right;

		@include r.sp {
			font-size: 1.3rem;
		}
	}

	th {
		text-align: center;
		font-size: 1.3rem;

		&:not(:first-child) {
			width: 27%;
		}

		@include r.sp {
			font-size: 1.2rem;
			line-height: 1.6;
		}
	}

	thead {
		font-weight: 700;

		@include r.pc {
			line-height: 1.5;
		}

		th {
			background-color: v.$color34;
		}
	}

	tbody {
		th {
			background-color: v.$color29;
			color: #ffffff;
			font-weight: 700;
		}
	}

	tr {
		&:last-child {
			th,
			td {
				border-bottom: none;
			}
		}
	}

	&.is-style1 {
		th {
			background: #404040;
			color: #fff;
			font-weight: 700;

			&:not(:first-child) {
				width: calc(100% / 3);
			}
		}

		td {
			text-align: center;
			padding: 8px;

			@include r.sp {
				padding: 4px;
			}
		}

		tr {
			&:last-child {
				td {
					@include r.sp {
						border-bottom: 1px solid #d2d2d2;
					}
				}
			}

			td {
				&:last-child {
					text-align: right;

					@include r.sp {
						padding-right: 16px;
					}
				}
			}
		}

		#{$r}__title {
			@include r.sp {
				display: none;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table07 {
	border: 1px solid v.$color23;
	border-radius: 4px;
	overflow: hidden;
	background: #fff;

	table {
		width: 100%;
		font-size: 1.4rem;
		line-height: 20px;

		tr {
			border-top: 1px solid v.$color23;
			border-bottom: 1px solid v.$color23;

			&:first-child {
				border-top: none;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		th,
		td {
			padding: 8px 16px;
			text-align: left;
			border-left: none;
			border-right: none;

			@include r.sp {
				padding: 4px;
			}
		}

		th {
			font-weight: bold;
			width: 95px;
		}

		td {
			padding-left: 0;
		}
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table08 {
	@include r.tablestyle;
	$root: &;

	&__table {
		background: #fff;

		@include r.sp {
			min-width: 1200px;
		}

		thead {
			font-size: 1.2rem;
			line-height: 14px;
			color: v.$color0;
			font-weight: bold;

			tr {
				background: v.$color34;

				th {
					&:last-child {
						border-right: 0;
					}
				}

				&:first-child {
					background: v.$color29;
					color: #fff;

					th {
						width: 10%;
						padding-bottom: 8px;

						&:first-child {
							width: 1%;

							@include r.sp {
								border-left: none;
							}
						}

						&:nth-child(2) {
							width: 1%;
						}

						&:nth-child(3) {
							width: 13%;
							text-align: left;
							word-break: keep-all;
						}
					}
				}

				&:nth-child(2) {
					th {
						line-height: 18px;
					}
				}
			}

			.nk23_c-btn02 {
				width: 80px;
				max-width: 100%;
				margin: 4px auto 0;
				font-weight: 400;
				min-height: 28px;

				@include r.sp {
					padding: 4px;
					border-radius: 24px;
				}
			}

			#{$root}__heading {
				.nk23_c-btn02 {
					width: 35px;
					min-height: 18px;
					position: absolute;
					right: 8px;
					margin-top: 0;

					@include r.sp {
						padding: 0 4px;
					}

					&__icon {
						width: 16px;
						margin: 0 auto;
					}
				}
			}
		}

		th,
		td {
			text-align: center;
			border: 1px solid v.$color19;
			padding: 4px 2px;
		}

		th {
			font-weight: bold;
		}

		tbody {
			th {
				font-size: 1.2rem;

				&:first-child {
					@include r.sp {
						border-left: none;
					}
				}
			}

			tr {
				&:last-child {
					td {
						border-bottom: 0;
					}
				}

				td {
					&:nth-child(1),
					&:nth-child(2) {
						font-size: 1.2rem;
					}

					&:last-child {
						border-right: 0;
					}
				}
			}
		}
	}

	&__heading {
		text-align: left;
		position: relative;
	}

	&__text {
		font-size: 1.6rem;
		line-height: 27px;
		color: v.$color-link;

		&:hover {
			text-decoration: underline;
		}
	}

	&__text2 {
		font-size: 1.2rem;
		line-height: 19px;
		color: v.$color-link;
		margin-top: 4px;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}

	&__number {
		font-size: 1.2rem;
		line-height: 19px;
		color: v.$color0;

		@include r.sp {
			font-size: 1.1rem;
		}

		span {
			font-size: 1.9rem;
			font-weight: bold;
			position: relative;
			top: 1.5px;

			@include r.sp {
				top: 2px;
			}
		}
	}

	&__text3 {
		font-size: 1.2rem;
		line-height: 1.9;
		color: v.$color-link;
		margin-top: 4px;

		&:hover {
			text-decoration: underline;
		}
	}

	&__icon {
		top: 160px;
		transform: translate(-50%, 0);
	}

	[class^="nk23_u-bg-color"] {
		color: #fff;
	}

	.is-left {
		text-align: left;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table09 {
	$r: &;

	&.w440 {
		width: 440px;
		margin: auto;

		@include r.sp {
			width: 100%;
		}
	}

	&__title {
		font-size: 1.4rem;
		text-align: center;
		background: #404040;
		color: #fff;
		padding: 8px 0;
		font-weight: bold;
		line-height: 18.2px;
		border-radius: 8px 8px 0 0;
		border-top: 0;

		@include r.sp {
			font-size: 1.3rem;
		}

		&--style2 {
			border-radius: 0;
			border-top: 1px solid #c9c9c9;
		}
	}

	&__wrap {
		background: #fff;
		overflow: hidden;
		border-radius: 0 0 8px 8px;
		border: 1px solid #c9c9c9;
	}

	&__table {
		width: 100%;
		font-size: 1.4rem;
		line-height: 18.2px;
		box-sizing: border-box;

		@include r.sp {
			font-size: 1.3rem;
		}

		thead {
			th {
				background: #f7f7f7;

				@include r.sp {
					width: 81%;
					padding: 4px 2px;
				}

				&:first-child {
					@include r.max-w(375px) {
						width: calc(100% - 66px);
					}
				}

				&:last-child {
					@include r.max-w(375px) {
						width: 66px;
					}
				}

				&:after {
					content: "";
					background: url(p.$path + "images/common/icon/icon_sort.svg") no-repeat;
					display: inline-block;
					width: 8px;
					height: 14px;
					vertical-align: -2px;
					margin-left: 4px;
				}

				&.no-sort {
					&:after {
						height: 7px;
						background-position: 0 -7px;
						vertical-align: 0;
					}
				}

				&.sorting {
					cursor: pointer;
				}

				&.sorting_desc {
					&:after {
						background: url(p.$path + "images/common/icon/icon-asc.svg") no-repeat center bottom;
					}
				}

				&.sorting_asc {
					&:after {
						background: url(p.$path + "images/common/icon/icon-desc.svg") no-repeat center top;
					}
				}
			}
		}

		tr {
			th,
			td {
				padding: 8px;
				border-top: 1px solid #c9c9c9;
				border-right: 1px solid #c9c9c9;

				@include r.sp {
					padding: 4px;
				}

				&:last-child {
					border-right: 0;
				}
			}

			td {
				text-align: center;

				&:last-child {
					text-align: right;
				}
			}
		}
	}

	&__dash {
		position: relative;

		&:after {
			content: "";
			width: 8px;
			height: 1px;
			background: #000;
			display: inline-block;
			margin: 0 4px;
			vertical-align: 4px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	th {
		&.sorting_asc,
		&.sorting_desc {
			background-color: v.$color19 !important;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table10 {
	width: 100%;
	background: #fff;
	border-radius: 8px;
	overflow: hidden;

	thead {
		text-align: center;
		background: v.$color29;

		th {
			font-size: 1.4rem;
			line-height: 18px;
			color: #ffffff;
			font-weight: bold;
			padding: 4px;

			@include r.sp {
				font-size: 1.3rem;
				padding: 4px 2px;
			}

			&:nth-child(1) {
				width: 6.5%;
				min-width: 30px;

				@include r.sp {
					width: 6%;
					min-width: auto;
				}
			}

			&:nth-child(2) {
				width: 6.5%;
				min-width: 30px;

				@include r.sp {
					width: 6.7%;
					min-width: auto;
				}
			}

			&:nth-child(3) {
				width: 40%;
				padding-left: 8px;

				@include r.pc {
					min-width: 210px;
				}

				@include r.sp {
					width: 38.6%;
					padding-left: 4px;
				}
			}

			&:nth-child(4) {
				width: 8.7%;

				@include r.sp {
					width: 9.2%;
				}
			}

			&:nth-child(5) {
				width: 8.7%;

				@include r.sp {
					width: 9.2%;
				}
			}

			&:nth-child(6) {
				width: 8.7%;

				@include r.sp {
					width: 9.2%;
				}
			}

			&:nth-child(7) {
				width: 12.8%;

				@include r.sp {
					width: 14.5%;
				}
			}
		}
	}

	tbody {
		th {
			font-weight: 700;
		}

		tr {
			&:last-child {
				th,
				td {
					border-bottom: none;
				}
			}
		}
	}

	th,
	td {
		border: 1px solid v.$color19;
		text-align: center;
		font-size: 1.4rem;
		line-height: 18px;
		padding: 4px 8px;

		@include r.sp {
			font-size: 1.3rem;
			padding: 4px 2px;
		}

		&:first-child {
			border-left: none;
		}

		&:last-child {
			border-right: none;
		}
	}

	.is-left {
		text-align: left;
	}

	.is-right {
		text-align: right;
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	[class^="nk23_u-bg-color"] {
		color: #fff;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table11 {
	&__wrap {
		position: relative;
		// max-width: max-content;
		margin: 0 auto;
		border-radius: 8px 8px 0 0;

		@include r.sp {
			margin: 0;
		}

		.nk23_c-link {
			&.is-help {
				margin-top: 8px;
			}
		}
	}

	// .nk23_c-table11__btnG .
	&__btnG {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;

		@include r.sp {
			background-color: #fff;
		}

		.nk23_l-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 16px 32px;

			@include r.sp {
				padding: 8px;
			}
		}

		.nk23_c-btn04 {
			margin-left: 16px;

			@include r.sp {
				margin-left: 8px;
			}
		}

		&.is-style1 {
			.nk23_l-container {
				align-items: flex-end;
			}

			.nk23_c-switch1__wrap {
				@include r.pc {
					margin-bottom: 2px;
				}
			}
		}
	}

	&__inner {
		padding: 0 40px 16px;
		width: 100%;

		@include r.sp {
			padding: 0 0 9px 0;
		}

		.scrollbar-track-x {
			background: #fff;
			opacity: 1;
			overflow: hidden;
			border-radius: 4px;
			height: 4px;

			// @include r.pc {
			//   width: calc(100% - 80px);
			//   right: 40px;
			//   left: 40px;
			// }
			// @include r.sp {
			//   width: calc(100% - 16px);
			//   right: 8px;
			//   left: 8px;
			// }
			.scrollbar-thumb {
				background: v.$color19;
				height: 4px;
			}
		}
	}

	&__tbcontent {
		padding-top: 72px;
		background: #fff;
		width: 1547px;
		min-width: max-content;

		margin: 0 auto;
		border-radius: 8px 8px 0 0;

		@include r.sp {
			border-radius: 0;
			padding-top: 48px;
		}

		// .nk23_c-table11__tbcontent.is-style1 .
		&.is-style1 {
			padding-top: 88px;

			@include r.sp {
				padding-top: 73px;
			}
		}
	}

	&__table {
		width: 100%;
		// max-width: max-content;
		// min-width: 1527px;
		margin: 0 auto;
		background: #ffffff;

		th,
		td {
			border: 1px solid v.$color19;
			text-align: center;
			font-size: 1.2rem;
			line-height: 14px;
			width: auto;
			min-width: max-content;
			max-width: max-content;
			word-break: keep-all;
			white-space: nowrap;
			padding: 4px;
			transition: 0.3s;

			// &:hover {
			// 	background-color: v.$color58;
			// }

			// .is-txtV,
			.is-link {
				vertical-align: text-top;
				display: inline-block;
				line-height: 1.2;
				text-align: center;
				writing-mode: vertical-rl;
				text-orientation: upright;
				word-break: keep-all;
				white-space: nowrap;
			}

			.is-link {
				color: v.$color-link;
				font-size: 1.6rem;

				@include r.hover {
					text-decoration: none;
				}
			}

			a {
				color: v.$color-link;

				@include r.hover {
					text-decoration: underline;
					opacity: 1;
				}
			}

			@include r.sp {
				padding: 4px 2px;
			}
		}

		th {
			background: v.$color29;
			color: #ffffff;
			font-weight: bold;
			// width: 86px;
		}

		.is-waku {
			th,
			td {
				font-weight: bold;
				text-align: center;
			}
		}

		.is-uma {
			th,
			td {
				font-weight: bold;
				text-align: center;
			}
		}

		.is-tx_tohaba {
			th,
			td {
				text-align: center;
			}

			td {
				vertical-align: top;
			}

			.is-txtV {
				vertical-align: text-top;
				display: inline-block;
				line-height: 1.2;
				text-align: center;
				writing-mode: vertical-rl;
				text-orientation: upright;
				word-break: keep-all;
				white-space: nowrap;
				&:first-child {
					margin-top: 16px;
					font-size: 1.1rem;
				}
			}

			th {
				vertical-align: top;

				.is-txtV {
					&:first-child {
						font-size: 1.2rem;
					}
				}
			}
		}

		.is-kishu {
			th,
			td {
				text-align: center;
			}

			td {
				font-size: 1rem;
				line-height: 12px;

				.is-link1 {
					font-size: 1.2rem;

					@include r.hover {
						text-decoration: underline;
						opacity: 1;
					}
				}
			}
		}

		.is-weight,
		.is-odds,
		.is-seirei {
			td {
				text-align: center;
			}
		}

		.is-time,
		.is-numsec,
		.is-zenso1 {
			th {
				// width: 70px;
				word-break: keep-all;
				white-space: nowrap;

				&:first-child,
				&:last-child {
					// width: 16px;
					padding: 4px 2px;
					word-break: break-all;
				}
			}
		}

		.is-time {
			td {
				word-break: keep-all;
			}
		}

		.is-zenso {
			&1,
			&2,
			&3,
			&4,
			&5 {
				td {
					letter-spacing: 0;
					vertical-align: top;

					.is-chaku {
						font-size: 1.6rem;
						font-weight: bold;
					}
				}
			}

			&2,
			&3,
			&4,
			&5 {
				th {
					width: 70px;
				}
			}

			&__bg1 {
				background: #f2d1ce;
			}

			&__bg2 {
				background: #dfe8f7;
			}

			&__bg3 {
				background: #fff9e6;
			}
		}

		.is-numsec {
			th {
				&:first-child,
				&:last-child {
					min-width: 18px;
				}
				&:nth-child(2),
				&:nth-child(19) {
					@include r.pc {
						min-width: 78px;
					}
				}
				&.is-numsec__col {
					@include r.sp {
						min-width: 73px;
					}
				}

				.is-txtV {
					letter-spacing: 2px;
					line-height: 0.8;

					&--style1 {
						letter-spacing: 0.8px;
						margin-top: 1px;
					}

					&--style2 {
						text-indent: 1.4rem;
					}
				}
			}

			&__wrap {
				display: flex;
				justify-content: flex-start;
			}

			&__item {
				text-align: right;
				min-width: 14px;
				margin: 0 1px;
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}

			&__n1 {
				color: v.$color13;
			}

			&__n2 {
				color: v.$color14;
			}

			&__n3 {
				color: v.$color16;
			}
		}

		.u-txtLine {
			letter-spacing: -1px;
		}

		[class^="nk23_u-bg-color"] {
			color: #fff;
			font-weight: bold;
		}

		td {
			text-align: left;
		}
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	&__tabcontent {
		padding-top: 88px;
		background: #fff;

		@include r.pc {
			border-radius: 8px 8px 0 0;
			max-width: 1527px;
			width: 100%;
			margin: 0 auto;
		}

		@include r.sp {
			padding-top: 73px;
		}
	}

	td.is-letter-05 {
		@include r.pc {
			letter-spacing: -0.05rem;
		}
	}

	th {
		&:first-child {
			@include r.sp {
				border-left: none;
			}
		}

		&:last-child {
			@include r.sp {
				border-right: none;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table12 {
	@include r.tablestyle;
	font-size: 1.4rem;
	line-height: 1;

	@include r.sp {
		overflow: hidden;
		position: relative;
		font-size: 1.3rem;
	}

	.custom-scrollbar-icon {
		@include r.sp {
			top: 160px;
			transform: translateY(0) translateX(-50%);
		}
	}

	&__table {
		width: 100%;
		background-color: #fff;
		border-collapse: separate;
		border-spacing: 0;
		overflow: unset;

		&.is-hide {
			thead {
				tr {
					&:last-child {
						td {
							border-bottom: 1px solid v.$color19;
						}
					}
				}

				@include r.sp {
					opacity: 0;
				}
			}
		}
	}

	&__inner {
		padding-bottom: 0;

		@include r.pc {
			overflow: unset;
		}

		@include r.sp {
			border-radius: 8px;
		}
	}

	&__topfixed {
		position: fixed;
		top: 0;
		z-index: 2;

		@include r.pc {
			max-width: 928px;
			min-width: 928px;
			pointer-events: none;
			opacity: 0;
		}

		@include r.sp {
			overflow-x: auto;
			top: 44px;
			left: 8px;
			width: calc(100% - 16px);
			pointer-events: none;
		}

		a {
			@include r.pc {
				pointer-events: none;
			}
		}

		&.is-active {
			@include r.pc {
				pointer-events: all;
				opacity: 1;
			}

			a {
				@include r.pc {
					pointer-events: all;
				}
			}

			.nk23_c-table12 {
				&__table {
					@include r.sp {
						transform: translateY(0);

						thead {
							tr {
								&:last-child {
									td {
										border-bottom: 1px solid v.$color19;
									}
								}
							}
						}
					}
				}
			}
		}

		/* width */
		&::-webkit-scrollbar {
			width: 0;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: transparent;
		}

		.nk23_c-table12 {
			&__table {
				@include r.pc {
					max-width: 928px;
					min-width: 928px;
				}

				@include r.sp {
					min-width: 680px;
					width: 100%;
					transition: 0.3s ease-in-out;
					transform: translateY(-100%);
				}

				tbody {
					@include r.sp {
						display: none;
					}
				}

				thead {
					@include r.sp {
						pointer-events: none;
					}

					th {
						@include r.sp {
							padding: 8px 4px;
							line-height: 1.155;
						}
					}

					td {
						@include r.sp {
							height: 32px;
						}
					}
				}
			}
		}

		th,
		td {
			@include r.pc {
				line-height: 1.643;
			}
		}

		thead {
			tr {
				&:last-child {
					td {
						@include r.pc {
							border-bottom: 1px solid v.$color19 !important;
						}
					}
				}
			}
		}
	}

	thead {
		@include r.pc {
			// position: sticky;
			// top: 0;
			// left: 0;
			// z-index: 1;
		}

		// .nk23_c-table12 thead th .
		th {
			background-color: v.$color29;
			color: #fff;
			font-weight: 700;
			border-top: none;
			word-break: keep-all;
			white-space: nowrap;

			@include r.sp {
				border-bottom: none;
				border-left: none;
			}

			&:first-child {
				width: 3%;
				min-width: 209px;

				@include r.sp {
					min-width: 100px;
					left: 0;
					position: sticky;
					z-index: 1;
					border-top-left-radius: 8px;
				}

				&:after {
					width: 1px;
					height: 100%;
					top: 0;
					right: -1px;
					background-color: v.$color19;
					position: absolute;

					@include r.sp {
						// content: '';
					}
				}
			}

			&:nth-child(2) {
				width: 4%;
				min-width: 61px;

				@include r.sp {
					min-width: 48px;
					left: 100px;
					position: sticky;
					z-index: 1;
				}

				&:after {
					width: 1px;
					height: 100%;
					top: 0;
					right: -1px;
					background-color: v.$color19;
					position: absolute;

					@include r.sp {
						// content: '';
					}
				}
			}

			&:nth-last-child(2) {
				width: 4%;
				min-width: 61px;

				@include r.sp {
					min-width: 48px;
				}
			}

			&:last-child {
				width: 4%;
				min-width: 108px;

				@include r.pc {
					border-right: none;
				}

				@include r.sp {
					min-width: 100px;
					border-top-right-radius: 8px;
				}
			}
		}

		// .nk23_c-table12 thead td .
		td {
			min-width: 40px;
			background-color: #fff;

			@include r.pc {
				width: 1%;
				padding-left: 4px;
				padding-right: 4px;
			}

			@include r.sp {
				min-width: 32px;
				border-left: none;
				border-bottom: none;
			}

			&:last-child {
				@include r.pc {
					border-right: none;
				}
			}
		}

		tr {
			&:last-child {
				td {
					@include r.pc {
						border-bottom: none;
					}
				}
			}
		}
	}

	tbody {
		td {
			word-break: keep-all;
			white-space: nowrap;

			&:first-child {
				text-align: left;

				@include r.sp {
					left: 0;
					position: sticky;
					z-index: 1;
					background-color: #fff;
				}

				&:after {
					width: 1px;
					height: 100%;
					top: 0;
					right: -1px;
					background-color: v.$color19;
					position: absolute;

					@include r.sp {
						// content: '';
					}
				}
			}

			&:nth-child(2) {
				@include r.sp {
					left: 100px;
					position: sticky;
					z-index: 1;
					// background-color: #fff;
				}

				&:after {
					width: 1px;
					height: 100%;
					top: 0;
					right: -1px;
					background-color: v.$color19;
					position: absolute;

					@include r.sp {
						// content: '';
					}
				}

				&:not([class^="is-bg"]) {
					@include r.sp {
						background-color: #fff;
					}
				}
			}

			@include r.sp {
				border-left: none;
				border-bottom: none;
			}
		}

		tr {
			&:last-child {
				td {
					border-bottom: none;

					&:first-child {
						@include r.sp {
							border-bottom-left-radius: 8px;
						}
					}

					&:last-child {
						@include r.sp {
							border-bottom-right-radius: 8px;
						}
					}
				}
			}
		}
	}

	// .nk23_c-table12 td, .nk23_c-table12 th .
	td,
	th {
		padding: 4px 8px;
		vertical-align: middle;
		text-align: center;

		@include r.pc {
			border-right: 1px solid v.$color19;
			border-top: 1px solid v.$color19;
		}

		@include r.sp {
			padding: 4px;
			border: 1px solid v.$color19;
		}

		&:first-child {
			border-left: none;
		}

		&:last-child {
			border-right: none;
		}
	}

	td {
		&:last-child {
			@include r.pc {
				border-right: none;
			}
		}

		img {
			display: inline;
		}
	}

	.is-red {
		color: v.$color13;
	}

	.is-bg1 {
		background-color: v.$color3;
	}

	.is-bg2 {
		background-color: v.$color6;
	}

	.is-bg3 {
		background-color: v.$color3;
	}

	.is-bg4 {
		background-color: v.$color9;
	}

	.is-bg5 {
		background-color: v.$color12;
	}

	strong,
	b {
		font-weight: 700;
	}

	// .nk23_c-table12 .nk23_c-btn01 .
	.nk23_c-btn01 {
		margin-bottom: 2px;
		min-height: 28px;
	}

	[data-scrollbar] {
		@include r.pc {
			overflow: unset !important;
		}

		@include r.sp {
			padding-bottom: 16px;
			font-size: 1.4rem;
			line-height: 2.3rem;
			width: 100%;
			overflow-y: hidden;
			overflow-x: auto;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table13 {
	position: relative;

	@include r.sp {
		.nk23_l-container {
			padding: 0;
		}
	}

	&__wrap {
		position: relative;
	}

	&__inner {
		padding-bottom: 8px;
		width: 100%;
		overflow-y: hidden;
		overflow-x: auto;
	}

	&__table {
		table-layout: fixed;
		overflow: hidden;
		min-width: 960px;

		@include r.pc {
			border-radius: 0 0 8px 8px;
		}

		thead {
			font-size: 1.4rem;
			line-height: 1.5rem;
			font-weight: bold;
			color: #221f1f;

			* {
				font-size: 1.4rem;
				line-height: 1.5rem;
				font-weight: bold;
			}

			.small {
				font-size: 1.2rem;
				line-height: 1.25;
			}

			.large {
				font-size: 1.6rem;
				line-height: 1.5;
			}

			.nk23_c-checkbox3 {
				input {
					width: 16px;
					height: 16px;
				}

				input:checked {
					border-color: #ffffff;
				}
			}

			th {
				background-color: #404040;
				color: #ffffff;
				line-height: 1.3;
			}

			td {
				background-color: #f7f7f7;
				text-align: center;
			}
		}

		thead {
			td[data-column] {
				min-width: 76px;
			}

			th[data-column] {
				&:after {
					filter: brightness(0) invert(1);
				}
			}

			td[data-column],
			th[data-column] {
				padding-bottom: 14px;
				cursor: pointer;
				background-image: url(p.$path + "images/common/icon/icon-desc.svg"),
					url(p.$path + "images/common/icon/icon-asc.svg");
				background-position:
					bottom 12px center,
					bottom 4px center;
				background-size:
					8px 6px,
					8px 6px;
				background-repeat: no-repeat, no-repeat;

				&.sort-desc {
					background-image: url(p.$path + "images/common/icon/icon-desc.svg");
					background-position: bottom 12px center;
					background-size: 8px 6px;
					background-repeat: no-repeat;
				}

				&.sort-asc {
					background-image: url(p.$path + "images/common/icon/icon-asc.svg");
					background-position: bottom 4px center;
					background-size: 8px 6px;
					background-repeat: no-repeat;
				}
			}

			th[data-column] {
				background-image: url(p.$path + "images/common/icon/icon-desc-white.svg"),
					url(p.$path + "images/common/icon/icon-asc-white.svg");

				&.sort-desc {
					background-image: url(p.$path + "images/common/icon/icon-desc-white.svg");
				}

				&.sort-asc {
					background-image: url(p.$path + "images/common/icon/icon-asc-white.svg");
				}
			}

			td.sort-asc,
			td.sort-desc {
				background-color: #c9c9c9 !important;
			}

			tr {
				&:nth-child(1) {
					th {
						&:nth-child(2) {
							@include r.sp {
								min-width: 25px;
							}
						}

						&:nth-child(3) {
							@include r.sp {
								min-width: 33px;
							}
						}
					}
				}

				&:nth-child(2) {
					td {
						&:nth-child(3) {
							@include r.sp {
								width: 10%;
								min-width: 242px;
							}
						}
					}
				}
			}
		}

		tbody {
			font-size: 1.4rem;
			line-height: 1.7rem;
			background-color: #ffffff;
			color: #221f1f;

			td {
				text-align: center;

				&[class*="nk23_u-bg-"] {
					color: #ffffff;
				}
			}
		}

		td,
		th {
			border: 1px solid #c9c9c9;
			border-bottom: 0;
			border-left: 0;
			padding: 4px 4px;

			&:last-child {
				border-right: 0;
			}
		}

		.width1 {
			width: 2%;

			@include r.sp {
				min-width: 23px;
			}
		}

		.width2 {
			width: 20%;

			@include r.sp {
				min-width: 204px;
			}
		}

		.width3 {
			width: 5.5%;
			min-width: 54px;

			@include r.sp {
				min-width: 56px;
			}
		}

		.width4 {
			width: 13%;
			min-width: 168px;
		}

		.width-column {
			min-width: 70px;
		}

		.text-center {
			text-align: center;
		}

		.text-left {
			text-align: left;
		}

		.text-right {
			text-align: right;
		}

		strong {
			font-weight: bold;
		}

		.nowrap {
			white-space: nowrap;
			word-break: keep-all;
		}

		&.nk23_u-minw1200 {
			thead {
				tr {
					&:first-child {
						th {
							&:nth-child(3) {
								@include r.sp {
									min-width: 168px;
								}
							}
						}
					}

					&:nth-child(2) {
						td {
							&:nth-child(3) {
								@include r.sp {
									min-width: 72px;
								}
							}
						}
					}
				}
			}
		}
	}

	&__color {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&__item {
			display: flex;
			align-items: center;
			gap: 4px;
			white-space: nowrap;
			word-break: keep-all;
		}

		&__box {
			width: 20px;
			height: 20px;
			display: inline-block;
		}
	}

	&__text1 {
		font-size: 1.2rem;
		line-height: 1.68rem;
	}

	&__text2 {
		color: #2461ee;
		font-size: 1.6rem;
		line-height: 1;
	}

	&__icon {
		display: none;

		@include r.sp {
			display: block;
			top: 88px;
			position: absolute;
			transform: translateX(-50%);
			left: 50%;
			z-index: 2;
			pointer-events: none;
			transition:
				opacity 0.3s ease-in-out,
				visibility 0.3s ease-in-out;
			opacity: 0;
			visibility: hidden;
		}

		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	&__para + &__para {
		margin-top: 4px;
	}

	&__bg {
		background-color: #f2f2f2;
	}

	&__bg2 {
		background-color: #f8f8f8;
	}

	&__gtext + &__gtext {
		margin-top: 4px;
	}

	.is-red {
		color: v.$color13;
	}

	.is-blue {
		color: v.$color14;
	}

	.scroll {
		&bar-thumb {
			background-color: v.$color19;
			transition: none !important;

			@include r.sp {
				height: 4px;
			}
		}

		&-content {
			position: relative;
		}

		&bar-track {
			border-radius: 20px;

			&-x {
				opacity: 1;
				background-color: #fff;

				@include r.sp {
					height: 4px;
					overflow: hidden;
				}
			}
		}
	}

	// .nk23_c-table13__topfixed .
	&__topfixed {
		@include r.sp {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			pointer-events: none;
			overflow: hidden;
		}

		&.is-active {
			@include r.sp {
				pointer-events: all;
			}

			.nk23_c-table13 {
				&__table {
					@include r.sp {
						transform: translateY(0);
					}

					thead {
						@include r.sp {
							pointer-events: all;
						}
					}
				}
			}

			input[type="checkbox"] {
				@include r.sp {
					pointer-events: all !important;
				}
			}
		}

		/* width */
		&::-webkit-scrollbar {
			width: 0;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: transparent;
		}

		.nk23_c-table13 {
			// .nk23_c-table13__topfixed .nk23_c-table13__cell .
			&__cell {
				@include r.sp {
					padding-top: 0 !important;
					padding-bottom: 0 !important;
				}

				// .nk23_c-table13__topfixed .nk23_c-table13__cell+.nk23_c-table13__cell .
				& + .nk23_c-table13__cell {
					@include r.sp {
						margin-top: 8px;
					}
				}
			}

			&__color {
				@include r.sp {
					padding: 4px 0;
					gap: 0;
				}

				&__item {
					@include r.sp {
						font-size: 1.3rem;
					}

					// .nk23_c-table13__topfixed .nk23_c-table13__color__item:not(:last-child) .
					&:not(:last-child) {
						@include r.sp {
							margin-bottom: 2px;
						}
					}
				}
			}

			&__table {
				@include r.sp {
					transition: 0.3s ease-in-out;
					transform: translateY(-100%);
				}

				tbody {
					@include r.sp {
						display: none;
					}
				}

				thead {
					@include r.sp {
						pointer-events: none;
						border-bottom: 1px solid v.$color19;
					}

					th {
						@include r.sp {
							font-size: 1.3rem;
						}
					}

					tr {
						&:nth-child(1) {
							th {
								&:nth-child(3) {
									@include r.sp {
										font-size: 1.2rem;
									}
								}
							}

							th {
								&:nth-child(4) {
									@include r.sp {
										font-size: 1.4rem;
									}
								}
							}
						}

						&:nth-child(2) {
							td {
								@include r.sp {
									font-size: 1.3rem;
								}
							}
						}
					}
				}
			}

			&__gtext {
				// .nk23_c-table13__topfixed .nk23_c-table13__gtext .is-small .
				.is-small {
					@include r.sp {
						font-size: 1.2rem !important;
					}
				}
			}
		}

		.nk23_c-checkbox1 {
			// .nk23_c-table13__topfixed .nk23_c-checkbox1__text .
			&__text {
				@include r.sp {
					font-size: 1.2rem;
				}
			}
		}

		.nk23_c-checkbox3 {
			@include r.sp {
				padding: 0 2px;
			}

			// .nk23_c-table13__topfixed .nk23_c-checkbox3__label .
			&__label {
				@include r.sp {
					padding-bottom: 0;
				}

				// .nk23_c-table13__topfixed .nk23_c-checkbox3__label:has(input[type=checkbox]) .
				&:has(input[type="checkbox"]) {
					@include r.sp {
						padding-bottom: 20px;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table14 {
	font-size: 1.4rem;
	@include r.tablestyle;

	.nk23_l-container {
		padding: 0 16px;

		@include r.sp {
			padding: 0;
		}
	}

	&__inner {
		font-size: 1.4rem;
		width: 100%;
		padding-bottom: 8px;
	}

	&__table {
		width: 100%;
		border-radius: 8px;
		background: #fff;
		overflow: hidden;
		min-width: 960px;

		@include r.sp {
			border-radius: 0;
		}

		thead {
			th,
			td {
				line-height: 14px;
				color: #fff;
				padding: 8px;
				text-align: center;
			}

			th {
				background: #404040;
				font-weight: 700;
			}

			td {
				background: #f7f7f7;
				color: #221f1f;
				font-weight: normal;
			}
		}

		tbody {
			tr {
				&:last-child {
					td {
						border-bottom: 0;
					}
				}

				th {
					font-weight: bold;
					width: 31px;
				}

				td {
					text-align: right;
					padding: 8px;
					line-height: 21px;
					width: 33px;

					&[class^="nk23_u-center"] {
						width: 45px;
					}

					&[class^="nk23_u-left"] {
						width: 224px;
					}
				}
			}
		}

		th,
		td {
			border: 1px solid #c9c9c9;

			&:first-child {
				border-left: 0;
			}

			&:last-child {
				border-right: 0;
			}
		}

		[class^="nk23_u-bg-color"] {
			color: #fff;
		}

		&.is-center {
			text-align: center;
		}
	}

	&__note {
		font-size: 1.2rem;
		line-height: 1.9rem;

		@include r.sp {
			padding: 8px 8px 0;
		}
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table15 {
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	text-align: center;
	$root: &;

	@include r.sp {
		border-radius: 0;
	}

	&__heading {
		padding: 16px;

		@include r.sp {
			padding: 8px;
		}
	}

	&__note {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		&__ttl {
			font-size: 1.4rem;
			line-height: 1.6;
			color: v.$color0;
			margin-right: 8px;

			@include r.sp {
				font-size: 1.2rem;
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
		}

		&__item {
			font-size: 1.4rem;
			line-height: 1.6;
			color: v.$color0;
			position: relative;
			padding-left: 20px;
			display: flex;
			align-items: center;

			@include r.sp {
				font-size: 1.2rem;
				padding-left: 18px;
			}

			&::before {
				content: "";
				width: 16px;
				height: 16px;
				background: v.$color47;
				border: 1px solid v.$color19;
				position: absolute;
				top: 3px;
				left: 0;

				@include r.sp {
					width: 14px;
					height: 14px;
				}
			}

			&:not(:last-child) {
				margin-right: 8px;
			}

			&.is-color1 {
				&::before {
					background: v.$color48;
				}
			}

			&.is-color2 {
				&::before {
					background: v.$color49;
				}
			}
		}
	}

	&__wrap {
		table {
			width: 100%;
			font-size: 1.4rem;
			line-height: 1.5;
			table-layout: fixed;

			th,
			td {
				border: 1px solid v.$color46;
				padding: 4px 8px;

				@include r.sp {
					padding: 4px;
				}

				&:first-child {
					border-left: none;
					width: 45px;

					@include r.sp {
						width: 40px;
					}
				}

				&:last-child {
					border-right: none;
				}
			}

			td {
				border-bottom: none;
			}

			th {
				padding: 4px 2px;
			}

			thead {
				th {
					background: v.$color29;
					color: #fff;
					font-weight: bold;
					border-top: none;
				}
			}

			tbody {
				td {
					color: v.$color0;
				}

				th {
					background: v.$color34;
					border-bottom: none;
				}
			}
		}
	}

	&__box {
		@include r.pc {
			display: flex;
		}

		@include r.sp {
			position: relative;
		}

		&.is-block-sp {
			@include r.sp {
				display: block;
			}
		}
	}

	&__ttl {
		font-size: 1.6rem;
		text-align: left;

		@include r.pc {
			width: calc(100% - 60px);
		}

		@include r.sp {
			font-size: 1.2rem;
		}

		span {
			text-align: left;
			display: block;
			font-size: 1.6rem;

			@include r.sp {
				font-size: 1.2rem;
			}
		}
	}

	// .nk23_c-table15__txt .
	&__txt {
		text-align: right;
		font-size: 1.4rem;

		@include r.pc {
			margin-left: auto;
			width: 60px;
		}

		@include r.sp {
			font-size: 1.2rem;
			width: 100%;
		}

		&[class*="nk23_u-color"] {
			font-weight: bold;
		}
	}

	&.is-style1 {
		#{$root}__ttl {
			span {
				@include r.sp {
					padding-right: 43px;
				}
			}
		}

		#{$root}__txt,
		#{$root}__textnum {
			@include r.sp {
				bottom: 0;
				right: 0;
			}
		}
	}

	&.is-style2 {
		#{$root}__wrap {
			table {
				th {
					&:first-child {
						width: 68px;

						@include r.sp {
							width: 52px;
						}
					}
				}

				tbody {
					tr {
						th {
							@include r.sp {
								font-size: 1.2rem;
							}

							span {
								font-size: 1.2rem;
							}
						}
					}
				}
			}
		}

		#{$root}__note__ttl {
			@include r.sp {
				font-weight: 700;
			}
		}

		#{$root}__ttl {
			display: flex;
			align-items: center;
			min-height: 42px;

			@include r.sp {
				min-height: auto;
			}
		}

		#{$root}__box {
			align-items: center;
			min-height: 38px;
		}
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	&__text {
		font-size: 1.6rem;
		margin-left: 0;
		text-align: left;

		@include r.pc {
			width: 100px;
		}
		&.is-w-full {
			width: 100%;
			max-width: 220px;
			@include r.sp {
				font-size: 1.2rem;
				letter-spacing: -0.05em;
				max-width: 100%;
				min-height: auto;
			}
			span {
				display: block;
			}
		}

		&num {
			flex: 1;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.nk23_c-table15__text {
				font-size: 1.4rem;
				width: max-content;
				margin-left: auto;
				text-align: right;
				&[class*="nk23_u-color"] {
					font-weight: bold;
				}

				@include r.sp {
					font-size: 1.2rem;
				}
			}
		}
	}

	&__time {
		margin-left: 8px;
		width: max-content;
		min-width: 35px;
		text-align: right;
	}

	&__num {
		font-size: 1.2rem;
	}

	&__notice {
		// .nk23_c-table15__notice td .
		td {
			padding: 6px 4px !important;
			color: v.$color13 !important;
			font-weight: 700;
			position: relative;

			@include r.sp {
				padding: 15px 4px !important;
			}

			&:after {
				content: "";
				width: calc(100% + 1px);
				height: calc(100% + 2px);
				border: 2px solid v.$color13;
				top: -1px;
				left: -1px;
				position: absolute;
				pointer-events: none;
			}
		}

		& + .nk23_c-table15__notice {
			td {
				&:after {
					border-top: none;
				}
			}
		}
	}

	&__timetext {
		display: flex;
		align-items: center;
		justify-content: center;

		.nk23_c-table15__text {
			font-size: 1.4rem;
			width: auto;
		}
	}

	tr {
		&:last-child {
			&.nk23_c-table15__notice {
				td {
					&:after {
						height: calc(100% + 1px);

						@include r.pc {
							border-radius: 0 0 8px 0;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table16 {
	@include r.tablestyle;
	$root: &;

	&__wrap {
		position: relative;
	}

	&__inner {
		overflow-y: unset;
		overflow-x: unset;
	}

	&__table {
		width: 640px;
		margin: auto;
		border-radius: 8px;
		overflow: hidden;

		@include r.pc {
			table-layout: initial;
		}

		@include r.sp {
			width: 100%;
			min-width: 100%;
			border-radius: 0;
		}

		th,
		td {
			border: 1px solid #c9c9c9;
		}

		th {
			text-align: center;

			&:first-child {
				border-left: 0;
			}

			&:not(:first-child) {
				width: 199px;

				@include r.sp {
					width: 35%;
				}
			}
		}

		td {
			text-align: right;
			border-bottom: 0;
			padding: 8px;

			&:last-child {
				border-right: 0;
			}
			&[class*="nk23_u-color"] {
				font-weight: bold;
			}
		}

		thead {
			background-color: #404040;
			font-weight: 700;
			color: #fff;
			line-height: 1.35;

			th {
				border-top: 0;
				padding: 4px 8px;

				&:last-child {
					border-right: 0;
				}
			}
		}

		tbody {
			background: #fff;
			line-height: 1.42;

			th {
				border-bottom: 0;
				background: #f7f7f7;
			}
		}
	}

	&__icon {
		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	&.is-style1 {
		#{$root}__table {
			table-layout: fixed;
			margin-top: 16px;

			@include r.sp {
				font-size: 1.3rem;
			}

			th {
				&:first-child {
					width: 60px;

					@include r.sp {
						width: 52px;
					}
				}

				&:not(:first-child) {
					width: auto;
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					width: 47px;
					box-sizing: border-box;

					@include r.sp {
						width: 38px;
					}
				}
			}

			tbody {
				th {
					padding: 8px 0;
					font-size: 1.2rem;
				}

				td {
					box-sizing: border-box;

					@include r.sp {
						padding: 8px 4px;
					}

					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						text-align: center;
					}

					&.is-right {
						text-align: right !important;
					}
				}
			}
		}
	}

	&.is-style2 {
		thead {
			th {
				@include r.sp {
					padding: 4px;
				}

				@include r.w374 {
					padding: 4px 2px;
				}

				&:nth-child(1) {
					width: 1%;
					min-width: 41px;

					@include r.sp {
						min-width: 30px;
					}

					@include r.w374 {
						min-width: 28px;
					}
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					width: 1%;
					min-width: 46px;

					@include r.sp {
						min-width: 36px;
					}

					@include r.w374 {
						min-width: 32px;
					}
				}
			}
		}

		tbody {
			td {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					&:not([class^="is-text-"]) {
						padding: 8px 4px;
						text-align: center;

						@include r.sp {
							padding: 4px 2px;
						}
					}
				}

				@include r.sp {
					padding: 4px 2px;
				}

				@include r.w374 {
					font-size: 1.2rem;
				}
			}
		}
	}

	&__notice {
		// .nk23_c-table16__notice td .
		td {
			padding: 6px 4px !important;
			color: v.$color13 !important;
			font-weight: 700;
			position: relative;

			@include r.sp {
				padding: 15px 4px !important;
			}

			&:after {
				content: "";
				width: calc(100% + 1px);
				height: calc(100% + 2px);
				border: 2px solid v.$color13;
				top: -1px;
				left: -1px;
				position: absolute;
				pointer-events: none;
			}
		}

		& + .nk23_c-table16__notice {
			td {
				&:after {
					border-top: none;
				}
			}
		}
	}

	tr {
		&:last-child {
			&.nk23_c-table16__notice {
				td {
					&:after {
						height: calc(100% + 1px);

						@include r.pc {
							border-radius: 0 0 8px 0;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table17 {
	background: #fff;
	border-radius: 8px;
	margin-top: 8px;
	$r: &;
	@include r.tablestyle;

	@include r.sp {
		border-radius: 0;
	}

	&__wrap {
		position: relative;
	}

	&__inner {
		padding-bottom: 0;

		@include r.sp {
			padding-bottom: 8px;
		}
	}

	&__table {
		width: 100%;
		@include r.tablestyle02;
		font-size: 1.4rem;
		line-height: 22px;
		border-radius: 0;

		@include r.sp {
			font-size: 1.3rem;
		}

		thead {
			th {
				color: #fff;
				font-weight: 700;
				text-align: center;
				background: #404040;
				word-break: keep-all;
				white-space: nowrap;
			}

			tr {
				&:not(:first-child) {
					th {
						background: #f7f7f7;
						color: #221f1f;
					}
				}

				@include r.pc {
					&:first-child {
						th {
							width: 116px;
						}
					}
				}
			}
		}

		tr {
			border-top: 1px solid v.$color23;
			border-bottom: 1px solid v.$color23;
		}

		tbody {
			td {
				padding: 8px;

				@include r.sp {
					padding: 4px;
				}

				&:nth-child(5),
				&:nth-child(8),
				&:nth-child(11) {
					font-weight: 700;
				}

				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(8),
				&:nth-child(9),
				&:nth-child(11),
				&:nth-child(12) {
					width: 45px;

					@include r.sp {
						width: auto;
					}
				}
			}
		}
	}

	&.is-style1 {
		#{$r}__table {
			thead {
				th {
					&:nth-child(3) {
						@include r.max-w(375px) {
							width: 14%;
						}
					}
				}

				tr {
					&:not(:first-child) {
						th {
							&:nth-child(3),
							&:nth-child(6) {
								width: 30%;
							}
						}
					}
				}
			}
		}
	}

	&.is-style2 {
		#{$r}__table {
			@include r.sp {
				min-width: 700px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table18 {
	&__table {
		border-collapse: collapse;
		border-radius: 8px;
		overflow: hidden;
		width: 100%;
	}

	&__texts {
		display: flex;
		align-items: flex-end;

		.is-small {
			top: 2px;
			position: relative;
		}

		.is-large {
			& + .is-small {
				margin-left: 4px;
			}
		}
	}

	&__group {
		position: relative;
	}

	&__error {
		color: v.$color13;
		text-align: center;
		font-weight: 700;

		&:after {
			content: "";
			position: absolute;
			left: -1px;
			bottom: -1px;
			top: 0;
			right: 0;
			border: 2px solid v.$color13;
			pointer-events: none;
		}
	}

	th,
	td {
		border: 1px solid v.$color19;
	}

	thead {
		font-weight: 700;
		font-size: 1.4rem;
		line-height: 2.1rem;

		th {
			padding: 8px 4px;
			background-color: v.$color29;
			color: #fff;
			text-align: center;
			border-top: none;

			@include r.pc {
				width: 29%;
				min-width: 287px;
			}

			&:first-child {
				border-left: none;
				width: 0.01%;
				min-width: 68px;
			}

			&:last-child {
				border-right: none;

				@include r.sp {
					width: 50%;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				@include r.sp {
					width: 0.01%;
					min-width: 23px;
				}
			}
		}

		td {
			padding: 8px 4px;

			&:first-child {
				background-color: v.$color29;
				color: #fff;
				text-align: center;
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}

			&:not(:first-child) {
				background-color: v.$color34;
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(8),
			&:nth-child(9) {
				@include r.pc {
					width: 0.1%;
					min-width: 25px;
				}
			}
		}

		.is-small {
			& + .is-small {
				&:after {
					@include r.sp {
						background-color: #fff;
					}
				}
			}
		}
	}

	tbody {
		td {
			padding: 8px 4px;
			text-align: center;
			font-size: 1.4rem;
			line-height: 2.1rem;

			&[class^="nk23_u-bg"] {
				color: #fff;
			}

			&:not([class^="nk23_u-bg"]) {
				background-color: #fff;
			}

			&.is-border-right {
				border-right: 1px solid v.$color19;
			}

			// .nk23_c-table18 tbody td.is-border-bottom .
			&.is-border-bottom {
				border-bottom: 1px solid v.$color19;
			}

			&:first-child {
				padding-left: 2px;
				padding-right: 2px;
			}

			&:last-child {
				border-right: none;
			}

			&.nk23_u-bg-color34 {
				@include r.sp {
					color: v.$color0;
				}
			}

			&.is-error {
				padding: 0;
				position: relative;
			}

			// .nk23_c-table18 tbody td .nk23_c-btn01 .
			.nk23_c-btn01 {
				margin-bottom: 2px;
			}
		}

		tr {
			&:last-child {
				td {
					border-bottom: none;

					&:last-child {
						.nk23_c-table18__error {
							&:after {
								border-bottom-right-radius: 8px;
								bottom: 0;
							}
						}
					}
				}
			}

			&:first-child {
				td {
					.nk23_c-table18__error {
						&:after {
							top: -1px;
						}
					}
				}
			}
		}
	}

	td {
		&[class^="nk23_u-bg"] {
			color: #fff;
		}
	}

	strong,
	b {
		font-weight: 700;
	}

	.is-text-left {
		text-align: left !important;
	}

	.is-label {
		padding: 4px;
		right: 0;
		bottom: 0;
		border-radius: 4px;
		color: #fff;
		font-size: 1.2rem;
		line-height: 1;
		font-weight: 700;
		width: 40px;
		text-align: center;
		display: inline-block;
		position: absolute;
		background-color: v.$color0;
	}

	.is-small {
		font-size: 1.2rem;
		line-height: 1.6rem;

		@include r.w374 {
			font-size: 1.1rem;
			line-height: 1.5rem;
		}

		& + .is-small {
			position: relative;
			margin-left: 16px;

			&:after {
				width: 1px;
				height: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -8px;
				background-color: v.$color0;
				content: "";
				pointer-events: none;
			}
		}

		&[class^="nk23_u-color"] {
			&:after {
				background-color: v.$color19;
			}
		}
	}

	// .nk23_c-table18 .is-large .
	.is-large {
		font-size: 1.6rem;
		line-height: 1;

		@include r.w374 {
			font-size: 1.4rem;
		}
	}

	// .nk23_c-table18 .nk23_c-btn01 .
	.nk23_c-btn01 {
		margin: 2px auto 0;
		width: 100%;
		max-width: 60px;

		@include r.sp {
			margin-top: 4px;
		}
	}

	.is-text-ver {
		writing-mode: vertical-rl;
		text-align: center;
		letter-spacing: 2px;
		word-break: keep-all;

		@include r.sp {
			line-height: 1;
		}
	}

	.is-num-ver {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include r.sp {
			line-height: 1.1;
		}
	}

	// .nk23_c-table18 .nk23_u-colorlink .
	.nk23_u-colorlink {
		color: v.$color14;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table19 {
	@include r.tablestyle3;
	$r: &;

	thead {
		th {
			@include r.sp {
				font-size: 1.3rem;
			}

			&:first-child {
				width: 0.1%;
				min-width: 41px;

				@include r.sp {
					min-width: 32px;
				}
			}

			&:nth-child(2) {
				width: 0.1%;
				min-width: 31px;

				@include r.sp {
					min-width: 22px;
				}
			}

			&:nth-child(3) {
				width: 0.1%;
				min-width: 51px;

				@include r.sp {
					min-width: 35px;
				}
			}

			// .nk23_c-table19 thead th:nth-child(4) .
			&:nth-child(4) {
				@include r.sp {
					width: 40%;
				}
			}

			&:last-child {
				width: 0.1%;
				min-width: 120px;

				@include r.sp {
					min-width: 105px;
				}
			}
		}
	}

	tbody {
		td {
			font-size: 1.3rem;
			line-height: 2.3rem;

			// @include r.pc{
			//   word-break: keep-all;
			//   white-space: nowrap;
			// }
			@include r.w374 {
				font-size: 1.1rem;
			}
		}

		tr {
			cursor: pointer;
		}
	}

	&__after {
		position: absolute;
		top: -5px;
		left: -4px;
		right: 0;
		bottom: 0;
		pointer-events: none;
		border: 2px solid v.$color0;

		@include r.sp {
			left: -4px;
		}
	}

	tr {
		&:not(.is-active) {
			.nk23_c-table19__after {
				display: none;
			}
		}

		&.is-active {
			position: relative;

			.nk23_c-checkbox2 {
				position: relative;
			}

			td {
				&:first-child {
					&:after {
						// content: '';
						position: absolute;
						top: -1px;
						left: 0;
						right: 0;
						bottom: 0;
						pointer-events: none;
						border: 2px solid v.$color0;
					}
				}
			}
		}

		&:last-child {
			&.is-active {
				.nk23_c-table19__after {
					@include r.pc {
						border-radius: 0 0 8px 8px;
						top: -6px;
					}
				}

				td {
					&:first-child {
						&:after {
							@include r.pc {
								border-radius: 0 0 8px 8px;
							}
						}
					}
				}
			}
		}
	}

	.nk23_c-checkbox2 {
		display: flex;
		align-items: center;

		input {
			top: 0;
		}
	}

	&__table {
		min-width: 100%;
	}

	&.is-style1 {
		@include r.sp {
			@include r.tablestyle;
		}

		#{$r}__wrap {
			@include r.sp {
				position: relative;
			}
		}

		#{$r}__table {
			@include r.sp {
				min-width: 500px;
			}

			thead {
				th {
					width: auto;

					&:last-child {
						min-width: 89px;
					}

					&:nth-child(5) {
						min-width: 110px;
					}
				}
			}

			tbody {
				td {
					@include r.w374 {
						font-size: 1.3rem;
					}

					&.is-bold {
						font-weight: 700;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-grtable19 {
	margin: 0 -8px;

	@include r.pc {
		display: flex;
		flex-wrap: wrap;
	}

	.nk23_c-table19 {
		@include r.pc {
			width: 50%;
			padding: 0 8px;
			margin-bottom: 16px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table20 {
	@include r.tablestyle;
	border-radius: 0;

	&__inner {
		padding-bottom: 0;

		@include r.sp {
			padding-bottom: 8px;
		}
	}

	&__table {
		width: 100%;
		@include r.tablestyle02;
		font-size: 1.4rem;
		line-height: 22px;
		border-radius: 0;
		background: #ffffff;
		border-radius: 0 0 8px 8px;

		@include r.sp {
			font-size: 1.3rem;
			width: 1176px;
			border-radius: 0;
		}

		thead {
			th {
				color: #fff;
				font-weight: 700;
				text-align: center;
				background: #404040;
				padding: 8px 4px;
				line-height: 18px;
				word-break: keep-all;
				white-space: nowrap;

				@include r.sp {
					font-size: 1.4rem;
				}
			}

			tr {
				&:not(:first-child) {
					th {
						background: #f7f7f7;
						color: #221f1f;
					}
				}
			}
		}

		tr {
			border-top: 1px solid v.$color23;
			border-bottom: 1px solid v.$color23;
		}

		tbody {
			td {
				padding: 4px;

				@include r.sp {
					padding: 4px;
					font-size: 1.4rem;
				}

				&:first-child {
					text-align: left;
					font-size: 1.2rem;
					line-height: 19px;
					word-break: keep-all;
					white-space: nowrap;

					.is-bold {
						font-size: 1.6rem;
						line-height: 22px;
					}
				}

				&.is-center {
					text-align: center;
				}

				&:nth-child(2) {
					font-weight: bold;
				}

				&.is-col-link {
					text-align: left;
					font-size: 1.6rem;
					width: 186px;

					@include r.sp {
						width: max-content;
					}

					.nk23_u-colorlink {
						&.is-small {
							font-size: 1.2rem;
							margin-top: 4px;
						}
					}
				}

				&.is-col-txt,
				&:last-child {
					text-align: left;
					font-size: 1.2rem;
					width: 255px;

					.is-bold {
						margin-right: 4px;
					}

					&[class^="nk23_u-bg-color"] {
						color: inherit;
					}
				}

				.is-bold {
					font-size: 1.9rem;
					font-weight: bold;
				}

				.nk23_u-colorlink {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}

				.nk23_c-btn01 {
					margin-bottom: 8px;
					width: 100%;
				}

				.nk23_c-table20__txt {
					margin-bottom: 8px;
				}
			}

			tr {
				&:first-child {
					td {
						&:nth-child(2) {
							font-weight: normal;
						}

						&:nth-child(3) {
							font-weight: bold;
						}
					}
				}

				&:last-child {
					border-bottom: none;

					td {
						border-bottom: none;
						font-size: 1.3rem;
						line-height: 21px;
						font-weight: normal;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table21 {
	@include r.tablestyle4;

	th,
	td {
		vertical-align: unset;
	}

	th {
		width: 3%;

		@include r.sp {
			min-width: 49px;
		}

		&:first-child {
			width: 4%;
			min-width: 53px;

			@include r.pc {
				background-color: v.$color34;
			}

			@include r.sp {
				width: 1%;
				min-width: 25px;
			}
		}

		&.is-active {
			font-weight: 700;
			background-color: v.$color0;
			color: #fff;
		}
	}

	td {
		&:first-child {
			background-color: v.$color34;
			font-weight: 700;
			vertical-align: middle;

			@include r.sp {
				font-size: 1.6rem;
			}
		}
	}

	&__icons {
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&.is-style1 {
			flex-direction: column;
			min-height: 76px;

			@include r.sp {
				min-height: 101px;
			}

			.nk23_c-table21__icon {
				// .nk23_c-table21__icons.is-style1 .nk23_c-table21__icon + .nk23_c-table21__icon .
				& + .nk23_c-table21__icon {
					margin-top: 16px;

					@include r.sp {
						margin-top: 24px;
					}
				}
			}
		}
	}

	&__icontext {
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		line-height: 1;

		&:not(:last-child) {
			margin-right: 24px;

			@include r.sp {
				margin-right: 8px;
			}
		}

		@include r.sp {
			font-size: 1.2rem;
		}
	}

	&__title {
		font-size: 2rem;
		line-height: 1;
		font-weight: 700;
	}

	&__icon {
		margin-right: 4px;
		border-radius: 4px;
		border: 1px solid #919599;
		box-shadow: 0 2px 0 #919599;
		width: 22px;
		height: 22px;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		font-size: 8px;
		color: v.$color0 !important;
		background-color: #fff;
		font-weight: 700;
		white-space: nowrap;
		transition: none;

		&.is-style1 {
			width: 28px;
			height: 28px;
			margin-right: 0;
			font-size: 1.2rem;

			@include r.sp {
				width: 40px;
				height: 40px;
				font-size: 1.6rem;
			}

			img {
				width: 20px;

				@include r.sp {
					width: 32px;
				}
			}
			@include r.hover {
				position: relative;
				box-shadow: none;
				top: 2px;
				text-decoration: none !important;
			}
		}

		&.is-disable {
			pointer-events: none;
			background-color: v.$color40;

			img {
				opacity: 0.5;
			}
		}

		img {
			width: 14px;
			height: auto;
		}
	}

	&__text {
		margin: 4px 0;
		font-size: 1rem;
		line-height: 1;
		text-align: center;
		display: block;
		@include r.sp {
			font-size: 1.2rem;
		}

		&.is-color0 {
			color: v.$color0 !important;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table22 {
	$root: &;
	margin-top: -89px;

	@include r.sp {
		margin-top: 0;
	}

	&__wrap {
		position: relative;
		margin: 0 auto;
		border-radius: 8px 8px 0 0;

		@include r.sp {
			margin: 0;
		}
	}

	&__inner {
		padding-bottom: 16px;
		padding: 0 40px 16px 40px;
		width: 100%;

		@include r.sp {
			// padding: 0 0 16px 0;
			padding: 0 0 8px 0;
			// overscroll-behavior: auto;

			// Add strix St
			&[data-scrollbar].margin-bottom {
				margin-bottom: 0;
			}

			// Add strix End
		}

		.scrollbar-track-x {
			background: #fff;
			opacity: 1;
			overflow: hidden;
			border-radius: 4px;

			scroll-content .scrollbar-thumb {
				background: v.$color19;
				height: 4px;
			}
		}

		// Add strix St
		.scrollbar-track-y {
			background: #fff;
			opacity: 1;
			overflow: hidden;
			border-radius: 4px;
			width: 4px;

			.scrollbar-thumb {
				background: v.$color19;
				width: 4px;
			}
		}

		.scroll-content {
			@include r.sp {
				height: 100vh;
			}
		}

		&.is-drug {
			cursor: grabbing;
			// .scroll-content {
			// 	pointer-events: none;
			// 	@include r.sp {
			// 		pointer-events: auto;
			// 	}
			// }
		}
		// Add strix End
	}

	&__icon {
		// Add strix St 23.12.13
		@keyframes hideop2 {
			0% {
				opacity: 1;
			}

			100% {
				opacity: 0;
				display:none;
			}
		}
		// End strix St 23.12.13

		@include r.sp {
			top: 280px;
			transform: translateX(-50%);
			position: absolute;
			left: 50%;
			z-index: 2;
			pointer-events: none;
			transition:
				opacity 0.3s ease-in-out,
				visibility 0.3s ease-in-out;
			opacity: 0;
			visibility: hidden;
		}

		// Add strix St 23.12.13
		// &.is-show {
		// 	opacity: 1;
		// 	visibility: visible;
		// }
		&.is-hide {
			animation: hideop2 0.3s linear 0s;
			animation-fill-mode: forwards;
		}

		&.is-show {
			opacity: 1;
			visibility: visible;
			display: block;
		}
		// End strix St 23.12.13
	}

	&__tbcontent {
		padding-top: 89px;
		background: #ffffff;
		width: max-content;
		margin: 0 auto;
		border-radius: 8px;
		overflow: hidden;

		@include r.sp {
			position: relative;
			border-radius: 0;
			padding-top: 0;
		}

		// .nk23_c-table22__tbcontent__top .
		&__top {
			overflow-y: hidden;
			height: 24px;
			// position: fixed;
			// Add strix St
			width: min-content;
			position: absolute;
			// Add strix End
			left: 0;
			top: 0;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;

			@include r.sp {
				z-index: 4;
				transform-origin: top left;
				pointer-events: none;
				// top: 44px;
				// Add strix St
				top: 0;
				// Add strix End
				box-shadow: none;
				transition: box-shadow 0.3s ease-in-out;
			}

			// .nk23_c-table22__tbcontent__top .nk23_c-table22__table .
			.nk23_c-table22__table {
				@include r.sp {
					position: relative;
					transform: translateY(-25px);
					transition: 0.3s ease-in-out;
				}

				th {
					@include r.sp {
						border-bottom: none;
					}
				}
			}

			// Add strix St
			.is-sticky & {
				position: fixed;
				top: 44px;
			}

			.is-sticky2 & {
				position: fixed;
				top: 44px;
				pointer-events: all;
				box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
				.nk23_c-table22__table {
					@include r.sp {
						transform: translateY(0);
					}

					th {
						@include r.sp {
							border-bottom: 1px solid v.$color19;
						}
					}
				}
			}
			// Add strix End

			&.is-active {
				@include r.sp {
					pointer-events: all;
					box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
				}

				.nk23_c-table22__table {
					@include r.sp {
						transform: translateY(0);
					}

					th {
						@include r.sp {
							border-bottom: 1px solid v.$color19;
						}
					}
				}
			}

			.nk23_c-table22__table {
				thead {
					tr {
						.is-col01 {
							@include r.sp {
								min-width: 19px;
							}

							&:last-child {
								@include r.sp {
									min-width: 20px;
								}
							}
						}

						.is-col03 {
							@include r.sp {
								min-width: 179px;
							}
						}

						.is-col07 {
							@include r.sp {
								// min-width: 50px;
							}
						}

						.is-col12 {
							@include r.sp {
								// min-width: 19px;

								// Add strix St
								min-width: 26px;
								// Add strix End
							}
						}

						.is-col16,
						.is-col17,
						.is-col18,
						.is-col19 {
							@include r.sp {
								min-width: 210px;
								background: #f7f7f7;
								color: #221f1f;
							}
						}

						.is-col20 {
							@include r.sp {
								min-width: 210px;
								background: #f7f7f7;
								color: #221f1f;
							}
						}

						.is-col024 {
							@include r.sp {
								width: 1%;
								// min-width: 163px;
							}
						}

						.is-col26 {
							@include r.sp {
								// min-width: 70px;
							}
						}

						.is-col27 {
							@include r.sp {
								width: 1%;
								// min-width: 92px;
							}
						}

						// .nk23_c-table22__tbcontent__top .nk23_c-table22__table thead tr .is-col29 .
						.is-col29 {
							@include r.sp {
								width: 1%;
								// min-width: 94px;
							}
						}

						// .nk23_c-table22__tbcontent__top .nk23_c-table22__table thead tr .is-col030 .
						.is-col030 {
							@include r.sp {
								width: 1%;
								min-width: 121px;
							}
						}

						.is-col31 {
							@include r.sp {
								width: 1%;
								min-width: 124px;
							}
						}

						// .nk23_c-table22__tbcontent__top .nk23_c-table22__table thead tr .is-col34 .
						.is-col34 {
							@include r.sp {
								width: 1%;
								// min-width: 66px;
							}
						}
					}
				}
			}
		}

		// .nk23_c-table22__tbcontent__bottom .
		&__bottom {
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;

			@include r.sp {
				transform: translateX(-100%);
				transition: transform 0.3s ease-in-out;
				z-index: 3;
				transform-origin: top left;
			}

			&.is-active {
				@include r.sp {
					transform: translateX(0);
				}
			}

			table {
				@include r.sp {
					width: 58px;
					min-width: auto;
					transform-origin: top left;

					thead {
						tr {
							height: auto;
						}
						th {
							height: 164px;
							border-right: 1px solid #c9c9c9 !important;

							&.h-none {
								height: auto;
							}
						}
					}

				}
			}
		}
	}

	&__table {
		width: 100%;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 0 0 8px 8px;
		min-width: 960px;

		@include r.sp {
			border-radius: 0;
		}

		thead {
			tr {
				th {
					font-weight: bold;
					word-break: keep-all;
					white-space: nowrap;
					line-height: 14px;

					@include r.pc {
						min-width: 23px;
					}

					.is-col-item00 {
						height: 50%;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;

						// Add strix St
						// transform: translateY(-1px);
						// Add strix End
					}

					.is-col-item01 {
						width: 50%;
						text-align: center;
					}

					.is-col-item02 {
						width: 50%;
						text-align: center;
					}

					&.nk23_col-in-col {
						position: relative;
						padding: 0;
						height: 85px;
						.nk23_u-line {
							margin: 0;
						}
						.nk23_u-d-flex {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 50%;
							justify-content: center;
						}
						.nk23_u-line-v {
							margin: 0;
							position: absolute;
							left: calc(50% - 0.5px);
							top: 0;
							bottom: 0;
						}
					}

					&.nk23_u-w50 {
						min-width: 60px;
					}

					&.is-col14 {
						.is-txtV {
							word-spacing: -8px;
						}
					}

					&.is-col03 {
						line-height: 16px;

						.nk23_u-text16 {
							line-height: 20px;
						}
					}
				}

				&:not(:first-child) {
					th {
						background: v.$color34;
						color: v.$color0;
						// font-size: 1.4rem;
					}
				}

				.is-space {
					margin: 0 0 5px 0;
					display: block;

					@include r.sp {
						margin: 0;
					}
				}

				.is-col {
					&01,
					&02 {
						width: 14px;
					}

					&14,
					&15 {
						min-width: 33px;
					}

					&16,
					&17,
					&18,
					&19,
					&20 {
						min-width: 210px;
					}
				}

				@include r.sp {
					.is-col {
						&01 {
							// min-width: 18px;
							// Add strix St
							min-width: 19px;
							// Add strix End
						}
						&02 {
							// min-width: 18px;
							// Add strix St
							min-width: 20px;
							// Add strix End
						}

						&03 {
							min-width: 176px;
						}

						&04 {
							min-width: 53px;
						}

						&05,
						&06 {
							min-width: 51px;
						}

						&07 {
							min-width: 50px;
						}

						&08,
						&09,
						&10,
						&11 {
							min-width: 37px;
						}

						&12 {
							// min-width: 19px;

							// Add strix St
							min-width: 26px;
							// Add strix End
						}

						&13 {
							// min-width: 69px;
							// Add strix St
							min-width: 78px;
							// Add strix End
						}

						&14,
						&15 {
							min-width: 33px;
						}

						&21 {
							// min-width: 77px;
							// Add strix St
							min-width: 85px;
							// Add strix End
						}

						&22 {
							// min-width: 69px;
							// Add strix St
							min-width: 72px;
							// Add strix End
						}

						&23 {
							// min-width: 70px;
							// Add strix St
							min-width: 73px;
							// Add strix End
						}

						&24 {
							min-width: 96px;
						}

						&024 {
							min-width: 191px;
						}

						&25 {
							min-width: 72px;
						}

						&26 {
							min-width: 72px;
						}

						&27 {
							min-width: 94px;
						}

						&28 {
							// min-width: 70px;
							// Add strix St
							min-width: 77px;
							// Add strix End
						}

						&29 {
							min-width: 92px;
						}

						&30 {
							min-width: 88px;
						}

						&030 {
							min-width: 86px;
						}

						&31 {
							min-width: 10px;
						}

						&32 {
							width: 39px;
						}

						&34 {
							min-width: 65px;
						}

						&33 {
							width: 100px;
						}

						&001 {
							width: 20px;
						}

						&002 {
							width: 36px;
						}

						&005 {
							// min-width: 145px;
							// Add strix St
							min-width: 150px;
							// Add strix End
						}
					}
				}

				img {
					margin: 5px auto 0;
					max-width: 16px;
				}
			}
		}

		tbody {
			tr {
				&:first-child {
					td {
						&:first-child,
						&:last-child {
							color: v.$color0;
						}
					}
				}

				td {
					min-height: auto;
					word-break: keep-all;
					white-space: nowrap;
					// Add strix St
					height: auto;
					// Add strix End
					@include r.sp {
						padding: 4px 2px;
						height: 94px;
					}

					.nk23_u-line {
						margin: 2px -2px;
					}

					&.is-col001 {
						font-size: 1rem;

						&.is-ios {
							@include r.sp {
								position: relative;
							}

							// &:before {
							// 	font-size: 8px;
							// 	font-weight: 700;
							// 	bottom: 11px;
							// 	// height: 14px;
							// 	height: auto;
							// 	// left: -1px;
							// 	left: 50%;
							// 	padding-bottom: 1px;
							// 	z-index: 2;
							// 	// width: 125%;
							// 	width: 100%;
							// 	background-color: #fff;
							// 	position: absolute;
							// 	color: #000;
							// 	pointer-events: none;
							// 	display: flex;
							// 	justify-content: center;
							// 	// align-items: flex-end;
							// 	// transform: translateX(-50%) rotate(90deg);
							// 	transform: translateX(-50%);

							// 	@include r.sp {
							// 		content: "...";
							// 	}
							// }

							// &.has-span {
							// 	@include r.sp {
							// 		padding-top: 8px;
							// 	}

							// 	// .nk23_c-table22__table tbody tr td.is-col001.is-ios.has-span:before .
							// 	&:before {
							// 		@include r.sp {
							// 			// bottom: 11px;
							// 			bottom: 8px;
							// 		}
							// 	}

							// 	&:after {
							// 		@include r.sp {
							// 			display: block;
							// 			content: '';
							// 			bottom: 10px;
							// 			height: 14px;
							// 			left: -1px;
							// 			bottom: 10px;
							// 			padding-bottom: 1px;
							// 			z-index: 1;
							// 			width: 100%;
							// 			background-color: #fff;
							// 			position: absolute;
							// 			color: #000;
							// 			pointer-events: none;
							// 			align-items: flex-end;
							// 			z-index:1;
							// 		}
							// 	}
							// }
						}

						// .is-txtV {
						// 	@include r.sp {
						// 		letter-spacing: 0;-webkit-text-orientation: upright;
						// 		text-orientation: upright;
						// 		-webkit-text-overflow: ellipsis;
						// 		text-overflow: ellipsis;
						// 		height: 80px;
						// 		overflow: hidden;
						// 	}
						// }
					}

					&.is-col001.nk23_u-bg-color0 {
						&:before {
							color: #fff;
							background-color: v.$color0;
						}

						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color0;
							}
						}
						// End strix St
					}

					&.is-col001.nk23_u-bg-color13 {
						&:before {
							color: #fff;
							background-color: v.$color13;
						}

						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color13;
							}
						}
						// End strix St
					}

					&.is-col001.nk23_u-bg-color14 {
						&:before {
							color: #fff;
							background-color: v.$color14;
						}

						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color14;
							}
						}
						// End strix St
					}

					&.is-col001.nk23_u-bg-color15 {
						&:before {
							color: #fff;
							background-color: v.$color15;
						}

						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color15;
							}
						}
						// End strix St
					}

					&.is-col001.nk23_u-bg-color16 {
						&:before {
							color: #fff;
							background-color: v.$color16;
						}

						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color16;
							}
						}
						// End strix St
					}

					&.is-col001.nk23_u-bg-color17 {
						&:before {
							color: #fff;
							background-color: v.$color17;
						}

						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color17;
							}
						}
						// End strix St
					}

					&.is-col001.nk23_u-bg-color18 {
						&:before {
							color: #fff;
							background-color: v.$color18;
						}
						// Add strix St
						&.has-span {
							&:after {
								color: #fff;
								background-color: v.$color18;
							}
						}
						// End strix St
					}

					&.is-col002 {
						font-size: 1rem;
						line-height: 1.2;
					}
					&.nk23_c-chart3__td {
						vertical-align: bottom;
					}
				}
			}
		}

		th,
		td {
			border: 1px solid v.$color19;
			text-align: center;
			font-size: 1.2rem;
			line-height: 14px;
			padding: 4px;

			@include r.sp {
				padding: 4px 2px;
				word-break: keep-all;
				white-space: nowrap;

				&:first-child {
					border-left: none;
				}

				&:last-child {
					border-right: none;
				}
			}

			.is-txtV {
				// vertical-align: text-top;
				display: inline-block;
				margin: 0 auto;
				line-height: 1.1;
				text-align: center;
				writing-mode: vertical-rl;
				text-orientation: upright;
				word-break: keep-all;

				.is-number {
					display: flex;
					// display: inline;
					flex: 0 1 auto;
					margin: 0 auto;
					align-self: center;
					letter-spacing: 0;
					writing-mode: horizontal-tb;
				}

				[data-texttrim] {
					display: flex;
					flex: 0 1 auto;
					margin: 0 auto;
					align-self: center;
				}

				&.is-space {
					display: inline-block;
				}
			}

			&.is-col {
				&01 {
					font-weight: bold;
					color: #ffffff;
				}
			}
		}

		tr>*:first-child .is-txtV {
			display: flex;
		}

		th {
			background: v.$color29;
			color: #ffffff;
			font-weight: bold;
		}

		td {
			position: relative;
			line-height: 16px;

			// Add strix St
			@include r.sp {
				padding: 0 2px;
			}
			// Add strix End

			.is-link {
				color: v.$color-link;

				&.nk23_u-text16 {
					padding: 6px 0;
					display: inline-block;
				}

				@include r.hover {
					text-decoration: underline;
					opacity: 1;
				}
			}

			.nk23_u-text12 {
				line-height: 14px;
			}

			.nk23_c-btn06 {
				margin-top: 2px;

				@include r.sp {
					margin-top: -5px;
				}
			}

			.is-line {
				height: 16px;
				width: 1px;
				background: v.$color0;
				margin: 3px auto;
			}

			.nk23_u-text19 {
				display: inline-block;
				font-weight: bold;
				margin-right: 4px;
				word-break: keep-all;
				white-space: nowrap;
			}

			.nk23_u-d-flex {
				align-items: baseline;
			}

			// .nk23_c-table22__table td.is-grtxt .
			&.is-grtxt {
				padding-right: 18px;
				vertical-align: top;
				text-align: left;

				.nk23_c-btn02 {
					position: absolute;
					bottom: 6px;
					right: 4px;
					@include r.sp {
						bottom: 4px;
					}

					@include r.hover {
						position: absolute;
						top: inherit;
						bottom: 4px;
					}
				}

				.is-link {
					margin: 2px 0 0 0;
					overflow: hidden;
					max-width: 180px;
					white-space: nowrap;
					word-break: keep-all;
					text-overflow: ellipsis;
					@include r.sp {
						max-width: 171px;
					}
					// display: -webkit-box;
					// -webkit-line-clamp: 1;
					// -webkit-box-orient: vertical;
				}
			}

			&.is-grnum {
				text-align: right;
				@include r.noto-mono;

				p {
					display: flex;
					justify-content: flex-end;
				}

				@include r.sp {
					min-width: 70px;
				}

				[class^="nk23_u-color"] {
					width: 15px;
					display: inline-block;
				}
			}

			.is-grPercent {
				display: flex;
				justify-content: space-between;
				width: 88px;
				@include r.noto-mono;

				&__item {
					text-align: right;
					min-width: 37px;
				}
			}

			.nk23_c-btn01 {
				max-width: 60px;
			}

			&.is-circle {
				z-index: 0;

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 29px;
					height: 29px;
					border: 2px solid #e67573;
					border-radius: 100%;
					z-index: -1;
				}
			}
			&.is-textRound {
				p {
					width: 10em;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	&__Grouptxt {
		&:not(:last-child) {
			margin-bottom: 8px;
		}

		span {
			display: block;
		}
	}

	&__title {
		@include r.sp {
			// position: fixed;
			// top: 45px;
			// Add strix St
			position: absolute;
			top: 1px;
			// Add strix End
			height: 24px;
			left: 0;
			z-index: 5;
			overflow: hidden;
			transform-origin: top left;

			// Add strix St
			.is-sticky & {
				position: fixed;
				top: 45px;
			}
			// Add strix End
		}

		&content {
			@include r.sp {
				background: v.$color29;
				color: #fff;
				font-weight: bold;
				display: flex;
				align-items: stretch;
				transition: 0.3s ease-in-out;
				transform: translateX(-100%) translateY(-24px);
			}

			&.is-active-x {
				transform: translateX(0) translateY(-24px);

				&.is-active-y {
					transform: translateX(0) translateY(0);
				}

				// Add strix St
				.is-sticky2 & {
					transform: translateX(0) translateY(0);
				}
				// Add strix End
			}

			&.is-active-y {
				transform: translateX(-100%) translateY(0);

				&.is-active-x {
					transform: translateX(0) translateY(0);
				}
			}
		}

		.is-td {
			text-align: center;
			width: 40px;
			font-size: 1.2rem;
			line-height: 1.23;
			padding: 4px;
			border-right: 1px solid v.$color19;

			&.is-empty {
				width: 18px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table23 {
	@include r.tablestyle;
	$root: &;
	width: 960px;
	margin: 0 auto;

	&__inner {
		@include r.pc {
			padding-bottom: 8px;
		}

		@include r.sp {
			// overflow-y: hidden;
			// overflow-x: auto !important;

			// Add strix St 23.12.13
			.scroll-content {
		    height: 70vh;
				scroll-behavior: auto;
			}
			// End strix St 23.12.13
		}

		&.has-sticky {
			@include r.sp {
				padding-top: 53px;
			}
		}
	}

	&__icon {
		top: 200px;
		transform: translateX(-50%);
	}

	@include r.sp {
		width: 100%;
	}

	&__table {
		width: 100%;
		background: #fff;
		border-radius: 0 0 8px 8px;

		@include r.sp {
			border-radius: 0;
			// min-width: 960px;
			overflow: unset;
			border-collapse: separate;
			border-spacing: 0;
			table-layout: fixed;
		}

		thead {
			background-color: v.$color29;

			&.is-sticky {
				@include r.sp {
					position: fixed;
					z-index: 1;
					top: 44px;
					left: 0;
					width: max-content;
				}
			}

			th {
				font-size: 1.2rem;
				font-weight: 700;
				color: #fff;
				line-height: 1.3;
				text-align: center;
				word-break: keep-all;
				border-top: 0;

				@include r.sp {
					// position: sticky;
					// top: 0;
					// left: 0;
					// z-index: 2;
					// border-bottom: 1px solid v.$color19;
				}

				&:nth-child(1),
				&:nth-child(2) {
					width: 22px;

					@include r.sp {
						// position: sticky;
						// top: 44px;
						// z-index: 1;
						// background-color: v.$color29;
					}
				}

				&:nth-child(1) {
					@include r.sp {
						// left: 0;
					}
				}

				&:nth-child(3) {
					min-width: 180px;

					@include r.sp {
						width: 180px;
					}
				}

				&:nth-child(4) {
					@include r.sp {
						width: 36px;
					}
				}

				&:nth-child(5),
				&:nth-child(6) {
					@include r.sp {
						width: 48px;
					}
				}

				&:nth-child(7) {
					@include r.sp {
						width: 57px;
					}
				}

				&:nth-child(9) {
					@include r.sp {
						width: 156px;
					}
				}

				&:nth-child(8),
				&:nth-child(10) {
					width: 57px;
				}

				&:nth-child(11) {
					width: 105px;
				}

				&:nth-child(12) {
					width: 100px;
				}

				&:last-child {
					img {
						max-width: 16px;
						width: 100%;
						display: inline-block;
						margin-left: 3px;
					}
				}

				&:nth-child(2) {
					@include r.sp {
					}
				}

				span {
					font-size: 1.6rem;
				}

				@include r.sp {
					// top: 44px;
					// position: sticky;
					// background-color: v.$color29;
				}
			}
		}

		th,
		td {
			padding: 8px 4px;

			@include r.pc {
				border: 1px solid v.$color19;
			}

			@include r.sp {
				border-right: 1px solid v.$color19;
				border-bottom: 1px solid v.$color19;
				padding-left: 4px;
				padding-right: 4px;
			}

			&:last-child {
				border-right: 0;
			}

			&:first-child {
				border-left: 0;
			}
		}

		td {
			padding: 2px 4px;

			@include r.pc {
				border-bottom: 0;
			}

			.nk23_c-btn01 {
				min-height: 28px;
			}
		}

		tbody {
			th {
				font-size: 1.2rem;
				font-weight: 700;

				@include r.sp {
					width: 22px;
				}
			}

			td {
				font-size: 1.2rem;
				text-align: center;

				#{$root}__txt1 {
					font-size: 1.6rem;
					line-height: 13px;
				}

				&#{$root}__txt2 {
					font-size: 1.4rem;
					line-height: 2.3rem;
				}

				#{$root}__txt3 {
					font-size: 1rem;
					line-height: 13px;
					display: block;
				}

				&.is-textRound {
					p {
						width: 10em;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			tr {
				&:last-child {
					th,
					td {
						@include r.sp {
							border-bottom: none;
						}
					}
				}

				&:nth-child(even) {
					td {
						&:nth-child(1) {
							@include r.sp {
								width: 22px;
							}
						}

						&:nth-child(2) {
							@include r.sp {
								width: 180px;
							}
						}

						&:nth-child(3) {
							@include r.sp {
								width: 58px;
							}
						}

						&:nth-child(4),
						&:nth-child(5) {
							@include r.sp {
								width: 78px;
							}
						}

						&:nth-child(6) {
							@include r.sp {
								width: 87px;
							}
						}

						&:nth-child(8) {
							@include r.sp {
								width: 116px;
							}
						}

						&:nth-child(7),
						&:nth-child(9) {
							@include r.sp {
								width: 57px;
							}
						}

						&:nth-child(11) {
							@include r.sp {
								width: 100px;
							}
						}
					}
				}

				&:nth-child(odd) {
					td {
						&:nth-child(2) {
							@include r.sp {
								width: 22px;
							}
						}

						&:nth-child(3) {
							@include r.sp {
								width: 180px;
							}
						}

						&:nth-child(4) {
							@include r.sp {
								width: 58px;
							}
						}

						&:nth-child(5),
						&:nth-child(6) {
							@include r.sp {
								width: 78px;
							}
						}

						&:nth-child(7) {
							@include r.sp {
								width: 87px;
							}
						}

						&:nth-child(9) {
							@include r.sp {
								width: 126px;
							}
						}

						&:nth-child(8),
						&:nth-child(10) {
							@include r.sp {
								width: 67px;
							}
						}
					}
				}
			}
		}
	}

	.custom-scrollbar {
		@include r.sp {
			width: calc(100% - 16px);
			margin-left: 8px;
		}

		&-icon {
			top: 210px;
			transform: translateX(-50%);
		}

		&-x {
			background-color: v.$color19;
		}
	}

	&__topfixed {
		@include r.sp {
			overflow-x: auto;
			// Add strix St 23.12.13
			// position: fixed;
			// top: 44px;
			position: absolute;
			top: 0;
			// End strix St 23.12.13
			left: 0;
			z-index: 2;
			width: min-content;
			// width: 100%;
			pointer-events: none;
			overflow: hidden;
			transform-origin: top left;
		}

			// Add strix St 23.12.13
		&.is-active {
			.nk23_c-table23 {
				&__table {
					@include r.sp {
						transform: translateY(0);
					}
				}
			}
		}
			// End strix St 23.12.13

		.js-fixed-ele.is-active & {
			.nk23_c-table23 {
				&__table {
					@include r.sp {
						// Add strix St 23.12.13
						position: fixed;
						top: 44px;
						// End strix St 23.12.13
						transform: translateY(0);
					}
				}
			}
		}

		/* width */
		&::-webkit-scrollbar {
			width: 0;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: transparent;
		}

		.nk23_c-table23 {
			&__table {
				@include r.sp {
					transition: 0.3s ease-in-out;
					transform: translateY(-100%);
				}

				tbody {
					@include r.sp {
						display: none;
					}
				}

				thead {
					@include r.sp {
						pointer-events: none;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table24 {
	background: #fff;
	border-radius: 8px;
	margin-top: 8px;
	$root: &;
	@include r.tablestyle;

	@include r.sp {
		border-radius: 0;
		margin: 8px -8px 0;
	}

	.nk23_c-pagging {
		background: transparent;
	}

	&__head {
		padding: 16px;

		@include r.sp {
			padding: 16px 8px;
		}

		.nk23_c-pagging {
			padding: 0 0 8px 0;
		}
	}

	&__wrap {
		position: relative;
	}

	&__inner {
		padding-bottom: 0;

		@include r.sp {
			padding-bottom: 8px;
		}
	}

	&__table {
		width: 100%;
		@include r.tablestyle02;
		font-size: 1.4rem;
		line-height: 22px;
		border-radius: 0;

		@include r.sp {
			font-size: 1.3rem;
			min-width: 960px;
		}

		thead {
			th {
				color: #fff;
				font-weight: 700;
				text-align: center;
				background: #404040;
				word-break: keep-all;
				white-space: nowrap;

				&:nth-child(1) {
					width: 60px;

					@include r.sp {
						width: 1%;
						min-width: 40px;
					}
				}

				&:nth-child(2) {
					width: 210px;

					@include r.sp {
						width: 1%;
						min-width: 120px;
					}
				}

				&:nth-child(3) {
					@include r.sp {
						width: 1%;
						min-width: 80px;
					}
				}

				&:nth-child(4) {
					@include r.sp {
						width: 1%;
						min-width: 104px;
					}
				}

				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7) {
					@include r.sp {
						width: 1%;
						min-width: 47px;
					}
				}

				&:nth-child(8) {
					@include r.sp {
						width: 1%;
						min-width: 95px;
					}
				}

				&:nth-child(9),
				&:nth-child(10) {
					@include r.sp {
						width: 1%;
						min-width: 98px;
					}
				}
			}
		}

		tr {
			border-top: 1px solid v.$color23;
			border-bottom: 1px solid v.$color23;
		}

		tbody {
			td {
				padding: 8px;
				word-break: keep-all;
				white-space: nowrap;

				@include r.sp {
					padding: 4px;
				}

				&:nth-child(2) {
					text-align: left;
				}

				&:nth-child(9),
				&:nth-child(10),
				&:nth-child(11) {
					text-align: right;
				}
			}
		}

		&.has-sticky-title {
			thead {
				@include r.sp {
					position: fixed;
					left: 0;
					top: 0;
				}
			}
		}
	}

	&.is-style1 {
		margin-top: 0;

		@include r.sp {
			background: none;
		}

		#{$root}__table {
			border-radius: 8px;

			@include r.sp {
				border-radius: 0;
			}

			thead {
				tr {
					border-top: 0;

					&:first-child {
						th {
							border-top: 0;

							&:first-child {
								border-radius: 8px 0 0 0;

								@include r.sp {
									border-radius: 0;
								}
							}

							&:last-child {
								border-radius: 0 8px 0 0;

								@include r.sp {
									border-radius: 0;
								}
							}
						}
					}

					th {
						&:nth-child(1) {
							width: 18px;
						}

						&:nth-child(2) {
							width: 40px;
						}

						&:nth-child(3) {
							width: 170px;

							@include r.sp {
								width: 135px;
							}
						}

						&:nth-child(4),
						&:nth-child(5) {
							width: 196px;

							@include r.sp {
								width: 165px;
							}
						}
					}
				}
			}

			th,
			td {
				padding: 4px;
			}

			tbody {
				background: #fff;

				td {
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5) {
						text-align: left;
					}

					&:nth-child(9),
					&:nth-child(10) {
						text-align: center;
					}
				}
			}
		}
	}

	&__topfixed {
		@include r.sp {
			overflow-x: auto;
			position: fixed;
			top: 44px;
			left: 0;
			z-index: 2;
			width: 100%;
			pointer-events: none;
		}

		&.is-active {
			.nk23_c-table24 {
				&__table {
					@include r.sp {
						transform: translateY(0);
					}
				}
			}
		}

		/* width */
		&::-webkit-scrollbar {
			width: 0;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: transparent;
		}

		.nk23_c-table24 {
			&__table {
				@include r.sp {
					width: 960px;
					transition: 0.3s ease-in-out;
					transform: translateY(-100%);
				}

				tbody {
					@include r.sp {
						display: none;
					}
				}

				thead {
					@include r.sp {
						pointer-events: none;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table25 {
	@include r.tablestyle;
	$r: &;

	&__wrap {
		position: relative;
		margin: 0 auto;
	}

	&__inner {
		width: 100%;
		padding-bottom: 8px;

		.scrollbar-track-x {
			background: #fff;
			opacity: 1;
			overflow: hidden;
			border-radius: 4px;
			height: 4px;

			.scrollbar-thumb {
				background: v.$color19;
				height: 4px;
			}
		}
	}

	&__content {
		min-width: 1440px;
		margin: 0 auto;

		@include r.sp {
			min-width: 767px;
		}

		#disp-chg-sp {
			.tb01 {
				@include r.sp {
					table-layout: fixed;
					line-height: 1.3;
				}

				thead {
					tr {
						&:first-child {
							th {
								&:first-child {
									width: 40px;
								}

								&:nth-child(2) {
									width: 48px;
								}

								&:nth-child(3) {
									width: 70px;
								}

								&:nth-child(4) {
									width: 85px;
								}
							}
						}
					}
				}

				tbody {
					tr {
						&:nth-child(odd) {
							td {
								&:nth-child(4),
								&:nth-child(5) {
									text-align: left;
								}
							}
						}
					}
				}
			}
		}
	}

	&.is-style1 {
		margin-bottom: 8px;

		@include r.sp {
			margin-bottom: 0;
		}

		#{$r}__content {
			min-width: 100%;

			#disp-chg-sp {
				.tb01 {
					thead {
						tr {
							&:first-child {
								th {
									&:first-child {
										width: 140px;
									}

									&:nth-child(2) {
										width: auto;
									}
								}
							}
						}
					}
				}
			}
		}

		#{$r}__inner {
			padding-bottom: 0;
		}

		.nk23_c-pagging {
			border-radius: 0;
			border-top: 1px solid #c9c9c9;

			&:last-child {
				border-radius: 0 0 8px 8px;

				@include r.sp {
					border-radius: 0;
					border-top: 0;
				}
			}
		}

		.table-wrapper {
			border-radius: 0;
		}

		#disp-chg-pc {
			.tb01 {
				thead {
					th {
						&:nth-child(4) {
							width: auto;
						}

						&:nth-child(5) {
							width: 290px;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-table26 {
	border-radius: 8px;
	overflow: hidden;
	font-size: 1.3rem;
	color: v.$color0;

	@include r.sp {
		border-radius: 0;
	}

	td {
		background: #fff;

		@include r.sp {
			font-size: 1.4rem;
		}

		&:first-child {
			@include r.sp {
				border-right: none;
				text-align: left;
			}
		}

		a {
			color: v.$color-link;
		}
	}

	.al_right {
		text-align: right !important;
	}

	.al_left {
		text-align: left !important;
	}

	.nowrap {
		white-space: nowrap;
	}

	tr {
		th {
			background: v.$color29;
			color: #fff;
			font-weight: 700;
			text-align: center;
		}

		&:nth-child(2) {
			th {
				background: v.$color34;
				color: v.$color0;
			}
		}

		&:last-child {
			td {
				@include r.sp {
					border-bottom: none !important;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.js-tableSort01 {
	&.sort-asc,
	&.sort-desc {
		.js-sort-show {
			display: table-cell;
		}
	}

	.js-sort-show {
		display: none;
	}
}

.js-tableHeadClone {
	position: fixed;
	left: 0;
	bottom: 100%;
	z-index: 100;
	overflow: visible;
	transition:
		top 0.2s ease-in-out,
		opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out;
	opacity: 0;
	visibility: hidden;

	&.show {
		opacity: 1;
		visibility: visible;
		bottom: auto;
	}
}
