@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

//utility
.nk23_u-minw1200 {
	min-width: 1200px !important;
}

.nk23_u-maxw1200 {
	max-width: 1200px !important;
}

.nk23_u-minw960 {
	min-width: 960px !important;
}

.nk23_u-maxw960 {
	max-width: 960px !important;
}

.nk23_u-b-radius-0 {
	border-radius: 0 !important;
}

.nk23_u-b-left-0 {
	border-left: 0 !important;
}

.nk23_u-b-right-0 {
	border-right: 0 !important;
}

.nk23_u-left {
	text-align: left;
}

.nk23_u-center {
	text-align: center;
}

.nk23_u-underline {
	text-decoration: underline;
	display: inline-block;
}

.nk23_u-txtRight {
	text-align: right !important;
}

.nk23_u-colorlink {
	@include r.hover {
		text-decoration: underline;
	}
}

.nk23_u-flex-end {
	display: flex;
	justify-content: flex-end;
}

.nk23_u-text-vertical {
	writing-mode: vertical-rl;
	text-align: center;
	word-break: keep-all;
}

.nk23_u-mt16 {
	margin-top: 16px !important;
	@include r.sp {
		margin-top: 8px !important;
	}
}

.nk23_u-mb16 {
	margin-bottom: 16px !important;
	@include r.sp {
		margin-bottom: 8px !important;
	}
}

.nk23_u-d-none {
	display: none !important;
}

.nk23_u-pb0 {
	padding-bottom: 0 !important;
}

.nk23_u-text12 {
	line-height: 19px;
	font-size: 1.2rem !important;
}
.nk23_u-text10 {
	line-height: 14px;
	font-size: 1rem !important;
}
.nk23_u-text11 {
	line-height: 14px;
	font-size: 1.1rem !important;
}
.nk23_u-text16 {
	line-height: 14px;
	font-size: 1.6rem !important;
}
.nk23_u-text19 {
	font-size: 1.9rem !important;
}

.txt_sp {
	margin-right: 4px;
}
.nk23_u-txtWrap {
	white-space: normal !important;
	word-break: normal !important;
}
.nk23_u-d-inline-b {
	display: inline-block;
}

.nk23_c-pt16 {
	padding-top: 16px;
}

.nk23_u-w25 {
	width: 25%;
}

.nk23_u-w100 {
	width: 100%;
}

.nk23_u-p16 {
	padding: 16px !important;
}

.nk23_u-disable {
	pointer-events: none;
	opacity: 0.25;
}

$ucolors: (
	"color0" #221f1f,
	"color1" #0b318f,
	"color2" #b8c5e6,
	"color3" #f2f4f9,
	"color4" #3a993a,
	"color5" #a3cca3,
	"color6" #f2faf2,
	"color7" #cc2a29,
	"color8" #e6a1a1,
	"color9" #faf2f2,
	"color10" #338f8f,
	"color11" #add9d9,
	"color12" #f2fafa,
	"color13" #e63434,
	"color14" #3570e6,
	"color15" #ffd83e,
	"color16" #4ba642,
	"color17" #f59331,
	"color18" #eb6ad5,
	"color19" #c9c9c9,
	"color20" #f27723,
	"color21" #cb6200,
	"color22" #c4c4c4,
	"color23" #d2d2d2,
	"color24" #ccad14,
	"color25" #f2ce18,
	"color26" #0050a8,
	"color27" #003285,
	"color28" #f2f2f2,
	"color29" #404040,
	"color33" #e63123,
	"color34" #f7f7f7,
	"color35" #f2d1ce,
	"color36" #dfe8f7,
	"color37" #fff9e6,
	"color38" #ffbb00,
	"color39" #ffecb3,
	"color40" #ffd966,
	"color41" #f5f5f5,
	"color42" #d9e6ff,
	"color43" #ffcccc,
	"color44" #ccffcc,
	"color45" #ccffff,
	"color46" #c7c6c6,
	"color52" #fff9e6,
	"color53" #40b324,
	"colorlink" #2461ee,
	"color54" rgba(230, 52, 52, 0.25)
);
$ubgcolor: (
	"color0" #221f1f,
	"color1" #0b318f,
	"color2" #b8c5e6,
	"color3" #f2f4f9,
	"color4" #3a993a,
	"color5" #a3cca3,
	"color6" #f2faf2,
	"color7" #cc2a29,
	"color8" #e6a1a1,
	"color9" #faf2f2,
	"color10" #338f8f,
	"color11" #add9d9,
	"color12" #f2fafa,
	"color13" #e63434,
	"color14" #3570e6,
	"color15" #ffd83e,
	"color16" #4ba642,
	"color17" #f59331,
	"color18" #eb6ad5,
	"color19" #c9c9c9,
	"color20" #f27723,
	"color21" #cb6200,
	"color22" #c4c4c4,
	"color23" #d2d2d2,
	"color24" #ccad14,
	"color25" #f2ce18,
	"color26" #0050a8,
	"color27" #003285,
	"color28" #f2f2f2,
	"color29" #404040,
	"color33" #e63123,
	"color34" #f7f7f7,
	"color35" #f2d1ce,
	"color36" #dfe8f7,
	"color37" #fff9e6,
	"color38" #ffbb00,
	"color39" #ffecb3,
	"color40" #ffd966,
	"color41" #f5f5f5,
	"color42" #d9e6ff,
	"color43" #ffcccc,
	"color44" #ccffcc,
	"color45" #ccffff,
	"color46" #c7c6c6,
	"color52" #fff9e6,
	"color53" #40b324,
	"colorlink" #2461ee,
	"color54" rgba(230, 52, 52, 0.25)
);

@each $name, $color in $ucolors {
	.nk23_u-#{$name} {
		color: $color;
	}
}
@each $name, $color in $ubgcolor {
	.nk23_u-bg-#{$name} {
		background-color: $color;
	}
}
.nk23_u-bg-color15 {
	//color: v.$color0 !important;
	text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}
.nk23_u-bg-color13,
.nk23_u-bg-color14,
.nk23_u-bg-color15,
.nk23_u-bg-color16,
.nk23_u-bg-color17,
.nk23_u-bg-color18 {
	text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.is-bg-urawa {
	background: v.$urawa-color !important;
}

.is-bg-urawa-thin {
	background-color: #b8c5e6 !important;
	.nk23_c-block01__list__item,
	.nk23_c-list01__item {
		&:not(:last-child) {
			border-color: v.$urawa-color-light;
		}
	}
	.nk23_c-block01__bottom.js-mheight .nk23_c-block01__col:first-child,
	.nk23_c-block01__col:last-child .nk23_c-block01__list__item:first-child {
		border-color: v.$urawa-color-light !important;
	}
	.nk23_c-block01__label.is-label-active {
		background: url(p.$path + "images/common/bg-label-urawa.svg") no-repeat bottom center / 100%;
	}
}

.is-bg-urawa-thin2 {
	background-color: #d9e6ff !important;
}

.is-bg-urawa-thin3 {
	background-color: #d9e6ff !important;
}

.is-bg-funabashi {
	background-color: v.$funabashi-color !important;
}

.is-bg-funabashi-thin {
	background-color: #a3cca3 !important;
	.nk23_c-block01__list__item,
	.nk23_c-list01__item {
		&:not(:last-child) {
			border-bottom-color: v.$funabashi-color-light;
		}
	}
	.nk23_c-block01__bottom.js-mheight .nk23_c-block01__col:first-child,
	.nk23_c-block01__col:last-child .nk23_c-block01__list__item:first-child {
		border-color: v.$funabashi-color-light !important;
	}
	.nk23_c-block01__label.is-label-active {
		background: url(p.$path + "images/common/bg-label-funabashi.svg") no-repeat bottom center / 100%;
	}
}

.is-bg-funabashi-thin2 {
	background-color: #ccffcc !important;
}

.is-bg-ooi {
	background-color: v.$ooi-color !important;
}

.is-bg-ooi-thin {
	background-color: #e6a1a1 !important;
	.nk23_c-block01__list__item,
	.nk23_c-list01__item {
		&:not(:last-child) {
			border-bottom-color: v.$ooi-color-light;
		}
	}
	.nk23_c-block01__bottom.js-mheight .nk23_c-block01__col:first-child,
	.nk23_c-block01__col:last-child .nk23_c-block01__list__item:first-child {
		border-color: v.$ooi-color-light !important;
	}

	.nk23_c-block01__label.is-label-active {
		background: url(p.$path + "images/common/bg-label-ooi.svg") no-repeat bottom center / 100%;
	}
}

.is-bg-ooi-thin2 {
	background-color: #ffcccc !important;
}

.is-bg-kawasaki {
	background-color: v.$kawasaki-color !important;
}

.is-bg-kawasaki-thin {
	background-color: #add9d9 !important;
	.nk23_c-block01__list__item,
	.nk23_c-list01__item {
		&:not(:last-child) {
			border-bottom-color: v.$kawasaki-color-light;
		}
	}

	.nk23_c-block01__bottom.js-mheight .nk23_c-block01__col:first-child,
	.nk23_c-block01__col:last-child .nk23_c-block01__list__item:first-child {
		border-color: v.$kawasaki-color-light !important;
	}
	.nk23_c-block01__label.is-label-active {
		background: url(p.$path + "images/common/bg-label-kawasaki.svg") no-repeat bottom center / 100%;
	}
}

.is-bg-kawasaki-thin2 {
	background-color: #ccffff !important;
}

.is-color-white {
	color: #fff !important;
}

/*
-------------------*/
.nk23_u-noto-mono {
	@include r.noto-mono;
}

.nk23_u-left {
	text-align: left !important;
}

.nk23_u-right {
	text-align: right !important;
}

.nk23_u-center {
	text-align: center !important;
}

.nk23_u-color {
	color: v.$color-link;
}

.nk23_u-bg-white {
	background-color: #fff;
}

.nk23_u-color1 {
	color: v.$color13;
}

.nk23_u-color13 a {
	color: v.$color13 !important;
}

.nk23_u-d-flex {
	display: flex;
	align-items: center;
}
.nk23_u-bold {
	font-weight: bold !important;
}

.nk23_u-line {
	display: block;
	margin: 4px -4px;
	height: 1px;
	background: v.$color19;
	@include r.sp {
		margin: 4px -2px;
	}
}
.nk23_u-line-v {
	display: block;
	margin: -4px 0 -12px;
	width: 1px;
	min-height: 43px;
	background: v.$color19;
	@include r.sp {
		margin: -4px 0 -14px;
		// Add strix St
		min-height: 50px;
		// Add strix End
	}
}
.nk23_u-color-w {
	color: #ffffff !important;
}

.nk23_u-mail {
	color: v.$color0 !important;

	@include r.hover {
		text-decoration: underline;
		opacity: 1;
	}
}

.nk23_u-grlabel {
	display: block;
	margin-bottom: -8px;
}

.nk23_u-selects {
	&.list_inline {
		// .nk23_u-selects.list_inline li .
		li {
			@include r.sp {
				width: 144px;
				margin-bottom: 4px;
			}

			@include r.w374 {
				width: 118px;
			}

			&.w15px {
				width: max-content;

				@include r.sp {
					font-size: 1.2rem;
					display: flex;
					align-items: center;
				}

				&:last-child {
					@include r.sp {
						margin-right: 0;
					}
				}
			}
		}
	}
}

.nk23_u-checkboxs {
	input {
		@include r.sp {
			margin-right: 4px;
		}
	}

	// .nk23_u-checkboxs label .
	label {
		@include r.sp {
			margin-bottom: 16px;
			align-items: center;
			display: flex;
			white-space: nowrap;
			width: max-content;
		}
	}

	&.list_inline {
		li {
			margin-right: 16px;
			width: max-content;
		}

		&.is-style1 {
			li {
				width: max-content;
				width: 60px;

				@include r.sp {
					margin-right: 4px;
				}
			}

			// .nk23_u-checkboxs.list_inline.is-style1 label .
			label {
				@include r.sp {
					font-size: 1rem;
				}
			}
		}
	}
}

.nk23_u-inputtels {
	li {
		width: max-content !important;

		@include r.sp {
			font-size: 1.2rem;
			display: flex;
			align-items: center;
		}

		&:not(:last-child) {
			@include r.sp {
				margin-right: 8px;
			}
		}
	}
}

.nk23_u-btns {
	display: flex;
	justify-content: center;

	&.list_inline {
		width: 100%;
		max-width: 100%;

		li {
			width: max-content;
		}
	}

	input {
		height: 23px;
		width: auto;
		background-color: transparent;

		@include r.pc {
			margin-right: 16px;
		}
	}

	li {
		&:not(:last-child) {
			input {
				@include r.sp {
					margin-right: 16px;
				}
			}
		}
	}

	&.list_inline {
		justify-content: center;

		li {
			@include r.sp {
				margin-right: 0;
				width: max-content;
			}
		}
	}
}

.nk23_u-boxtable {
	border-radius: 8px;
	padding-bottom: 24px;
	background-color: #fff;

	.round-cnr {
		margin-top: 4px;
		padding: 0 4px;
	}

	// .nk23_u-boxtable .nk23_c-text01 .
	.nk23_c-text01 {
		margin-top: 24px;

		&.is-style4 {
			margin-top: 4px;
			padding: 0 4px !important;

			&:last-child {
				@include r.sp {
					margin-bottom: -8px;
				}
			}
		}
	}

	.title02 {
		padding: 8px 4px 0;
	}
}

.nk23_u-hide {
	display: none !important;
}

.nk23_u-bg-cancel {
	font-size: 1.2rem;
	white-space: nowrap;
}

.nk23_u-w175 {
	width: 175px;
}
