@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/
.nk23_c-breadcrumb {
  margin-bottom: 16px;
  font-size: 1.2rem;
  line-height: 1.9rem;

  &__item {
    display: inline;

    // .nk23_c-breadcrumb__item:not(:last-child) .
    &:not(:last-child) {
      a,
      span {
        &:after {
          margin: 0 8px;
          width: 6px;
          position: relative;
          height: 18px;
          top: 4px;
          display: inline-block;
          content: '';
          pointer-events: none;
          background: url(p.$path + 'images/common/icon/icon_right1.svg')
            no-repeat center / 6px auto;
        }
      }
    }
  }

  a,
  span {
    display: inline;
  }

  a {
    color: v.$color-link;

    @include r.hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
