@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.nk23_l-side {
	max-width: 1280px;
	padding: 0 16px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	@media screen and (max-width: 1280px) {
		padding: 0;
	}

	@include r.pc {
		display: flex;
		justify-content: space-between;
	}

	@include r.sp {
		padding: 0 8px;
	}

	&__content {
		@include r.pc {
			flex: 1;
			max-width: 852px;
		}
	}

	&__sidebar {
		@include r.pc {
			padding-left: 16px;
			flex: 1;
			min-width: 380px;
		}

		@include r.sp {
			margin-top: 8px;
		}

		.nk23_c-block02 {
			& + .nk23_c-block03 {
				margin-top: 16px;

				@include r.sp {
					margin-top: 8px;
				}
			}
		}

		.nk23_c-block03 {
			&:not(:last-child) {
				margin-bottom: 16px;

				@include r.sp {
					margin-bottom: 8px;
				}
			}

			& + .nk23_c-block03 {
				margin-top: 16px;

				@include r.sp {
					margin-top: 8px;
				}
			}
		}
	}

	&.is-style1 {
		max-width: 992px;

		@include r.pc {
			flex-direction: row-reverse;
		}

		.nk23_l-side {
			&__content {
				@include r.pc {
					max-width: 770px;
				}
			}

			&__sidebar {
				@include r.pc {
					padding-right: 16px;
					flex: 0;
					padding-left: 0;
					min-width: 196px;
				}
			}
		}
	}
}
