@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;
%borderiput {
	border-radius: 8px;
	border: 1px solid v.$color19;
}

/*------------------------------------------------------------
form
------------------------------------------------------------*/

.nk23_0x {
}

/*
------------------------------------------------------------*/

.nk23_c-dropdown {
	position: relative;
	z-index: 3;
	min-height: 38px;
	background: url(p.$path + 'images/common/icon/icon_down.svg') no-repeat center
		right 7px / 10px auto;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid v.$color19;
	text-align: center;
	&__wrap {
		position: relative;
	}
	select {
		display: none;
	}
	.select-selected.select-arrow-active:after {
		border-color: transparent transparent #fff transparent;
		top: 7px;
	}
	.select-items div,
	.select-selected {
		color: v.$color0;
		padding: 8px 16px;
		cursor: pointer;
		user-select: none;
	}
	.select-items {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 99;
		background-color: #fff;
		text-align: center;
		max-height: 50vh;
		overflow-x: hidden;
		overflow-y: auto;
		border: 1px solid v.$color0;
	}
	.select-hide {
		display: none;
	}
	.select-items div:hover,
	.same-as-selected {
		background-color: rgba(0, 0, 0, 0.1);
	}
}

/*
------------------------------------------------------------*/

.nk23_c-checkbox {
	@include r.formcontrol;
}

/*
------------------------------------------------------------*/

.nk23_c-checkbox2 {
	$root: &;
	&__label {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 2.2rem;
		color: v.$color0;
		min-height: 24px;
		position: relative;
		padding-left: 32px;
		cursor: pointer;
		@include r.max-w(374px) {
			font-size: 1.2rem;
			padding-left: 28px;
		}
	}
	input {
		display: inline-block;
		width: 24px;
		height: 24px;
		background-color: #fff;
		border: 1px solid v.$color0;
		border-radius: 4px;
		position: absolute;
		top: -1px;
		left: 0;
		pointer-events: none;
		background-repeat: no-repeat;
		background-position: center top 5px;
		background-size: 15px auto;
		transition: all 0.3s ease-in-out;
		&:checked {
			background-color: v.$color0;
			background-image: url(p.$path + 'images/common/icon/icon_checked-white.svg');
		}
	}
	&.is-style1 {
		#{$root}__label {
			padding-left: 24px;
			display: block;
			width: 24px;
			margin: 0 auto;
		}
		// .nk23_c-checkbox2.is-style1 input[type=radio] .
		input[type='radio'] {
			border-radius: 50%;
			background-size: 12px auto;
			background-position: center;
		}
	}
	&.is-style2 {
		#{$root}__label {
			@include r.pc {
				padding-left: 24px;
				font-size: 1.3rem;
				line-height: 1.9rem;
			}
		}
		input {
			@include r.pc {
				background-position: center top 4px;
				background-size: 10px auto;
				top: 2px;
				width: 16px;
				height: 16px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-checkbox3 {
	&__label {
		min-width: 17px;
		text-align: center;
		display: inline-block;
		font-size: 1.4rem;
		line-height: 2.2rem;
		color: #fff;
		position: relative;
		padding-bottom: 20px;
		cursor: pointer;
		@include r.sp {
			font-size: 1.3rem;
		}
	}
	input {
		display: inline-block;
		width: 17px;
		height: 17px;
		background-color: #fff;
		border: 1px solid v.$color0;
		border-radius: 4px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		pointer-events: none;
		transition: all 0.3s ease-in-out;
		background-size: 10px auto;
		background-position: center top 4px;
		background-repeat: no-repeat;
		&:checked,
		&.is-checked {
			background-color: v.$color0;
			background-image: url(p.$path + 'images/common/icon/icon_checked-white.svg');
			border-color: #fff;
		}
		&.no-checked {
			border-color: v.$color0 !important;
			background-color: #fff;
			background-image: none;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-radio {
	@include r.formcontrol;
	input {
		border-radius: 50%;
	}
	&.is-style1 {
		.nk23_c-radio__label {
			@include r.pc {
				font-size: 1.3rem;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-radiobtn01 {
	input:checked ~ .nk23_c-btn01 {
		background-color: v.$color0;
		border-color: v.$color19;
		color: #fff;
		box-shadow: none;
		font-weight: 700;
		top: 2px;
	}
	input {
		display: none;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-input {
	// .nk23_c-input input .
	input {
		padding: 0 7px;
		width: 150px;
		height: 40px;
		border: 1px solid v.$color19;
		border-radius: 8px;
		font-size: 1.4rem;
		display: inline-block;
		@include r.sp {
			border-radius: 4px;
			width: 100%;
		}
		&::-webkit-input-placeholder {
			color: v.$color19;
		}
		&:-ms-input-placeholder {
			color: v.$color19;
		}
		&::placeholder {
			color: v.$color19;
		}
	}
	&.is-disable {
		input {
			pointer-events: none;
			background-color: v.$color19;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-selectbox {
	// .nk23_c-selectbox__text .
	&__text {
		margin-right: 5px;
		top: -1px;
		display: inline-block;
		position: relative;
	}
	// .nk23_c-selectbox__select .
	&__select,
	select {
		padding: 0 20px 0 9px;
		@extend %borderiput;
		width: 52px;
		text-align: center;
		height: 28px;
		font-size: 1.4rem;
		background-image: none !important;
		background: #fff url(p.$path + 'images/common/icon/icon_down.svg') no-repeat
			center right 7px / 10px auto !important;
		color: v.$color19;
		cursor: pointer;
		option:not(:first-child) {
			color: v.$color19;
		}
		option:not(:first-child) {
			color: v.$color0;
		}
		&.is-hide {
			display: none;
		}
		&.is-chose {
			color: v.$color0;
		}
	}
	&.is-style1 {
		.nk23_c-selectbox__select {
			padding-left: 8px;
			font-size: 1.6rem;
			line-height: 1.5;
			width: 102px;
			height: 40px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-selectbox2 {
	// .nk23_c-selectbox__text .
	&__text {
		margin-right: 5px;
		top: -1px;
		display: inline-block;
		position: relative;
	}
	// .nk23_c-selectbox__select .
	&__select,
	select {
		padding: 8px 32px 8px 16px;
		border: 1px solid v.$color19;
		width: 100%;
		height: 48px;
		font-size: 1.6rem;
		font-weight: bold;
		background-image: none !important;
		background: #fff url(p.$path + 'images/common/icon/icon_down.svg') no-repeat
			center right 15px / 16px auto !important;
		color: v.$color0;
		&.is-chose {
			color: v.$color0;
		}
	}
}
