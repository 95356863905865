@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
list
------------------------------------------------------------*/
.nk23_c-listcheck {
	display: flex;
	flex-wrap: wrap;

	@include r.pc {
		margin: 0 -2px;
	}

	@include r.sp {
		justify-content: space-between;
	}

	&__item {
		width: calc(100% / 8);
		margin-bottom: 2px;

		@include r.sp {
			width: calc(50% - 4px);
			margin-bottom: 16px;
		}

		@media screen and (max-width: 767px) and (min-width: 569px) {
			width: calc(33.3333% - 4px);
			margin-bottom: 16px;
		}

		@media screen and (max-width: 568px) and (min-width: 415px) {
			width: calc(50% - 4px);
		}

		&:last-child {
			width: 100%;
		}
	}

	&.is-style1 {
		.nk23_c-listcheck__item {
			@include r.sp {
				width: calc(50% - 4px);
			}
		}
	}

	&.is-style2 {
		.nk23_c-listcheck__item {
			width: calc((100% / 6) - 12px);
			margin-left: 12px;

			@include r.sp {
				width: calc(50% - 4px);
				margin-left: 0;
			}

			&:nth-child(6n + 1) {
				margin-left: 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-listbtn {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@include r.pc {
		justify-content: center;
	}

	// .nk23_c-listbtn__item .
	&__item {
		margin-bottom: 8px;

		&.is-full {
			width: 100% !important;
			margin: 0 !important;
			padding: 0 !important;
		}

		// .nk23_c-listbtn__item:not(:last-child) .
		&:not(:last-child) {
			margin-right: 16px;

			@include r.sp {
				margin-right: 8px;
			}
		}
	}

	&.is-style1 {
		.nk23_c-listbtn {
			&__item {
				&:not(:last-child) {
					margin-right: 8px;
					margin-bottom: 8px;
				}
			}
		}

		// .nk23_c-listbtn.is-style1 .nk23_c-btn01 .
		.nk23_c-btn01 {
			padding: 0 15px 1px;
			min-width: 46px;
			width: auto;
		}
	}

	// .nk23_c-listbtn .nk23_c-btn05 .
	.nk23_c-btn05 {
		width: auto;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list01 {
	$root: &;

	//-------------------------------------------------------
	&__item {
		background: #fff;
		border-top: 1px solid v.$color19;
		padding: 0 0 10px 16px;
		position: relative;
		&:first-child {
			border-top: 1px solid v.$color19;
		}

		@include r.sp {
			padding: 0 0 10px 8px;

			&:nth-of-type(-n + 2) {
				border-top: none;
			}
			&:not(:last-child) {
				border-bottom: 1px solid v.$color19;
			}
		}

		&.gray {
			background: v.$color36;
		}

		&.is-bg1 {
			background-color: v.$color55;
		}
	}

	//-------------------------------------------------------
	&__heading {
		//display: flex;
		//flex-wrap: wrap;
		//align-items: flex-start;
		//justify-content: space-between;
		@include r.pc {
			//width: 100%;
		}
	}

	//-------------------------------------------------------
	&__detail {
		display: flex;
		flex-wrap: wrap;
		flex: 1;
	}

	//-------------------------------------------------------
	&__tag1 {
		display: block;
		width: 40px;
		min-height: 32px;
		background: url(p.$path + "images/common/bg-label.svg") no-repeat bottom center / 100%;
		text-align: center;
		font-size: 1.4rem;
		line-height: 1;
		color: #ffffff;
		padding: 6px 0 0;
		position: relative;
		// margin: 0 0 8px;

		// &::after {
		// 	content: "";
		// 	width: 0;
		// 	height: 0;
		// 	border-style: solid;
		// 	border-width: 8px 20px 0 20px;
		// 	border-color: v.$color0 transparent transparent transparent;
		// 	position: absolute;
		// 	left: 0;
		// 	bottom: -8px;
		// }
	}

	//-------------------------------------------------------
	&__taglist {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		margin: 4px 0 0 8px;
	}

	//-------------------------------------------------------
	&__tagitem {
		font-size: 1.4rem;
		line-height: 1.45;
		color: v.$color0;

		@include r.sp {
			font-size: 1.2rem;
			line-height: 1.5;
		}

		&:not(:last-child) {
			margin-right: 8px;
		}

		&.red {
			color: v.$color13;
		}
	}

	//-------------------------------------------------------
	&__tag2 {
		display: block;
		background: v.$color13;
		font-size: 1rem;
		line-height: 1;
		color: #ffffff;
		font-weight: bold;
		padding: 4px 8px 4px 6px;
		margin: 4px -4px 0 0;
		position: relative;
		@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
			padding: 0 8px 0 6px;
			height: 16px;
			line-height: 16px;
		}

		&::before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 9px 0 9px 10px;
			border-color: v.$color13 transparent;
			position: absolute;
			top: 0;
			right: 100%;
			@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
				border-width: 8px 0 8px 5px;
				min-height: 16px;
				right: 99.7%;
			}
		}

		&::after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 4px 4px 0 0;
			border-color: v.$color35 transparent transparent transparent;
			position: absolute;
			right: 0;
			bottom: -4px;
		}

		&.gray {
			&::before {
				border-color: transparent transparent transparent v.$color36;
			}
		}

		&.is-bg1 {
			background-color: v.$color56;

			&::before {
				border-color: v.$color56 transparent;
			}
		}

		&.is-bg2 {
			background-color: v.$color16;

			&::before {
				border-color: v.$color16 transparent;
			}
		}

		&.is-bg3 {
			background-color: v.$color14;

			&::before {
				border-color: v.$color14 transparent;
			}
		}

		&.is-bg4 {
			background-color: v.$color15;
			color: v.$color0;

			&::before {
				border-color: v.$color15 transparent;
			}
		}
	}

	//-------------------------------------------------------
	&__info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 0 22px 0 0;
		margin: -2px 0 0;

		@include r.sp {
			padding: 0 22px 0 0;
			margin: 1px 0 0;
		}
	}

	//-------------------------------------------------------
	&__ttl {
		flex: 1;
		font-size: 1.6rem;
		line-height: 1.25;
		color: v.$color-link;
		font-weight: bold;

		@include r.sp {
			-webkit-line-clamp: 2;
			font-size: 1.4rem;
			line-height: 1.6;
		}

		a {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;

			@include r.hover {
				opacity: 1;
				text-decoration: underline;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: transparent;
			}
			&::after {
				content: "";
				position: absolute;
				right: 8px;
				width: 8px;
				height: 14px;
				background: url(p.$path + "images/common/icon/icon-obj_arrow.svg") no-repeat center / 8px !important;
				top: calc(50% - 7px);
			}
		}

		&:only-child {
			margin-top: 8px;

			@include r.sp {
				margin-top: 4px;
				margin-bottom: -4px;
			}
		}
	}

	//-------------------------------------------------------
	&__btn {
		// .nk23_c-list01__btn .
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding: 0 22px 0 0;
		margin: 16px 0 0;
		justify-content: flex-end;

		@include r.sp {
			gap: 8px;
			padding: 0 22px 0 14px;
		}

		&.w50-sp {
			justify-content: space-between;

			& > * {
				@include r.sp {
					width: calc(50% - 2px) !important;
					flex: none !important;
					max-width: none !important;
				}
			}
			@include r.sp {
				.nk23_c-list01__btn--row {
					width: 100% !important;
				}
			}
		}

		// .nk23_c-list01__btn .nk23_c-btn02 .
		.nk23_c-btn02 {
			flex: 1;
			min-width: max-content;

			@include r.sp {
				font-size: 1.4rem !important;
				min-height: 35px !important;
			}

			@include r.w374 {
				font-size: 1rem !important;
			}

			&.is-small2 {
				@include r.sp {
					font-size: 1.2rem !important;
					letter-spacing: -0.05em !important;
				}
			}

			&.is-small3 {
				@include r.sp {
					font-size: 1.1rem !important;
					letter-spacing: -0.05em !important;
				}
			}

			&__icon {
				@include r.w374 {
					width: 16px !important;
				}
			}
		}

		// .nk23_c-list01__btn .nk23_c-btn03 .
		.nk23_c-btn03 {
			flex: 1;
		}
		&--row {
			display: flex;
			width: 100%;
			justify-content: flex-start;
			gap: 2px 4px;
		}
	}

	.nk23_c-btn03 {
		width: 110px;
		// thanglq 091123
		max-width: max-content;
		min-width: 81px;
		border-radius: 8px;
		@include r.sp {
			max-width: none;
			min-width: auto;
		}

		&::after {
			left: -8px;
		}

		@include r.sp {
			font-size: 1.4rem;
		}
	}

	//-------------------------------------------------------
	&.is-style1 {
		#{$root}__item {
			padding: 0 8px 10px 8px;
			.nk23_c-list01__ttl a {
				&::after {
					right: 4px;
				}
			}
			.nk23_c-btn02__text {
				font-size: 1.2rem;
			}
		}

		#{$root}__tag1 {
			@include r.sp {
				line-height: 1;
			}
		}

		#{$root}__tagitem {
			@include r.sp {
				font-size: 1rem;
			}
		}

		#{$root}__info {
			padding: 0;
		}

		#{$root}__tag2 {
			//width: 100%;
			text-align: center;
			padding: 4px 8px;
			margin: 4px 8px 0;
			@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
				padding: 0 8px;
				height: 16px;
				line-height: 16px;
			}
			@include r.sp {
				margin: 4px 4px 0;
			}

			&::before {
				border-width: 9px 0 9px 5px;
				@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
					border-width: 8px 0 8px 5px;
				}
			}

			&::after {
				border-width: 9px 5px 9px 0;
				border-color: v.$color13 transparent;
				left: 100%;
				bottom: 0;
				@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
					border-width: 8px 5px 8px 0;
					left: 99.7%;
				}
			}

			&.is-bg1 {
				background-color: v.$color56;

				&::after {
					border-color: v.$color56 transparent;
				}
			}

			&.is-bg2 {
				background-color: v.$color16;

				&::after {
					border-color: v.$color16 transparent;
				}
			}

			&.is-bg3 {
				background-color: v.$color14;

				&::after {
					border-color: v.$color14 transparent;
				}
			}

			&.is-bg4 {
				background-color: v.$color15;
				color: v.$color0;

				&::after {
					border-color: v.$color15 transparent;
				}
			}
		}

		#{$root}__ttl {
			line-height: 1.38;
			margin-top: 8px;

			@include r.sp {
				-webkit-line-clamp: 4;
				line-height: 1.45;
				margin-top: 2px;
			}
		}

		// .nk23_c-list01.is-style1 .nk23_c-list01__btn .
		#{$root}__btn {
			padding: 0;
			display: flex;
			align-items: flex-end;
			gap: 6px 4px;
			margin: 8px 0 0;

			@include r.sp {
				margin: 16px 0 0;
			}

			// .nk23_c-list01.is-style1 .nk23_c-list01__btn .nk23_c-btn02, .nk23_c-list01.is-style1 .nk23_c-list01__btn .nk23_c-btn03 .
			.nk23_c-btn02,
			.nk23_c-btn03 {
				max-width: 85px;
				width: 50%;

				border-radius: 8px;
				border-color: #919599;
				box-shadow: 0 2px 0 #919599;

				@include r.sp {
					max-width: 50%;
				}
			}

			.nk23_c-btn03 {
				@include r.w374 {
					font-size: 1rem;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list02 {
	//-------------------------------------------------------
	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		&:not(:last-child) {
			margin: 0 0 16px;

			@include r.sp {
				margin: 0 0 24px;
			}
		}
	}

	//-------------------------------------------------------
	&__info {
		width: calc(100% - 120px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	//-------------------------------------------------------
	&__img {
		width: 32px;

		@include r.sp {
			width: 48px;
		}

		img {
			width: 100%;
		}
	}

	//-------------------------------------------------------
	&__ttl {
		width: calc(100% - 40px);
		font-size: 1.6rem;
		line-height: 1.5;
		color: v.$color-link;
		font-weight: bold;

		@include r.hover {
			text-decoration: underline;
		}

		@include r.sp {
			width: calc(100% - 56px);
		}
	}

	.nk23_c-btn02 {
		width: 110px;

		@include r.sp {
			font-size: 1.4rem;
			padding: 8px 4px;
			border-radius: 24px;
		}

		&__text {
			@include r.sp {
				flex-direction: row !important;
			}
		}

		&__icon {
			@include r.sp {
				width: 20px;
				margin-right: 4px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list03 {
	width: 380px;
	margin-left: auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 8px;

	@include r.sp {
		width: 100%;
	}

	//-------------------------------------------------------
	&__item {
		background: #fff;
		border-radius: 6px;

		p {
			font-size: 14px;
			padding: 4px 4px 0;
		}

		@include r.sp {
			border: 1px solid #d1d1d1;
			box-shadow: 0 2px 0 #c9c9c9;
		}
	}

	//-------------------------------------------------------
	&__link {
		display: block;
		padding: 8px 4px 4px 4px;
		position: relative;

		@include r.sp {
			padding: 8px 4px 4px 4px;
		}

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			background: rgba($color: #fff, $alpha: 0.6);
			border-radius: 4px;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		@include r.hover {
			opacity: 1;

			&::after {
				opacity: 1;
			}
		}
	}

	//-------------------------------------------------------
	&__img {
		display: block;
		position: relative;
		border: 1px solid v.$color0 !important;
		border-radius: 4px;
		overflow: hidden;

		&::after {
			content: "";
			display: block;
			padding-top: 50%;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list04 {
	&__item {
		&:not(:last-child) {
			.nk23_c-list04__link {
				border-bottom: 1px solid #c7c6c6;
			}
		}
	}

	&__link {
		position: relative;
		width: 100%;
		font-size: 1.4rem;
		line-height: 20px;
		padding: 0 40px 0 16px;
		height: 52px;
		line-height: 52px;
		color: v.$color-link;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;

		@include r.sp {
			font-size: 1.2rem;
			height: 33px;
			line-height: 33px;
			padding: 0 24px 0 0;
		}

		@include r.hover {
			text-decoration: underline;
			opacity: 1;
		}

		&::before {
			content: "";
			position: absolute;
			right: 16px;
			top: calc(50% - 6px);
			width: 8px;
			height: 12px;
			background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center;

			@include r.sp {
				width: 6px;
				height: 9px;
				background-size: 100%;
				right: 8px;
				top: calc(50% - 4px);
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list05 {
	@include r.sp {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		position: relative;
		display: block;
		padding-left: 20px;
		width: 100%;
		font-size: 1.4rem;
		line-height: 20px;
		margin: 4px 0;

		@include r.sp {
			width: 50%;
		}

		@include r.max-w(340px) {
			padding-left: 10px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 11px;
			top: 9px;
			width: 4px;
			height: 4px;
			border-radius: 100%;
			background: v.$color0;

			@include r.max-w(340px) {
				left: 4px;
			}
		}

		a {
			color: v.$color-link;

			@include r.hover {
				text-decoration: underline;
				opacity: 1;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list06 {
	$root: &;

	&__item {
		&:not(:last-child) {
			.nk23_c-list06__link {
				border-bottom: 1px solid #c7c6c6;
			}
		}

		&:last-child {
			.nk23_c-list06__link {
				padding-bottom: 0;
			}
		}
	}

	&__link {
		position: relative;
		width: 100%;
		font-size: 1.4rem;
		line-height: 16px;
		padding: 8px 40px 8px 0;

		@include r.sp {
			padding-left: 8px;
			padding-right: 20px;
		}

		&::before {
			content: "";
			position: absolute;
			right: 16px;
			top: calc(50% - 6px);
			width: 8px;
			height: 12px;
			background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center;

			@include r.sp {
				right: 8px;
				top: calc(50% - 4px);
				width: 6px;
				height: 9px;
				background-size: 100%;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		position: relative;

		#{$root}__txt {
			&__layer1 {
				&::after {
					content: "";
					display: inline-block;
					width: 0;
					height: 20px;
				}
			}
		}

		&.is-new,
		&.is-image.is-new {
			#{$root}__txt {
				&__layer1 {
					max-width: calc(100% - 44px);

					@include r.sp {
						max-width: 100%;
						position: relative;

						&::after {
							content: "NEW";
							display: inline-block;
							width: 37px;
							height: 20px;
							line-height: 20px;
							background: #e63123;
							color: #ffffff;
							border-radius: 4px;
							text-transform: uppercase;
							font-size: 1.2rem;
							font-weight: bold;
							margin-left: 4px;
							text-align: center;
						}
					}
				}

				&::after {
					content: "NEW";
					display: inline-block;
					width: 44px;
					height: 20px;
					line-height: 20px;
					background: #e63123;
					color: #ffffff;
					border-radius: 4px;
					text-transform: uppercase;
					font-size: 1.2rem;
					font-weight: bold;
					margin-left: 8px;
					text-align: center;

					@include r.sp {
						margin-left: 4px;
						width: 36px;
						content: none;
					}
				}
			}
		}

		&.is-image {
			#{$root}__txt {
				&__layer1 {
					&::after {
						content: "";
						display: inline-block;
						position: relative;
						width: 26px;
						height: 20px;
						background: url(p.$path + "images/common/camera.svg") no-repeat center / cover;
						margin-left: 8px;

						@include r.sp {
							margin-left: 4px;
							content: none;
						}
					}
				}

				&__layer2 {
					max-width: calc(100% - 26px);

					@include r.sp {
						max-width: 100%;

						&::after {
							content: "";
							display: inline-block;
							position: relative;
							top: 4px;
							width: 26px;
							height: 20px;
							background: url(p.$path + "images/common/camera.svg") no-repeat center / cover;
							margin-left: 8px;
						}
					}
				}
			}
		}

		@include r.hover {
			#{$root}__txt__layer2 {
				text-decoration: underline;
				opacity: 1;
			}
		}
	}

	&__img {
		width: 72px;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		img {
			max-width: 40px;
		}

		@include r.sp {
			width: 64px;
			justify-content: flex-start;

			img {
				max-width: none;
				width: 56px;
			}
		}
	}

	&__cat-name {
		width: 100%;
		text-align: center;
		font-size: 1.2rem;
		font-weight: bold;
		margin-top: 3px;

		@include r.sp {
			width: initial;
			margin-right: 8px;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		width: calc(100% - 72px);

		#{$root}__grtag {
			display: flex;
			align-items: center;

			@include r.sp {
				width: 100%;
			}
		}

		@include r.sp {
			flex-wrap: wrap;
			width: calc(100% - 64px);
		}

		#{$root}__bag {
			width: 64px;
			height: 24px;
			border: 1px solid #0b8f8f;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			font-size: 1.2rem;
			font-weight: bold;
			margin-right: 8px;

			@include r.sp {
				font-size: 1rem;
				height: 18px;
				width: 56px;
			}

			&.is-border1 {
				border-color: #378f0b;
			}

			&.is-border2 {
				border-color: #b32d24;
			}

			&.is-bg1 {
				background-color: v.$color15;
				border: none;
			}
		}

		#{$root}__tag {
			width: 60px;
			height: 24px;
			background: v.$color20;
			color: #ffffff;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			font-size: 1.2rem;
			font-weight: bold;
			margin-left: 8px;

			@include r.sp {
				font-size: 1rem;
				height: 18px;
				width: 54px;
			}

			&.is-style1 {
				background: #0b8f8f;
			}
		}

		#{$root}__time {
			font-size: 1.4rem;

			@include r.sp {
				font-size: 1.2rem;
			}
		}
	}

	&__text {
		display: flex;
		align-items: center;
	}

	&__txt {
		color: v.$color-link;
		margin-left: 16px;
		display: flex;

		&__layer1 {
			display: flex;
			align-items: center;
			overflow: hidden;

			@include r.sp {
				display: inline-block;
			}
		}

		&__layer2 {
			max-width: fit-content;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			text-overflow: ellipsis;

			@include r.sp {
				max-width: 100%;
				display: contents;
				overflow: initial;
				text-overflow: initial;
			}
		}

		@include r.sp {
			margin-left: 0;
			line-height: 22.4px;
			padding-top: 3px;
			display: block;
		}
	}

	&__icon {
		margin-left: 8px;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list07 {
	$root: &;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@include r.sp {
		flex-wrap: wrap;
	}

	&__item {
		width: calc(50% - 8px);
		display: flex;

		@include r.sp {
			width: 100%;

			&:last-child {
				margin-top: 16px;
			}
		}

		&.no-img {
			#{$root}__content {
				width: 100%;

				@include r.sp {
					margin-left: 118px;
					width: calc(100% - 118px);
				}
			}
		}
	}

	&__link {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include r.hover {
			opacity: 1;

			#{$root}__ttl {
				text-decoration: underline;
			}
		}
	}

	&__img {
		overflow: hidden;
		width: 102px;
		height: 100px;
		border-radius: 4px;
		border: 1px solid #d2d2d2;
		margin-right: 8px;

		img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			margin-right: 16px;
		}
	}

	&__content {
		font-size: 1.4rem;
		line-height: 22px;
		margin-left: 8px;
		width: calc(100% - 118px);

		#{$root}__ttl {
			font-size: 1.6rem;
			font-weight: bold;
			color: v.$color-link;
			margin-bottom: 8px;
		}
	}
}

/*
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
.nk23_c-list08 {
	$root: &;
	background: #fff;
	border-radius: 8px;

	&__item {
		border-bottom: 1px solid v.$color19;

		&:last-child {
			border-bottom: none;
		}
	}

	// .nk23_c-list08__link .
	&__link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 42px 16px 16px;
		font-size: 1.4rem;
		line-height: 20px;
		position: relative;

		@include r.hover {
			opacity: 0.5;
		}

		@include r.sp {
			padding: 16px 24px 16px 8px;
			font-size: 1.3rem;
			line-height: 19px;
		}

		@include r.max-w(374px) {
			font-size: 1.2rem;
			line-height: 18px;
		}

		&::before {
			content: "";
			position: absolute;
			right: 16px;
			top: calc(50% - 4px);
			width: 6px;
			height: 10px;
			background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center / 100%;

			@include r.sp {
				width: 6px;
				height: 9px;
				background-size: 100%;
				right: 8px;
				top: calc(50% - 4px);
			}
		}
	}

	// nk23_c-list08__col1 .
	&__col1 {
		font-weight: bold;
		width: 100%;
		max-width: 50%;
		font-size: 1.6rem;
		line-height: 2.4rem;

		@include r.sp {
			font-size: 1.4rem;
			line-height: 2rem;
			width: max-content;
			max-width: 40%;
		}

		@include r.max-w(374px) {
			span {
				display: block;
			}
		}

		// .nk23_c-list08__col1 .nk23_c-list08__text .
		.nk23_c-list08__text {
			font-size: 1.4rem;
			line-height: 2rem;

			@include r.sp {
				font-size: 1.2rem;
				line-height: 1.8rem;
			}
		}
	}

	&__colorItem {
		width: 25px;
		height: 25px;
		text-align: center;
		line-height: 25px;
		color: #ffffff;
		font-weight: bold;
		font-size: 1.3rem;
	}

	&__col2 {
		width: calc(100% - 298px);

		@include r.sp {
			width: calc(100% - 200px);
		}

		@include r.max-w(374px) {
			width: calc(100% - 170px);
		}
	}

	&__col3 {
		width: 100px;
		text-align: right;

		@include r.sp {
			width: 100px;
		}

		@include r.max-w(374px) {
			width: 90px;
		}
	}

	// .nk23_c-list08__col .
	&__col {
		max-width: calc(100% - 220px);
		text-align: right;

		@include r.sp {
			max-width: 56%;
			font-size: 1.4rem;
			line-height: 2rem;
		}

		&row {
			display: flex;
		}

		&title {
			flex: 1;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;
		}

		&text {
			min-width: 30px;
			text-align: right;
			display: inline-block;
		}
	}

	.is-colorGroup {
		display: flex;
		min-width: 100px;
		justify-content: flex-end;
	}

	&__colornum {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&:not(:last-child) {
			margin-right: 16px;

			@include r.sp {
				margin-right: 8px;
			}
		}

		.numbottom {
			@include r.sp {
				font-size: 1.2rem;
			}
		}
	}

	&__text {
		margin-top: 4px;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: normal;

		@include r.sp {
			font-size: 1.2rem;
			line-height: 1.5;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list09 {
	$root: &;
	// Add strix St 23.11.20
	// background: #ffffff;
	// border-radius: 8px;
	// End strix St 23.11.20


	// Add strix St 23.11.20
	&__item {
		font-size: 1.2rem;
		border-top: 1px solid transparent;
		transition: border-color .3s ease;

		overflow: hidden;

		// & + .nk23_c-list09__item {
		// 	border-top: 1px solid #d1d1d1;
		// }

		.is-openlist & {
			& + .nk23_c-list09__item {
				border-color: #d1d1d1;
			}
		}
	}
	// End strix St 23.11.20

	// .nk23_c-list09__link .
	&__link {
		padding: 8px;
		display: block;
		position: relative;

		@include r.pc {
			padding: 8px 16px;
			font-size: 1.4rem;
		}

		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	right: 16px;
		// 	top: calc(50% - 4px);
		// 	width: 6px;
		// 	height: 10px;
		// 	background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center / 100%;

		// 	@include r.sp {
		// 		width: 6px;
		// 		height: 9px;
		// 		background-size: 100%;
		// 		right: 8px;
		// 		top: calc(50% - 4px);
		// 	}
		// }

		&:hover {
			.nk23_c-list09__text2 {
				text-decoration: underline;
				opacity: 1;
			}
		}

		&.is-bottom {
			&::before {
				right: 10px;
				transform: rotate(90deg);
			}
		}
	}

	&__text1 {
		font-weight: bold;

		// .nk23_c-list09__text1+.nk23_c-list09__text2 .
		& + .nk23_c-list09__text2 {
			@include r.pc {
				margin-top: 4px;
			}
		}
	}

	&__text2 {
		color: v.$color-link;
		// Add strix St 23.11.20
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;

		.is-openlist & {
			-webkit-line-clamp: 2;
		}
		// End strix St 23.11.20
	}

	// Add strix St 23.11.20
	&__trigger {
		display: flex;
		justify-content: center;
		width: 25px;
		padding-top: 14px;
		border-left: 1px solid #d1d1d1;

		@include r.pc {
			width: 40px;
			padding-top: 16px;
		}

		img {
			width: 12px;
			height: 6px;
			cursor: pointer;
			transform: rotate(180deg);
			transition: transform .3s ease-out;

			@include r.sp {
				width: 8px;
				height: 4px;
			}
		}

		.is-openlist & {
			img {
				transform: rotate(0deg);
			}
		}
	}
	// End strix St 23.11.20
}

/*
------------------------------------------------------------*/
.nk23_c-list10 {
	@media screen and (max-width: 767px) and (min-width: 415px) {
		margin-bottom: -16px;
	}

	&__list {
		@include r.pc {
			margin: 0 -8px;
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__item {
		@include r.pc {
			padding: 0 8px;
			width: 20%;
		}

		@media screen and (max-width: 767px) and (min-width: 415px) {
			width: 50%;
			margin-bottom: 16px;
		}

		&:last-child {
			.nk23_c-list10__item__inner {
				@include r.sp {
					border-radius: 0 0 8px 8px;
				}
			}
		}

		&__inner {
			border: 1px solid v.$color23;
			background-color: #fff;

			@include r.pc {
				padding: 16px;
				border-radius: 8px;
			}

			@include r.sp {
				display: flex;
				align-items: stretch;
				border-top: 0;
			}
		}
	}

	&__icon {
		text-align: center;

		@include r.pc {
			margin-bottom: 16px;
		}

		@include r.sp {
			padding: 16px 4px;
			width: 58px;
			border-right: 1px solid v.$color23;
		}

		img {
			display: inline-block;
			width: 24px;
		}
	}

	&__listcheck {
		display: flex;

		@include r.pc {
			justify-content: space-between;
		}

		@include r.sp {
			padding: 8px 32px;
			align-items: center;
		}

		@include r.w374 {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&__checkitem {
		@include r.sp {
			margin-right: 24px;
		}

		&:nth-child(3n + 3) {
			@include r.sp {
				margin-right: 0;
			}
		}
	}

	&__top {
		display: flex;
		align-items: stretch;
		border-bottom: 1px solid v.$color23;
		border-radius: 8px 8px 0 0;
		overflow: hidden;
	}

	&__title {
		font-size: 1.4rem;
		line-height: 1;
		padding: 8px;
		text-align: center;
		text-align: center;
		background-color: v.$color0;
		color: #fff;

		&:first-child {
			width: 59px;
			border-right: 1px solid v.$color23;
		}

		&:last-child {
			flex: 1;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list-txt {
	li {
		display: block;
		margin: 4px 0;
		position: relative;
	}

	&.is-style01 {
		font-size: 1.4rem;
		line-height: 22px;

		li {
			padding-left: 14px;

			&::before {
				content: "・";
				position: absolute;
				left: 0;
				top: 0;
				width: 14px;
				font-size: 14px;
			}
		}
	}

	&.is-style02 {
		font-size: 1.2rem;
		line-height: 19px;
		margin-top: 4px;

		li {
			padding-left: 16px;

			&::before {
				content: "※";
				position: absolute;
				left: 0;
				top: 0;
				width: 12px;
				font-size: 12px;
			}
		}
	}

	&.is-style03 {
		font-size: 1.2rem;
		line-height: 19px;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list11 {
	$root: &;

	&__row {
		display: none;

		@include r.sp {
			display: flex;
			flex-wrap: wrap;
			background: #404040;
			border-radius: 8px 8px 0 0;
		}

		#{$root}__head {
			&:first-child {
				@include r.sp {
					border-right: 1px solid #c9c9c9;
				}
			}
		}
	}

	&__head {
		width: 58px;
		box-sizing: border-box;

		@include r.sp {
			width: 50%;
		}
	}

	&__ttl {
		color: #fff;
		background: #404040;
		width: 100%;
		border-radius: 8px 0 0 8px;
		display: flex;
		font-weight: 700;
		align-items: center;
		justify-content: center;
		height: 100%;
		box-sizing: border-box;
		font-size: 1.4rem;

		@include r.sp {
			border-radius: 0;
			background: transparent;
			font-size: 1.3rem;
			padding: 4px 0;
		}
	}

	&__head,
	&__item {
		margin-bottom: 8px;
		text-align: center;

		@include r.sp {
			margin-bottom: 0;
		}
	}

	&__logo {
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 8px auto;

		@include r.sp {
			width: 60px;
			height: 60px;
		}

		img {
			width: 100%;
			height: auto;
		}

		&.fnb {
			padding: 6px;
			@include r.sp {
				padding: 3px;
			}
		}
	}

	&__txt {
		color: v.$color0;
	}

	&__box {
		background: #fff;
		border-right: 1px solid #c9c9c9;
		width: calc((100% - 58px) / 5);
		padding: 0;
		font-size: 1.4rem;

		@include r.sp {
			width: 50%;
			background: transparent;
			border-top: 1px solid #c9c9c9;
			font-size: 1.3rem;
		}

		.nk23_u-colorlink {
			display: block;
			padding: 8px;

			@include r.hover {
				text-decoration: none;

				span {
					text-decoration: underline;
				}
			}
		}
	}

	&__item {
		&:nth-child(6n) {
			border-right: 0;
			border-radius: 0 8px 8px 0;

			@include r.sp {
				border-right: 1px solid #c9c9c9;
				border-radius: 0;
			}
		}

		&:nth-child(3),
		&:nth-child(5) {
			@include r.sp {
				border-right: 0;
			}
		}

		&:nth-child(2n) {
			@include r.sp {
				border-right: 0;
			}
		}

		&:nth-child(2),
		&:nth-child(4),
		&:nth-child(6) {
			@include r.sp {
				border-right: 1px solid #c9c9c9;
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;

		@include r.sp {
			background: #fff;
			border-radius: 0 0 8px 8px;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list12 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px;

	&__item {
		width: calc(100% / 8 - 16px);
		margin: 7px 8px;
		text-align: center;
		font-size: 1.4rem;
		line-height: 20px;

		@include r.pc {
			width: calc(100% / 8 - 16px);
		}

		@include r.sp {
			width: calc(100% / 5 - 16px);
		}

		@include r.max-w(610px) {
			width: calc(100% / 4 - 16px);
		}

		@include r.max-w(470px) {
			width: calc(100% / 3 - 16px);
		}
	}

	&__img {
		border-radius: 4px;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	&__ttl {
		margin-top: 4px;
	}

	&__link {
		transition: all 0.3s ease-out;
		color: v.$color-link;

		@include r.hover {
			.nk23_c-list12__img {
				opacity: 1;
			}

			.nk23_c-list12__ttl {
				text-decoration: underline;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-list13 {
	background: #fff;
	padding: 4px 10px;

	&__item {
		display: flex;
		flex-wrap: wrap;
	}

	&__tag {
		display: flex;
		flex-wrap: wrap;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
	}
}

.nk23_c-list14 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px;

	div {
		padding: 0 8px;
		width: 33.3333%;
	}

	li {
		padding: 0 8px;
		width: 33.3333%;
	}

	// .nk23_c-list14 h3 .
	h3 {
		padding: 6px;
		text-align: center;
		font-weight: 700;
		color: #fff;
		font-size: 1.6rem;
		background-color: v.$color32;
		background-image: none;
		border-radius: 0 0 4px 4px;

		& + p {
			margin-top: 10px;
		}
	}

	// .nk23_c-list14 img .
	img {
		width: 100%;
		border-radius: 4px 4px 0 0;
	}

	p {
		font-size: 1.3rem;
		line-height: 2.1rem;
	}
}
