@use "../settings/mixin" as r;
@use "../settings/variable" as v;
@use "../settings/_path" as p;

* {
	padding: 0;
	margin: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

li {
	list-style-type: none;
}

th {
	font-weight: inherit;
}

address {
	font-style: inherit;
}

fieldset {
	min-width: 0;
	padding: 0;
	border: 0;
}

img {
	margin: 0;
	padding: 0;
	border: 0;
	max-width: 100%;
	height: auto;
	width: auto;
	vertical-align: middle;
}

a {
	display: inline-block;
	transition: 0.3s ease-in-out;
	text-decoration: none;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	box-sizing: border-box;
}

a:hover {
	opacity: 1;
}

button,
input,
select,
textarea {
	padding: 0;
	border: 0;
}

button:not(:disabled):not([aria-disabled="true"]) {
	cursor: pointer;
}

html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	overscroll-behavior: none;

	&.smartphone {
		@media screen and (orientation: landscape) {
			overflow-x: hidden;
		}
	}

	&.is-page-top {
		scroll-behavior: smooth;
	}
}

body {
	font-family: "Noto Sans JP", sans-serif;
	font-weight: 400;
	font-size: 16px;
	background-color: v.$base-color;
	color: #221f1f;
	width: 100%;

	text-size-adjust: none;
  -webkit-text-size-adjust: none; /* Safari */

	@include r.pc {
		min-width: 1248px;
	}

	@include r.sp {
		min-width: 320px;
	}

	&.is-fixed {
		overflow: hidden;
		position: fixed;
	}
	// Add strix St 23.11.15
	&::before {
		display: block;
		// content: '';
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		// background: url(p.$path + 'images/common/nankan_loading.gif') center no-repeat rgba(34, 31, 31, 0.75);
		background: url(p.$path + 'images/common/nankan_loading.gif') center no-repeat rgba($color: #ebebeb, $alpha: 0.94);
		background-size: 250px 250px;
		opacity: 0;
		z-index: 9999;
		transition: opacity .3s ease-in-out;
		pointer-events: none;
	}

	// &.is-loading {
	// 	overflow: hidden;
	// 	position: fixed;

	// 	&::before {
	// 		opacity: 1;
	// 		transition: opacity .3s ease-in-out;
	// 	}

	// 	.js-mheight {
	// 		opacity: 0 !important;
	// 	}
	// }
	// Add strix End 23.11.15
}

#wrapper {
	width: 100%;

	&.is-fixed {
		overflow: hidden;
		position: fixed;
	}
}


table {
	border-collapse: collapse;
}

select,
input,
textarea {
	box-sizing: border-box;
	border: none;
	background-color: #fff;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
	padding: 0;
	box-shadow: none;
	outline: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

button {
	cursor: pointer;
}

select {
	width: 100%;
	color: #221f1f;

	&::-ms-expand {
		display: none;
	}
}

input {
	width: 100%;
	color: #221f1f;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"] {
		-moz-appearance: textfield;
	}

	&::-webkit-input-placeholder {
		color: #c9c9c9;
	}

	&:-ms-input-placeholder {
		color: #c9c9c9;
	}

	&::placeholder {
		color: #c9c9c9;
	}

	&::-ms-clear {
		display: none;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 100px white inset !important;
	}

	&:hover,
	&:focus {
		border: none;
		box-shadow: none;
		outline: none;
	}
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

textarea {
	resize: none;
	width: 100%;
	height: 100%;
	padding: 0 0;
	color: #221f1f;

	&::-webkit-input-placeholder {
		color: #c9c9c9;
	}

	&:-ms-input-placeholder {
		color: #c9c9c9;
	}

	&::placeholder {
		color: #c9c9c9;
	}
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
	body {
		// Safari用のハック
		height: -webkit-fill-available;
	}
}

[v-cloak] {
	visibility: hidden;
}

.pc {
	@media screen and (max-width: 767px) and (orientation: portrait) {
		display: none !important;
	}
}

.sp {
	@media screen and (min-width: 768px),
		screen and (min-width: 768px) and (orientation: portrait),
		screen and (orientation: landscape) {
		display: none !important;
	}
}

.display_pc {
	@media screen and (max-width: 767px) and (orientation: portrait) {
		display: none !important;
	}
}

.display_sp {
	@media screen and (min-width: 768px),
		screen and (min-width: 768px) and (orientation: portrait),
		screen and (orientation: landscape) {
		display: none !important;
	}
}

%container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 16px;

	@include r.sp {
		padding: 0 8px;

		&.is-sp-full {
			padding: 0;
		}
	}

	[class^="nk23_l-container"] {
		padding: 0;
	}
}

.nk23_l-container {
	max-width: 992px;
	@extend %container;
}

.nk23_l-container1 {
	max-width: 1232px;
	@extend %container;
}

.nk23_l-container2 {
	max-width: 960px;
	@extend %container;

	@include r.sp {
		max-width: 100%;
	}
}
.nk23_l-container3 {
	max-width: 884px;
	@extend %container;

	@include r.sp {
		max-width: 100%;
	}
}
// #wrapper {
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;

//   .nk23_c-footer {
//     margin-top: auto;
//   }
// }

[data-scrollbar] {
	.scrollbar-track {
		&.scrollbar-track-x {
			width: 100%;
			left: 0;
			right: 0;
			margin: auto;
			height: 4px;

			@include r.sp {
				width: calc(100% - 16px) !important;
			}

			.scrollbar-thumb-x {
				background-color: transparent !important;
				height: 4px;

				&:after {
					content: "";
					display: block;
					position: relative;
					background-color: #c9c9c9;
					border-radius: 20px;
					width: 100%;
					height: 100%;

					@include r.sp {
						width: calc(100% - 16px) !important;
					}
				}
			}
		}

		&.scrollbar-track-y {
			width: 4px;
			// Add strix St
			height: 100%;
			left: auto;
			right: 0;
			margin: auto;
			opacity: 1;

			@include r.sp {
				height: calc(100% - 16px) !important;
			}

			// Add strix End

			.scrollbar-thumb-y {
				width: 4px;
				// Add strix St
				background-color: transparent !important;

				&:after {
					content: "";
					display: block;
					position: relative;
					background-color: #c9c9c9;
					border-radius: 20px;
					width: 100%;
					height: 100%;

					@include r.sp {
						height: calc(100% - 16px) !important;
					}
				}

				// Add strix End
			}
		}
	}

	&.margin-bottom {
		margin-bottom: 8px;

		@include r.pc {
			margin-bottom: 16px;
		}
	}

	&.is-scrollbar-full {
		.scrollbar-track.scrollbar-track-x {
			width: calc(100% - 80px);
		}

		.scrollbar-track.scrollbar-track-x .scrollbar-thumb-x:after {
			width: calc(100% - 80px);
		}

		// Add strix St
		.scrollbar-track.scrollbar-track-y {
			height: calc(100% - 80px);
		}

		.scrollbar-track.scrollbar-track-y .scrollbar-thumb-y:after {
			height: calc(100% - 80px);
		}

		// Add strix End
	}

	@include r.sp {
		//height: 100vh;
	}
}

@include r.pc {
	.btn-tenkey.btn-tenkey-clear {
		background-position: -204px 0 !important;
	}
	.btn-tenkey.btn-tenkey-ent {
		background-position: -204px -40px !important;
	}
}
