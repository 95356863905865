// js tab
[data-tab-wrapper] {
  position: relative;

  [data-tab-id] {
    display: block !important;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    z-index: -999;
    overflow: hidden;
  }

  [data-tab-id].is-active {
    pointer-events: auto;
    position: relative;
    opacity: 1;
    z-index: auto;
    height: auto;
    overflow: visible;
  }
}

// js line-tab
[data-js="line-tab"] {
  position: relative;

  [data-line-bar] {
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1), background-color 0.2s linear, opacity 0s;

    &.is-active {
      opacity: 1;
    }

    &.no-transition {
      transition: none !important;
    }
  }
}

[data-line-item] {
	transition: all 0.3s ease-in-out, background 0s ease-in-out 0s;

	&.is-active {
		transition: all 0.3s ease-in-out, background 1s ease-in-out;
	}
}

//
.js-tableHeadClone {
	.thead-clone {
		border-bottom: 1px solid #c9c9c9;

		.clone-show {
			display: block !important;
		}

		.clone-hide {
			display: none !important;
		}

		.nk23_c-checkbox3__label {
			padding-bottom: 0 !important;
		}
	}
}

.is-no-bg {
	background: none !important;
}
