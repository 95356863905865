@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

table tr {
	height: 0;
}

/*
------------------------------------------------------------*/
.nk23_c-chart1 {
	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__item {
		text-align: left;
		font-size: 1.4rem;
		line-height: 2rem;
		margin: 4px 0;

		&.is-color-0 .is-percent {
			background-color: #f29898;
		}

		&.is-color-1 .is-percent {
			background-color: #99b7f2;
		}

		.is-bar {
			display: block;
			width: 100%;
			height: 17px;
			background-color: #e4e4e4;
		}

		.is-percent {
			display: inline-block;
			background-color: #404040;
			height: 100%;
			max-width: 100%;
			transition: width 1s ease-in-out;

			&.is-init {
				width: 0 !important;
			}
		}
	}

	&__text {
		text-align: left;
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-chart2 {
	@include r.sp {
		.nk23_l-container {
			padding: 0;
		}
	}

	&__spwrap {
		display: none;
		margin: 0 !important;
		@include r.sp {
			display: block;
			width: 100%;
		}
	}

	&__scroll {
		overflow: auto;
	}

	&__scroll &__body,
	&__scroll &__unit {
		min-width: 800px;
	}

	&__top {
		display: flex;
		justify-content: flex-end;
		padding: 16px 16px 0 48px;
		margin-bottom: -24px;
		@include r.sp {
			margin-bottom: 0;
		}
	}

	&__inner {
		background-color: #ffffff;
		border-radius: 8px;
		@include r.sp {
			border-radius: 0;
		}

		canvas {
			display: block;
			max-width: 100%;
			@include r.sp {
				min-height: 250px;
			}
		}
	}

	&__body {
		position: relative;
	}

	&__unit {
		font-size: 1.2rem;
		line-height: 1.9rem;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 16px;
		@include r.sp {
			padding: 0 14px;
		}

		&L {
		}

		&R {
			display: none;
			@include r.sp {
				display: block;
			}
		}
	}

	&__list1 {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;

		li {
			display: flex;
			align-items: center;
			font-size: 1.2rem;
			line-height: 2.2rem;
			color: #221f1f;
			margin-bottom: 6px;
			margin: 0 4px;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__icon1 {
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 1px solid #221f1f;
		background-color: #ffffff;
		border-radius: 50%;
		margin-right: 4px;

		&.is-color1 {
			background-color: #f29898;
		}

		&.is-color2 {
			background-color: #99b7f2;
		}

		&.is-color3 {
			background-color: #f8e69a;
		}
	}

	&__icon2 {
		display: inline-block;
		background-color: #ebebeb;
		width: 32px;
		height: 16px;
	}

	.msg {
		text-align: center;
		font-weight: 700;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-chart3 {
	padding: 0 6px;

	&.is-full {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&.is-full &__inner {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__td {
		padding: 0 !important;
		height: 0;
		@-moz-document url-prefix() {
			height: 100%;
		}
	}

	&__inner {
		position: relative;
		text-align: center;
		max-width: 50px;
		margin: 0 auto;
		padding-bottom: 4px;

		span {
			position: relative;
			font-size: 1.1rem;
			line-height: 1.7rem;
			display: block;
			z-index: 1;
		}

		.is-text1 {
			font-size: 1.4rem;
			line-height: 1.7rem;
		}

		.is-percent,
		.is-number,
		.is-total {
			font-size: 1.2rem;
			line-height: 2rem;
		}

		.is-number,
		.is-total {
			margin-bottom: -7px;
		}

		.is-total {
			border-top: 1px solid #221f1f;
			padding-top: 2px;
			margin-top: 2px;
		}

		.is-bar {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 0;
			max-height: 100%;
			width: 100%;
			z-index: 0;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-chart4 {
	$r: &;
	background-color: #ffffff;
	border-radius: 8px;

	&--style2 {
		.nk23_c-chart4 {
			&__info1 {
				display: none;
			}

			&__grid {
				padding-left: 66px;
				@include r.sp {
					padding-left: 34px;
				}
			}

			&__gridInner {
				width: 100%;
			}

			&__bar1 {
				width: 100%;
			}

			&__percent {
			}

			&__item1 {
				padding-left: 50px;
				@include r.sp {
					padding-left: 26px;
				}
			}

			&__num1 {
				background-color: #f7f7f7;
				border-color: #f7f7f7;
			}
		}

		&.fw-normal {
			.nk23_c-chart4 {
				&__num1 {
					font-weight: normal;
				}
			}
		}
	}

	&__top {
		padding: 16px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include r.sp {
			padding: 16px 8px 8px 8px;
		}
	}

	&__inner {
		position: relative;
		overflow: hidden;

		&.is-no-color {
			#{$r}__num1 {
				font-weight: normal;
				background-color: #f7f7f7;
				border-color: #f7f7f7;
				color: #221f1f;
			}
		}
	}

	&__num1 {
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 4px;
		background-color: #ffffff;
		border: 1px solid #221f1f;
		color: #221f1f;
		font-size: 1.6rem;
		line-height: 2.4rem;
		font-weight: bold;
		@include r.sp {
			width: 22px;
			font-size: 1.2rem;
			line-height: 1.8rem;
		}
	}

	&__text1 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	&__list1 {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-left: auto;

		li {
			font-size: 1.4rem;
			line-height: 2rem;
			display: flex;
			align-items: center;
			margin: 0 8px;
			&:last-child {
				margin-right: 0;
			}
			@include r.sp {
				font-size: 1.2rem;
				line-height: 1.8rem;
				margin: 0 5px;
			}
		}
	}

	&__list2 {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 38px 16px 16px 16px;
		@include r.sp {
			padding: 38px 16px 16px 8px;
		}
	}

	&__grid {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 38px 16px 16px 54px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@include r.sp {
			padding: 38px 16px 16px 34px;
		}

		&Inner {
			width: 60%;
			height: calc(100% + 16px);
			display: flex;
			justify-content: space-between;
			position: relative;
			@include r.sp {
				width: 55%;
				height: calc(100% + 8px);
			}

			li {
				position: relative;
				width: 1px;
				flex-shrink: 0;
				height: 100%;
				background-color: #f2f2f2;
			}
		}

		&Num {
			position: absolute;
			bottom: calc(100% + 8px);
			font-size: 1.4rem;
			line-height: 2rem;
			left: 0;
			right: 0;
			margin: auto;
			display: flex;
			justify-content: center;
			@include r.sp {
				font-size: 1.2rem;
				line-height: 1.8rem;
			}
		}
	}

	&__item1 {
		position: relative;
		padding-left: 38px;
		margin-bottom: 18px;
		&:last-child {
			margin-bottom: 0;
		}
		@include r.sp {
			padding-left: 26px;
		}
	}

	&__title1 {
		font-size: 1.6rem;
		line-height: 1.8rem;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		@include r.sp {
			font-size: 1.4rem;
			line-height: 1.6rem;
			margin-bottom: 2px;
			-webkit-line-clamp: 3;
		}
	}

	&__link1 {
		display: block;
		font-size: 1.2rem;
		line-height: 1.6rem;
		color: v.$color-link;

		@include r.hover {
			text-decoration: underline;
		}

		@include r.sp {
			margin-bottom: 2px;
		}
	}

	&__icon1 {
		width: 16px;
		height: 16px;
		background-color: #d9d9d9;
		display: inline-block;
		margin-right: 4px;

		&.is-color1 {
			background-color: #f29898;
		}

		&.is-color2 {
			background-color: #99b7f2;
		}

		&.is-color3 {
			background-color: #f8e69a;
		}
	}

	&__wrap1 {
		display: flex;
		flex-wrap: wrap;
	}

	&__bar1 {
		width: 60%;
		display: flex;
		align-items: center;
		@include r.sp {
			width: 55%;
		}
	}

	&__info1 {
		width: 40%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		@include r.sp {
			width: 45%;
		}
	}

	&__info2 {
		display: flex;
		flex-wrap: wrap;

		li {
			font-size: 1.4rem;
			line-height: 2rem;
			margin: 0 4px;
			@include r.sp {
				font-size: 1.2rem;
				line-height: 1.8rem;

				&.is-rate {
					display: none;
				}
			}
		}
	}

	&__percent {
		position: relative;
		display: flex;
		width: 100%;
		height: 24px;

		li {
			height: 100%;

			&.is-percent-0 {
				background-color: #f29898;
			}

			&.is-percent-1 {
				background-color: #99b7f2;
			}

			&.is-percent-2 {
				background-color: #f8e69a;
			}

			&.is-percent-3 {
				background-color: #d9d9d9;
			}
		}

		li.is-percent-rate {
			position: absolute;
			flex-shrink: 0;
			margin-left: auto;
			left: 0;
			top: -10px;
		}
	}

	&__rate {
		display: flex;
		position: relative;
		border-radius: 8px;
		border: 1px solid #221f1f;
		background-color: #ffffff;
		padding: 4px 8px;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition:
			opacity 0.3s ease-in-out,
			visibility 0.3s ease-in-out;
		@include r.pc {
			display: none;
		}

		&.show {
			opacity: 1;
			visibility: visible;
		}

		li {
			white-space: nowrap;
			font-size: 1.2rem;
			line-height: 1;
			margin: 0 4px;
		}
	}
}
