@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
block
------------------------------------------------------------*/

/*
------------------------------------------------------------*/

.nk23_c-block01 {
	padding: 16px;
	background-color: v.$color2;
	$root: &;
	@include r.pc {
		border-radius: 8px;
	}
	@include r.sp {
		padding: 8px;
	}
	&__listbtn {
		display: flex;
		flex-wrap: wrap;
		@include r.sp {
			margin: 0 -4px;
		}
		// .nk23_c-block01__listbtn .nk23_c-btn02 .
		.nk23_c-btn02 {
			margin-bottom: 9px;
			@include r.pc {
				flex: 1;
				max-width: 124px;
			}
			@include r.sp {
				margin: 0 4px 10px;
				min-height: 35px;
				width: calc(33.3333% - 8px);
			}
			// .nk23_c-block01__listbtn .nk23_c-btn02:not(:last-child) .
			&:not(:last-child) {
				@include r.pc {
					margin-right: 9px;
				}
			}
			&.is-style1 {
				@include r.sp {
					font-size: 1.4rem;
				}

				.nk23_c-btn02 {
					&__icon {
						@include r.sp {
							width: 20px;

							&.is-small {
								width: 16px;
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
	&__text {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
	// .nk23_c-block01__btns .
	&__btns {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin: 0 -4px;
		@include r.sp {
			padding-left: 14px;
		}

		// .nk23_c-block01__btns .nk23_c-btn03 .
		.nk23_c-btn03 {
			flex: 1;
			min-width: auto;
			// thanglq 091123
			// min-width: 88px;
			padding: 0 10px;
			border-radius: 8px;
			border-color: #919599;
			box-shadow: 0 2px 0 #919599;
			max-width: calc(100% / 3 - 8px);
			margin: 0 4px;
			@include r.hover {
				box-shadow: none;
				position: relative;
				top: 2px;
			}
			@include r.sp {
				font-size: 1.4rem;
				min-width: auto;
			}
		}
		// .nk23_c-block01__btns .nk23_c-btn02 .
		.nk23_c-btn02 {
			flex: 1;
			// thanglq 091123
			// min-width: 88px;
			padding: 0 10px;
			border-radius: 8px;
			border-color: #919599;
			box-shadow: 0 2px 0 #919599;
			max-width: calc(100% / 3 - 8px);
			margin: 0 4px;
			@include r.hover {
				box-shadow: none;
				position: relative;
				top: 2px;
			}
			@include r.sp {
				min-width: auto;
			}
			// .nk23_c-block01__btns .nk23_c-btn02:not(:last-child) .

			// .nk23_c-block01__btns .nk23_c-btn02.is-style1 .
			&.is-style1 {
				min-width: auto;
				// thanglq 091123
				// min-width: 88px;
				padding: 0 10px;
				border-radius: 8px;
				border-color: #919599;
				box-shadow: 0 2px 0 #919599;
				max-width: calc(100% / 3 - 8px);
				margin: 0 4px;
				@include r.hover {
					box-shadow: none;
					position: relative;
					top: 2px;
				}
				@include r.sp {
					font-size: 1.4rem;
					min-height: 35px;
					white-space: nowrap;
					min-width: auto;
				}
				.nk23_c-btn02__icon {
					width: 20px;
					@media screen and (max-width: 420px) {
						width: 14px;
					}
				}
				// .nk23_c-block01__btns .nk23_c-btn02.is-style1:only-child .
				&:only-child {
					// max-width: 100%;
				}

				&.is-hover{
					box-shadow: none;
					position: relative;
					top: 2px;
				}
			}

			&.is-disable {
				pointer-events: all;
				cursor: default;
				background: rgba($color: #fff, $alpha: 0.5);
				box-shadow: 0 2px 0 #c9c9c9;
				border: 1px solid #c9c9c9;

				&:hover {
					top: 0px;
					cursor: default;
					background: rgba($color: #fff, $alpha: 0.5);
					box-shadow: 0 2px 0 #c9c9c9;
					border: 1px solid #c9c9c9;
				}
			}
		}
	}
	// .nk23_c-block01__label .
	&__label {
		padding: 6px 0 4px;
		font-size: 1.4rem;
		line-height: 1;
		text-align: center;
		min-width: 40px;
		min-height: 32px;
		width: max-content;
		position: relative;
		// background-color: v.$color0;
		background: url(p.$path + "images/common/bg-label.svg") no-repeat bottom center / 100%;
		color: #fff;
		display: inline-block;
		@include r.sp {
			padding-bottom: 4px;
			height: max-content;
		}
		// .nk23_c-block01__label:after .
		// &:after {
		// 	bottom: -10px;
		// 	border-width: 10px 20px 0 20px;
		// 	left: 0;
		// 	position: absolute;
		// 	content: "";
		// 	border-style: solid;
		// 	border-color: transparent;
		// 	border-top-color: v.$color0;
		// 	@include r.sp {
		// 		bottom: -8px;
		// 		border-width: 8px 20px 0 20px;
		// 	}
		// }
	}
	// .nk23_c-block01__texts .
	&__texts {
		padding-left: 8px;
		word-break: break-all;
		.nk23_c-block01__text {
			// .nk23_c-block01__texts .nk23_c-block01__text:not(:last-child) .
			&:not(:last-child) {
				margin-right: 5px;
				@include r.w374 {
					margin-right: 0;
				}
			}
			&.is-color1 {
				color: v.$color13;
			}
		}
	}
	// .nk23_c-block01__titlebtn .
	&__titlebtn {
		margin-bottom: 16px;
		display: flex;
		align-items: flex-end;
		// .nk23_c-block01__titlebtn .nk23_c-btn03 .
		.nk23_c-btn03 {
			margin-bottom: 2px;
			margin-left: auto;
			min-width: max-content;
			@include r.pc {
				padding-left: 36px;
				padding-right: 36px;
			}
			@include r.sp {
				min-width: 109px;
			}
		}
	}
	// .nk23_c-block01__info .
	&__info {
		display: flex;
		align-items: center;
		padding-right: 20px;
		@include r.sp {
			padding-right: 10px;
		}
		// .nk23_c-block01__info__logo .
		&__logo {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 24px;
			vertical-align: middle;
			@include r.pc {
				min-width: 80px;
				min-height: 80px;
			}
			@include r.sp {
				margin-right: 8px;
				max-width: 40px;
				min-width: 40px;
				max-height: 40px;
				min-height: 40px;
			}
			img {
				width: 100%;
				height: 100%;
			}
			&.fnb {
				padding: 6px;

				@include r.sp {
					padding: 3px;
				}
			}
		}
		&__texts {
			display: flex;
			flex-wrap: wrap;
			// .nk23_c-block01__info__texts .nk23_c-block01__info__text .
			.nk23_c-block01__info__text {
				margin-bottom: 0;
				// .nk23_c-block01__info__texts .nk23_c-block01__info__text:not(:last-child) .
				&:not(:last-child) {
					margin-right: 16px;
					@include r.sp {
						margin-right: 16px;
					}
				}
			}
		}
		// .nk23_c-block01__info__text .
		&__text {
			font-size: 1.6rem;
			line-height: 1.5;
			margin-bottom: 4px;
			display: flex;
			@include r.sp {
				font-size: 1.2rem;
				line-height: 1.8rem;
			}
			&.has-icon {
				// .nk23_c-block01__info__text.has-icon:after .
				&:after {
					top: 3px;
					margin-left: 4px;
					position: relative;
					content: "";
					display: inline-block;
					width: 20px;
					height: 20px;
					background: url(p.$path + "images/common/icon/icon_sun-orange.svg") no-repeat center / 20px auto;
					@include r.sp {
						top: 1px;
						width: 16px;
						height: 16px;
						background-size: 16px auto;
					}
				}
				&.is-moon {
					// .nk23_c-block01__info__text.has-icon.is-moon:after .
					&:after {
						margin-left: 8px !important;
						top: 7px !important;
						width: 12px !important;
						height: 13px !important;
						background: url(p.$path + "images/common/icon/icon_moon.svg") no-repeat center / 12px auto !important;
						@include r.sp {
							width: 11px !important;
							height: 12px !important;
							background-size: 11px auto !important;
							top: 4px !important;
						}
					}
				}
			}
		}
		// .nk23_c-block01__info__title .
		&__title {
			margin-bottom: 5px;
			font-size: 2rem;
			line-height: 2.9rem;
			font-weight: 700;
			@include r.sp {
				margin-bottom: 4px;
				font-size: 1.6rem;
				line-height: 1.5;
			}
		}
	}
	&__list {
		// .nk23_c-block01__list__title .
		&__title {
			color: v.$color-link;
			font-weight: 700;
			font-size: 1.4rem;
			line-height: 2rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			word-break: break-all;
			// .nk23_c-block01__list__title:not(:only-child) .
			&:not(:only-child) {
				padding-right: 3px;
			}
			@include r.hover {
				text-decoration: underline;
			}
			&.is-nolink {
				color: v.$color0;
				@include r.hover {
					text-decoration: none;
				}
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: transparent;
			}
			&::after {
				content: "";
				position: absolute;
				right: 8px;
				width: 8px;
				height: 14px;
				background: url(p.$path + "images/common/icon/icon-obj_arrow.svg") no-repeat center / 8px !important;
				top: calc(50% - 7px);
			}
		}
		// .nk23_c-block01__list__top .
		&__top {
			margin-bottom: 6px;
			display: flex;
			align-items: flex-start;
			max-width: 227px;
			@include r.sp {
				margin-bottom: 4px;
				overflow: hidden;
				min-height: 33px;
				position: relative;
				max-width: calc((205 / 375) * 100vw);
			}
			@include r.w374 {
				max-width: 150px;
			}
		}
		// .nk23_c-block01__list__item .
		&__item {
			padding: 0 22px 10px 16px;
			position: relative;
			background-color: #fff;
			@include r.sp {
				padding: 0 22px 10px 8px;
			}
			&:not(:last-child) {
				border-bottom: 1px solid v.$color23;
			}
			&:not(:first-child) {
				// .nk23_c-block01__list__item:not(:first-child) .nk23_c-block01__list__top .
				.nk23_c-block01__list__top {
					@include r.sp {
						top: -1px;
					}
				}
			}
			&.is-bg1 {
				background-color: v.$color55;
			}
			&.is-bg2 {
				background-color: #fff;
			}
			&.is-bg3 {
				background-color: v.$color36;
			}
			/*20230427 追加*/
			&__flex {
				display: flex;
				@include r.sp {
					//display: block;
				}
				justify-content: space-between;
				//margin-bottom: 3.5px;
				&__left {
					min-width: 230px;
					@include r.sp {
						min-width: 170px;
					}
				}
				&__right {
					position: relative;
					display: inline-block;
					min-width: 152px;
					text-align: right;
					margin-right: -26px;
					font-size: 0;
					@include r.sp {
						min-width: 148px;
						//margin-right: -12px;
					}
				}
			}
			/**/
		}
		// .nk23_c-block01__list__label .
		&__label {
			position: absolute;
			right: -4px;
			top: 3px;
			color: #fff;
			padding: 4px 16px 4px 13px;
			display: inline-block;
			background-color: v.$color13;
			font-size: 1rem;
			line-height: 1;
			font-weight: 700;
			@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
				padding: 0 16px 0 13px;
				height: 16px;
				line-height: 16px;
			}
			@include r.w374 {
				padding: 4px 10px;
			}
			&.is-style1 {
				right: unset; //20230427 変更
				white-space: nowrap; //20230427 追加
				margin: 3.5px 0 4.5px; //20230427 追加
				position: relative; //20230427 変更
				top: unset; //20230427 変更
			}
			// .nk23_c-block01__list__label:before .
			&:before {
				border-width: 9px 0 9px 6px;
				content: "";
				position: absolute;
				top: 0;
				right: 100%;
				border-style: solid;
				border-color: v.$color13 transparent;
				height: 18px;
				@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
					border-width: 8px 0 8px 5px;
					height: 16px;
					right: 99.7%;
				}
			}
			// .nk23_c-block01__list__label:after .
			&:after {
				content: "";
				position: absolute;
				border-style: solid;
				border-color: transparent;
				border-left-color: v.$color35;
				bottom: -4px;
				right: -4px;
				border-width: 0 4px 4px 4px;
			}
			&.is-bg1 {
				background-color: v.$color56;
				&:before {
					border-color: v.$color56 transparent;
				}
				&:after {
					border-left-color: v.$color60;
				}
			}
			&.is-bg2 {
				background-color: v.$color16;
				&:before {
					border-color: v.$color16 transparent;
				}
				&:after {
					border-left-color: v.$color61;
				}
			}
			&.is-bg3 {
				background-color: v.$color14;
				&:before {
					border-color: v.$color14 transparent;
				}
				&:after {
					border-left-color: v.$color62;
				}
			}
			&.is-bg4 {
				background-color: v.$color15;
				color: v.$color0;
				&:before {
					border-color: v.$color15 transparent;
				}
				&:after {
					border-left-color: v.$color63;
				}
			}
		}
	}
	&__listlabel {
		&__text {
			// .nk23_c-block01__listlabel__text:not(:last-child) .
			&:not(:last-child) {
				margin-right: 2px;
			}
		}
	}
	// .nk23_c-block01__top .
	&__top {
		border-radius: 8px;
		background-color: #fff;
	}
	// .nk23_c-block01__on .
	&__on {
		padding: 16px;
		position: relative;
		z-index: 1;
		border-radius: 0 0 8px 8px;
		@include r.shadow3;
		@include r.pc {
			min-height: 40px;
			display: flex;
			align-items: flex-start;
		}
		@include r.sp {
			padding: 8px;
		}
		// .nk23_c-block01__on__top .
		&__top {
			@include r.pc {
				padding: 4px 0;
			}
			@include r.sp {
				display: flex;
				align-items: center;
				width: 100%;
			}
			// .nk23_c-block01__on__top .nk23_c-btn04 .
			.nk23_c-btn04 {
				@include r.pc {
					top: 16px !important;
					right: 16px;
					position: absolute !important;
					z-index: 1;
				}
				@include r.sp {
					margin-left: auto;
				}
				@include r.hover {
					top: 18px !important;
				}
			}
		}
		// .nk23_c-block01__on__btns .
		&__btns {
			display: flex;
			@include r.pc {
				margin-left: auto;
				padding-top: 50px;
			}
			@include r.sp {
				margin-top: 8px;
			}
			&.is-style1 {
				padding-top: 0;
			}
			.nk23_c-btn02,
			.nk23_c-btn03 {
				width: 160px;
				&:not(:first-child) {
					@include r.pc {
						margin-left: 8px;
					}
				}
				&:not(:last-child) {
					@include r.sp {
						margin-right: 8px;
					}
				}
				@include r.sp {
					flex: 1;
				}
			}
			.nk23_c-btn02.is-style1 {
				@include r.pc {
					font-size: 1.6rem;
				}
				.nk23_c-btn02__icon {
					@include r.pc {
						width: 24px;
					}
				}
			}
			// .nk23_c-block01__on__btns .nk23_c-btn02.is-style1 .
			.nk23_c-btn02.is-style1 {
				@include r.pc {
					min-height: 44px;
				}
				// .nk23_c-block01__on__btns .nk23_c-btn02.is-style1:not(.is-color3) .
				&:not(.is-color3) {
					@include r.sp {
						font-size: 1.4rem;
					}
					.nk23_c-btn02__icon {
						@include r.sp {
							width: 20px;
						}
					}
				}
			}
		}
		// .nk23_c-block01__on.is-style1 .
		&.is-style1 {
			// .nk23_c-block01__on.is-style1 .nk23_c-btn04 .
			.nk23_c-btn04 {
				@include r.pc {
					top: 50% !important;
					transform: translateY(-50%) !important;
				}
				@include r.hover {
					top: calc(50% + 2px) !important;
				}
			}
		}
	}
	// .nk23_c-block01__under .
	&__under {
		padding: 16px 16px 8px;
		@include r.sp {
			padding: 8px;
		}
	}
	// .nk23_c-block01__bottom .
	&__bottom {
		@include r.pc {
			display: flex;
		}
	}
	// .nk23_c-block01__col .
	&__col {
		@include r.pc {
			width: 50%;
		}
		&.is-bg1 {
			background-color: v.$color34;
		}
		&.is-bg2 {
			background-color: #fff;
		}
		&.is-bg3 {
			background-color: v.$color11;
		}
		&:first-child {
			border-radius: 8px 0 0 8px;
			@include r.sp {
				border-radius: 8px 8px 0 0;
				.nk23_c-block01__list {
					border-radius: 8px 8px 0 0;
					li {
						&:first-child {
							border-radius: 8px 8px 0 0;
						}
					}
				}
			}
			.nk23_c-block01__list__item {
				&:first-child {
					@include r.pc {
						border-radius: 8px 0 0 0;
					}
				}
				&:last-child {
					@include r.pc {
						border-radius: 0 0 0 8px;
					}
				}
			}
		}
		// .nk23_c-block01__col:last-child .
		&:last-child {
			border-radius: 0 8px 8px 0;
			@include r.sp {
				border-radius: 0 0 8px 8px;
				.nk23_c-block01__list {
					border-radius: 0 0 8px 8px;
					li {
						&:last-child {
							border-radius: 0 0 8px 8px;
						}
					}
				}
			}
			.nk23_c-block01__list__item {
				&:first-child {
					@include r.pc {
						border-radius: 0 8px 0 0;
					}
					@include r.sp {
						border-top: 1px solid v.$color23;
					}
					.nk23_c-block01__list__top {
						@include r.sp {
							margin-top: -1px;
						}
					}
				}
				&:last-child {
					@include r.pc {
						border-radius: 0 0 8px 0;
					}
				}
			}
		}
	}
	&.is-style1 {
		.nk23_c-block01 {
			// .nk23_c-block01.is-style1 .nk23_c-block01__under .
			&__under {
				padding: 0 16px 1px;
				@include r.sp {
					padding: 0 8px;
				}
			}
			&__info {
				// .nk23_c-block01.is-style1 .nk23_c-block01__info__logo .
				&__logo {
					margin-right: 16px;
					&.fnb {
						padding: 6px;

						@include r.sp {
							padding: 3px;
						}
					}
					@include r.sp {
						margin-right: 8px;
					}
				}
				// .nk23_c-block01.is-style1 .nk23_c-block01__info__title .
				&__title {
					@include r.sp {
						margin-bottom: 3px;
					}
				}
				&__texts {
					.nk23_c-block01__info__text {
						&:not(:last-child) {
							@include r.sp {
								margin-right: 8px;
							}
						}
					}
				}
			}
			// .nk23_c-block01.is-style1 .nk23_c-block01__on .
			&__on {
				margin-bottom: 8px;
				padding: 16px 16px 0;
				align-items: center;
				box-shadow: none;
				@include r.sp {
					padding: 8px 9px 0 8px;
					display: flex;
				}
				&__top {
					padding-bottom: 0;
				}
				&__btns {
					@include r.sp {
						margin-left: auto;
						margin-top: 0;
					}
				}
				// .nk23_c-block01.is-style1 .nk23_c-block01__on .nk23_c-btn03.is-color3 .
				.nk23_c-btn03.is-color3 {
					@include r.pc {
						min-height: 44px;
					}
					@include r.sp {
						max-width: 110px;
					}
				}
			}
		}
		.nk23_c-btn03 {
			// @include r.pc {
			// 	padding-left: 27px;
			// 	padding-right: 27px;
			// }
		}
	}
	&.is-col2 {
		padding: 0 !important;
		.nk23_c-block01 {
			&__under {
				@include r.pc {
					display: grid;
    			align-items: flex-end;
					padding-bottom: 8px;

					.nk23_c-notice2 {
						margin-bottom: 8px;
					}
				}
			}
			&__list {
				&__item {
					&:first-child {
						border-radius: 8px 8px 0 0;
					}
					&:last-child {
						border-radius: 0 0 8px 8px;
					}
				}
			}
			&__bottom {
				display: block;
			}
			&__col {
				width: 100%;
				@include r.pc {
					border-radius: 0;
				}
			}
			&__listbtn {
				@include r.pc {
					margin: 0 -4px;
				}
				.nk23_c-btn02 {
					@include r.pc {
						margin: 0 4px 10px;
						width: calc(33.3333% - 8px);
						flex: auto;
						max-width: calc(33.3333% - 8px);
					}
				}
			}
			&__info {
				// .nk23_c-block01.is-col2 .nk23_c-block01__info__logo .
				&__logo {
					@include r.pc {
						margin-right: 8px;
						min-width: 56px;
						width: 56px;
					}
					img {
						width: 100%;
					}
				}
				&__texts {
					@include r.pc {
						flex-direction: column;
					}
					.nk23_c-block01__info__text {
						// .nk23_c-block01.is-col2 .nk23_c-block01__info__texts .nk23_c-block01__info__text:not(:last-child) .
						&:not(:last-child) {
							@include r.pc {
								margin-bottom: 4px;
								margin-right: 0;
							}
						}
					}
				}
			}
			// .nk23_c-block01.is-col2 .nk23_c-block01__on .
			&__on {
				@include r.pc {
					//margin-bottom: 16px;
				}
				// .nk23_c-block01.is-col2 .nk23_c-block01__on__btns .
				&__btns {
					@include r.pc {
						bottom: 4px;
						right: 16px;
						position: absolute;
					}
				}
			}
		}
		// .nk23_c-block01.is-col2 .nk23_c-banner1 .
		.nk23_c-banner1 {
			@include r.pc {
				padding: 16px 16px 18px;
				display: block;
			}
			&.is-style2 {
				padding: 0;
				&.is-bg-style1 {
					@include r.sp {
						margin-right: 8px;
					}
				}
				&.is-bg-style2 {
					@include r.sp {
						margin-left: 8px;
					}
				}
				.nk23_c-banner1__head {
					padding: 16px;
					border-bottom: 1px solid v.$color19;
					margin-bottom: 0;
					@include r.sp {
						padding: 12px 8px;
					}
				}
				.nk23_c-banner1__bl2 {
					padding-left: 0;
				}
				.nk23_c-banner1__top {
					display: flex;
					font-size: 1.4rem;
					@include r.sp {
						font-size: 1.2rem;
						flex-direction: column;
					}
				}
				.nk23_c-banner1__top__text1 {
					@include r.pc {
						margin-right: 8px;
						white-space: nowrap;
					}
					@include r.sp {
						margin-bottom: 5px;
					}
					& + .nk23_c-banner1__texticon {
						@include r.pc {
							white-space: nowrap;
						}
					}
				}
				.nk23_c-banner1__texts2 {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					@include r.pc {
						margin-top: 10px;
					}
					@include r.sp {
						flex-direction: column;
						max-width: 100%;
						padding-right: 0;
					}
				}
				.nk23_c-banner1__textnum {
					@include r.pc {
						margin-top: 0;
						margin-right: 30px;
					}
					@include r.sp {
						flex-direction: column;
						max-width: 100%;
						padding-right: 0;
						position: inherit;
						margin: 5px 0 8px;
						text-align: right;
					}
					.is-color {
						@include r.sp {
							font-size: 1.8rem;
						}
					}
				}
				.nk23_c-banner1__body {
					margin: 16px;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					@include r.sp {
						margin: 12px 8px;
					}
					& + .nk23_c-banner1__body {
						border-top: 1px solid #c9c9c9;
						padding-top: 16px;
						@include r.sp {
							padding-top: 12px;
						}
					}
				}
				.nk23_c-btn02 {
					@include r.sp {
						width: 100%;
					}
				}
			}
			// .nk23_c-block01.is-col2 .nk23_c-banner1__bl1 .
			&__bl1 {
				@include r.pc {
					margin-bottom: 17px;
					padding-right: 0;
				}
			}
			&__bl2 {
				@include r.pc {
					margin-top: 0;
				}
			}
			&__texts2 {
				@include r.pc {
					padding-top: 0;
					display: block;
					margin-right: auto;
				}
			}
			&__texticon {
				@include r.pc {
					margin-right: 0;
				}
				&:before {
					@include r.pc {
						margin-right: 5px;
					}
				}
			}
			// .nk23_c-block01.is-col2 .nk23_c-banner1__textnum .
			&__textnum {
				@include r.pc {
					margin-top: 16px;
					margin-left: 0;
					padding-top: 0;
					position: relative;
					top: 2px;
				}
				.is-color {
					@include r.pc {
						top: -5px;
						position: relative;
						font-size: 2.2rem;
					}
				}
			}
			.nk23_c-btn02 {
				@include r.pc {
					margin-left: 0;
				}
			}
		}
		.nk23_c-btn03 {
			// @include r.pc {
			// 	padding-left: 24.5px;
			// 	padding-right: 24.5px;
			// }
		}
		.nk23_c-btn02 {
			&.is-style1 {
				.nk23_c-btn02 {
					&__icon {
						@include r.pc {
							width: 20px;
						}
					}
				}
			}
		}
	}
	&.is-bg2,
	&.is-bg-funabashi-thin {
		background-color: v.$color5;
		.nk23_c-block01__list__item:not(:last-child),
		.nk23_c-block01__col:first-child .nk23_c-block01__list {
			border-color: v.$funabashi-color-light;
		}
	}
	&.is-bg3,
	&.is-bg-kawasaki-thin {
		background-color: v.$color11;
		.nk23_c-block01__list__item:not(:last-child),
		.nk23_c-block01__col:first-child .nk23_c-block01__list {
			border-color: v.$kawasaki-color-light;
		}
	}
	&.is-bgauto {
		background-color: v.$color19;
	}
	&.is-bg5,
	&.is-bg-ooi-thin {
		background-color: v.$color8;
		.nk23_c-block01__list__item:not(:last-child),
		.nk23_c-block01__col:first-child .nk23_c-block01__list {
			border-color: v.$ooi-color-light;
		}
	}
	&.is-bg-urawa-thin {
		.nk23_c-block01__list__item:not(:last-child),
		.nk23_c-block01__col:first-child .nk23_c-block01__list {
			border-color: v.$urawa-color-light;
		}
	}
	&__row {
		display: flex;
		// .nk23_c-block01__row__col .
		&__col {
			padding: 16px;
			width: 50%;
			display: flex;
			flex-direction: column;
			@include r.sp {
				padding: 8px;
			}
			&:nth-child(odd) {
				@include r.sp {
					padding: 8px 0 8px 8px;
				}
				.nk23_c-block01__top {
					@include r.sp {
						border-radius: 8px 0 0 8px;
						border-right: 1px solid #d1d1d1;
					}
				}
			}
			&:nth-child(even) {
				@include r.sp {
					padding: 8px 8px 8px 0;
				}
				.nk23_c-block01__top {
					@include r.sp {
						border-radius: 0 8px 8px 0;
					}
				}
			}
			&.is-bg1 {
				background-color: v.$color8;
			}
			&.is-bg2 {
				background-color: v.$color38;
			}
			&.is-bg3 {
				background-color: v.$color11;
			}
			.nk23_c-tab4__content {
				&:not(.mb_auto) {
					@include r.pc {
						margin-bottom: auto;
					}
				}
			}
			.nk23_c-block01__info__text {
				@include r.sp {
					font-size: 1rem;
				}
			}
			.nk23_c-block01__on__btns {
				@include r.sp {
					margin-right: auto;
					margin-left: 0 !important;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					.nk23_c-btn03 {
						order: 2;
					}
					.nk23_c-block01__info__texts {
						order: 1;
						display: block;
					}
				}
			}

			// Update 100223
			@include r.pc {
				.nk23_c-block01__btns--row {
					display: flex;
					width: 66.667%;
					.nk23_c-btn02 {
						max-width: 50%;
					}
					&:first-child {
						width: 33.333%;
						.nk23_c-btn02 {
							max-width: 100%;
						}
					}
				}
			}

			@include r.sp {
				.nk23_c-block01__list__item {
					padding: 0 14px 10px 4px;
				}
				.nk23_c-block01__list__title {
					//display: flex;
					//width: 100%;
					//align-items: center;
					&::after {
						right: 4px;
					}
				}
				.nk23_c-block01__btns {
					padding-left: 0;
					margin: 16px -2px 0px -2px;
					align-items: flex-end;
					.nk23_c-btn02 {
						&.is-style1 {
							max-width: calc(50% - 4px);
							margin: 0 2px;
							padding: 0 5px;
							.nk23_c-btn02__icon {
								width: 18px;
								margin-bottom: 0;
							}
						}
					}
					.nk23_c-block01__btns--row {
						display: flex;
						width: 100%;
						&:first-child {
							margin-bottom: 6px;
						}
					}
				}
				.nk23_c-btn02__text {
					font-size: 1.2rem;
				}
				&:first-child {
					.nk23_c-block01__bottom {
						.nk23_c-block01__col {
							border-right: 1px solid v.$color19;
						}
					}
					.nk23_c-block01__col:first-child .nk23_c-block01__list li:first-child {
						border-radius: 8px 0 0 0;
					}
					.nk23_c-block01__col:last-child .nk23_c-block01__list li:last-child {
						border-radius: 0 0 0 8px;
					}
				}
				&:last-child {
					.nk23_c-block01__col:first-child .nk23_c-block01__list li:first-child {
						border-radius: 0 8px 0 0;
					}
					.nk23_c-block01__col:last-child .nk23_c-block01__list li:last-child {
						border-radius: 0 0 8px 0;
					}
				}
				.nk23_c-block01__list__label {
					position: relative;
					width: calc(100% - 1px);
					margin-bottom: 6px;
					margin-top: 4px;
					left: 6px;
					display: block;
					text-align: center;
					&::after {
						border-width: 9px 5px 9px 0;
						border-color: #e63434 transparent;
						left: 100%;
						bottom: 0;
					}
				}
				.nk23_c-list01__ttl {
					display: flex;
					width: 100%;
					align-items: center;
				}
				.nk23_c-list01__btn {
					.nk23_c-btn02 {
						padding: 0;
					}
				}
				.nk23_c-block01__listbtn .nk23_c-btn02 {
					width: calc(50% - 4px) !important;
					max-width: calc(50% - 4px) !important;
					margin: 0 2px 10px;
				}
			}
			// End update 100223
		}
		.nk23_c-block01__on {
			@include r.sp {
				flex-direction: column;
			}
		}
		.nk23_c-block01__info {
			@include r.sp {
				margin-bottom: 8px;
				padding-right: 0;
			}
			&__title {
				@include r.sp {
					font-size: 1.4rem;
				}
			}
		}
		.nk23_c-block01__listbtn .nk23_c-btn02 {
			margin-bottom: 6px;
			flex: 1;
			@include r.sp {
				width: calc(50% - 8px);
				flex: auto;
				max-width: calc(50% - 8px);
			}
		}
		.nk23_c-btn03.is-color3 {
			font-size: 1.4rem;
		}
		.nk23_c-notice2 {
			@include r.sp {
				margin: 8px 8px 0px !important;
			}
		}
	}
	// .nk23_c-block01 .nk23_c-notice2 .
	.nk23_c-notice2 {
		margin-bottom: 16px;
		@include r.sp {
			margin-bottom: 15px;
		}
	}
	// .nk23_c-block01 .nk23_c-banner1 .
	.nk23_c-banner1 {
		margin-top: 16px;
		@include r.sp {
			margin-top: 7px;
			overflow: hidden;
		}
	}
	&__full {
		border-radius: 8px;
		overflow: hidden;
		#{$root}__list__item {
			padding: 0 16px 16px;
			@include r.sp {
				padding: 0 8px 8px;
			}
		}
		&__bnt {
			position: absolute;
			top: calc(50% - 18px);
			right: 16px;
			@include r.sp {
				top: 8px;
				right: 8px;
			}
			.nk23_c-btn02 {
				width: 150px;
				@include r.sp {
					width: 72px;
				}
			}
		}
	}
	&__list2 {
		margin: 0 160px 0 0;
		@include r.sp {
			margin: 0;
		}
		&__item {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			@include r.sp {
				display: block;
			}
			& + & {
				margin-top: 8px;
				@include r.sp {
					margin-top: 4px;
				}
			}
		}
	}
	&__tag {
		width: 100px;
		border: 1px solid #e63434;
		border-radius: 4px;
		line-height: 26px;
		color: #e63434;
		font-size: 1.4rem;
		font-weight: bold;
		text-align: center;
		margin-right: 8px;
		@include r.sp {
			width: 74px;
			font-size: 1.1rem;
			line-height: 23px;
		}
	}
	&__txt {
		@include r.sp {
			margin-top: 8px;
		}
		&1 {
			color: #ffffff;
			font-size: 1.4rem;
			font-weight: bold;
			border-radius: 4px;
			width: 30px;
			line-height: 28px;
			display: inline-block;
			text-align: center;
			margin-right: 8px;
			@include r.sp {
				width: 25px;
				line-height: 25px;
				font-size: 1.1rem;
			}
			&__white {
				background: #ffffff;
				color: v.$color0;
				border: 1px solid #c9c9c9;
			}
			&__black {
				background: v.$color0;
			}
			&__red {
				background: v.$color13;
				text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
			}
			&__blue {
				background: v.$color14;
				text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
			}
			&__yellow {
				background: v.$bg-yellow;
				text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
			}
			&__green {
				background: v.$bg-green;
				text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
			}
			&__orange {
				background: v.$bg-orange;
				text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
			}
			&__pink {
				background: v.$bg-peach;
				text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
			}
		}
		&2,
		&3 {
			font-size: 1.4rem;
			line-height: 30px;
			display: inline-block;
			@include r.sp {
				line-height: 25px;
			}
		}
		&2 {
			@include r.sp {
				font-size: 1.2rem;
			}
		}
		&3 {
			color: #2461ee;
			margin-left: 8px;
		}
	}
	&__time {
		font-size: 1.4rem;
		line-height: 28px;
		@include r.sp {
			margin-top: 4px;
		}
		span {
			color: #e63434;
		}
	}
	&__text2 {
		font-size: 1.4rem;
		line-height: 28px;
		margin-left: 8px;
		@include r.sp {
			margin: 8px 0 0;
			line-height: 22px;
		}
		span {
			color: #2461ee;
		}
	}
}

.js-mheight {
	opacity: 0;
}

/*
------------------------------------------------------------*/

.nk23_c-block02 {
	width: 380px;
	margin-left: auto;
	background: #fff;
	border-radius: 8px;
	$root: &;
	@include r.pc {
		box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.15);
	}
	@include r.sp {
		width: 100%;
	}
	//-------------------------------------------------------
	&__content {
		display: flex;
		flex-wrap: wrap;
	}
	//-------------------------------------------------------
	&__item {
		// .nk23_c-list01__btn .nk23_c-btn02 .
		.nk23_c-list01__btn {
			.nk23_c-btn02 {
				// thanglq 091123
				border-radius: 8px;
				min-width: 81px;
				max-width: 109px;
				border-color: #919599;
				box-shadow: 0 2px 0 #919599;
				@include r.hover {
					box-shadow: none;
					position: relative;
					top: 2px;
				}
				@include r.sp {
					font-size: 1.4rem;
					min-width: auto;
					max-width: calc(100% / 3 - 6px);
				}

				&.is-disable {
					pointer-events: all;
					cursor: default;
					background: rgba($color: #fff, $alpha: 0.5);
					box-shadow: 0 2px 0 #c9c9c9;
					border: 1px solid #c9c9c9;

					&:hover {
						top: 0px;
						cursor: default;
						background: rgba($color: #fff, $alpha: 0.5);
						box-shadow: 0 2px 0 #c9c9c9;
						border: 1px solid #c9c9c9;
					}
				}
			}
		}
		&.is-border1 {
			border-top: 1px solid v.$color19;
		}
	}
	//-------------------------------------------------------
	&__ttl {
		background: #fff;
		font-size: 2rem;
		line-height: 1.45;
		color: v.$color0;
		font-weight: bold;
		border-bottom: 1px solid v.$color19;
		border-radius: 8px 8px 0 0;
		padding: 8px 16px 4px;
		@include r.sp {
			font-size: 1.6rem;
			line-height: 1.5;
			padding: 12px 8px;
		}
		&.is-style1 {
			@include r.sp {
				height: 49px;
			}
		}
		&.is-full {
			@include r.sp {
				width: calc(100vw - 16px);
				background-color: white;
				z-index: 1;
				position: relative;
			}
		}
	}
	//-------------------------------------------------------
	&__ttl2 {
		background: #fff;
		display: flex;
		align-items: center;
		padding: 16px;
		@include r.sp {
			display: none;
		}
		&__icon {
			width: 32px;
			margin: 0 8px 0 0;

			&.fnb {
				padding: 3px;
			}
		}
		&__text {
			flex: 1;
			font-size: 1.6rem;
			line-height: 1.5;
			color: v.$color0;
			font-weight: bold;
		}
	}
	//-------------------------------------------------------
	&__info {
		@include r.sp {
			width: 100%;
		}
		.nk23_c-list01 {
			&__item {
				&:last-child {
					border-radius: 0 0 8px 8px;
				}
				&.rounded-none {
					border-radius: 0;
				}
				&.is-left {
					border-radius: 0 0 0px 8px;
				}
				&.is-right {
					border-radius: 0 0 8px 0;
				}
			}
		}
	}
	//-------------------------------------------------------
	&.is-style1 {
		background: none;
		box-shadow: none;
		#{$root}__item {
			width: 50%;
			box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.15);
			border-radius: 0 0 8px 8px;
			position: relative;
			z-index: 2;
			&:nth-child(2n) {
				border-left: 1px solid v.$color19;
				z-index: 1;
			}
		}
		#{$root}__ttl2 {
			padding: 8px 4px 8px 8px;
			&__icon {
				width: 24px;
				margin: 0 4px 0 0;
			}
			&__text {
				font-size: 1.4rem;
				line-height: 1.45;
			}
		}
	}
	//-------------------------------------------------------
	&.is-style2 {
		#{$root}__item {
			width: 50%;
			&:nth-child(2n) {
				border-left: 1px solid v.$color19;
			}
			&:not(.w-full) {
				.nk23_c-list01 {
					&__item {
						&:last-child {
							border-radius: 0;
						}
					}
				}
			}
			&.w-full {
				width: 100%;
			}
		}
		#{$root}__ttl2 {
			padding: 8px 4px 8px 8px;
			&__icon {
				width: 24px;
				margin: 0 4px 0 0;
			}
			&__text {
				font-size: 1.4rem;
				line-height: 1.45;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-block03 {
	width: 380px;
	margin-left: auto;
	background: #ffffff;
	border-radius: 8px;
	@include r.sp {
		width: 100%;
	}
	//-------------------------------------------------------
	&__ttl {
		font-size: 1.6rem;
		line-height: 1.5;
		color: v.$color0;
		font-weight: bold;
		border-bottom: 1px solid v.$color19;
		padding: 11px 16px 5px;
		&.is-color01 {
			border-bottom: 1px solid v.$color59;
		}
		@include r.sp {
			padding: 11px 8px 5px;
		}
		img {
			margin: 4px 0 3px;
		}
	}
	//-------------------------------------------------------
	&__info {
		padding: 15px 16px;
		@include r.sp {
			padding: 14px 8px;
		}
	}
	.nk23_c-slide01 {
		margin: -8px -16px;
		@include r.sp {
			margin: -6px -8px;
		}
	}
	.swiper {
		&-button-next,
		&-button-prev {
			@include r.pc {
				top: 8px;
				height: 87%;
				width: 17px;
			}
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block04 {
	border-radius: 8px;
	background-color: #fff;
	// .nk23_c-block04__ttl
	&__ttl {
		display: flex;
		justify-content: space-between;
		min-height: 41px;
		padding: 16px;
		line-height: 1;
		font-size: 1.6rem;
		font-weight: bold;
		@include r.sp {
			padding: 12px 8px;
		}
		// .nk23_c-block04__link
		&__link {
			color: v.$color-link;
			font-size: 1.4rem;
			margin-top: 2px;
			cursor: pointer;
			@include r.sp {
				font-size: 1.2rem;
			}
			@include r.hover {
				text-decoration: underline;
			}
		}
	}
	// .nk23_c-block04__item
	&__item {
		border-top: 1px solid v.$color23;
		min-height: 56px;
		display: flex;
		align-items: center;
		width: 100%;
		@include r.sp {
			display: block;
			padding: 0;
		}
	}
	// .nk23_c-block04__title
	&__title {
		font-size: 1.6rem;
		font-weight: bold;
		width: calc(100% - 378px);
		color: v.$color-link;
		padding-left: 16px;
		@include r.sp {
			width: 100%;
			padding: 10px 8px;
			border-bottom: 1px solid v.$color23;
		}
		&.no-border-sp {
			@include r.sp {
				border-bottom: none;
			}
		}
	}
	a {
		&:hover {
			text-decoration: underline;
		}
	}
	&__weather {
		display: block;
		font-size: 1.2rem;
		font-weight: 400;
		color: v.$color0;
		display: flex;
		align-items: center;
		margin-top: 5px;
		img {
			margin-left: 4px;
		}
	}
	// .nk23_c-block04__content
	&__content {
		width: 378px;
		@include r.sp {
			width: 100%;
			padding: 0 8px;
		}
		.nk23_c-block01__list__item {
			padding: 0 22px 10px 0;
			@include r.sp {
				padding: 0 8px 10px 0;
				.nk23_c-block01__btns {
					padding: 0 8px;
				}
				.nk23_c-block01__list__title {
					&::after {
						right: 0px;
					}
				}
			}
		}
	}
	// .nk23_c-block04__text
	&__text {
		font-weight: 700;
		@include r.sp {
			padding-bottom: 16px;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block05 {
	border-radius: 8px;
	background-color: #fff;
	// .nk23_c-block05__ttl
	&__ttl {
		border-bottom: 1px solid v.$color33;
		justify-content: space-between;
		min-height: 41px;
		padding: 16px;
		line-height: 1;
		font-size: 1.6rem;
		font-weight: bold;
		@include r.sp {
			padding: 12px 8px;
		}
	}
	&__wrap {
		padding: 16px;
	}
	&__txt {
		font-size: 1.4rem;
		line-height: 22px;
		margin-bottom: 12px;
	}
}

/*------------------------------------------------------------*/

.nk23_c-block06 {
	border-radius: 8px;
	background-color: #fff;
	// .nk23_c-block06__ttl
	&__ttl {
		border-bottom: 1px solid v.$color23;
		display: flex;
		justify-content: space-between;
		min-height: 41px;
		padding: 16px;
		line-height: 1;
		font-size: 1.6rem;
		font-weight: bold;
		@include r.sp {
			padding: 12px 8px;
		}
		// .nk23_c-block06__link
		&__link {
			color: v.$color-link;
			font-size: 1.4rem;
			margin-top: 2px;
			min-width: max-content;
			max-width: 40px;
			white-space: nowrap;
			@include r.sp {
				font-size: 1.2rem;
			}
			@include r.hover {
				text-decoration: underline;
				opacity: 1;
			}
		}
		&__txt {
			padding-right: 16px;

			@include r.pc {
				line-height: 1.438;
			}

			@include r.sp {
				padding-right: 8px;
			}
		}
	}
	// .nk23_c-block06__wrap
	&__wrap {
		padding: 8px;
		@include r.sp {
			padding: 21px 0 8px;
		}
		.nk23_c-tab6__navi {
			// padding: 8px;
			// margin: 6px 8px;
			@include r.sp {
				// margin: 0 8px;
				// padding: 4px;
			}
			&__text {
				@include r.hover {
					// background: v.$color0;
				}
				&.is-active {
					// background-color: v.$color0;
				}
				@include r.sp {
					font-size: 1.2rem;
					word-break: keep-all;
				}
			}
			&__item {
				@include r.sp {
					max-width: none;
				}
			}
		}
		.nk23_c-list06 {
			margin-top: 8px;
		}
	}
	.nk23_c-tab6__navi {
		@include r.sp {
			border: 1px solid v.$color19;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block07 {
	margin-top: 10px;
	border-radius: 8px;
	background-color: #fff;
	&.is-style1 {
		border-radius: 8px 8px 0 0;
	}
	// .nk23_c-block07__ttl
	&__ttl {
		border-bottom: 1px solid v.$color23;
		min-height: 41px;
		padding: 16px;
		line-height: 1;
		font-size: 1.6rem;
		font-weight: bold;
		@include r.sp {
			padding: 12px 8px;
		}
		&.is-icon {
			padding-left: 44px;
			position: relative;
			@include r.sp {
				padding-left: 36px;
			}
			.nk23_c-block07__ttl__icon {
				position: absolute;
				left: 16px;
				bottom: 0;
				@include r.sp {
					left: 10px;
				}
			}
		}
	}
	&__wrap {
		padding: 0 8px;
	}
}

/*------------------------------------------------------------*/

.nk23_c-block08 {
	background: #fff;
	border: 1px solid v.$color39;
	border-radius: 8px;
	padding: 0 22px 12px 16px;
	position: relative;
	$root: &;
	@include r.sp {
		padding: 0 22px 9px 8px;
	}
	&.is-bg1 {
		background-color: v.$color55;
	}
	//-------------------------------------------------------
	&__heading {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}
	//-------------------------------------------------------
	&__tag {
		width: 44px;
		text-align: center;
		padding: 5px 5px 12px;
		position: relative;
		background: url(p.$path + "images/common/bg-label_urw.svg") no-repeat bottom center/100%;
		&__icon {
			display: block;
			img {
				margin: 0 auto;
			}
		}
		&__txt {
			display: block;
			font-size: 1.2rem;
			line-height: 1.1;
			color: #ffffff;
			font-weight: bold;
		}
	}
	//-------------------------------------------------------
	&__logo {
		width: 50px;
		margin-right: 8px;
		@include r.sp {
			display: none;
		}
		img {
			margin: 0 auto;
		}
	}
	//-------------------------------------------------------
	&__info {
		flex: 1;
		display: flex;
		align-items: center;
		padding: 5px 0 0 7px;
	}
	//-------------------------------------------------------
	&__detail {
		flex: 1;
	}
	//-------------------------------------------------------
	&__txt {
		font-size: 1.2rem;
		line-height: 1.4;
		color: v.$color0;
	}
	//-------------------------------------------------------
	&__ttl {
		font-size: 1.4rem;
		line-height: 1.72;
		color: v.$color-link;
		font-weight: bold;
		margin: -2px 0 0;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		@include r.hover {
			text-decoration: underline;
		}
		a {
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: transparent;
			}
			&::after {
				content: "";
				position: absolute;
				right: 8px;
				width: 8px;
				height: 14px;
				background: url(p.$path + "images/common/icon/icon-obj_arrow.svg") no-repeat center / 8px !important;
				top: calc(50% - 7px);
			}
		}
	}
	//-------------------------------------------------------
	&__btn {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 8px;
		margin: 13px 0 0;
		@include r.sp {
			grid-template-columns: repeat(2, 1fr);
			gap: 10px 9px;
			padding-left: 14px;
		}

		.nk23_c-btn02,
		.nk23_c-btn03 {
			width: auto !important;
			border-radius: 8px;
			@include r.sp {
				max-width: none;
			}
		}

		.nk23_c-btn02 {
			@include r.sp {
				font-size: 1.4rem !important;
				min-height: 35px !important;
			}

			&.is-style1{
				border-color: #919599;
				box-shadow: 0 2px 0 #919599;

				&.is-disable{
					box-shadow: 0 2px 0 rgba(145, 149, 153, 0.5);
					border: 1px solid rgba(145, 149, 153, 0.5);
				}

				&.is-active,
				&.is-hover,
				&:hover{
					box-shadow: none!important;
				}

				&.is-btn-urawa {
					border-color: v.$urawa-color-light;
					box-shadow: 0 2px 0 v.$urawa-color-light;

					&.is-disable{
						box-shadow: 0 2px 0 rgba(96, 123, 191, 0.5);
						border: 1px solid rgba(96, 123, 191, 0.5);
					}
				}

				&.is-btn-ooi {
					border-color: v.$ooi-color-light;
					box-shadow: 0 2px 0 v.$ooi-color-light;

					&.is-disable{
						box-shadow: 0 2px 0 rgba(204, 92, 92, 0.5);
    				border: 1px solid rgba(204, 92, 92, 0.5);
					}
				}

				&.is-btn-funabashi {
					border-color: v.$funabashi-color-light;
					box-shadow: 0 2px 0 v.$funabashi-color-light;

					&.is-disable{
						box-shadow: 0 2px 0 rgba(100, 166, 100, 0.5);
    				border: 1px solid rgba(100, 166, 100, 0.5);
					}

				}

				&.is-btn-kawasaki {
					border-color: v.$kawasaki-color-light;
					box-shadow: 0 2px 0 v.$kawasaki-color-light;

					&.is-disable{
						box-shadow: 0 2px 0 rgba(83, 166, 166, 0.5);
    				border: 1px solid rgba(83, 166, 166, 0.5);
					}
				}
			}

			&.is-color1 {
				border: 1px solid v.$color2 !important;
				box-shadow: 0 2px 0 v.$color2 !important;
				background-color: v.$color3 !important;
				.nk23_c-btn02__text {
					color: v.$color0 !important;
				}
				@include r.hover {
					box-shadow: none !important;
				}
			}
		}
		.nk23_c-btn03 {
			@include r.sp {
				font-size: 1.4rem;
			}
		}
		&.onelayout {
			@include r.sp {
				grid-template-columns: repeat(1, 1fr);
				.nk23_c-btn02.is-style1 {
					width: calc(50% - 4.5px) !important;
					margin-left: auto;
				}
			}
		}
		// &.twolayout {
		// 	grid-template-columns: repeat(2, 1fr);
		// }
	}
	//-------------------------------------------------------
	&.is-style1 {
		border-color: v.$funabashi-color;
		#{$root}__tag {
			background: url(p.$path + "images/common/bg-label_fnb.svg") no-repeat bottom center/100%;
		}
	}
	//-------------------------------------------------------
	&.is-style2 {
		border-color: v.$ooi-color;
		#{$root}__tag {
			background: url(p.$path + "images/common/bg-label_tck.svg") no-repeat bottom center/100%;
		}
	}
	//-------------------------------------------------------
	&.is-style3 {
		border-color: v.$kawasaki-color;
		#{$root}__tag {
			background: url(p.$path + "images/common/bg-label_kws.svg") no-repeat bottom center/100%;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block09 {
	// .nk23_c-block09__ttl
	&__ttl {
		background: #ffffff;
		padding: 10px 16px;
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 35px;
		@include r.sp {
			background: transparent;
			font-size: 1.6rem;
			line-height: 24px;
		}
	}
	// .nk23_c-block09__wrap
	&__wrap {
		background: v.$color2;
		border-radius: 0 0 8px 8px;
		overflow: hidden;
		@include r.sp {
			border-radius: 0;
		}
	}
	.nk23_c-banner1 {
		margin-top: 16px;
		@include r.sp {
			margin-top: 8px;
		}
	}
	// .nk23_c-block09__item
	&__item {
		padding: 16px;
		&:not(:first-child) {
			padding-top: 0;
		}
		@include r.sp {
			padding: 8px;
		}
		&.is-bg1 {
			&:not(:first-child) {
				padding-top: 16px;
				@include r.sp {
					padding-top: 8px;
				}
			}
			background: v.$color8;
			.nk23_c-banner1__label {
				background: v.$ooi-color;
			}
		}
		&.is-bg2 {
			&:not(:first-child) {
				padding-top: 16px;
				@include r.sp {
					padding-top: 8px;
				}
			}
			background: v.$color11;
			.nk23_c-banner1__label {
				background: v.$kawasaki-color;
			}
		}
		&.is-bg3 {
			&:not(:first-child) {
				padding-top: 16px;
				@include r.sp {
					padding-top: 8px;
				}
			}
			background: v.$color5;
			.nk23_c-banner1__label {
				background: v.$funabashi-color;
			}
		}
	}
	// .nk23_c-block09__listbtn
	&__listbtn {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		margin: 0 -4px 8px;
		@include r.sp {
			grid-template-columns: repeat(2, 1fr);
			gap: 0;
			margin-bottom: 4px;
		}
		.nk23_c-btn02 {
			margin: 0 4px 10px;
			border-radius: 8px;
			border-color: #919599;
			box-shadow: 0 2px 0 #919599;
			@include r.hover {
				box-shadow: none;
				position: relative;
				top: 2px;
			}
			@include r.pc {
				width: auto;
			}
			@include r.sp {
				width: auto;
				max-width: none;
				font-size: 1.4rem;
				min-height: 35px;
			}
			&__icon {
				width: auto !important;
			}
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block10 {
	max-width: 770px;
	$root: &;
	// .nk23_c-block10__top
	&__top {
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		padding: 0 16px;
		margin-bottom: 16px;
		// .nk23_c-block10__top__img
		&__img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			overflow: hidden;
			width: 100%;
			z-index: 0;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		// .nk23_c-block10__top__ttl
		&__ttl {
			position: relative;
			z-index: 1;
			min-height: 104px;
			display: flex;
			align-items: center;
			color: #ffffff;
			font-weight: bold;
			@include r.sp {
				min-height: 104px;
			}
			// .nk23_c-block10__top__icon
			.nk23_c-block10__top__icon {
				max-width: 72px;
				margin-right: 16px;
				@include r.sp {
					max-width: 56px;
					margin-right: 8px;
				}
			}
			// .nk23_c-block10__top__ttl0
			&0 {
				display: flex;
				align-items: center;
			}
			// .nk23_c-block10__top__ttl1
			&1 {
				font-size: 3.2rem;
				padding: 0 16px 0 0;
				@include r.sp {
					font-size: 2.5rem;
					padding: 0 8px 0 0;
				}
			}
			// .nk23_c-block10__top__ttl2
			&2 {
				font-size: 1.6rem;
				text-transform: uppercase;
			}
		}
	}
	// .nk23_c-block10__content
	&__content {
		border-radius: 8px;
		background: #ffffff;
		min-height: 500px;
		padding: 16px;
		@include r.sp {
			padding: 8px;
		}
	}
	// .nk23_c-block10__info
	&__info {
		.nk23_c-slide03 {
			width: 278px;
			@include r.sp {
				width: auto;
				margin: 0 auto;
			}
		}
		display: flex;
		justify-content: space-between;
		@include r.sp {
			flex-wrap: wrap;
		}
		#{$root}__detail {
			width: calc(100% - 294px);
			@include r.sp {
				width: 100%;
				margin-top: 32px;
			}
		}
		.nk23_c-table07 {
			margin: 16px 0;
		}
	}
	// .nk23_c-block10__ttl
	&__ttl {
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 22px;
		margin-bottom: 8px;
	}
	// .nk23_c-block10__img
	&__img {
		border-radius: 4px;
		overflow: hidden;
	}
	// .nk23_c-block10__txt
	&__txt {
		font-size: 1.4rem;
		line-height: 22px;
	}
	// .nk23_c-block10__btn
	&__btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;
		@include r.sp {
			flex-wrap: wrap;
			padding: 0 8px;
		}
		.nk23_c-btn01,
		.nk23_c-btn02 {
			padding-left: 16px;
			padding-right: 16px;
			@include r.sp {
				width: 100% !important;
				border-radius: 999px !important;
				padding-left: 0;
				padding-right: 0;
			}
		}
		.nk23_c-btn02 {
			@include r.sp {
				margin-top: 16px;
				.nk23_c-btn02__text {
					flex-direction: inherit !important;
					font-size: 1.4rem;
				}
				.nk23_c-btn02__icon {
					margin-right: 4px;
				}
			}
		}
	}
	&__list {
		margin-top: 32px;
		.nk23_c-block10__ttl {
			margin-bottom: 16px;
		}
	}
	&__contact {
		margin-top: 32px;
		&__txt {
			font-size: 1.4rem;
			margin-bottom: 4px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
		.is-tel {
			font-size: 2.4rem;
			font-weight: bold;
			margin-left: 8px;
			pointer-events: none;
		}
		&__link {
			color: v.$color-link;
			margin-left: 8px;
			@include r.sp {
				margin-left: 0;
				display: block;
				margin-top: 4px;
			}
			@include r.hover {
				text-decoration: underline;
				opacity: 1;
			}
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block11 {
	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include r.sp {
			flex-direction: column;
		}
	}
	&__item {
		width: 472px;
		margin-bottom: 16px;
		@include r.sp {
			width: 100%;
		}
		.nk23_c-table01 {
			&__title {
				@include r.sp {
					position: relative;
				}
				&:after {
					@include r.sp {
						content: "";
						background: url(p.$path + "images/common/icon/icon_arrow_black.svg") no-repeat;
						position: absolute;
						width: 12px;
						height: 6px;
						background-size: 12px 6px;
						right: 18px;
						top: 50%;
						transform: translateY(-50%) rotate(180deg);
					}
				}
				&.active {
					&:after {
						transform: rotate(0);
						margin-top: -3px;
					}
				}
			}
			&__table {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				width: 100%;
				table-layout: unset;
				font-size: 1.4rem;
				box-sizing: border-box;
				@include r.sp {
					min-width: 100%;
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
				}
				.is-bg2 {
					background: #f7f7f7;
				}
				td {
					@include r.sp {
						border-bottom: 1px solid #c9c9c9;
					}
				}
			}
		}
	}
	&__time {
		font-size: 1.4rem;
		line-height: 1rem;
		margin-bottom: 8px;
		padding: 0 8px;
		@include r.sp {
			font-size: 1.2rem;
		}
	}
	&__info {
		background: #ffffff;
		border-radius: 8px;
		padding: 16px;
		display: flex;
		justify-content: space-between;
		@include r.sp {
			padding: 8px;
		}
		&__img {
			width: 220px;
			@include r.sp {
				width: 149px;
			}
			img {
				width: 100%;
			}
		}
		&__detail {
			flex: 1;
			padding-left: 16px;
			@include r.sp {
				padding-left: 8px;
			}
		}
		&__text {
			font-size: 1.4rem;
			line-height: 2rem;
			@include r.sp {
				font-size: 1.2rem;
			}
		}
		&__ttl {
			font-size: 2.2rem;
			font-weight: bold;
			line-height: 2rem;
			@include r.sp {
				font-size: 2rem;
			}
		}
		&__btn {
			margin: 16px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.nk23_c-btn01 {
				width: calc(50% - 4px);
				@include r.sp {
					width: calc(50% - 2px);
					max-height: 35px;
				}
			}
			.nk23_c-btn02 {
				width: 100%;
				margin-top: 8px;
				font-weight: 400;
				&:not(.is-style1) {
					@include r.sp {
						border-radius: 999px;
					}
					.nk23_c-btn02__text {
						@include r.sp {
							flex-direction: row;
						}
					}
				}
				&__icon {
					width: 20px;
					@include r.sp {
						margin-right: 4px;
					}
				}
			}
		}
		&__table {
			font-size: 1.4rem;
			border-collapse: collapse;
			width: 100%;
			tr {
				border-bottom: 1px solid #c9c9c9;
				&:last-child {
					border-bottom: 0;
				}
				td {
					padding: 8px 0;
				}
			}
		}
		&__logo {
			display: inline-block;
			vertical-align: middle;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block12 {
	background: #fff;
	max-width: 456px;
	padding: 16px 16px 16px 8px;
	border-bottom: 1px solid #c9c9c9;
	margin: auto;
	&:last-of-type {
		border-bottom: 0;
	}
	@include r.sp {
		padding: 8px 8px 8px 0;
		max-width: 100%;
	}
	&__title {
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 18.2px;
	}
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__left {
		width: calc(100% - 103px);
		padding: 0 8px;
		@include r.sp {
			width: calc(100% - 106px);
		}
	}
	&__right {
		width: 103px;
		border: 1px solid #221f1f;
		border-radius: 8px;
		font-size: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px 8px;
		@include r.sp {
			width: 106px;
		}
	}
	&__table {
		font-size: 1.4rem;
		width: 100%;
		tr {
			border-bottom: 1px solid #c9c9c9;
			th {
				width: 36px;
				text-align: left;
				vertical-align: top;
				padding: 8px 0;
				box-sizing: border-box;
			}
			td {
				padding: 8px 0 8px 16px;
				width: calc(100% - 36px);
				box-sizing: border-box;
			}
		}
	}
	&__number {
		line-height: 18.2px;
		&:after {
			content: "/";
			font-size: 1.4rem;
			display: inline-block;
			margin: 0 6px;
		}
		&:last-child {
			&:after {
				display: none;
				margin: 0;
			}
		}
	}
	&__content {
		max-width: 56px;
		margin: auto;
	}
	&__price {
		font-size: 1.2rem;
		text-align: right;
		span {
			font-size: 2.2rem;
			font-weight: bold;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block13 {
	@include r.sp {
		padding: 0 8px;
	}
	&__heading {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px 0 8px;
		@include r.sp {
			padding: 0;
		}
	}
	&__tooltip {
		font-size: 1.4rem;
		line-height: 22px;
		color: v.$color-link;
		position: relative;
		padding: 0 0 0 26px;
		&::before {
			content: "";
			width: 18px;
			height: 18px;
			background: url(p.$path + "images/common/icon/icon_help.svg") no-repeat center / 100%;
			position: absolute;
			top: 2px;
			left: 0;
		}
		@include r.hover {
			text-decoration: underline;
			opacity: 1;
		}
	}
	&__option {
		display: flex;
		flex-wrap: wrap;
		@include r.sp {
			width: 100%;
			order: -1;
			padding: 0 24px 0 8px;
		}
		@include r.max-w(374px) {
			padding: 0 8px;
		}
		.nk23_c-checkbox2 {
			@include r.sp {
				width: calc((100% - 24px) / 4);
			}
			&:not(:last-child) {
				margin-right: 24px;
				@include r.sp {
					margin: 0 8px 16px 0;
				}
			}
			&:nth-child(4n) {
				@include r.sp {
					margin-right: 0;
				}
			}
		}
	}
	.nk23__accordion {
		@include r.sp {
			width: calc(100% + 16px);
			order: -1;
			margin: 0 -8px 16px;
		}
	}
	&__text {
		font-size: 1.2rem;
		line-height: 18px;
		color: v.$color0;
	}
	&__info {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		margin: 16px 0 0;
		@include r.sp {
			margin: 8px 0 0;
		}
	}
	&__content {
		width: calc(50% - 8px);
		@include r.sp {
			width: 100%;
		}
	}
	&__detail {
		width: calc(50% - 8px);
		background: #fff;
		border-radius: 8px;
		@include r.sp {
			width: 100%;
			margin-top: 8px;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block14 {
	.nk23_c-table12 {
		&__table {
			border-radius: 0;
			@include r.sp {
				border-radius: 8px;
			}
		}
		tbody {
			tr {
				&:last-child {
					td {
						@include r.pc {
							border-bottom: 1px solid v.$color19;
						}
					}
				}
			}
		}
		.scrollbar-thumb {
			background-color: v.$base-color;
		}
	}
	// .nk23_c-block14__text .
	&__text {
		margin-bottom: 10px;
		padding-left: 10px;
		text-indent: -10px;
		font-size: 1.2rem;
		line-height: 1.9rem;
		@include r.sp {
			margin-bottom: 8px;
		}
	}
	&__des {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: flex-start;
		&__text {
			display: flex;
			font-size: 1.2rem;
			line-height: 1;
			align-items: center;
			@include r.pc {
				margin-bottom: 8px;
			}
			// .nk23_c-block14__des__text:not(:first-child) .
			&:not(:first-child) {
				margin-left: 24px;
				@include r.w374 {
					margin-left: 8px;
				}
			}
		}
		&__label {
			margin-right: 4px;
			line-height: 2.25rem;
			color: v.$color13;
			text-align: center;
			width: 24px;
			height: 25px;
			border: 1px solid v.$color19;
			font-size: 1.2rem;
			&.is-icon {
				position: relative;
				&:after {
					top: 10px;
					left: 50%;
					transform: translateX(-50%);
					width: 12px;
					height: 2px;
					background-color: v.$color0;
					position: absolute;
					content: "";
				}
			}
		}
	}
	&__btns {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		@include r.sp {
			margin: 0 -4px;
		}
		.nk23_c-btn01 {
			background-color: #fff;
			@include r.pc {
				margin-bottom: 10px;
			}
			@include r.sp {
				margin: 0 4px;
				min-width: calc(33.3333% - 8px) !important;
			}
			&:not(:last-child) {
				@include r.sp {
					margin-bottom: 10px;
				}
				@include r.pc {
					margin-right: 16px;
				}
			}
		}
	}
	&__box {
		padding: 16px 16px 8px;
		border-radius: 8px;
		background-color: #fff;
		@include r.pc {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		@include r.sp {
			padding: 8px;
			border-radius: 4px;
		}
		&:first-child {
			border-radius: 8px 8px 0 0;
			margin-bottom: 1px;
			@include r.sp {
				margin-bottom: 8px;
				border-radius: 8px;
			}
		}
		// .nk23_c-block14__box:last-child .
		&:last-child {
			border-radius: 0 0 8px 8px;
			@include r.sp {
				margin-top: 8px;
				border-radius: 8px;
			}
		}
		&.is-flex-reverse {
			@include r.pc {
				flex-direction: row-reverse;
			}
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block15 {
	border-radius: 8px;
	background-color: #fff;
	&__btns {
		margin-top: 8px;
		display: flex;
		flex-wrap: wrap;
		@include r.pc {
			margin-left: -4px;
			margin-right: -4px;
		}
		@include r.sp {
			justify-content: space-between;
		}
		.nk23_c-btn01 {
			width: calc(25% - 8px);
			margin-bottom: 10px;
			@include r.pc {
				margin-left: 4px;
				margin-right: 4px;
			}
			@include r.sp {
				width: calc(50% - 4px);
			}
		}
	}
	&__title {
		font-size: 1.8rem;
		line-height: 2.7rem;
		font-weight: 700;
		@include r.pc {
			margin-bottom: 8px;
			display: flex;
		}
		@include r.sp {
			padding-left: 48px;
			position: relative;
		}
		&__text {
			@include r.pc {
				padding-left: 8px;
				flex: 1;
			}
		}
		// .nk23_c-block15__title img .
		img {
			display: inline-block;
			min-width: 32px;
			@include r.sp {
				top: 7px;
				left: 0;
				position: absolute;
				min-width: 40px;
			}
		}
	}
	&__text {
		font-size: 1.4rem;
		line-height: 2rem;
		@include r.sp {
			margin-top: 4px;
			padding-left: 48px;
			font-size: 1.3rem;
			line-height: 1.9rem;
		}
	}
	&__item {
		padding: 16px 16px 8px;
		@include r.sp {
			padding: 16px 8px 8px;
		}
		&:not(:last-child) {
			border-bottom: 1px solid v.$color19;
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block16 {
	max-width: 640px;
	margin: 0 auto;
	&__note {
		font-size: 1.2rem;
		line-height: 17px;
		&__item {
			display: flex;
			margin: 8px 0 0 0;
		}
		&__ttl {
			min-width: 36px;
			margin-left: 12px;
		}
		&__txt {
			width: calc(100% - 48px);
		}
	}
}

/*------------------------------------------------------------*/

.nk23_c-block17 {
	border-radius: 8px;
	margin-top: 16px;

	@include r.sp {
		// Add strix St 23.11.20
		// border-radius: 0;
		// End strix St 23.11.20
		margin: 8px -8px 0;
	}
	&__head {
		display: flex;
		justify-content: space-between;
		padding: 12px 16px;
		align-items: center;
		border-bottom: 1px solid v.$color19;
		background: #fff;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		@include r.sp {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
	&__ttl {
		font-size: 1.6rem;
		font-weight: bold;
	}

	// Add strix St 23.11.20
	&__link {
		background: #ffffff;
		border-radius: 8px;
		padding: 8px 25px 8px 8px;
		display: block;
		position: relative;

		@include r.pc {
			font-size: 1.4rem;
			padding: 8px 40px 8px 16px;
		}

		&::before {
			content: "";
			position: absolute;
			right: 13px;
			top: calc(50% - 3px);
			width: 12px;
			height: 6px;
			background: url(p.$path + "images/common/icon/icon_totop.svg") no-repeat center / 100%;
			transform: rotate(90deg);

			@include r.sp {
				width: 8px;
				height: 4px;
				background-size: 100%;
				right: 8px;
				top: calc(50% - 3px);
			}
		}

		&:hover {
			.nk23_c-block17__text2 {
				text-decoration: underline;
				opacity: 1;
			}
		}

		&.is-bottom {
			&::before {
				right: 10px;
				transform: rotate(90deg);
			}
		}
	}

	&__text1 {
		font-weight: bold;
		font-size: 1.2rem;

		& + .nk23_c-block17__text2 {
			@include r.pc {
				margin-top: 4px;
			}
		}

		@include r.pc {
			font-size: 1.4rem;
		}

	}

	&__text2 {
		font-size: 1.2rem;
		color: v.$color-link;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;

		@include r.pc {
			font-size: 1.4rem;
		}

	}
	// End strix St 23.11.20

	.nk23_c-tab2 {
		&__navi {
			background: #fff;
			padding-top: 16px;
			position: relative;
			&:before {
				content: "";
				width: 100%;
				height: 16px;
				background: #fff;
				position: absolute;
				bottom: -16px;
				left: 0;
			}
			&__text {
				font-size: 1.6rem !important;
			}
		}

		.swiper {
			&-button-prev {
				&:after {
					@include r.sp {
						background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					}
				}
			}

			&-button-next {
				&:after {
					@include r.sp {
						background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					}
				}
			}
		}
	}
	.nk23_c-tab2__content {
		margin-top: 16px;
	}
}

/*------------------------------------------------------------*/

.nk23_c-block18 {
	margin-bottom: 16px;
	padding: 16px 16px 18px;
	border-radius: 0 0 8px 8px;
	background-color: #fff;
	$root: &;
	@include r.sp {
		padding: 16px 8px 18px;
		border-radius: 8px;
	}
	&__cols {
		@include r.pc {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}
	&__btns {
		display: flex;
		flex-wrap: wrap;
		.nk23_c-radiobtn01 {
			&:nth-child(2n + 1) {
				@include r.sp {
					margin-right: 8px;
				}
			}
		}
		// .nk23_c-block18__btns .nk23_c-btn01 .
		.nk23_c-btn01 {
			width: 182px;
			@include r.pc {
				margin-left: 8px;
			}
			@include r.sp {
				width: 135px;
			}
			&:nth-child(2n + 1) {
				@include r.sp {
					margin-right: 8px;
				}
			}
		}
	}
	&__grselect {
		display: flex;
		@include r.pc {
			margin-bottom: 8px;
			justify-content: space-between;
		}
		@include r.sp {
			margin-bottom: 8px;
			flex-wrap: wrap;
		}
		@include r.max-w(414px) {
			justify-content: space-between;
		}
	}
	// .nk23_c-block18__selectbox .
	&__selectbox {
		display: flex;
		align-items: center;
		@include r.sp {
			margin-bottom: 8px;
		}
		@include r.max-w(414px) {
			width: calc(50% - 4px);
		}
		// .nk23_c-block18__selectbox:not(:last-child) .
		&:not(:last-child) {
			margin-right: 12px;
			@include r.max-w(414px) {
				margin-right: 0;
			}
		}
		&:nth-child(even) {
			@include r.max-w(414px) {
				padding-left: 4px;
			}
		}
		// .nk23_c-block18__selectbox .nk23_c-selectbox__select .
		.nk23_c-selectbox__select {
			width: 102px !important;
			@include r.sp {
				width: 99px !important;
			}
			@include r.w374 {
				width: 70px !important;
			}
		}
	}
	// .nk23_c-block18__selecttext .
	&__selecttext {
		font-size: 1.4rem;
		line-height: 1;
		font-weight: 700;
		@include r.pc {
			margin-right: 8px;
		}
		@include r.sp {
			padding-right: 8px;
			font-size: 1.2rem;
			min-width: 64px;
		}
	}
	&__text {
		margin-bottom: 12px;
		font-size: 1.2rem;
		line-height: 2.2rem;
		width: 100%;
		&.is-note {
			padding-left: 10px;
			text-indent: -10px;
		}
		&.is-style1 {
			font-weight: 700;
			@include r.pc {
				font-size: 1.4rem;
			}
		}
	}
	// .nk23_c-block18__col .
	&__col {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include r.pc {
			width: max-content;
		}
		// .nk23_c-block18__col:first-child .
		&:first-child {
			@include r.pc {
				margin-right: auto;
				flex-direction: column;
				align-items: flex-start;
			}
		}
		&:nth-child(2) {
			@include r.pc {
				align-items: center;
			}
			@include r.sp {
				border-top: 1px solid v.$color19;
				padding-top: 8px;
				margin-bottom: 16px;
			}
			.nk23_c-block18__text {
				margin-bottom: 0;
				@include r.sp {
					padding-right: 8px;
					min-width: 64px;
				}
			}
		}
		&.is-full {
			width: 100%;
			@include r.pc {
				padding-top: 16px;
				border-top: 1px solid v.$color19;
			}
			// .nk23_c-block18__col.is-full .nk23_c-btn01 .
			.nk23_c-btn01 {
				margin-left: auto;
				margin-right: auto;
				max-width: 320px;
				width: 100%;
				@include r.sp {
					max-width: 100%;
				}
			}
		}
		.nk23_c-block18__text {
			width: unset;
		}
	}
	&.is-style1 {
		border-radius: 8px;
		@include r.sp {
			padding: 8px;
		}
		#{$root}__text {
			margin-bottom: 0;
		}
		.nk23_c-btn01 {
			@include r.sp {
				width: 100%;
				margin-bottom: 8px;
				text-align: center;
			}
		}
		.nk23_c-selectbox__select {
			option {
				text-align: left;
				border: 0;
			}
		}
	}
}

.nk23_c-block19 {
	@include r.pc {
		max-width: 472px;
	}
	&__item {
		padding: 8px 8px 10px 8px;
		border-radius: 8px;
		background-color: #fff;
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
	&__info {
		padding-left: 8px;
	}
	&__content {
		padding-right: 8px;
		display: flex;
		align-items: center;
		flex: 1;
	}
	&__title {
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: normal;
		&:not(:only-child) {
			margin: 4px 0;
		}
		strong,
		b {
			font-weight: 700;
		}
	}
	&__btns {
		width: 64px;
		.nk23_c-btn01 {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
	&__img {
		min-width: 40px;
		img {
			width: 100%;
			max-width: 40px;
		}
	}
	.nk23_c-title01 {
		margin-bottom: 16px;
	}
	.nk23_c-text01 {
		& + .nk23_c-text01 {
			margin: 8px 16px 16px;
		}
	}
	&__titlelink {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		justify-content: center;
		.nk23_c-title01 {
			margin-bottom: 0;
		}
		.nk23_c-link {
			margin-left: 8px;
		}
	}
}

.nk23_c-block20 {
	@include r.sp {
		padding: 0 8px;
	}
	&__nav {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 8px;
		gap: 8px 16px;
		&__list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			gap: 8px;
			@include r.sp {
				width: 100%;
			}
			li {
				font-size: 1.2rem;
				line-height: 1.9rem;
				font-weight: bold;
				display: flex;
				align-items: center;
			}
		}
		&__note {
			font-size: 1.2rem;
			line-height: 1.9rem;
			text-align: right;
		}
		&__icon1 {
			position: relative;
			display: inline-block;
			width: 50px;
			height: 2px;
			background-color: #c9c9c9;
			margin-right: 4px;
			&.is-color1 {
				background-color: v.$color13;
			}
			&.is-color2 {
				background-color: v.$color14;
			}
			&.is-color3 {
				background-color: #F2CD38;
			}
			&:after {
				content: "";
				width: 10px;
				height: 10px;
				position: absolute;
				background-color: inherit;
				border-radius: 50%;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}
		}
	}
	&__body {
		position: relative;
		background-color: #ffffff;
		border-radius: 8px;
		overflow: hidden;
		& + & {
			margin-top: 16px;
			@include r.sp {
				margin-top: 8px;
			}
		}
	}
	&__title1 {
		padding: 12px 16px;
		font-weight: bold;
		font-size: 1.6rem;
		line-height: 2.2rem;
		color: v.$color0;
		border-bottom: 1px solid v.$color19;
	}
	&__title2 {
		color: v.$color-link;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: bold;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		margin-bottom: 8px;

		@include r.hover {
			text-decoration: underline;
		}
	}
	&__title3 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 8px;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 2rem;
		margin-top: 8px;
		margin-bottom: 4px;
	}
	&__text1 {
		display: block;
		text-align: center;
		font-size: 1.4rem;
		line-height: 2.2rem;
		font-weight: bold;
		color: v.$color13;
		padding: 0 16px;
	}
	&__text2 {
		font-size: 1.2rem;
		line-height: 1.9rem;
		font-weight: normal;
	}
	&__num {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		&__txt1 {
			display: block;
			position: relative;
			text-align: center;
			width: 40px;
			font-size: 1.4rem;
			line-height: 2rem;
			color: #ffffff;
			background-color: v.$color0;
			&:after {
				position: absolute;
				bottom: -12px;
				left: 0;
				content: "";
				width: 100%;
				height: 12px;
				border-top: 6px solid v.$color0;
				border-bottom: 6px solid transparent;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
			}
		}
		&__txt2 {
			font-size: 1rem;
			line-height: 1.6rem;
			margin-left: 8px;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -1px;
		margin-right: -1px;
	}
	&__item {
		width: 25%;
		border-bottom: 1px solid v.$color19;
		border-right: 1px solid v.$color19;
		display: flex;
		flex-direction: column;
		@include r.sp {
			width: 50%;
		}
	}
	&__top {
		flex-shrink: 0;
		padding: 0 16px;
	}
	&__chart {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		canvas {
			min-width: 100%;
			max-width: 100%;
			margin-top: auto;
		}
	}
}

.nk23_c-block21 {
	background-color: #ffffff;
	padding: 16px 0;
	@include r.sp {
		padding: 8px;
	}
	&__inner {
		margin: 0 auto;
		display: flex;
		gap: 16px;
		align-items: center;
		@include r.sp {
			gap: 8px;
		}
	}
	&__note {
		margin-left: auto;
		align-self: flex-end;
		position: relative;
		bottom: -8px;
		font-size: 1.4rem;
		line-height: 2rem;
		@include r.sp {
			letter-spacing: -0.8px;
			bottom: -4px;
		}
	}
	&__help {
		position: relative;
		padding-left: 26px;
		font-size: 1.3rem;
		line-height: 1.5rem;
		color: v.$color-link;
		min-height: 19px;
		@include r.sp {
			letter-spacing: -0.8px;
		}
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			height: 18px;
			background: url(p.$path + "images/common/icon/icon_help.svg") no-repeat center/18px;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	.nk23_c-btn01.nk23_c-btn01--setting {
		width: 84px;
		@include r.sp {
			order: -1;
		}

		img {
			// margin-right: 4px;
		}
	}
}

.is-urawa-sidebar {
	.nk23_c-list01__item {
		border-color: v.$urawa-color-light;
	}
}
.is-ooi-sidebar {
	.nk23_c-list01__item {
		border-color: v.$ooi-color-light;
	}
}
.is-funabashi-sidebar {
	.nk23_c-list01__item {
		border-color: v.$funabashi-color-light;
	}
}
.is-kawasaki-sidebar {
	.nk23_c-list01__item {
		border-color: v.$kawasaki-color-light;
	}
}

.nk23_l-side__sidebar {
	.nk23_c-list01__item {
		&:first-child {
			border-color: v.$color19 !important;
		}
	}
}
