@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
slider
------------------------------------------------------------*/

.nk23_c-slide01 {
	$root: &;
	//-------------------------------------------------------
	&__wrap {
		align-items: center;
	}

	//-------------------------------------------------------
	&__item {
		&.swiper-slide-active {
			#{$root}__link {
				transform: scale(1);
			}
		}

		&:only-child {
			.nk23_c-slide01__link {
				transform: scale(1);
			}
		}

		&.is-hover {
			.nk23_c-slide01__link {
				&:after {
					opacity: 1;
				}
			}
		}
	}

	//-------------------------------------------------------
	&__link {
		display: block;
		width: 100%;
		transition: all 0.3s ease-out;
		transform: scale(0.92985);
		transform-origin: center;
		position: relative;
		@include r.sp {
			transform: scale(0.9261);
		}

		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background: rgba($color: #fff, $alpha: 0.5);
			border-radius: 4px;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		@include r.hover {
			opacity: 1;
			&::after {
				opacity: 1;
			}
		}
	}

	//-------------------------------------------------------
	&__img {
		position: relative;
		border-radius: 4px;
		overflow: hidden;
		// .nk23_c-slide01__img::after .
		&::after {
			content: '';
			display: block;
			padding-top: 66.6%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}

		a {
			display: block;
		}

		&.slide-top {
			@include r.pc {
				&::after {
					padding-top: 0;
					aspect-ratio: 804 / 340;
				}
			}
			@include r.sp {
				&::after {
					padding-top: 0;
					aspect-ratio: 327 / 146;
				}
			}
		}
	}

	.swiper {
		&-pagination {
			position: relative;
			bottom: 0 !important;
			margin-top: 8px;
			font-size: 0;

			&-bullet {
				width: 10px;
				height: 10px;
				background: #d9d9d9;
				opacity: 1;
				cursor: pointer;
				@include r.hover {
					background: #707070;
				}

				&.swiper-pagination-bullet-active {
					background: #707070;
				}
			}

			&1 {
				position: relative;
				bottom: 0 !important;
				margin-top: 8px;
				font-size: 0;
				text-align: center;

				.swiper-pagination {
					&-bullet {
						width: 10px;
						height: 10px;
						background: #d9d9d9;
						opacity: 1;
						cursor: pointer;
						@include r.hover {
							background: #707070;
						}

						&.swiper-pagination-bullet-active {
							background: #707070;
						}
					}
				}
			}
		}

		&-button-next {
			right: 0;
			left: auto;
		}

		&-button-prev {
			left: 0;
			right: auto;
		}

		&-button-next,
		&-button-prev {
			top: 19px;
			height: 91%;
			width: 42px;
			margin-top: 0;
			@include r.sp {
				top: 2vw;
				height: 89%;
				width: 5vw;
			}
			@include r.max-w(375px) {
				height: 85%;
			}

			&:after {
				display: none;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-slide02 {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	$root: &;
	// .nk23_c-slide02__item .
	&__item {
		padding: 24px 72px;
		background-color: #fff;
		@include r.sp {
			padding: 16px 36px;
		}
	}

	&__title {
		flex: 1;
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 700;
		@include r.sp {
			margin-top: 6px;
		}
	}

	&__box {
		display: flex;
		align-items: center;
	}

	&__img {
		width: 64px;

		img {
			display: block;
			width: 100%;
		}

		&.fnb {
			position: absolute;

			+ div {
				margin-left: 64px;
			}
		}

		&.fnb img {
			width: auto;
			height: 45px;
			margin-left: 12px;
		}
	}

	// .nk23_c-slide02__content .
	&__content {
		padding-left: 16px;
		flex: 1;
		@include r.pc {
			flex-direction: row-reverse;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		@include r.sp {
			padding-left: 8px;
			position: relative;
			top: 2px;
		}
	}

	&__text {
		font-size: 1.2rem;
		line-height: 1;
	}

	&__date {
		margin-right: 5px;
		display: inline-block;
	}

	@include r.swiper1;

	.swiper {
		&-button {
			&-next,
			&-prev {
				&:focus {
					outline: none;
				}

				@include r.hover {
					top: calc(50% + 2px);
					box-shadow: none;
				}
			}
		}
	}

	&.is-style1 {
		#{$root}__content {
			display: block;
		}

		#{$root}__title {
			font-size: 2rem;
			margin-top: 8px;
			@include r.sp {
				font-size: 1.6rem;
				margin-top: 2px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-slide04 {
	$root: &;
	@include r.pc {
		margin-left: 4px;
		margin-bottom: 16px;
		padding: 30px 32px 0 12px !important;
	}
	@include r.sp {
		margin: 0 -8px 8px !important;
	}

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		padding: 0 32px 8px;
		color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		aspect-ratio: 804 / 340;
		pointer-events: none;
		@include r.sp {
			padding: 8px;
			aspect-ratio: 327 / 146;
		}
	}

	&__text {
		@include r.pc {
			margin: 18px 0 0 90px;
		}
		@include r.sp {
			font-size: calc(10vw / 3.75);
			margin-left: 48px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		&__bottom {
			@include r.pc {
				margin-top: 10px;
			}

			span {
				& + span {
					margin-left: 10px;
				}
			}
		}
	}

	&__main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&__title {
		font-size: 3.2rem;
		font-weight: bold;
		line-height: 1.2;

		@include r.pc {
			height: 110px;
			margin: 4px 0;
		}
		@include r.sp {
			font-size: calc(14vw / 3.75);
			height: calc(51vw / 3.75);
			margin: 0 0 calc(8vw / 3.75) 48px;
		}

		&__sub {
			font-size: 1.6rem;
			display: block;
			font-weight: 400;
			@include r.sp {
				font-size: calc(10vw / 3.75);
				flex-basis: 100%;
			}
		}

		.is-overflow {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 32px;
		@include r.sp {
			left: 8px;
			width: 40px;
		}
	}

	&__img {
		border-radius: 8px;
		overflow: hidden;
		position: relative;

		&.is-image {
			border: 2px solid #ffffff;

			a {
				display: block;
			}
		}

		&::after {
			content: '';
			display: block;
			//padding-top: 45%;
			padding-top: 0;
			aspect-ratio: 804 / 340;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}

		@include r.sp {
			&::after {
				aspect-ratio: 327 / 146;
			}
		}
	}

	&__listbtn1 {
		display: flex;
		position: absolute;
		right: 33px;
		top: 17px;
		font-size: 1.2rem;
		pointer-events: all;
	}

	&__btn1 {
		border: 1px solid #fff;
		background-color: rgba(v.$color0, 0.5);
		padding: 4px 16px;
		border-radius: 16px;
		transition: 0.3s ease-in-out;
		cursor: pointer;

		& + #{$root}__btn1 {
			margin-left: 10px;
		}

		@include r.hover {
			opacity: 0.5;
		}
	}

	&__listbtn2 {
		display: flex;
		justify-content: space-between;
		pointer-events: all;
	}

	&__btn2 {
		border-radius: 8px;
		border: 1px solid #fff;
		padding: 14px;
		flex: 1;
		background-color: rgba(184, 197, 230, 0.15);

		&:not(.is-active) {
			@include r.hover {
				opacity: 0.5;
			}
		}

		@include r.sp {
			padding: calc(6vw / 3.75);
			width: 98px;
		}

		& + #{$root}__btn2 {
			margin-left: 16px;
			@include r.sp {
				margin-left: calc(8vw / 3.75);
			}
		}

		&.is-active {
			background-color: v.$urawa-color;
			@include r.hover {
				opacity: 0.5;
			}
		}

		&.is-disable {
			opacity: 0.25;
			pointer-events: none;
		}

		&__icon {
			margin: 0 auto 8px;
			width: 40px;
			@include r.sp {
				width: calc(24vw / 3.75);
				margin: 0 auto calc(4vw / 3.75);
			}
		}

		&__text {
			font-size: 1.9rem;
			font-weight: bold;
			text-align: center;
			display: block;
			@include r.sp {
				font-size: calc(13vw / 3.75);
			}
		}
	}

	&__subtext__top {
		@include r.sp {
			margin-left: 6px;
		}
	}

	.swiper {
		&-pagination {
			position: relative;
			bottom: 0 !important;
			margin-top: 30px;
			font-size: 0;

			&-bullet {
				width: 10px;
				height: 10px;
				background-color: #b8b7b7;
				opacity: 1;
				cursor: pointer;

				&.swiper-pagination-bullet-active {
					background-color: v.$color0;
				}

				&:hover {
					background-color: v.$color0;
				}
			}
		}

		&-wrapper {
			transition-timing-function: ease-out;
		}

		&-slide {
			overflow: hidden;
			border-radius: 8px;
			transition: all 0.3s ease-out;
			opacity: 0 !important;
			@include r.sp {
				transform: scale(0.94) !important;
				opacity: 0.5 !important;
				box-shadow: 0 3px 6px rgba(#000000, 0.55);
			}

			&-active {
				opacity: 1 !important;
				box-shadow: 0 3px 6px rgba(#000000, 0.55);

				@include r.sp {
					transform: scale(1) !important;
				}
			}

			&-next {
				opacity: 0.5 !important;
				box-shadow: 0 3px 6px rgba(#000000, 0.55);
			}

			&:not(.swiper-slide-active) {
				@include r.pc {
					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						background-color: rgba(255, 255, 255, 0.5);
						box-shadow: 0 3px 6px rgba(0, 0, 0, 0.55);
					}
				}
			}
		}
	}
}
