@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

@for $i from 1 through 10 {
	[data-tab#{$i}-content] {
		display: none;

		&.is-active {
			display: block;
		}
	}

	@if ($i==1) {
		[data-tab-content] {
			display: none;

			&.is-active {
				display: block;
				overflow: hidden;
			}
		}
	}
}

.custom-scrollbar {
	margin-top: 4px;
	width: 100%;
	background-color: #fff;
	border-radius: 999px;
	position: relative;
	height: 4px;

	&.is-hidden {
		display: none;
	}

	&-inner {
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&-icon {
		top: calc(50% - 4px);
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		z-index: 2;
		pointer-events: none;
		transition:
			opacity 0.3s ease-in-out,
			visibility 0.3s ease-in-out;
		opacity: 0;
		visibility: hidden;

		&.is-show {
			opacity: 1;
			visibility: visible;
		}
	}

	&-x {
		border-radius: 999px;
		width: 0;
		height: 4px;
		top: 0;
		left: 0;
		position: absolute;
		background-color: v.$base-color;
		display: block;
	}
}

/*------------------------------------------------------------
other
------------------------------------------------------------*/

.nk23_c-link {
	padding-left: 14px;
	color: v.$color-link;
	font-size: 1.4rem;
	line-height: 2rem;
	display: inline-block;
	position: relative;
	cursor: pointer;

	@include r.hover {
		text-decoration: underline;
		opacity: 1;
	}

	@include r.sp {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	&:not(.no-icon) {
		&:before {
			content: "";
		}
	}

	&:before {
		top: 6px;
		left: 0;
		background: url(p.$path + "images/common/icon/icon_left-blue.svg") no-repeat center / 10px auto;
		width: 10px;
		height: 10px;
		position: absolute;

		@include r.sp {
			top: 5px;
		}
	}

	&.is-help {
		padding-left: 26px;
		font-size: 1.3rem !important;
		line-height: 1.5rem !important;

		&:before {
			top: -1px !important;
			width: 18px;
			height: 18px;
			background: url(p.$path + "images/common/icon/icon_help.svg") no-repeat center / 18px auto;
		}
	}

	&.is-icon-only {
		width: 18px;
		height: 18px;
		font-size: 0 !important;
		padding-left: 18px;

		@include r.hover {
			opacity: 0.5;
		}

		&:before {
			top: 0 !important;
		}
	}

	&.is-style1 {
		padding-left: 0;
		font-size: 1.2rem;
		line-height: 1.8rem;

		@include r.sp {
			font-size: 1.6rem;
			line-height: 2.4rem;
		}

		&:before {
			display: none;
		}
	}

	&.is-style2 {
		@include r.sp {
			font-size: 1.4rem;
		}
	}

	&.is-small {
		font-size: 1.2rem;
		line-height: 1.8rem;
		display: flex;
		width: max-content;
		max-width: 100%;

		&:before {
			top: 5px;
		}
	}
}

.nk23_c-listlink {
	display: flex;
	flex-wrap: wrap;

	@include r.sp {
		justify-content: center;
	}

	.nk23_c-link {
		margin-right: 16px;
		color: #1861f2;
		padding: 0;

		@include r.sp {
			margin: 0 16px;
		}
	}

	& + .nk23_c-listcheck {
		margin-top: 8px;

		@include r.sp {
			margin-top: 16px;
		}
	}

	&.is-right {
		justify-content: flex-end;

		.nk23_c-link {
			margin-left: 16px;
			margin-right: 0;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab1 {
	// .nk23_c-tab1__navi .
	&__navi {
		margin-bottom: 15px;
		padding: 4px;
		border-radius: 999px;
		background-color: #fff;
		overflow: hidden;

		@include r.sp {
			margin-bottom: 7px;
		}

		&__wrap {
			@include r.sp {
				padding: 0 25px !important;
			}
		}

		&__list {
			@include r.pc {
				display: flex;
			}
		}

		&__item {
			font-size: 1.4rem;
			line-height: 1;
			text-align: center;
			max-width: 79px;

			@include r.pc {
				flex: 1;
			}

			@include r.sp {
				font-size: 1.2rem;
				max-width: 62.5px;
			}

			//&.is-active {
			//	@include r.sp {
			//		margin-left: 28px;
			//	}
			//}
		}

		// .nk23_c-tab1__navi__text .
		&__text {
			padding: 7px 5px;
			transition: 0.3s ease-in-out;
			display: inline-block;
			width: 100%;
			cursor: pointer;
			border-radius: 999px;
			overflow: hidden;

			@include r.hover {
				background-color: v.$color58;
				color: v.$color0;
				opacity: 1;
			}

			@include r.sp {
				padding: 8px 5px;
			}

			&.is-active {
				background-color: v.$color0;
				color: #fff;
				font-weight: 700;
			}
		}
	}

	&__grtext {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		&.is-flex-center {
			align-items: center;
		}

		.nk23_c-tab1__text {
			display: inline-block;
			width: max-content;

			// .nk23_c-tab1__grtext .nk23_c-tab1__text:not(:last-child) .
			&:not(:last-child) {
				margin-right: 8px;
			}
		}

		.nk23_c-tab1__texts {
			padding: 0 5px;
		}

		.nk23_c-tab1__texts__gr {
			justify-content: center;
		}
	}

	&__accor {
		// .nk23_c-tab1__accor__grtext .
		&__grtext {
			@include r.sp {
				margin-bottom: 8px;
				padding: 0 8px;
				display: flex;
				flex-direction: column;
			}

			.nk23_c-tab1 {
				&__text {
					// .nk23_c-tab1__accor__grtext .nk23_c-tab1__text:not(:last-child) .
					&:not(:last-child) {
						margin-right: 8px;
					}
				}

				&__texts {
					// .nk23_c-tab1__accor__grtext .nk23_c-tab1__texts.is-style1 .
					&.is-style1 {
						@include r.sp {
							margin-top: 7px;
						}

						// .nk23_c-tab1__accor__grtext .nk23_c-tab1__texts.is-style1 .nk23_c-tab1__text .
						.nk23_c-tab1__text {
							@include r.sp {
								margin-bottom: 7px;
							}
						}
					}

					&__gr {
						margin-top: 0;
					}
				}
			}
		}

		// .nk23_c-tab1__accor__content .
		&__content {
			@include r.sp {
				margin-top: -1px;
				padding: 5px 0 8px;
				border-top: 1px solid v.$color19;
			}

			&:not(.is-active) {
				@include r.sp {
					display: none;
				}
			}
		}
	}

	&__texts {
		display: flex;

		// .nk23_c-tab1__texts__gr .
		&__gr {
			margin-top: 7px;
			display: flex;
			flex-wrap: wrap;
		}

		// .nk23_c-tab1__texts.is-style1 .
		&.is-style1 {
			margin-top: 3px;
		}
	}

	&__text {
		font-size: 1.2rem;
		line-height: 1.8rem;

		// .nk23_c-tab1__text.is-style1 .
		&.is-style1 {
			@include r.sp {
				min-width: 44px;
				height: 42px;
				font-size: 1.4rem;
				font-weight: 700;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				background-color: v.$base-color;
			}
		}
	}

	// .nk23_c-tab1__title .
	&__title {
		font-size: 1.8rem;
		line-height: 2.4rem;
		font-weight: 700;
		white-space: wrap !important;

		@include r.pc {
			margin: 4px 0 9px;
		}

		@include r.sp {
			padding-bottom: 10px;
			display: flex;
			align-items: center;
			font-size: 1.4rem;
			line-height: 1.8rem;
		}

		&.is-style1 {
			margin: 4px 0 8px;

			@include r.sp {
				margin: 0;
			}
		}

		// .nk23_c-tab1__title__text .
		&__text {
			@include r.sp {
				padding-right: 8px;
				flex: 1;
			}
		}

		.nk23_c-btn02 {
			@include r.sp {
				min-width: 52px;
			}
		}
	}

	// .nk23_c-tab1__subtitle .
	&__subtitle {
		margin-bottom: 8px;
		padding: 8px 0 0 57px;
		position: relative;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 400;
		min-height: 56px;

		@include r.sp {
			margin-bottom: 1px;
			padding-left: 48px;
			font-size: 1.2rem;
			line-height: 1.8rem;
		}

		.is-blue {
			color: v.$color30;
		}

		// .nk23_c-tab1__subtitle__label .
		&__label {
			padding: 16px 0 8px;
			width: 40px;
			height: max-content;
			position: absolute;
			top: 0;
			left: 0;
			background-color: v.$color0;
			color: #fff;
			text-align: center;
			font-size: 1.6rem;
			line-height: 1;
			max-height: 39px;

			@include r.sp {
				padding: 16px 0 0;
				height: 36px;
				font-size: 1.4rem;
			}

			// .nk23_c-tab1__subtitle__label:after .
			&:after {
				bottom: -13.5px;
				border-width: 14px 20px 0 20px;
				left: 0;
				pointer-events: none;
				position: absolute;
				content: "";
				border-style: solid;
				border-color: transparent;
				border-top-color: v.$color0;

				@include r.sp {
					bottom: -13px;
					border-top-width: 13px;
				}
			}
		}

		&__bottom {
			margin-top: 3px;
			display: block;

			.nk23_c-tab1__subtitle__text {
				&:last-child {
					margin-left: 7px;
				}
			}
		}

		// .nk23_c-tab1__subtitle .is-medium .
		.is-medium {
			margin-left: 2px;
			font-size: 1.6rem;
			line-height: 1.5;
			font-weight: 700;

			@include r.sp {
				margin-left: 3px;
				font-size: 1.4rem;
				line-height: 2rem;
			}
		}

		&__text {
			& + .nk23_c-tab1__subtitle__text {
				margin-left: 4px;
			}
		}

		a.is-blue {
			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	// .nk23_c-tab1__imgtext .
	&__imgtext {
		padding: 15px 15px 14px;
		background-color: v.$base-color;
		border-radius: 8px;
		border: 1px solid v.$color19;

		@include r.pc {
			margin-top: 15px;
			width: 247px;
		}
	}

	// .nk23_c-tab1__img .
	&__img {
		margin-bottom: 8px;
		display: flex;
		justify-content: center;
		width: 100%;

		// .nk23_c-tab1__img img .
		img {
			width: 100%;

			@include r.max-w(414px) {
				max-width: 257px;
			}
		}
	}

	&__btns {
		width: max-content;
		display: flex;
		flex-wrap: wrap;

		.nk23_c-btn02 {
			@include r.pc {
				width: 125px;
			}

			&:not(:last-child) {
				margin-right: 8px;

				@include r.sp {
					margin-right: 4px;
				}
			}

			&.is-style1 {
				@include r.w374 {
					width: 93px;
				}
			}
		}

		// .nk23_c-tab1__btns.is-style1 .
		&.is-style1 {
			margin-top: 18px;
			width: 100%;

			@include r.sp {
				margin-top: 10px;
			}

			// .nk23_c-tab1__btns.is-style1 .nk23_c-btn02 .
			.nk23_c-btn02 {
				max-width: 179px;
				flex: 1;

				@include r.sp {
					min-width: max-content;
				}

				&:not(:last-child) {
					@include r.sp {
						margin-right: 8px;
					}
				}

				// .nk23_c-tab1__btns.is-style1 .nk23_c-btn02.is-color3 .
				&.is-color3 {
					@include r.sp {
						min-width: 87px;
					}
				}

				&.is-active {
					position: relative;
					top: 2px;
				}
			}
		}
	}

	// .nk23_c-tab1__box .
	&__box {
		@include r.pc {
			padding: 0 16px;
			background-color: #fff;
			border-radius: 8px;
		}

		// .nk23_c-tab1__box__content .
		&__content {
			@include r.pc {
				padding-right: 50px;
				flex: 1;
			}
		}

		// .nk23_c-tab1__box__top .
		&__top {
			padding-bottom: 14px;
			border-radius: 8px 8px 0 0;
			background-color: #fff;

			@include r.pc {
				border-bottom: 1px solid v.$color19;
				display: flex;
				align-items: flex-start;
			}

			@include r.sp {
				margin-bottom: 8px;
				padding: 0 8px;
				border-radius: 8px;
			}

			&:only-child {
				border-bottom: none;
			}
		}

		// .nk23_c-tab1__box__bottom .
		&__bottom {
			padding: 16px 0 18px;
			border-radius: 0 0 8px 8px;
			background-color: #fff;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			@include r.sp {
				padding: 8px 8px 10px;
				border-radius: 8px;
			}

			// .nk23_c-tab1__box__bottom .nk23_c-link .
			.nk23_c-link {
				max-width: calc(100% - 265px);

				@include r.sp {
					max-width: calc(100% - 215px);
				}

				@include r.w374 {
					max-width: calc(100% - 198px);
				}
			}
		}
	}

	@include r.swiper;

	.swiper {
		&-wrapper {
			@include r.sp {
				width: auto;
			}
		}

		&-button-prev {
			@include r.sp {
				left: 0;
			}
		}

		&-button-next {
			@include r.sp {
				right: 0;
			}
		}

		&-button-prev,
		&-button-next {
			@include r.sp {
				top: calc(50% - 1px);
			}

			&.swiper-button-disabled {
				@include r.sp {
					pointer-events: none;
					opacity: 0;
				}
			}

			&:focus {
				outline: none;
			}
		}
	}

	// &.is-loading {
	// 	.nk23_c-tab1 {
	// 		&__navi {
	// 			@include r.sp {
	// 				height: 37px;
	// 			}
	// 		}
	// 	}
	// }
}

/*
------------------------------------------------------------*/

.nk23_c-tab2 {
	$root: &;

	> .nk23_l-container {
		@include r.sp {
			padding: 0;
		}
	}

	&.is-style1 {
		.nk23_c-tab2__navi {
			&__wrap {
				@include r.sp {
					padding: 0 20px;
				}
			}

			// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__item:not(:last-child) .
			&__item {
				&:not(:last-child) {
					margin-right: 48px;

					@include r.sp {
						margin-right: 24px;
					}
				}
			}

			&__text {
				font-size: 1.8rem;

				@include r.sp {
					font-size: 1.4rem;
				}

				&.is-active {
					// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text.is-active .is-small .
					.is-small {
						margin-left: 2px;
					}
				}

				// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text .is-small .
				.is-small {
					font-size: 1.4rem;
					display: inline-block;

					@include r.sp {
						font-size: 1.2rem;
					}

					&:nth-of-type(1),
					&:only-child {
						margin-left: 3px;
						position: relative;
						top: -1px;
					}

					// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text .is-small:last-child .
					&:last-child {
						margin-top: 7px;
						top: 0;
						margin-left: 0;

						@include r.sp {
							margin-top: 6px;
						}
					}

					// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text .is-small:only-child .
					&:only-child {
						display: inline;
					}
				}
			}
		}
	}

	&.is-style2 {
		.nk23_c-tab2__navi {
			// .nk23_c-tab2.is-style2 .nk23_c-tab2__navi__wrap .
			&__wrap {
				@include r.sp {
					padding: 0 20px;
				}
			}

			// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__item:not(:last-child) .
			&__item {
				&:not(:last-child) {
					margin-right: 48px;

					@include r.sp {
						margin-right: 24px;
					}
				}
			}

			&__text {
				font-size: 1.6rem;

				@include r.sp {
					font-size: 1.4rem;
				}

				&.is-active {
					// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text.is-active .is-small .
					.is-small {
						margin-left: 2px;
					}
				}

				// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text .is-small .
				.is-small {
					font-size: 1.4rem;
					display: inline-block;

					@include r.sp {
						font-size: 1.2rem;
					}

					&:nth-of-type(1),
					&:only-child {
						margin-left: 3px;
						position: relative;
						top: -1px;
					}

					// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text .is-small:last-child .
					&:last-child {
						margin-top: 7px;
						top: 0;
						margin-left: 0;

						@include r.sp {
							margin-top: 6px;
						}
					}

					// .nk23_c-tab2.is-style1 .nk23_c-tab2__navi__text .is-small:only-child .
					&:only-child {
						display: inline;
					}
				}

				.is-small2 {
					font-size: 1.2rem;
				}
			}
		}
	}

	// .nk23_c-tab2__navi .
	&__navi {
		padding: 0 8px;
		position: relative;

		&:after {
			height: 2px;
			width: 100%;
			bottom: 0;
			content: "";
			position: absolute;
			left: 0;
			border-bottom: 2px solid v.$color19;
		}

		@include r.pc {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		&__list {
			display: flex;

			&.is-items-center {
				justify-content: center;
			}
		}

		&__item {
			width: max-content !important;

			// .nk23_c-tab2__navi__item:not(:last-child) .
			&:not(:first-child) {
				@include r.sp {
					padding-left: 12px;
				}
			}

			&:not(:last-child) {
				margin-right: 48px;

				@include r.sp {
					margin-right: 12px;
				}
			}
		}

		// .nk23_c-tab2__navi__text .
		&__text {
			padding-bottom: 16px;
			font-size: 1.4rem;
			line-height: 1;
			position: relative;
			cursor: pointer;
			display: inline-block;
			text-align: center;
			min-width: max-content;

			@include r.sp {
				padding-bottom: 8px;
			}

			@include r.hover {
				opacity: 0.5;
			}

			&.is-active {
				font-weight: 700;

				&:after {
					opacity: 1;
					transition: opacity 0s 0.4s;
				}

				@include r.hover {
					opacity: 1;
				}
			}

			// &:after {
			//   height: 2px;
			//   bottom: 0;
			//   width: 100%;
			//   transition: 0.3s ease-in-out;
			//   position: absolute;
			//   opacity: 0;
			//   left: 0;
			//   background-color: v.$color0;
			//   @include r.sp {
			//     content: '';
			//   }
			// }
			&.is-disable {
				opacity: 0.25;
				pointer-events: none;
			}

			&:after {
				height: 2px;
				bottom: 0;
				width: 100%;
				position: absolute;
				left: 0;
				background-color: v.$color0;
				content: "";
				opacity: 0;
			}
		}

		&__wrap {
			@include r.sp {
				padding: 0 !important;
			}
		}
	}

	// .nk23_c-tab2__subnavi .
	&__subnavi {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include r.pc {
			padding: 0 80px;
			justify-content: center;
		}

		@include r.sp {
			padding: 0 8px;
		}

		// .nk23_c-tab2__subnavi.no-padding .
		&.is-style1 {
			padding: 0;

			.nk23_c-tab2__subnavi__item {
				&:last-child {
					@include r.sp {
						margin-left: 0;
					}
				}
			}
		}

		// .nk23_c-tab2__subnavi__item
		&__item {
			margin-bottom: 16px;

			@include r.sp {
				margin-bottom: 10px;
				display: inline-block;
			}

			// .nk23_c-tab2__subnavi__item:not(:last-child) .
			&:not(:last-child) {
				margin-right: 16px;

				@include r.sp {
					margin-right: 8px;
				}
			}

			&:last-child {
				@include r.sp {
					// margin-left: auto;
					display: block;
					width: max-content;
				}
			}

			&.is-last {
				margin-left: auto;
			}
		}

		.nk23_c-btn01 {
			width: max-content;
			padding-left: 15px;
			padding-right: 15px;
			transition: 0.3s ease-in-out;

			cursor: pointer;

			&.is-active {
				position: relative;
				top: 2px;
			}

			&.is-disable {
				background: rgba($color: #fff, $alpha: 0.5);
				box-shadow: 0 2px 0 #c9c9c9;
				border: 1px solid #c9c9c9;
				color: rgba($color: #221f1f, $alpha: 0.25);
			}
		}

		&.is-style2 {
			.nk23_c-btn01 {
				@include r.hover {
					top: 2px;
					box-shadow: none;

					border: 1px solid #c9c9c9 !important;
					color: rgba($color: v.$color0, $alpha: 0.25) !important;
				}

				&.is-active {
					box-shadow: none;
					background-color: v.$color0;
					color: #fff;
					top: 2px;

					@include r.hover {
						border: 1px solid v.$color0 !important;
						color: v.$color0 !important;
					}

				}
			}
		}
	}

	// .nk23_c-tab2__content .
	&__content {
		padding-top: 16px;
		position: relative;

		// .nk23_c-tab2__content .nk23_c-tab2__content .
		.nk23_c-tab2__content {
			padding-top: 2px;

			@include r.sp {
				padding-top: 6px;
			}
		}

		.nk23_c-table01 {
			@include r.sp {
				margin-left: -8px;
				margin-right: -8px;
			}

			&__foot {
				@include r.sp {
					padding-left: 8px;
				}
			}
		}

		.nk23_c-table19 {
			@include r.sp {
				margin-left: -8px;
				margin-right: -8px;
			}
		}
	}

	// .nk23_c-tab2__text .
	&__text {
		font-size: 1.2rem;
		line-height: 1;

		@include r.pc {
			top: 28px;
			right: 15px;
			position: absolute;
		}
	}

	@include r.swiper;

	.swiper {
		&-slide {
			@include r.sp {
				width: max-content;
			}
		}

		&-wrapper {
			@include r.sp {
				width: auto;
			}
		}

		&-button-prev {
			&:focus {
				outline: none;
			}

			@include r.sp {
				left: 0;
			}

			&:after {
				@include r.sp {
					right: -7px;
					background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, v.$base-color 100%);
				}
			}

			// .nk23_c-tab2 .swiper-button-prev:before .
			&:before {
				@include r.sp {
					right: 7px;
					background: url(p.$path + "images/common/icon/icon_left.svg") no-repeat center left 7px/6px auto;
				}
			}
		}

		&-button-next {
			&:focus {
				outline: none;
			}

			@include r.sp {
				right: 0;
			}

			&:after {
				@include r.sp {
					left: -7px;
					background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, v.$base-color 100%);
				}
			}

			// .nk23_c-tab2 .swiper-button-next:before .
			&:before {
				@include r.sp {
					left: 9px;
					background: url(p.$path + "images/common/icon/icon_right.svg") no-repeat center left 7px/6px auto;
				}
			}
		}

		// .nk23_c-tab2 .swiper-button-prev, .nk23_c-tab2 .swiper-button-next .
		&-button-prev,
		&-button-next {
			border: none;
			box-shadow: none;
			background: none;

			@include r.sp {
				top: calc(50% - 1px);
				height: 100%;
			}

			// .nk23_c-tab2 .swiper-button-prev:after, .nk23_c-tab2 .swiper-button-next:after .
			&:after {
				@include r.sp {
					content: "" !important;
					width: 30px;
					height: 100%;
					content: "";
					display: block !important;
					position: absolute;
					top: -1px;
				}
			}

			&:before {
				@include r.sp {
					content: "";
					top: 47%;
					transform: translateY(-50%);
					z-index: 1;
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}

			&.swiper-button-disabled {
				@include r.sp {
					opacity: 0;
				}
			}
		}
	}

	&.w-1200 {
		#{$root}__navi {
			max-width: 960px;
			margin: 0 auto;
		}

		#{$root}__subnavi {
			max-width: 960px;
			margin: 0 auto;

			&__item {
				&:last-child {
					@include r.sp {
						margin-left: 0;
					}
				}
			}
		}
	}

	&__line {
		font-size: 0;
		height: 2px;
		width: 70px;
		background-color: v.$color0;
		position: absolute;
		bottom: 0;
		left: auto;
		opacity: 0;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab3 {
	// .nk23_c-tab3__navi .
	&__navi {
		border-radius: 8px;
		background-color: #fff;
		padding: 8px 8px 0;

		@include r.sp {
			padding-bottom: 4px;
		}
	}

	// .nk23_c-tab3__content .
	&__content {
		padding-top: 16px;

		@include r.sp {
			padding-top: 8px;
		}

		& > .nk23_l-container {
			@include r.sp {
				padding: 0;
			}
		}

		.nk23_c-notice {
			@include r.sp {
				width: calc(100% - 16px);
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	// .nk23_c-tab3 .nk23_c-notice .
	.nk23_c-notice {
		margin-bottom: 16px;

		@include r.sp {
			margin-bottom: 8px;
		}
	}

	// .nk23_c-tab3 > .nk23_l-container .
	& > .nk23_l-container {
		@include r.sp {
			padding: 0 8px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab4 {
	// .nk23_c-tab4__list .
	&__list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@include r.pc {
			justify-content: center;
		}

		@include r.sp {
			justify-content: center;
		}

		// .nk23_c-tab4__list__item .
		&__item {
			margin-bottom: 8px;
			width: max-content;

			@include r.sp {
				margin-bottom: 4px;
				text-align: center;
				min-width: 114px;
			}

			@include r.w374 {
				min-width: 95px;
			}

			// .nk23_c-tab4__list__item:not(:last-child) .
			&:not(:last-child) {
				@include r.pc {
					margin-right: 64px;
				}
			}
		}

		// .nk23_c-tab4__list__text .
		&__text {
			padding-bottom: 4px;
			min-height: 20px;
			cursor: pointer;
			display: inline-block;
			font-size: 1.4rem;
			line-height: 1;
			position: relative;
			min-width: max-content;

			@include r.pc {
				transition:
					font-weight 0.3s ease-in-out,
					opacity 0.3s ease-in-out;
				text-align: center;
			}

			@include r.sp {
				min-height: 20px;
			}

			&:not(.is-active) {
				@include r.sp {
					font-size: 1.2rem;
				}
			}

			@include r.hover {
				opacity: 0.5;
			}

			&:after {
				content: "";
				height: 1px;
				width: 100%;
				background-color: v.$color0;
				position: absolute;
				bottom: 0;
				left: 0;
				opacity: 0;
				transition: 0.3s ease-in-out;
			}

			// .nk23_c-tab4__list__text.is-active .
			&.is-active {
				font-weight: 700;
				cursor: default;

				@include r.hover {
					opacity: 1;
				}

				@include r.sp {
					top: -1px;
					padding-bottom: 6px;
				}

				@include r.w374 {
					font-size: 1.2rem;
				}

				&:after {
					opacity: 1;
				}
			}

			&.is-disable {
				opacity: 0.25;
				pointer-events: none;
			}
		}
	}

	// .nk23_c-tab4__content .
	&__content {
		padding-top: 16px;

		@include r.sp {
			padding-top: 8px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab5 {
	& > .nk23_l-container {
		max-width: 892px;

		@include r.sp {
			padding: 0;
		}
	}

	// .nk23_c-tab5__top .
	&__top {
		display: flex;
		align-items: flex-end;

		@include r.sp {
			padding: 0 8px;
			align-items: flex-start;
			border-bottom: 1px solid v.$color0;
		}

		// .nk23_c-tab5__top .nk23_c-btn04 .
		.nk23_c-btn04 {
			margin-left: 15px;

			@include r.pc {
				margin-bottom: 7px;
				min-width: 141px;
				height: auto;
				padding: 10px 8px;
				&.is-top {
					padding: 10px 0;
					// border: 1px solid #919599;
					// box-shadow: 0 2px 0 #919599;
					// @include r.hover {
					// 	box-shadow: none;
					// }
					.nk23_c-btn04__left {
						font-size: 1.4rem;
						display: block;
						.num {
							margin-right: 3px;
						}
					}
				}
			}

			@include r.sp {
				margin-left: 17px;
			}

			@include r.w374 {
				margin-left: auto;
			}
		}
	}

	// .nk23_c-tab5__subnavi .
	&__subnavi {
		margin: -4px auto 0;
		padding: 2px;
		display: flex;
		width: 100%;
		background-color: #fff;
		border-radius: 999px;
		transform-origin: center;
		transform: scale(0);
		transition:
			margin-top 0.3s cubic-bezier(0.33, 1, 0.68, 1),
			transform 0.3s cubic-bezier(0.33, 1, 0.68, 1),
			max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1),
			max-width 0.3s cubic-bezier(0.33, 1, 0.68, 1);
		font-weight: normal;
		max-height: 0;
		max-width: 0;
		overflow: hidden;

		@include r.w374 {
			max-width: 88px;
		}

		&__item {
			flex: 1;

			@include r.sp {
				max-height: 0;
				opacity: 0;
				visibility: hidden;
				transform: scale(0);
				transform-origin: center;
				transition:
					opacity 0s cubic-bezier(0.33, 1, 0.68, 1),
					visibility 0.3s cubic-bezier(0.33, 1, 0.68, 1),
					transform 0.3s cubic-bezier(0.33, 1, 0.68, 1),
					max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1);
			}
		}

		// .nk23_c-tab5__subnavi__text .
		&__text {
			padding: 6.5px 2px;
			display: block;
			text-align: center;
			font-size: 1.2rem;
			line-height: 1;
			border-radius: 999px;
			cursor: pointer;
			color: v.$color0;

			@include r.w374 {
				font-size: 1rem;
			}

			&.is-active,
			&:focus,
			&:active {
				background-color: v.$color0;
				font-weight: 700;
				color: #fff;
				// transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
			}
		}

		&.is-disable {
			background-color: rgba($color: #fff, $alpha: 0.34);

			.nk23_c-tab5 {
				&__subnavi {
					&__text {
						&.is-active {
							background-color: transparent;
							color: v.$color0;
							font-weight: normal;
						}
					}
				}
			}
		}
	}

	// .nk23_c-tab5__navi .
	&__navi {
		display: flex;
		flex: 1;
		align-items: flex-end;
		min-height: 51px;

		@include r.pc {
			border-bottom: 1px solid v.$color0;
		}

		@include r.sp {
			height: 64px;
		}

		// .nk23_c-tab5__navi__text .
		&__text {
			padding: 8px 10px 8px;
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			border-radius: 8px 8px 0 0;
			font-size: 1.6rem;
			line-height: 1;
			position: relative;
			width: 227px;
			cursor: pointer;

			@include r.pc {
				transition: 0.4s linear;
				line-height: 18px;
			}

			@include r.hover {
				background-color: rgba($color: #fff, $alpha: 0.5);
			}

			@include r.sp {
				padding: 8px;
				max-height: 45px;
				font-size: 1.2rem;
				width: 66px;
				//transition:
				//	width 0.3s cubic-bezier(0.33, 1, 0.68, 1),
				//	max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1),
				//	line-height 0.3s cubic-bezier(0.33, 1, 0.68, 1),
				//	font-weight 0.2s cubic-bezier(0.33, 1, 0.68, 1),
				//	color 0.2s cubic-bezier(0.33, 1, 0.68, 1),
				//	background-color 0.2s cubic-bezier(0.33, 1, 0.68, 1);

				&:not(.is-active) {
					padding: 16px 8px;
				}
			}

			@include r.w374 {
				padding: 10px 5px;
				width: 60px;
				font-size: 1rem;
			}

			// .nk23_c-tab5__navi__text.is-active .
			&.is-active {
				color: #fff;
				font-weight: 700;
				background-color: v.$color0;

				@include r.pc {
					padding: 16px 10px 16px;
				}

				@include r.sp {
					width: 127px;
					max-height: 64px;
					line-height: 1;
				}

				@include r.w374 {
					width: 107px;
				}

				&:after {
					opacity: 1;
				}

				.nk23_c-tab5__subnavi {
					@include r.sp {
						margin-top: 7px;
						transform: scale(1);
						max-height: 29px;
						max-width: 95px;
					}

					&__item {
						@include r.sp {
							opacity: 1;
							visibility: visible;
							transform: scale(1);
							max-height: 25px;
						}
					}
				}
			}

			// .nk23_c-tab5__navi__text:after .
			&:after {
				bottom: -7px;
				border-width: 7px 9px 0 9px;
				position: absolute;
				left: 50%;
				//transform: translateX(-50%);
				border-style: solid;
				border-color: transparent;
				border-top-color: v.$color0;
				opacity: 0;
				pointer-events: none;
				//transition: opacity 0.4s linear;
				z-index: 1;

				@include r.pc {
					content: "";
				}
			}
		}

		&__item {
			flex: 1;
			max-width: max-content;

			// .nk23_c-tab5__navi__item:not(:last-child) .
			&:not(:last-child) {
				margin-right: 8px;

				@include r.w374 {
					margin-right: 5px;
				}
			}

			a {
				display: block;
			}
		}
	}

	&.is-style1 {
		.nk23_l-container,
		.nk23_l-container1 {
			@include r.sp {
				padding: 0;
			}
		}

		.nk23_c-tab5 {
			&__navi {
				min-height: 59px;

				@include r.sp {
					min-height: 48px;
				}

				&__item {
					max-width: unset;
				}

				// .nk23_c-tab5.is-style1 .nk23_c-tab5__navi__text .
				&__text {
					width: 100%;
					padding: 17px 8px;

					@include r.sp {
						padding: 8px 4px;
						line-height: 1.1;
					}

					// .nk23_c-tab5.is-style1 .nk23_c-tab5__navi__text.is-active .
					&.is-active {
						padding: 21px 8px;

						@include r.sp {
							padding: 12px 4px;
						}
					}
				}
			}
		}
	}

	&.is-style2 {
		.nk23_l-container,
		.nk23_l-container1 {
			@include r.sp {
				padding: 0;
			}
		}

		& > .nk23_l-container {
			max-width: 992px;
		}

		.nk23_c-tab5 {
			// .nk23_c-tab5.is-style2 .nk23_c-tab5__content .
			&__content {
				@include r.sp {
					padding: 16px 0 0;
				}
			}

			&__navi {
				&__item {
					max-width: unset;

					&:not(:last-child) {
						@include r.pc {
							margin-right: 16px;
						}
					}
				}

				// .nk23_c-tab5.is-style2 .nk23_c-tab5__navi__text .
				&__text {
					padding: 15px 8px;
					width: 100%;
					font-size: 1.2rem;

					@include r.sp {
						padding: 15px 4px;
						font-size: 1.4rem;
					}

					// .nk23_c-tab5.is-style2 .nk23_c-tab5__navi__text.is-active .
					&.is-active {
						padding: 20px 8px;
						font-size: 1.6rem;

						@include r.sp {
							padding: 20px 4px;
							font-size: 1.6rem;
						}
					}

					&:after {
						display: none;
					}
				}
			}
		}

		// .nk23_c-tab5.is-style2 .nk23_c-table01 .
		.nk23_c-table01 {
			@include r.sp {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.nk23_c-block18 {
			@include r.sp {
				width: calc(100% - 16px);
				margin-left: auto;
				margin-right: auto;
			}
		}

		.nk23_c-text01,
		.nk23_c-text02 {
			@include r.sp {
				padding: 0 8px;
			}
		}

		.nk23_c-text01 {
			& + .nk23_c-text02 {
				margin-top: 16px;
			}
		}

		.nk23_c-listbtn {
			@include r.sp {
				padding: 0 8px;
			}
		}
	}

	&.is-style3 {
		.nk23_l-container,
		.nk23_l-container1 {
			@include r.sp {
				padding: 0;
			}
		}

		& > .nk23_l-container {
			max-width: 992px;
		}

		.nk23_c-tab5 {
			// .nk23_c-tab5.is-style2 .nk23_c-tab5__content .
			&__content {
				@include r.sp {
					padding: 16px 0 0;
				}
			}

			&__navi {
				&__item {
					max-width: unset;

					&:not(:last-child) {
						@include r.pc {
							margin-right: 16px;
						}
					}
				}

				// .nk23_c-tab5.is-style2 .nk23_c-tab5__navi__text .
				&__text {
					padding: 15px 8px;
					width: 100%;
					font-size: 1.2rem;

					@include r.sp {
						padding: 15px 4px;
						font-size: 1.4rem;
					}

					// .nk23_c-tab5.is-style2 .nk23_c-tab5__navi__text.is-active .
					&.is-active {
						padding: 20px 8px;
						font-size: 1.6rem;

						@include r.sp {
							padding: 20px 4px;
							font-size: 1.6rem;
						}
					}

					&:after {
						display: none;
					}
				}
			}
		}

		// .nk23_c-tab5.is-style2 .nk23_c-table01 .
		.nk23_c-table01 {
			@include r.sp {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.nk23_c-block18 {
			@include r.sp {
				width: calc(100% - 16px);
				margin-left: auto;
				margin-right: auto;
			}
		}

		.nk23_c-text01,
		.nk23_c-text02 {
			@include r.sp {
				padding: 0 8px;
			}
		}

		.nk23_c-text01 {
			& + .nk23_c-text02 {
				margin-top: 16px;
			}
		}

		.nk23_c-listbtn {
			@include r.sp {
				padding: 0 8px;
			}
		}
	}

	&.is-style4 {
		.nk23_l-container,
		.nk23_l-container1 {
			@include r.sp {
				padding: 0;
			}
		}

		& > .nk23_l-container {
			max-width: 992px;
		}

		.nk23_c-tab5 {
			&__top {
				@include r.sp {
					padding: 0 1px;
				}
			}

			// .nk23_c-tab5.is-style4 .nk23_c-tab5__content .
			&__content {
				@include r.sp {
					padding: 16px 0 0;
				}
			}

			&__navi {
				@include r.sp {
					min-height: 61px;
				}

				&__item {
					max-width: unset;

					&:not(:last-child) {
						@include r.pc {
							margin-right: 16px;
						}

						@include r.sp {
							margin-right: 2px;
						}
					}
				}

				// .nk23_c-tab5.is-style4 .nk23_c-tab5__navi__text .
				&__text {
					padding: 15px 8px;
					width: 100%;
					font-size: 1.2rem;

					@include r.sp {
						padding: 5px 4px;
						font-size: 1.4rem;
						line-height: 2rem;
					}

					// .nk23_c-tab5.is-style4 .nk23_c-tab5__navi__text.is-active .
					&.is-active {
						padding: 20px 8px;
						font-size: 1.6rem;

						@include r.sp {
							padding: 10px 4px;
							font-size: 1.4rem;
							font-weight: 700;
						}
					}

					&:after {
						display: none;
					}
				}
			}
		}

		// .nk23_c-tab5.is-style4 .nk23_c-table01 .
		.nk23_c-table01 {
			@include r.sp {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.nk23_c-block18 {
			@include r.sp {
				width: calc(100% - 16px);
				margin-left: auto;
				margin-right: auto;
			}
		}

		.nk23_c-text01,
		.nk23_c-text02 {
			@include r.sp {
				padding: 0 8px;
			}
		}

		.nk23_c-text01 {
			& + .nk23_c-text02 {
				margin-top: 16px;
			}
		}

		.nk23_c-listbtn {
			@include r.sp {
				padding: 0 8px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab6 {
	// .nk23_c-tab6__top .
	&__top {
		padding: 16px;
		border-radius: 0 8px 0 0;
		position: relative;

		@include r.pc {
			background-color: #fff;
		}

		@include r.sp {
			margin: 8px 0;
			padding: 0;
			border-radius: 999px;
			background: v.$colorw;
		}
	}

	// .nk23_c-tab6__navi .
	&__navi {
		padding: 7px;
		display: flex;
		border-radius: 999px;
		align-items: center;
		position: relative;
		z-index: 2;

		@include r.pc {
			border: 1px solid v.$color19;
		}

		@include r.sp {
			padding: 5px 4px;
		}

		// .nk23_c-tab6__navi__text .
		&__text {
			padding: 8px;
			text-align: center;
			border-radius: 999px;
			display: block;
			font-size: 1.4rem;
			line-height: 1.5;
			transition: 0.3s ease-in-out;
			cursor: pointer;

			@include r.hover {
				background-color: v.$color58 !important;
			}

			@include r.sp {
				padding: 3px 8px;
			}

			&:not(.is-active) {
				background-color: transparent !important;
			}

			&.is-active {
				color: #fff;
				font-weight: 700;
				background-color: v.$color0;
				pointer-events: none;

				@include r.hover {
					background-color: transparent !important;
				}
			}

			&.is-icon01 {
				position: relative;

				&::before {
					content: "";
					width: 0;
					height: 0;
					top: 1px;
					position: absolute;
					border: 5px solid transparent;
					border-top: 5px solid v.$color33;
					left: 50%;
					transform: translateX(-50%);

					@include r.sp {
						top: -2px;
					}
				}

				&::after {
					display: block;
					content: "NEW";
					background-color: v.$color33;
					color: #ffffff;
					border-radius: 4px;
					font-weight: bold;
					width: 36px;
					height: 17px;
					font-size: 1.2rem;
					position: absolute;
					top: -16px;
					left: 50%;
					transform: translateX(-50%);

					@include r.sp {
						font-size: 1rem;
						width: 30px;
						height: 15px;
					}
				}
			}
		}

		&__item {
			flex: 1;

			@include r.pc {
				max-width: 160px;
			}

			@include r.sp {
				&:nth-last-child(6) {
					flex-grow: 1.5;
				}
			}
		}
	}

	&__line {
		font-size: 0;
		width: 160px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		height: 37px;
		z-index: 1;
		pointer-events: none;
		border-radius: 999px;
		display: inline-block;
		background-color: v.$color0;
		transform: translate3d(23px, 0px, 0px);

		&:not(.no-transition) {
			transition:
				all 0.6s cubic-bezier(0.33, 1, 0.68, 1),
				background-color 0.2s linear;
		}

		@include r.sp {
			left: 0;
			height: 24px;
			width: 70px;
		}

		&.is-bg0 {
			background-color: v.$color0;
		}

		&.is-bg1 {
			background-color: v.$urawa-color;
		}

		&.is-bg2 {
			background-color: v.$funabashi-color;
		}

		&.is-bg3 {
			background-color: v.$ooi-color;
		}

		&.is-bg4 {
			background-color: v.$kawasaki-color;
		}

		&.is-bgx {
			background-color: v.$colorw;
		}

		&.is-icon01 {
			// background-color: v.$color0 !important;
		}
	}

	// .nk23_c-tab6 .nk23_c-block01 .
	.nk23_c-block01 {
		border-radius: 0;

		&.is-style1 {
			@include r.pc {
				border-radius: 0 0 8px 8px;
			}
		}

		// .nk23_c-tab6 .nk23_c-block01:only-child .
		&:only-child {
			@include r.pc {
				border-radius: 0 0 8px 8px;
				overflow: hidden;
			}
		}

		@include r.sp {
			padding: 10px 8px 8px;
		}

		&:last-child {
			@include r.pc {
				border-radius: 0 0 8px 8px;
				overflow: hidden;
			}
		}
	}

	// .nk23_c-tab6 .nk23_c-tab4__content .
	.nk23_c-tab4__content {
		@include r.sp {
			padding-top: 8px;
		}
	}

	&.is-style1 {
		.nk23_c-tab6 {
			&__navi {
				// .nk23_c-tab6.is-style1 .nk23_c-tab6__navi__text .
				&__text {
					@include r.sp {
						min-width: max-content;
						font-size: 1.2rem;
					}

					// @include r.hover {
					//   background-color: v.$color0;
					// }
					// &.is-active {
					//   background-color: v.$color0;
					// }
				}
			}
		}
	}

	.nk23_c-block09 {
		& + .nk23_c-block04 {
			margin-top: 16px;

			@include r.sp {
				margin-top: 8px;
			}
		}
	}

	.nk23_c-block04 {
		& + .nk23_c-block05 {
			margin-top: 16px;

			@include r.sp {
				margin-top: 8px;
			}
		}
	}

	.nk23_c-block05 {
		& + .nk23_c-block06 {
			margin-top: 16px;

			@include r.sp {
				margin-top: 8px;
			}
		}
	}

	.nk23_c-block06 {
		& + .nk23_c-block07 {
			margin-top: 16px;

			@include r.sp {
				margin-top: 8px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab7 {
	$r: &;
	background: #fff;
	border-radius: 8px;

	@include r.sp {
		border-radius: 0;
	}

	&.is-style2 {
		background-color: transparent;

		#{$r}__top {
			background: #fff;
			border-radius: 8px;
			margin-bottom: 16px;
		}
	}

	&.is-style3 {
		background-color: transparent;

		#{$r}__top {
			background: #ffffff;
			border-radius: 8px 8px 0 0;

			&.is-empty {
				border-radius: 8px;
			}
		}

		#{$r}__content {
			border-radius: 0 0 8px 8px;
			background-color: #ffffff;

			&.is-empty {
				padding-top: 16px;
				background-color: transparent;
			}
		}
	}

	&__top {
		border-radius: 8px 8px 0 0;
		box-shadow: 0 2px 3px rgba($color: v.$color0, $alpha: 0.1);
		padding: 16px;
		position: relative;
		z-index: 2;

		@include r.sp {
			padding: 16px 4px 8px;
		}
	}

	&__navi {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		@include r.sp {
			justify-content: flex-start;
		}

		&__item {
			margin: 0 4px;
			text-align: center;

			@include r.sp {
				margin: 0 4px 8px;
			}
		}

		&__text {
			display: block;
			text-align: center;
			border: 1px solid rgba($color: v.$color0, $alpha: 0.1);
			border-radius: 24px;
			font-size: 1.4rem;
			line-height: 1.45;
			transition: 0.3s ease-in-out;
			cursor: pointer;
			padding: 8px 16px;

			@include r.hover {
				//font-weight: 700;
				//border-color: v.$color0;
				color: rgba($color: v.$color0, $alpha: 0.25);
			}

			&.is-hover{
				color: rgba($color: v.$color0, $alpha: 0.25);
			}

			&.is-active {
				font-weight: 700;
				border-color: v.$color0;

				@include r.hover {
					color: v.$color0;
					border-color: v.$color0;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-tab8 {
	// .nk23_c-tab8__top .
	&__top {
		display: flex;
		align-items: flex-end;

		@include r.sp {
			padding: 0 8px;
			margin: 0 -8px;
			align-items: flex-start;
			border-bottom: 1px solid v.$color0;
			background-color: #fff;
		}
	}

	// .nk23_c-tab8__navi .
	&__navi {
		display: flex;
		flex: 1;
		align-items: flex-end;
		min-height: 56px;
		justify-content: space-between;

		// .nk23_c-tab8__navi__item
		&__item {
			width: calc(50% - 8px);

			@include r.sp {
				width: calc(50% - 4px);
			}
		}

		@include r.pc {
			border-bottom: 1px solid v.$color0;
		}

		// .nk23_c-tab8__navi__text .
		&__text {
			padding: 13px 10px;
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			align-self: center;
			background-color: #fff;
			border-radius: 8px 8px 0 0;
			font-size: 1.6rem;
			line-height: 1;
			position: relative;
			width: 100%;
			cursor: pointer;
			transition: 0.3s ease-in-out;

			@include r.hover {
				color: #fff;
				font-weight: 700;
				background-color: v.$color0;
			}

			// .nk23_c-tab8__navi__text.is-active .
			&.is-active {
				padding: 20px 10px;
				color: #fff;
				font-weight: 700;
				background-color: v.$color0;
			}
		}
	}

	// .nk23_c-tab8__content
	&__content {
		background: #ffffff;
		border-radius: 0 0 8px 8px;
		padding: 8px;
		min-height: 100px;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-notice {
	padding: 6px 10px;
	border-radius: 8px;
	width: 100%;
	display: block;
	font-size: 1.4rem;
	line-height: 2rem;
	color: v.$color13;
	text-align: center;
	border: 2px solid v.$color33;
	font-weight: 700;
	background-color: #fff;

	@include r.sp {
		padding: 7px 10px;
		border: 1px solid v.$color33;
	}

	&--style1 {
		color: v.$color0;
		border-color: #ffffff;
		padding: 16px 10px;
	}

	&--style2 {
		margin-bottom: 16px;

		@include r.sp {
			margin-bottom: 8px;
		}
	}

	&__wrap {
		@include r.sp {
			padding: 0 8px;
			box-sizing: border-box;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-notice2 {
	padding: 2px 8px;
	display: flex;
	justify-content: center;
	border-radius: 4px;
	background-color: rgba($color: v.$color33, $alpha: 0.1);

	// .nk23_c-notice2__text .
	&__text {
		display: flex;
		align-items: center;
		font-size: 1.2rem;
		line-height: 1.45;
		font-weight: 700;

		@include r.sp {
			font-size: 1rem;
		}

		// .nk23_c-notice2__text:before .
		&:before {
			margin-right: 8px;
			left: 3px;
			position: relative;
			width: 14px;
			height: 14px;
			background: url(p.$path + "images/common/icon/icon_coins1.svg") no-repeat center / 14px auto;
			content: "";

			@include r.sp {
				margin-right: 5px;
			}
		}

		@include r.sp {
			&.row2 {
				align-items: flex-start;
				text-align: center;
				white-space: nowrap;

				&:before {
					margin-right: -8px;
					left: 20px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-banner1 {
	padding: 14px 16px 16px;
	border-radius: 8px;
	background-color: #fff;

	@include r.pc {
		display: flex;
	}

	@include r.sp {
		padding: 7px 8px 6px;
	}

	&.is-style1 {
		padding: 0;
		flex-direction: column;

		.nk23_c-banner1__head {
			padding: 16px;
			border-bottom: 1px solid v.$color19;

			@include r.sp {
				padding: 10px 8px;
			}
		}

		.nk23_c-banner1__body {
			padding: 16px;
			margin-top: 0;

			@include r.sp {
				padding: 10px 8px;
				flex-direction: column;
			}
		}

		.nk23_c-banner1__content {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.nk23_c-banner1__texts1 {
			padding-top: 0;
		}

		.nk23_c-banner1__texts2 {
			margin-right: 16px;
			padding-top: 0;
		}

		.nk23_c-banner1__texticon {
			@include r.sp {
				margin-left: 22px;
			}
		}

		.nk23_c-banner1__textnum {
			@include r.pc {
				margin-left: 16px;
			}

			@include r.sp {
				text-align: left;
				max-width: none;
				width: auto;
				margin-right: 12px;
			}

			.is-color {
				@include r.sp {
					font-size: 2.4rem;
				}
			}
		}

		.nk23_c-banner1__textnum__wrap {
			@include r.sp {
				display: flex;
				justify-content: flex-end;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 10px;
			}
		}

		.nk23_c-btn02 {
			@include r.sp {
				min-width: max-content;
				max-width: 140px;
				flex: 1;
			}
		}
	}

	&__bl1,
	&__bl2 {
		display: flex;
		align-items: center;
	}

	&__bl1 {
		@include r.pc {
			padding-right: 10px;
		}
	}

	// .nk23_c-banner1__bl2 .
	&__bl2 {
		justify-content: space-between;
		margin-top: 2px;

		@include r.pc {
			padding-left: 23px;
			flex: 1;
		}

		@include r.sp {
			margin-top: 9px;
		}
	}

	// .nk23_c-banner1__img .
	&__img {
		margin-right: 8px;
		min-width: 149px;
		width: 149px;

		@include r.pc {
			padding-bottom: 1px;
		}

		@include r.sp {
			margin-right: 5px;
			min-width: 124px;
			width: 124px;
		}
	}

	// .nk23_c-banner1__texticon .
	&__texticon {
		font-size: 1.4rem;
		line-height: 1;
		font-weight: 700;
		display: flex;
		align-items: center;

		@include r.sp {
			width: 100%;
			font-size: 1.2rem;
		}

		// .nk23_c-banner1__texticon:before .
		&:before {
			margin-right: 4px;
			width: 16px;
			height: 16px;
			background: url(p.$path + "images/common/icon/icon_coins1.svg") no-repeat center / 16px auto;
			content: "";
		}
	}

	// .nk23_c-banner1__textnum .
	&__textnum {
		font-size: 1.6rem;
		line-height: 1;
		font-weight: 700;

		@include r.pc {
			margin-left: 4px;
			padding-top: 6px;
			display: flex;
		}

		@include r.sp {
			margin-top: 11px;
			top: -6px;
			position: relative;
			max-width: 208px;
			text-align: right;
			width: 100%;
			word-break: keep-all;
		}

		@include r.w374 {
			font-size: 1.4rem;
		}

		// .nk23_c-banner1__textnum .is-color .
		.is-color {
			margin-right: 3px;
			top: -6px;
			font-size: 2.4rem;
			color: v.$color37;
			position: relative;

			@include r.sp {
				margin-right: 2px;
				top: 1px;
				font-size: 2.4rem;
			}

			@include r.w374 {
				font-size: 2rem;
			}
		}
	}

	// .nk23_c-banner1__label .
	&__label {
		padding: 4px 2px;
		margin-left: 8px;
		border-radius: 4px;
		display: inline-block;
		background-color: v.$urawa-color;
		font-size: 1.2rem;
		min-width: 40px;
		text-align: center;
		line-height: 1;
		color: #fff;

		@include r.sp {
			min-width: 36px;
			font-size: 1rem;
		}

		&.is-bg1 {
			background-color: v.$ooi-color;
		}

		&.is-bg2 {
			background-color: v.$funabashi-color;
		}

		&.is-bg3 {
			background-color: v.$kawasaki-color;
		}
	}

	// .nk23_c-banner1__texts1 .
	&__texts1 {
		padding-top: 7px;
		font-size: 1.4rem;
		line-height: 2rem;
		display: flex;
		align-items: center;

		@include r.sp {
			padding-top: 1px;
			font-size: 1.2rem;
			line-height: 1.7rem;
		}
	}

	// .nk23_c-banner1__texts2 .
	&__texts2 {
		min-width: max-content;

		@include r.pc {
			padding-top: 5px;
			display: flex;
			align-items: center;
		}

		@include r.sp {
			padding-right: 10px;
			flex: 1;
			max-width: calc(100% - 104px);
			min-width: auto;
		}
	}

	// .nk23_c-banner1 .nk23_c-btn02 .
	.nk23_c-btn02 {
		min-width: 126px;
		width: 126px;
		// border-radius: 8px;

		@include r.sp {
			min-height: 35px;
			font-size: 1.4rem;
		}

		@include r.w374 {
			min-width: 104px;
			width: 104px;
			font-size: 1.2rem;
		}

		&.is-style1 {
			.nk23_c-btn02 {
				&__icon {
					width: 20px;

					@include r.w374 {
						width: 18px;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-card01 {
	background: #fff;
	border-radius: 8px;
	$root: &;

	// .nk23_c-card01__head
	&__head {
		border-bottom: 1px solid v.$color23;
		display: flex;
		justify-content: space-between;
		min-height: 55px;
		padding: 10px 16px 4px;
		font-size: 1.6rem;
		font-weight: bold;

		@include r.sp {
			display: block;
			padding: 16px 8px 10px;
		}

		// .nk23_c-card01__head__left
		&__left {
			font-size: 2rem;
			font-weight: bold;
			display: flex;
			align-items: center;

			img {
				max-height: 18px;
				margin: 0 16px;
			}

			@include r.sp {
				font-size: 1.6rem;
				line-height: 24px;
			}
		}

		// .nk23_c-card01__head__right
		&__right {
			font-size: 1.6rem;
			font-weight: 400;
			margin-top: 10px;

			@include r.sp {
				font-size: 1.2rem;
				margin-top: 4px;
			}
		}
	}

	&__body {
		padding: 16px;

		@include r.sp {
			padding: 8px;
		}
	}

	&--style1 {
		margin-top: 16px;

		@include r.sp {
			margin-top: 8px;
		}

		#{$root}__head {
			font-size: 1.6rem;
			min-height: 48px;
			display: flex;
			align-items: center;
			padding: 0 16px;

			@include r.sp {
				padding: 0 16px;
				min-height: 32px;
			}
		}

		#{$root}__body {
			font-size: 1.4rem;

			@include r.sp {
				font-size: 1.3rem;
				padding: 8px 16px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-switch1 {
	padding: 3px;
	display: flex;
	border: 1px solid v.$color23;
	border-radius: 999px;
	width: max-content;

	@include r.sp {
		padding: 4px;
	}

	&__item {
		width: max-content;
		display: flex;
		max-width: 60px;
	}

	&__text1 {
		font-size: 1.2rem;
		line-height: 1;
		margin-bottom: 8px;
		width: 100%;
		text-align: center;
	}

	&__navi {
		position: relative;

		// &.is-loading {
		// 	.nk23_c-switch1__line {
		// 		display: none;
		// 	}
		// }
	}

	// .nk23_c-switch1__text .
	&__text {
		height: 26px;
		width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 999px;
		font-size: 1.3rem;
		text-align: center;
		position: relative;
		z-index: 1;

		@include r.pc {
			transition: 0.3s ease-in-out;
		}

		@include r.sp {
			height: 22px;
			font-size: 1.2rem;
			width: 45px;
		}

		@include r.hover {
			background-color: v.$color58;
		}

		&.is-active {
			font-weight: 700;
			color: #fff;

			// background-color: v.$color0;
			@include r.hover {
				background: transparent;
			}

			@include r.sp {
				font-weight: 700;
				color: #fff;
			}
		}
	}

	&__line {
		font-size: 0;
		width: 46px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 26px;
		left: 4px;
		z-index: 0;
		pointer-events: none;
		border-radius: 999px;
		display: inline-block;
		background-color: v.$color0;
		transition: 0.3s ease-in-out;

		@include r.sp {
			height: 27px;
			left: 5px;
			width: 44px;
		}
	}

	&.is-style1 {
		// .nk23_c-switch1.is-style1 .nk23_c-switch1__text .
		.nk23_c-switch1 {
			&__item {
				@include r.pc {
					justify-content: center;
					align-items: center;
				}

				&:nth-child(3) {
					.nk23_c-switch1__text {
						@include r.pc {
							min-width: 42px;
						}
					}
				}
			}

			&__text {
				width: max-content;
				padding-left: 4px;
				padding-right: 4px;
				min-width: 60px;

				@include r.sp {
					height: 27px;
					min-width: 29px;
					font-size: 1.4rem;
					padding-left: 8px;
					padding-right: 8px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-preview {
	background: #fff;
	max-width: 472px;
	border-radius: 8px;
	padding-bottom: 8px;

	@include r.sp {
		max-width: 100%;
	}

	&__ttl {
		font-size: 1.6rem;
		font-weight: bold;
		padding: 16px;
		border-bottom: 1px solid #c9c9c9;

		@include r.sp {
			font-size: 1.4rem;
			padding: 8px;
		}
	}

	&__wrap {
		overflow: hidden;
	}

	&__content {
		padding: 0 8px 16px;

		display: none;

		@include r.sp {
			padding: 0 8px 8px;
		}
	}

	.nk23_c-btn01 {
		width: calc(100% - 16px);
		margin: 16px auto;
		background: #fff;
		color: v.$color0;
		box-shadow: 0 2px 0 v.$color19;

		@include r.sp {
			width: 116px;
		}

		&.is-open {
			background: v.$color0;
			color: #fff;
			box-shadow: none;
		}
	}

	&__btn {
		transition: all 0.3s linear;
		margin: 16px;

		@include r.sp {
			width: 116px;
			margin: 16px auto;
		}

		.nk23_c-btn02 {
			width: 100%;
			font-weight: 400;

			@include r.sp {
				font-size: 1.4rem;
				min-height: 35px;
				margin: 0 auto;
			}
		}

		&.is-open {
			display: none;
		}
	}

	// .nk23_c-preview .nk23_c-table09 .
	.nk23_c-table09 {
		padding: 0 8px;
		margin-bottom: 16px;

		// display: none;
		@include r.sp {
			padding: 0;
		}
	}

	.nk23_c-block12 {
		&:nth-last-of-type(2) {
			border-bottom: 0;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23__accordion {
	position: relative;

	&__heading {
		background: #fff;
		border: 1px solid v.$color0;
		box-sizing: border-box;
		font-size: 1.6rem;
		line-height: 1.5;
		color: v.$color0;
		font-weight: bold;
		position: relative;
		z-index: 3;
		padding: 8px 24px 8px 16px;

		&::after {
			content: "";
			width: 16px;
			height: 16px;
			background: url(p.$path + "images/common/icon/icon_arrow_black.svg") no-repeat center / 100% auto;
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translate(0, -50%);
			transition: all 0.3s ease-in-out;
		}

		&.is-open {
			&::after {
				transform: translate(0, -50%) rotate(180deg);
			}
		}

		&.is-fixed-btm,
		&.is-fixed {
			position: fixed;
			z-index: 10;
			top: 0;
			width: 100%;
			left: 0;
		}
	}

	&__info {
		width: 100%;
		position: fixed;
		top: 41px;
		left: 0;
		z-index: 2;
		transition: all 2s ease-in-out;
		display: none;

		&:after {
			content: "";
			background: rgba($color: #221f1f, $alpha: 0.75);
			width: 100%;
			height: 200%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
		}

		@include r.sp {
			transition: none;
			height: 100vh;
			z-index: 11;
		}

		&.is-scroll {
			@include r.sp {
				overflow-y: auto;
				overflow-x: hidden;
			}
		}
	}

	.nk23_c-table04 {
		&__table {
			@include r.sp {
				border-radius: 0 0 8px 8px;
				overflow: hidden;
			}
		}

		thead {
			th {
				@include r.sp {
					background-color: v.$color0;
					color: #fff;
					z-index: 2;
					position: sticky;
					top: 0;
					left: 0;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-calendar1 {
	// &.is-loading {
	// 	@include r.pc {
	// 		overflow: hidden;
	// 		height: 236px;
	// 	}
	// }

	&__inner {
		position: relative;
		padding-left: 32px;
		max-width: 960px;
		width: max-content;
		margin-left: auto;
		margin-right: auto;
	}

	// .nk23_c-calendar1__table .
	&__table {
		min-width: 840px;
		position: relative;
		z-index: 1;
		border-collapse: separate;
		border-spacing: 0;

		th,
		td {
			border-top: 1px solid v.$color19;
			border-right: 1px solid v.$color19;
			padding: 8px;
			text-align: center;
		}

		thead {
			font-size: 1.4rem;
			line-height: 1;
			border-radius: 8px 8px 0 0;
			background-color: #fff;

			// .nk23_c-calendar1__table thead th .
			th {
				border-top: none;
				line-height: 1.43;

				&:first-child {
					border-left: none;
					border-top-left-radius: 8px;

					&.is-active {
						border-left: none;
					}
				}

				&:last-child {
					border-top-right-radius: 8px;
					border-right: none;
				}

				&.is-active {
					background-color: v.$color0;
					border-right: 2px solid v.$color0;
					border-left: 2px solid v.$color0;
					border-bottom: 2px solid v.$color0;
					color: #fff;
					font-weight: 700;

					.nk23_c-calendar1__table__text {
						color: #fff;
					}
				}
			}
		}

		tbody {
			background-color: v.$color41;
			border-radius: 0 0 8px 8px;
			font-size: 1.4rem;
			line-height: 1;

			td {
				line-height: 1.3;

				&:first-child {
					border-left: none;
				}

				&:last-child {
					border-right: none;
				}

				&.is-bg-white {
					background-color: #fff;
				}

				&.is-bg1 {
					background-color: v.$color42;
				}

				&.is-bg2 {
					background-color: v.$color43;
				}

				&.is-bg3 {
					background-color: v.$color44;
				}

				&.is-bg4 {
					background-color: v.$color45;
				}

				&.is-blue {
					color: v.$color-link;
				}

				&.is-active {
					position: relative;
					border-right: 2px solid v.$color0;
					border-left: 2px solid v.$color0;

					&:after {
						content: "";
						top: -1px;
						bottom: 0;
						left: -2px;
						right: -2px;
						border: 2px solid v.$color0;
						position: absolute;
						border-top: none;
						border-bottom: none;
						pointer-events: none;
					}

					& + .is-active {
						border-left: none;

						&:after {
							border-left: none;
						}
					}
				}
			}

			tr {
				&:last-child {
					td {
						border-bottom: none;

						&.is-active {
							border-bottom: 2px solid v.$color0;
						}

						&:first-child {
							border-bottom-left-radius: 8px;

							&.is-active {
								&:after {
									border-bottom-left-radius: 8px;
								}
							}
						}

						&:last-child {
							border-bottom-right-radius: 8px;

							&.is-active {
								&:after {
									border-bottom-right-radius: 8px;
								}
							}
						}
					}
				}
			}
		}

		&__text {
			margin-top: 4px;
			font-size: 1.2rem;
			display: block;

			&.is-blue {
				color: v.$color-link;
			}

			&.is-red {
				color: v.$color13;
			}
		}
	}

	// .nk23_c-calendar1__text .
	&__text {
		top: 59px;
		height: calc(100% - 95px);
		left: -2px;
		padding: 8px;
		font-size: 1.6rem;
		font-weight: 700;
		line-height: 1;
		writing-mode: vertical-lr;
		background-color: v.$color41;
		border: 1px solid v.$color19;
		border-radius: 8px 0 0 8px;
		text-align: center;
		position: absolute;
		letter-spacing: 4.5px;
	}

	&__slide {
		position: relative;
		overflow: hidden;

		&__item {
			width: 960px !important;
		}

		&__wrap {
			position: relative;
		}
	}

	@include r.swiper1;

	.swiper {
		overflow: hidden;

		&-button {
			&-next {
				right: 8px;
				background-image: url(p.$path + "images/common/icon/icon_right1.svg");
			}

			&-prev {
				left: 8px;
				background-image: url(p.$path + "images/common/icon/icon_left1.svg");
			}

			// .nk23_c-calendar1 .swiper-button-next, .nk23_c-calendar1 .swiper-button-prev .
			&-next,
			&-prev {
				width: 14px;
				height: 20px;
				background-size: 14px auto;
				box-shadow: none;
				border: none;
				background-color: transparent;
				background-position: center;
				transition: opacity 0.3s ease-in-out;

				@include r.hover {
					opacity: 0.5;
				}
			}

			// .nk23_c-calendar1 .swiper-button-disabled .
			&-disabled {
				opacity: 0.2 !important;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-filter1 {
	padding: 16px 16px 10px;
	border-radius: 8px;
	background-color: #fff;

	@include r.sp {
		padding: 8px 8px 2px;
	}

	&__text {
		margin-bottom: 16px;
		font-size: 1.4rem;
		line-height: 2.2rem;

		@include r.pc {
			text-align: center;
		}
	}

	&__row {
		@include r.pc {
			display: flex;
		}
	}

	&__col {
		display: flex;
		align-items: flex-start;

		@include r.pc {
			width: max-content;
			min-width: 236px;
		}

		&:not(:first-child) {
			@include r.pc {
				padding-left: 16px;
			}
		}

		// .nk23_c-filter1__col:not(:last-child) .
		&:not(:last-child) {
			@include r.pc {
				position: relative;
				padding-right: 16px;
			}

			@include r.sp {
				padding-bottom: 2px;
				margin-bottom: 8px;
				border-bottom: 1px solid v.$color19;
			}

			&:after {
				width: 1px;
				height: calc(100% - 8px);
				background-color: v.$color19;
				position: absolute;
				top: 0;
				right: 0;

				@include r.pc {
					content: "";
				}
			}
		}

		&__title {
			padding-top: 8px;
			min-width: max-content;
			margin-right: 8px;
			font-size: 1.4rem;
			line-height: 2.2rem;
			font-weight: 700;

			@include r.sp {
				min-width: 64px;
				max-width: 64px;
			}
		}
	}

	&__btns {
		display: flex;
		flex-wrap: wrap;

		@include r.sp {
			width: calc(100% - 64px);
		}

		// .nk23_c-filter1__btns .nk23_c-btn01 .
		.nk23_c-btn01 {
			margin-bottom: 8px;

			@include r.pc {
				flex: 1;
				max-width: 80px;
			}

			@include r.sp {
				width: calc(50% - 4px);
				max-width: 131.5px;
				min-width: max-content;
			}

			&:not(:last-child) {
				margin-right: 8px;
			}

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ .nk23_c-btn01,
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .nk23_c-btn01 {
				@include r.sp {
					max-width: 61.75px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-filter2 {
	position: relative;
	background-color: #ffffff;
	border-radius: 8px;
	padding: 16px;

	@include r.sp {
		padding: 8px;
	}

	&__wrap {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 16px 32px;

		@include r.sp {
			flex-direction: column;
			align-items: center;
			gap: 8px;
		}
	}

	&__btns {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 16px;

		@include r.sp {
			gap: 8px 6px;
		}

		.nk23_c-btn01 {
			min-width: 168px;
			white-space: nowrap;
			word-break: keep-all;
		}
	}

	&__selects {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 8px;

		@include r.max-w(360px) {
			display: inline-flex;
			flex-direction: column;
		}

		.nk23_c-selectbox {
			display: flex;
			align-items: center;
			gap: 8px;

			&__select {
				text-align-last: center;
			}

			&.is-label &__label {
				white-space: nowrap;
				font-size: 1.4rem;
				line-height: 2rem;
			}

			&.is-label.is-sp-wrap {
				@include r.sp {
					flex-direction: column;
					align-items: flex-end;
				}

				@include r.max-w(360px) {
					flex-direction: row;
					align-items: center;
				}
			}

			&__select {
				min-width: 138px;

				@include r.max-w(360px) {
					min-width: 200px;
				}
			}
		}
	}

	&__title {
		font-size: 1.4rem;
		line-height: 1.8rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 16px;

		@include r.sp {
			margin-bottom: 8px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-filter3 {
	@include r.pc {
		padding: 8px 16px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		background-color: #fff;
	}

	// .nk23_c-filter3__text .
	&__text {
		font-size: 1.4rem;

		@include r.w374 {
			font-size: 1.2rem;
		}

		&:not(:last-child) {
			padding-right: 16px;
			margin-right: 16px;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				width: 1px;
				height: calc(100% - 6px);
				background-color: v.$color19;
			}
		}
	}

	&__number {
		font-size: 1.6rem;
		font-weight: 700;

		@include r.w374 {
			font-size: 1.4rem;
		}
	}

	&__info {
		line-height: 2.2rem;
		display: flex;
		align-items: center;

		@include r.pc {
			min-width: max-content;
		}
	}

	&__title {
		font-size: 2rem;
		line-height: 2.8rem;
		font-weight: 700;

		@include r.pc {
			margin-right: 16px;
		}

		@include r.sp {
			margin-bottom: 8px;
		}
	}

	&__top {
		@include r.pc {
			display: flex;
			align-items: center;
			width: calc(100% - 110px);
		}

		@include r.sp {
			margin-bottom: 16px;
			padding: 16px 8px;
			border-radius: 8px;
			background-color: #fff;
		}
	}

	.nk23_c-selectbox {
		@include r.pc {
			margin-left: auto;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-filter4 {
	padding: 16px;
	background-color: #fff;
	border-radius: 8px;

	@include r.pc {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}

	@include r.sp {
		padding: 16px 8px;
	}

	&__control {
		display: flex;
		align-items: center;

		@include r.sp {
			margin-top: 16px;
		}
	}

	&__text {
		flex-shrink: 0;
		font-size: 1.4rem;
		line-height: 2.2rem;

		// .nk23_c-filter4__text:not(:last-child) .
		&:not(:last-child) {
			margin-right: 16px;

			@include r.w374 {
				margin-right: 8px;
			}
		}
	}

	&__btn {
		@include r.sp {
			.nk23_c-btn01 {
				width: 164px;
			}
		}

		@include r.w374 {
			.nk23_c-btn01 {
				width: 100px;
			}
		}
	}

	.nk23_c-selectbox {
		margin-right: 8px;

		&.is-style1 .nk23_c-selectbox__select {
			@include r.sp {
				width: 127px;
			}

			@include r.w374 {
				width: 88px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-box1 {
	background: #fff;
	border-radius: 8px;
	margin: 16px 0;
	padding: 16px;

	@include r.sp {
		margin: 8px 0 16px;
		padding: 8px 8px 0;
	}

	&__btns {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 16px;

		.nk23_c-btn02 {
			max-width: 179px;
			flex: 1;

			@include r.sp {
				margin-bottom: 8px;
			}

			&:not(:last-child) {
				margin-right: 8px;
			}

			&.is-color3 {
				@include r.sp {
					min-width: 87px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-box2 {
	padding: 16px;
	background-color: #fff;
	font-size: 1.1rem;
	line-height: 1.7rem;
	border-radius: 8px;

	ul {
		li {
			padding-left: 8px;
			position: relative;

			&:before {
				content: "";
				width: 2px;
				height: 2px;
				border-radius: 50%;
				background-color: v.$color0;
				position: absolute;
				left: 0;
				top: 8px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-box3 {
	border-radius: 8px;
	background-color: #fff;

	.title02 {
		@include r.sp {
			padding: 4px 8px;
			border-bottom: 1px solid v.$color19;
		}
	}

	.searchform_area {
		margin-bottom: 8px;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-box4 {
	padding: 20px 10px 18px;
	border-radius: 8px;
	background-color: v.$color51;
	border: 1px solid v.$color32;

	&.bg-spat4-02,
	.bg-spat4-02 {
		background-image: none;
	}

	.login {
		// .nk23_c-box4 .login a .
		a {
			margin: 0 auto 25px;
			font-size: 1.3rem;
			font-weight: 700;
			line-height: 3.6rem;
			text-align: center;
			color: v.$color32;
			background-color: #fff;
			border: 1px solid v.$color32;
			width: 385px;
			box-shadow: 0 2px v.$color32;
			border-radius: 999px;
			transition: none;

			@include r.hover {
				box-shadow: none;
				transform: translateY(2px);
				opacity: 1;
			}

			&.active {
				border-color: v.$color31;
				background-color: v.$color32 !important;
				box-shadow: none;
				transform: translateY(2px);
				color: #fff;
			}
		}
	}

	h2 {
		margin-bottom: 13px;
		font-size: 1.6rem;
		font-weight: 700;
		color: v.$color0;
	}

	ul {
		padding-left: 8px;

		a {
			font-size: 1.3rem;
			line-height: 1.3;
			color: v.$color-link;

			@include r.hover {
				text-decoration: underline;
			}
		}

		li {
			padding-left: 8px;
			position: relative;
			line-height: 1.2;

			// .nk23_c-box4 ul li:before .
			&:before {
				top: 10px;
				left: 0;
				position: absolute;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: v.$color0;
				content: "";
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-box5 {
	max-width: 100%;

	// .nk23_c-box5 .line-dotted .
	.line-dotted {
		margin-top: 32px;
		padding-top: 8px;
		border-top: 1px solid v.$color19;
		background: none;
	}

	table {
		width: 100%;
		max-width: 100%;

		td {
			&:nth-child(1) {
				padding-right: 5px;
				padding-left: 0;
			}

			&:nth-child(2) {
				padding-left: 5px;
				padding-right: 0;
			}

			a {
				display: flex;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-box6 {
	padding: 20px 10px 30px;
	background-color: #fff;
	border-radius: 8px;

	.title-spat4-02 {
		padding-left: 16px !important;
		margin-left: -10px !important;
		background: none;

		&:before {
			display: none;
		}
	}

	// .nk23_c-box6 .title-spat4-03 .
	.title-spat4-03 {
		margin: 0 0 10px !important;
	}

	.list-faq {
		margin-bottom: 8px;
	}

	p {
		margin-bottom: 8px;
		font-size: 1.3rem;
		line-height: 2.4rem;

		& + .title-spat4-03 {
			margin-top: 26px !important;
		}

		& + p {
			margin-top: 13px;
		}
	}

	.box-freedial {
		// .nk23_c-box6 .box-freedial p .
		p {
			margin-bottom: 13px;
		}
	}

	.list00 {
		margin: 0 -4px;

		a {
			border: 1px solid v.$color19;
			border-radius: 8px;

			img {
				border-radius: 8px;
			}
		}

		// .nk23_c-box6 .list00 li .
		li {
			padding: 0 4px;
			width: 33.3333%;
			float: none;
			margin-left: 0;
			font-size: 1.3rem;
		}
	}

	.clearfix {
		display: flex;
		justify-content: center;

		.tx-blue {
			font-size: 1.3rem;
			color: v.$color0;
		}

		.bl-left {
			padding: 0 16px;
		}

		.tx-small {
			margin-top: 8px;
			display: block;
		}

		a {
			color: v.$color-link;

			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	.tb00 {
		margin-top: 13px;
		max-width: 100%;

		td {
			padding: 4px 8px;
			padding-bottom: 4px;
			width: 16.7%;
		}

		& + .title-spat4-03 {
			margin-top: 15px !important;
		}
	}

	.mark-pdf {
		&:last-child {
			margin-bottom: 8px;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-box7 {
	padding: 10px 15px 30px;
	background-color: #fff;
	border-radius: 8px;

	img {
		height: 16px;
	}

	h2 {
		margin-bottom: 19px;

		img {
			height: 33px;
		}
	}

	// .nk23_c-box7__box .
	&__box {
		padding: 21px 10px 21px 21px;
		margin-top: 13px;
		margin-bottom: 10px;
		border-radius: 8px;
		border: 1px solid v.$color19;
		background-color: v.$color34;
	}

	// .nk23_c-box7 .qr-code .
	.qr-code {
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 140px;
		border-color: v.$color19 !important;
		border-radius: 8px;

		// .nk23_c-box7 .qr-code+p .
		& + p {
			margin-bottom: 8px;
		}

		img {
			height: auto;
		}
	}

	p {
		font-size: 1.3rem;
		line-height: 2.1rem;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-search {
	background: #fff;
	border-radius: 8px;
	padding: 16px;
	$r: &;

	@include r.sp {
		margin: 0 8px;
	}

	&__head {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;

		.nk23_c-link {
			width: 65px;
		}
	}

	&__ttl1 {
		font-size: 1.4rem;
		width: calc(100% - 65px);
	}

	&__row1 {
		display: flex;
		align-items: baseline;

		@include r.sp {
			flex-direction: column;
		}
	}

	&__row2 {
		display: flex;
		align-items: baseline;
		width: calc(100% - 192px);
		padding-left: 16px;

		@include r.sp {
			border-top: 1px solid #c9c9c9;
			padding-top: 8px;
			padding-left: 0;
			width: 100%;
			margin-top: 8px;
		}
	}

	&__ttl {
		font-size: 1.4rem;
		font-weight: 700;
		width: 56px;
	}

	&__item1 {
		width: 192px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include r.sp {
			width: 100%;
		}
	}

	.nk23_c-selectbox__select {
		width: 128px;
		min-height: 40px;
		color: #221f1f;

		@include r.sp {
			width: calc(100% - 72px);
		}
	}

	&__box {
		flex: 1;
		display: flex;
		align-items: center;

		@include r.sp {
			margin-left: -8px;
		}

		.nk23_c-btn01 {
			width: 60px;
		}
	}

	&__col1 {
		width: calc(100% - 56px);
		display: flex;
		align-items: center;

		@include r.sp {
			flex-direction: column;
			padding-left: 16px;
		}
	}

	&__input {
		width: 128px;
		border: 1px solid #c9c9c9;
		min-height: 40px;
		padding: 8px;
		text-align: center;
		border-radius: 8px;
		margin: 0 8px;

		@include r.sp {
			width: calc((100% - 85px) / 2);
		}

		&:hover,
		&:focus {
			border: 1px solid #c9c9c9;
		}

		&:disabled {
			color: #d2d2d2;
		}
	}

	&__note {
		font-size: 1.2rem;
		padding-left: 8px;

		@include r.sp {
			padding-left: 0;
			padding-top: 8px;
		}
	}

	&__btns2 {
		display: flex;
		justify-content: space-between;
		padding-left: 16px;

		@include r.sp {
			padding-left: 0;
			width: 100%;
			margin-top: 8px;
		}

		.nk23_c-btn01 {
			width: 142px;
			box-sizing: border-box;

			@include r.sp {
				width: 100%;
			}

			&:last-child {
				margin-left: 8px;
			}
		}
	}

	&.is-style1 {
		#{$r}__row2 {
			width: auto;
		}
	}

	&__error {
		font-weight: 700;
		padding-top: 1em;
		&.is-validation {
			color: v.$color13;
			font-size: 1.4rem;
		}
		@include r.sp {
			margin: 0 8px;
			padding-top: 8px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-pagging {
	display: flex;
	justify-content: space-between;
	padding: 16px;
	background: #fff;

	@include r.pc {
		border-radius: 8px 8px 0 0;
	}

	@include r.sp {
		flex-direction: column;
	}

	a,
	span {
		&.is-hide {
			display: none !important;
		}
	}

	&__ttl {
		font-size: 1.4rem;

		@include r.sp {
			text-align: center;
		}

		span {
			&:last-child {
				padding-left: 16px;
			}
		}

		strong {
			font-size: 1.6rem;
			font-weight: 700;
		}
	}

	&__number {
		@include r.sp {
			padding-top: 8px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-pagenavi {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;

	@include r.sp {
		text-align: center;
		justify-content: center;
	}

	a,
	span {
		display: inline-block;
		font-size: 1.4rem;
		color: #2461ee;
		padding: 0 12px;
		border-left: 1px solid #c9c9c9;

		&:first-child {
			border-left: 0;
		}

		&.current {
			font-weight: bold;
			color: #221f1f;

			&:only-child {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&.one-page {
			font-weight: bold;
			color: #221f1f;
			padding: 0;
		}
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	.previouspostslink {
		padding-right: 16px;
		position: relative;
		padding-left: 0;
		border-left: 0;

		&:before {
			content: "";
			background: url(p.$path + "images/common/icon/icon_arrow_left_blue.svg") no-repeat;
			width: 6px;
			height: 10px;
			background-size: 6px 10px;
			display: inline-block;
			margin-right: 8px;
		}
	}

	.nextpostslink {
		position: relative;
		padding-right: 0;
		border-left: 0;

		&:after {
			content: "";
			background: url(p.$path + "images/common/icon/icon_arrow_right_blue.svg") no-repeat;
			width: 6px;
			height: 10px;
			background-size: 6px 10px;
			display: inline-block;
			margin-left: 8px;
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-banner2 {
	position: relative;
	z-index: 1;

	@include r.sp {
		z-index: 6;
		padding: 0;
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		max-width: 960px;
		margin: auto;
		padding: 16px;
		background: #fff;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		@include r.sp {
			flex-direction: column-reverse;
			padding: 8px 0 0;
			border-radius: 0;
		}
	}

	&__left {
		display: flex;
		align-items: center;

		@include r.sp {
			width: 100%;
			justify-content: space-between;
			padding: 8px;
			background-color: #fff;
			transition: 0.3s ease-in-out;
		}

		&.is-sticky {
			@include r.sp {
				position: fixed;
				z-index: 4;
				top: 0;
				left: 0;
				width: 100%;
			}
		}

		.nk23_c-btn01 {
			margin: 0 8px;
			width: 74px;

			@include r.sp {
				width: 52px;
				font-size: 1.2rem;
				min-height: 28px;
			}

			&.is-disable {
				color: rgba($color: #221f1f, $alpha: 0.25);
			}
		}
	}

	&__txt {
		@include r.sp {
			font-size: 1.2rem;
			line-height: 1.3;
		}
	}

	&__right {
		display: flex;
		align-items: flex-end;

		@include r.sp {
			width: 100%;
			justify-content: space-between;
			padding: 0 8px 8px;
			box-shadow: 0 2px 3px rgba($color: #221f1f, $alpha: 0.1);
			position: relative;
		}

		.nk23_c-btn01 {
			width: 78px;

			@include r.sp {
				width: 62px;
			}

			@include r.max-w(350px) {
				font-size: 1.2rem;
				width: 55px;
			}

			&.is-disable {
				color: rgba($color: #221f1f, $alpha: 0.25);
				pointer-events: none;
			}

			&--setting {
				margin-left: 8px;

				@include r.sp {
					width: 68px;
				}

				img {
					// margin-right: 4px;

					@include r.max-w(350px) {
						max-width: 16px;
						width: 100%;
					}
				}

				&.is-disable img {
					opacity: 0.25;
					pointer-events: none;
				}
			}
		}
	}

	&__block {
		display: flex;
		align-items: flex-end;

		@include r.sp {
			justify-content: space-between;
		}
	}

	&__spat4 {
		border-radius: 50px;
		box-shadow: 0 2px 0 #17307b;
		border: 1px solid #17307b;
		background-color: #004ea2;
		width: 150px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
		line-height: 1.4rem;
		font-weight: normal;
		color: #fff;
		transition: none;

		@include r.sp {
			width: 118px;
			font-size: 1.2rem;
			height: 28px;
		}

		img {
			max-width: 75px;
			width: 100%;

			@include r.sp {
				max-width: 66px;
			}
		}

		@include r.hover {
			box-shadow: none !important;
			opacity: 1;
			position: relative;
			top: 2px;
		}

		&.is-disable {
			opacity: 0.25;
			pointer-events: none;
		}

		&__txt {
			text-align: center;
		}
	}

	&__switch {
		margin: 0 8px;

		&__note {
			font-size: 1.2rem;
			text-align: center;
			margin-bottom: 4px;
		}

		.nk23_c-switch1__text {
			font-size: 1.4rem;

			@include r.sp {
				width: 40px;
			}

			@include r.max-w(350px) {
				font-size: 1.2rem;
				width: 30px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-fixed__menu {
	background-color: #fff;
	padding: 8px 8px 10px;
	border-radius: 8px 8px 0 0;
	border: 1px solid v.$color19;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 998;
	opacity: 0;
	pointer-events: none;
	animation: SlideDown 0.3s;

	&.is-show {
		animation: SlideIn 0.3s;
		opacity: 1;
		pointer-events: auto;
	}

	&.is-absolute {
		top: -72px;
		position: absolute;
		right: 40px;

		@include r.sp {
			right: 16px;
		}
	}

	.nk23_c-tab6__navi {
		margin-top: 10px;
		border: 1px solid v.$color19;

		// &__item {
		// }
		&__link {
			display: inline-block;
			width: 100%;
		}
	}
}

@keyframes SlideIn {
	0% {
		opacity: 0;
		transform: translateY(64px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes SlideDown {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(64px);
	}
}

/*
------------------------------------------------------------*/

.nk23_c-modal1 {
	padding-top: 40px;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	visibility: hidden;
	// background-color: rgba($color: v.$color0, $alpha: 0.75);
	opacity: 0;
	transition:
		opacity 0.3s ease-in-out,
		visibility 0.3s ease-in-out;
	pointer-events: none;

	// Add strix St
	&::after {
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba($color: v.$color0, $alpha: 0.75);
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		content: "";
	}

	// Add strix End

	@include r.pc {
		overflow-x: auto;
	}

	@include r.sp {
		transition:
			opacity 0s ease-in-out,
			visibility 0.3s ease-in-out;
		overflow-x: hidden;
	}

	&.is-active {
		visibility: visible;
		pointer-events: all;
		opacity: 1;
	}

	&__wrap {
		padding: 16px;
		border-radius: 8px 8px 0 0;
		height: max-content;
		background-color: v.$base-color;

		@include r.pc {
			min-width: 1280px;
		}

		@include r.sp {
			min-height: 100%;
			padding: 16px 8px;
		}
	}

	&__inner {
		position: relative;
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	&__btn {
		right: 0;
		background: url(p.$path + "images/common/icon/icon_close.svg") no-repeat center / 20px auto;
		cursor: pointer;
		z-index: 2;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 4px;
		font-size: 0;

		@include r.sp {
			right: 8px;
		}

		@include r.w374 {
			right: 0;
			width: 18px;
			height: 18px;
			background-size: 16px auto;
		}
	}

	&__bottom {
		padding: 8px 16px 10px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		z-index: 2;
		border-radius: 0 0 8px 8px;
		box-shadow: 0 -2px 4px rgba($color: #000, $alpha: 0.1);
		display: flex;
		align-items: center;
		justify-content: center;

		.nk23_c-btn01 {
			width: 240px;

			@include r.sp {
				flex: 1;
				max-width: 163px;
			}

			&:not(:last-child) {
				margin-right: 40px;

				@include r.sp {
					margin-right: 8px;
				}
			}
		}
	}

	// .nk23_c-modal1__scroll .
	&__scroll {
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: 16px;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		.scroll-content {
			@include r.sp {
				height: auto;
			}
		}
	}

	&__text {
		font-size: 1.4rem;
		line-height: 2rem;

		&.is-small {
			font-size: 1.2rem;
			line-height: 1.7rem;
		}

		&.is-note {
			padding-left: 10px;
			text-indent: -10px;
		}
	}

	&__title {
		font-size: 1.6rem;
		line-height: 2rem;
		font-weight: 700;

		&link {
			width: max-content;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			@include r.sp {
				margin-bottom: 8px;
			}

			.nk23_c-link {
				min-width: max-content;
			}
		}
	}

	&__grtext {
		margin-bottom: 8px;

		@include r.pc {
			display: flex;
			align-items: center;
		}

		// .nk23_c-modal1__grtext .nk23_c-link .
		.nk23_c-link {
			@include r.pc {
				width: max-content;
				max-width: 10%;
				padding-right: 24px;
			}

			@media screen and (max-width: 767px) and (min-width: 415px) {
				padding-right: 8px;
			}
		}

		.nk23_c-modal1__title {
			@include r.pc {
				width: max-content;
				// max-width: 50%;
				padding-right: 24px;
			}

			@include r.sp {
				margin-bottom: 8px;
			}

			&link {
				.nk23_c-modal1__title {
					@include r.sp {
						margin-bottom: 0;
						margin-right: 16px;
					}
				}
			}
		}
	}

	// .nk23_c-modal1__scrollwrap .
	&__scrollwrap {
		padding-bottom: 72px;
		min-height: max-content;

		@include r.sp {
			padding-bottom: 80px;
		}
	}

	// .nk23_c-modal1__content .
	&__content {
		padding: 16px;
		border-radius: 8px;
		height: max-content;
		background-color: #fff;

		@include r.sp {
			padding: 8px;
		}

		&:not(:last-child) {
			margin-bottom: 16px;

			@include r.sp {
				margin-bottom: 8px;
			}
		}

		&:nth-child(2) {
			.nk23_c-modal1__title {
				padding-right: 8px;

				@include r.sp {
					padding-right: 0;
					margin-right: 8px;
				}
			}
		}
	}

	&.is-style1 {
		@include r.pc {
			padding: 40px 16px;
		}

		@include r.sp {
			overflow-y: hidden;
			transition: none;
			opacity: 1;
		}

		.nk23_c-modal1 {
			&__wrap {
				@include r.pc {
					border-radius: 8px;
					margin-left: auto;
					margin-right: auto;
					width: max-content;
					height: max-content;
					min-height: unset;
					min-width: unset;
					max-width: 472px;
				}

				@include r.sp {
					transition:
						transform 0.3s cubic-bezier(0.65, 0, 0.35, 1),
						visibility 0.3s cubic-bezier(0.65, 0, 0.35, 1);
					transform: translateY(100%);
					max-width: 427px;
					margin: auto;
				}
			}
		}

		&.is-active {
			.nk23_c-modal1 {
				&__wrap {
					@include r.sp {
						transform: translate(0);
					}
				}
			}
		}

		&.is-flex {
			@include r.pc {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&.is-style2 {
		overflow-y: hidden;

		@include r.pc {
			padding: 16px 40px;
		}

		@include r.sp {
			transition: none;
			opacity: 1;
		}

		.nk23_c-modal1 {
			// .nk23_c-modal1.is-style2 .nk23_c-modal1__wrap .
			&__wrap {
				position: relative;
				padding-bottom: 69px;
				height: max-content;
				max-height: 100%;
				margin-left: auto;
				margin-right: auto;
				overflow: hidden;

				@include r.pc {
					min-width: unset;
					border-radius: 8px;
					max-width: 1200px;
					width: 100%;
				}

				@include r.sp {
					transition:
						transform 0.3s cubic-bezier(0.65, 0, 0.35, 1),
						visibility 0.3s cubic-bezier(0.65, 0, 0.35, 1);
					transform: translateY(100%);
				}
			}

			&__inner {
				height: 100%;
				max-width: 100%;
				display: flex;
				flex-direction: column;
			}

			&__content {
				@media screen and (min-width: 768px) and (max-width: 999px) {
					padding-bottom: 0;
				}

				@media (max-width: 767px) and (orientation: landscape) {
					padding-bottom: 0;
				}
			}
		}

		&.is-active {
			.nk23_c-modal1 {
				&__wrap {
					@include r.sp {
						transform: translate(0);
					}
				}
			}
		}

		&.is-flex {
			@include r.pc {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}

		.nk23_c-list10 {
			@media screen and (max-width: 767px) and (min-width: 415px) {
				margin-bottom: 0;
			}

			&__item {
				&__inner {
					@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
						padding: 8px;
					}
				}

				@media screen and (min-width: 768px) and (max-width: 999px) {
					width: 25%;
					margin-bottom: 16px;
				}

				@media screen and (max-width: 767px) and (min-width: 415px) {
					margin-bottom: 0;
					width: 100%;
				}

				@media (max-width: 767px) and (orientation: landscape) {
					width: 50%;
					margin-bottom: 16px;
				}
			}
		}

		.nk23_c-checkbox {
			&__label {
				@media screen and (min-width: 768px) and (max-width: 820px) {
					padding-left: 20px;
				}
			}

			input {
				@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
					top: 0;
				}
			}
		}
	}

	&--style1 {
		@include r.pc {
			padding: 0 !important;
			background: none;
			overflow: unset;
			position: relative;
			overflow: unset;
			transition: none;
			display: none;
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}

		&.is-show {
			@include r.pc {
				display: block;
			}
		}

		.nk23_c-table01__wrap {
			@include r.sp {
				position: relative;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}

		.nk23_c-modal1 {
			&__wrap {
				@include r.pc {
					max-width: 992px !important;
					padding: 0 16px 24px !important;
				}
			}

			&__btn {
				@include r.pc {
					display: none;
				}
			}
		}

		.nk23_c-grtext1 {
			@include r.pc {
				padding-right: 0;
			}
		}

		&:after {
			@include r.pc {
				display: none;
			}
		}
	}

	// .nk23_c-modal1 .nk23_c-grtext1 .
	.nk23_c-grtext1 {
		padding-right: 48px;

		@include r.w374 {
			padding-right: 24px;
		}

		// .nk23_c-modal1 .nk23_c-grtext1 + .nk23_c-table01 .
		& + .nk23_c-table01 {
			margin-top: 8px;
		}
	}

	.nk23_c-listcheck {
		@include r.pc {
			margin-bottom: 16px;
		}
	}

	.nk23_c-text01 {
		& + .nk23_c-listcheck {
			margin-top: 16px;
		}
	}

	.nk23_c-table22 {
		margin: 0 -8px;

		&__tbcontent {
			padding-top: 0;
			border-radius: 0;
		}

		&__wrap,
		&__table {
			border-radius: 0;
			margin-bottom: 4px;
		}

		&__inner {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 4px;
		}

		&__icon {
			@include r.sp {
				top: 160px;
			}
		}
	}

	&__tb-tag {
		position: absolute;
		top: calc(50% - 30px);
		left: calc(50% - 110px);
		z-index: 2;
		width: 221px;
		height: 49px;
		background: rgba(34, 31, 31, 0.3);
		color: #ffffff;
		font-size: 2.4rem;
		line-height: 1;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0.75;
		pointer-events: none;
	}
}

/*
------------------------------------------------------------*/

.nk23_c-zoom {
	position: relative;

	// Add strix St
	@include r.sp {
		overflow: hidden;

		// .is-sticky & {
		//   position: fixed;
		//   z-index: 4;
		//   top: 44px;
		//   left: 0;
		//   width: 100%;
		// }
	}

	// Add strix End

	// .nk23_c-zoom__btns .
	&__btns {
		display: flex;
		justify-content: center;
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 4;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition:
			opacity 0.2s ease-in-out,
			visibility 0.2s ease-in-out;
		bottom: 32px;

		&.is-show {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}

	&__content {
		transform-origin: left top;
	}

	&__btn {
		width: 46px;
		height: 46px;
		background-size: 46px auto;
		background-repeat: no-repeat;
		border-radius: 50%;
		font-size: 0;
		user-select: none;
		cursor: pointer;
		z-index: 4;

		&:not(:last-child) {
			margin-right: 56px;
		}

		&.is-out {
			background-image: url(p.$path + "images/common/icon/icon_zoomout.svg");
		}

		&.is-in {
			background-image: url(p.$path + "images/common/icon/icon_zoomin.svg");
		}
	}
}

/*
------------------------------------------------------------*/

.nk23_c-banner3 {
	background: #fff;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center; //update NANKAN_HP_MOCK-1235
	padding: 16px;

	@include r.sp {
		flex-direction: column;
		padding: 8px;
	}

	&__row {
		display: flex;
		align-items: center;

		@include r.sp {
			margin-bottom: 16px;
		}
	}

	&__img {
		width: 64px;

		img {
			width: 100%;
		}

		&.fnb {
			width: 48px;
			margin-right: 14px;
		}
	}

	&__content {
		padding-left: 8px;
		flex: 1;
	}

	&__text {
		font-size: 1.2rem;
	}

	&__date {
		margin-right: 8px;
	}

	&__title {
		font-size: 2rem;
		font-weight: 700;
		margin-top: 8px;

		@include r.sp {
			font-size: 1.6rem;
			margin-top: 4px;
		}
	}

	.nk23_c-btn02 {
		@include r.sp {
			width: 100%;
		}
	}
}

.js-hide-top {
	transition-delay: 0s;

	&.is-active {
		border-radius: 8px !important;
		transition-delay: 0.3s;

		@include r.sp {
			border-radius: 0 !important;
		}
	}
}

.accordion__table {
	@include r.pc {
		display: block !important;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-block01__toggle1 {
	@include r.sp {
		display: none;
	}

	&.is-active {
		@include r.sp {
			display: block;
		}
	}
}

.nk23_c-block01__toggle2 {
	@include r.sp {
		// display: none;
		width: 100%;
		opacity: 0;
		position: fixed;
		pointer-events: none;
	}

	&.is-active {
		@include r.sp {
			// display: block;
			opacity: 1;
			position: static;
			pointer-events: all;
		}
	}
}

// Add css modal z-index
//-------------------------------
.page-guideline {
	#wrapper {
		@include r.sp {
			overflow-x: hidden;
		}
	}

	.nk23_c-modal1 {
		z-index: 999;
	}

	.nk23_c-modal1--style1 {
		@include r.pc {
			display: none !important;
		}

		&.is-show {
			@include r.pc {
				display: none !important;
			}
		}
	}

	// .page-guideline .c-gdl-icon-list .nk23_c-tab1__subtitle__label .
	.c-gdl-icon-list {
		.nk23_c-tab1__subtitle__label {
			position: relative;
		}
	}
}
