@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
.nk23_c-btn01 {
	@include r.btn01(v.$color19, v.$color19, #fff, v.$color0);

	&.is-color1,
	&.is-btn-urawa {
		@include r.btn01(v.$urawa-border, v.$urawa-shadow, v.$color3, v.$urawa-color);
	}

	&.is-color2,
	&.is-btn-ooi {
		@include r.btn01(v.$ooi-border, v.$ooi-shadow, v.$color9, v.$ooi-color);
	}

	&.is-color3 {
		@include r.btn01(v.$color20, v.$color21, #fff, v.$color20);

		color: v.$color20;

		&.is-active {
			color: #fff;
		}
	}

	&.is-color4,
	&.is-btn-funabashi {
		@include r.btn01(v.$funabashi-border, v.$funabashi-shadow, v.$color6, v.$funabashi-color);
	}

	&.is-color5,
	&.is-btn-kawasaki {
		@include r.btn01(v.$kawasaki-border, v.$kawasaki-shadow, v.$color12, v.$kawasaki-color);
	}

	&.is-color6 {
		@include r.btn01(v.$color31, v.$color31, #fff, v.$color32);

		color: v.$color32;

		&.is-active {
			color: #fff;
		}
	}

	&.is-hover {
		box-shadow: none;
		top: 2px;
	}

	input {
		width: 0;
		height: 0;
		opacity: 0;
	}

	&--setting {
		// .nk23_c-btn01--setting img .
		img {
			margin-right: 4px;
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-btn02 {
	@include r.btn02(v.$color19, v.$color19, #fff, v.$color0);
	color: v.$color0;

	&.is-color1,
	&.is-btn-urawa {
		@include r.btn02(v.$urawa-border, v.$urawa-shadow, v.$color3, v.$urawa-color);
	}

	&.is-color2,
	&.is-btn-ooi {
		@include r.btn02(v.$ooi-border, v.$ooi-shadow, v.$color9, v.$ooi-color);
	}

	&.is-color3 {
		@include r.btn02(v.$color20, v.$color21, #fff, v.$color20);
	}

	&.is-color4,
	&.is-btn-funabashi {
		@include r.btn02(v.$funabashi-border, v.$funabashi-shadow, v.$color6, v.$funabashi-color);
	}

	&.is-color5,
	&.is-btn-kawasaki {
		@include r.btn02(v.$kawasaki-border, v.$kawasaki-shadow, v.$color12, v.$kawasaki-color);
	}

	&.is-color6,
	&.is-btn-ooi {
		@include r.btn02(v.$ooi-border, v.$ooi-shadow, v.$color9, v.$ooi-color-light);
	}

	// .nk23_c-btn02.is-accor .
	&.is-accor {
		@include r.sp {
			padding: 2px 2px 2px 14px;
			width: 52px;
			min-height: 25px;
		}

		// .nk23_c-btn02.is-accor.is-active .
		&.is-active {
			@include r.sp {
				top: 0;
				background-color: #fff;
				color: v.$color0;
				box-shadow: 0 2px 0 v.$color19;
			}

			.nk23_c-btn02__text {
				color: v.$color0;

				&:after {
					transform: rotate(45deg);
					opacity: 0;
				}
			}
		}

		.nk23_c-btn02__text {
			@include r.sp {
				position: relative;
				font-size: 1.2rem;
				border-radius: 2px;
				min-width: 24px;
				text-align: center;
				white-space: nowrap;
			}

			&:before,
			&:after {
				@include r.sp {
					content: "";
					position: absolute;
					background-color: v.$color0;
				}
			}

			&:before {
				@include r.sp {
					top: 9px;
					left: -12px;
					height: 1px;
					width: 9px;
				}
			}

			&:after {
				@include r.sp {
					top: 5px;
					left: -8px;
					height: 9px;
					width: 1px;
					transition: 0.3s ease-in-out;
				}
			}
		}
	}

	&.is-small {
		min-height: 28px;
		width: 72px;
		font-size: 1.2rem;

		.nk23_c-btn02 {
			&__icon {
				width: 16px;
			}
		}
	}
	&.is-small2 {
		font-size: 1.2rem !important;
		letter-spacing: -0.05em;
		white-space: nowrap;
		@media screen and (max-width: 420px) {
			font-size: 1.1rem !important;
			letter-spacing: -0.1em;
			.nk23_c-btn02__icon {
				width: 10px !important;
			}
		}
	}
	&.is-small3 {
		font-size: 1.1rem !important;
		letter-spacing: -0.05em;
		white-space: nowrap;
		@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
			font-size: 1rem !important;
			letter-spacing: -0.15em;
		}
		@media screen and (max-width: 420px) {
			font-size: 1.1rem !important;
			letter-spacing: -0.1em;
			.nk23_c-btn02__icon {
				width: 10px !important;
			}
		}
	}
	&--small {
		width: 39px;
		margin-left: 4px;
		min-height: 18px;
		position: relative;
		margin-top: 0;

		.nk23_c-btn02__icon {
			width: 16px;
			margin: 1px auto 0;
		}
	}
	&:not(.is-style1) {
		&.nk23_c-btn02--small {
			@include r.sp {
				width: 39px;
				margin-left: 4px;
				min-height: 18px;
				position: relative;
				margin-top: 0;
				padding: 0 4px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-btn03 {
	&.is-btn-urawa {
		@include r.btn03(v.$color2, v.$urawa-color);
	}

	&.is-btn-ooi {
		@include r.btn03(v.$color8, v.$ooi-color);
	}

	&.is-color3 {
		@include r.btn03(v.$color21, v.$color20);
	}

	&.is-btn-funabashi {
		@include r.btn03(v.$color5, v.$funabashi-color);
	}

	&.is-btn-kawasaki {
		@include r.btn03(v.$color11, v.$kawasaki-color);
	}

	&.is-disable {
		opacity: 0.5;
		pointer-events: none;
	}
}

/*
------------------------------------------------------------*/
.nk23_c-btn04 {
	padding: 5px 8px;
	display: flex;
	border-radius: 8px;
	align-items: center;
	min-width: max-content;
	width: 116px;
	height: 40px;
	justify-content: center;
	@include r.shadow(v.$color19);
	@include r.border(v.$color19);
	background-color: #fff;
	transition: none;
	position: relative;

	&:not(.is-active) {
		@include r.hover {
			box-shadow: none;
			top: 2px;
			text-decoration: none !important;
		}
	}

	@include r.hover {
		opacity: 1 !important;
	}

	@include r.sp {
		width: 114px;
		height: 32px;
		padding: 5px;
	}

	&.is-hover {
		box-shadow: none;
		top: 2px;
	}

	&__right {
		padding-left: 8px;
		font-size: 1.2rem;
		line-height: 1.8rem;
		display: flex;
		align-items: center;
		border-left: 1px solid v.$color19;
		font-weight: 700;

		@include r.sp {
			padding-left: 4px;
		}
	}

	&__icon {
		margin-right: 2px;
		width: 16px;

		@include r.sp {
			margin-right: 4px;
		}
	}

	&__left {
		font-size: 1rem;
		line-height: 1.3rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-right: 8px;

		@include r.pc {
			font-size: 1.2rem;
		}

		@include r.sp {
			padding-bottom: 1px;
			padding-right: 5px;
			flex-direction: row;
			line-height: 1.5rem;
		}
	}

	&.is-top {
		@include r.sp {
			width: 60px;
			padding: 4px 5px 5px;
			flex-direction: column;
			height: auto;
		}

		.nk23_c-btn04 {
			&__right {
				@include r.sp {
					margin-top: 4px;
					padding-top: 5px;
					padding-left: 0;
					border-left: none;
					border-top: 1px solid v.$color19;
					justify-content: center;
					width: 100%;
				}
			}

			&__icon {
				@include r.sp {
					margin-right: 2px;
				}
			}

			&__left {
				@include r.sp {
					padding: 0;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.nk23_c-btn05 {
	@include r.btn05(v.$color19, v.$color19, #fff, v.$color0);
	color: v.$color0;

	&.is-color1,
	&.is-btn-urawa {
		@include r.btn05(v.$urawa-border, v.$urawa-shadow, #fff, v.$urawa-color);
	}

	&.is-color2,
	&.is-btn-ooi {
		@include r.btn05(v.$ooi-border, v.$ooi-shadow, #fff, v.$ooi-color);
	}

	&.is-color5,
	&.is-btn-kawasaki {
		@include r.btn05(v.$kawasaki-border, v.$kawasaki-shadow, #fff, v.$kawasaki-color);
	}

	&.is-color4,
	&.is-btn-funabashi {
		@include r.btn05(v.$funabashi-border, v.$funabashi-shadow, #fff, v.$funabashi-color);
	}
}

/*
------------------------------------------------------------*/
.nk23_c-btn06 {
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background: rgba(0, 78, 162, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
	&::before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 12px;
		height: 3px;
		border-radius: 1px;
		background: v.$color32;
	}
	@include r.hover {
		opacity: 0.8;
	}
	&.is-active1 {
		&::before {
			width: 24px;
			height: 24px;
			border-radius: 0;
			top: 48%;
			background: url(p.$path + "images/common/icon/icon_check-mark_1.svg") no-repeat center / 100%;
		}
	}
	&.is-active2 {
		&::before {
			width: 24px;
			height: 24px;
			border-radius: 100%;
			border: 3px solid v.$color32;
			top: 48%;
			background: transparent;
		}
	}
	&.is-active3 {
		&::before {
			width: 24px;
			height: 24px;
			border-radius: 100%;
			border: 3px solid v.$color32;
			top: 48%;
			background: transparent;
		}
		&::after {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			border: 3px solid v.$color32;
			left: 50%;
			top: 48%;
			transform: translate(-50%, -50%);
			background: transparent;
		}
	}
	&.is-active4 {
		&::before {
			width: 24px;
			height: 24px;
			border-radius: 0;
			top: 48%;
			background: url(p.$path + "images/common/icon/icon_check-mark_4.svg") no-repeat center / 100%;
		}
	}
	&.is-active5 {
		&::before {
			width: 0;
			height: 0;
			border: 12px solid transparent;
			border-top: 0;
			border-bottom: 20px solid v.$color32;
			background: transparent;
		}
	}
}
.sp-btn6 {
	.sp-list-btn6 {
		position: absolute;
		bottom: 0;
		left: 1px;
		z-index: 999;
		// display: none;
		// Add strix St
		display: flex;
		opacity: 0;
		transition: opacity 0.3s ease-out;
		pointer-events: none;
		// Add strix End
		&.show {
			// display: flex;
			// Add strix St
			opacity: 1;
			pointer-events: all;
			// Add strix End
		}
		.nk23_c-btn06 {
			display: block;
			margin-left: 1px;
			background: rgba(229, 237, 246, 1);
		}
	}
}

.nk23_c-btn07 {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__link {
		font-size: 1.2rem;
		font-weight: 700;
		text-align: center;
		border-radius: 8px;
		box-shadow: 0 2px 0 v.$color19;
		border: 1px solid v.$color19;
		background-color: #fff;
		padding: 8px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 4px;
		width: calc(100% / 6 - 8px);

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}

		&.is-disable {
			opacity: 0.25;
		}
	}
}
