@use "./settings/variable" as v;
@use "./settings/mixin" as r;
@use "./settings/_path" as p;

/* css No5,No6,No8 -reused css of the old site
------------------------------------------------------------*/
#cboxCurrent {
  font-size: 1.3rem;
  color: v.$color0;
  bottom: 11px;
  left: 64px;
}

#cboxLoadedContent {
  padding: 8px 8px 0;
  margin-bottom: 40px;
}

#cboxClose {
  bottom: 8px;
  right: 8px;
  background-position: -21px 0;

  @include r.hover {
    background-position: -21px -25px;
  }
}

#cboxContent {
  border-radius: 8px;
  overflow: hidden;
}

#cboxPrevious {
  bottom: 8px;
  left: 8px;
}

#cboxPrevious,
#cboxNext,
#cboxClose {
  background-size: 176px auto;
}

#cboxNext {
  bottom: 8px;
  left: 32px;
}

#cboxWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background: url(/info/spat4/images/colorbox/overlay2.png) left top repeat;
}

#cboxTopRight,
#cboxTopLeft,
#cboxBottomRight,
#cboxBottomLeft,
#cboxTopCenter,
#cboxBottomCenter,
#cboxMiddleLeft,
#cboxMiddleRight {
  display: none;
}

ul.tab-navigation {
  display: flex;
  flex-wrap: wrap;
  border: none;
  background: none;
  padding: 0;
  margin: 0 -4px;

  li {
    width: 33.3333%;
    margin: 0;
    padding: 0 4px;
  }

  a {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 4rem;
    text-align: center;
    color: v.$color32;
    background-color: #fff !important;
    border: 1px solid v.$color32 !important;
    width: 251px;
    box-shadow: 0 2px v.$color32;
    border-radius: 999px;
    transition: none;
    background-image: none;
    margin: 0;
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%) !important;
    font-weight: normal;

    @include r.hover {
      box-shadow: none;
      transform: translateY(2px);
      opacity: 1;
      color: v.$color32;
    }

    &.active {
      box-shadow: none !important;
      border-color: v.$color31 !important;
      background-color: v.$color32 !important;
      transform: translateY(2px);
      font-weight: bold;

      @include r.hover {
        color: #fff;
      }
    }
  }
}

.clearfix {
  @include r.clear;
}

.nk23_l-row {
  display: flex;

  @include r.sp {
    flex-direction: column-reverse;
  }
}

#contents {
  padding: 16px 0 40px;
  width: 100%;
}

#contents170 {
  margin: 0;
  padding: 0;
  width: 180px !important;

  @include r.sp {
    width: 100% !important;
    margin-top: 40px;
  }
}

#contents770 {
  margin: 0;
  padding: 0;
  width: 770px;
  margin-left: 8px !important;

  @include r.sp {
    width: 100%;
    margin-left: 0 !important;
  }

  #box-course-info {
    color: #221f1f;

    @include r.sp {
      margin-top: 32px;
    }
  }
}

.tl-kawasaki-img {
  img {
    width: 100%;
    border-radius: 8px;
  }
}

#nav-info,
#nav-info2 {
  margin-top: 0 !important;
  width: 180px !important;

  @include r.sp {
    width: 100% !important;
  }

  .link-home {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    padding: 0 8px 8px 0;

    img {
      display: inline;
      margin-right: 4px;
    }
  }

  ul {
    border: none !important;

    li {
      border-bottom: 0 !important;
    }
  }
}

#submenu {
  @include r.pc {
    min-width: 180px;
  }
}

#submenu,
#submenu2 {
  // background: #fff;
  border: none !important;
  border-radius: 4px;
  min-width: 180px;

  @include r.sp {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid #c9c9c9;
    background: #fff;
    &:first-child {
      background: transparent;
      border-bottom: 0;
      a {
        padding-top: 6px;
        padding-left: 0;
        &::after {
          content: none;
        }
      }
    }
    &:nth-of-type(2) {
      background: transparent;
      th {
        a {
          background: #ffffff;
          border-radius: 4px 4px 0 0;
        }
      }
    }

    &:last-child {
      border-bottom: 0;
      border-radius: 0 0 4px 4px;
      th {
        a {
          background: #ffffff;
          border-radius: 0 0 4px 4px;
        }
      }
    }

    a {
      position: relative;
      img {
        position: relative;
        z-index: 2;
      }
      &:after {
        content: "";
        background: url(p.$path + "images/common/icon/icon_right1.svg") no-repeat;
        width: 4px;
        height: 8px;
        background-size: 4px 8px;
        position: absolute;
        right: 11px;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
      }

      &.is-active {
        box-shadow: 0 1px 4px rgba($color: #221f1f, $alpha: 0.1);

        &:after {
          right: 9px;
          width: 10px;
          height: 10px;
          background: url(p.$path + "images/common/icon/icon_down_gray.svg") no-repeat;
          background-size: 10px 10px;
        }
      }
    }

    th {
      border: none !important;

      a {
        font-size: 1.4rem;
        line-height: 2.1rem;
        font-weight: 700;
        padding: 8px 8px;
        display: flex;
        align-items: center;
        text-align: left;

        @include r.sp {
          padding: 16px 8px;
        }

        // img {
        //   display: inline;
        //   margin-right: 4px;

        //   &.submenu-spat4 {
        //     width: 22px;
        //   }
        // }
      }
    }

    td {
      background: transparent !important;
      border: none !important;
      padding: 0 !important;

      ul {
        li {
          padding-left: 8px;
          border-top: 0 !important;

          &.title {
            padding: 8px 0 !important;
            background: #f7f7f7;
            border-bottom: 1px solid #c9c9c9 !important;

            @include r.sp {
              padding: 16px 0 !important;
            }

            &--bdtop {
              border-top: 1px solid #c9c9c9 !important;
            }

            h3 {
              font-size: 1.4rem;
              color: #221f1f !important;
              font-weight: 700;
              line-height: 2.1rem;
              padding: 0 4px 0 6px;
              border-left: 2px solid #221f1f;
            }
          }

          &.subtitle {
            background: transparent !important;

            h4 {
              font-size: 1.2rem;
              font-weight: 700;
              line-height: 1.8rem;
              padding: 8px 8px 8px 0;
              border-bottom: 1px solid #c9c9c9;

              @include r.sp {
                font-size: 1.4rem;
                line-height: 2.1rem;
                padding: 16px 8px 16px 0;
              }
            }

            &--bdtop {
              border-top: 1px solid #c9c9c9 !important;
            }
          }

          &:last-child {
            a {
              border-bottom: 0;
            }
          }

          a {
            font-size: 1.2rem;
            line-height: 1.8rem;
            padding: 8px 24px 8px 0 !important;
            display: block;
            border-bottom: 1px solid #c9c9c9;

            @include r.sp {
              padding: 16px 17px 16px 0 !important;
              font-size: 1.4rem;
              line-height: 2.1rem;
            }

            @include r.hover {
              text-decoration: none !important;
            }

            &.bd-none {
              border-bottom: none;
            }
          }
        }
      }
    }

    &.submenu-qanda,
    &.submenu-spat4,
    &.submenu-info {
      // display: none !important;

      &.is-active {
        // display: table-row !important;
      }
    }
  }
}

//for present sp page,for calc_marks sp page
#submenu2 {
  margin-top: 0;
  margin-bottom: 40px;

  th {
    padding: 0;
    background-color: #fff;
  }
}

// #breadcrumbs {
//   margin-top: 0 !important;
//   margin-bottom: 12px !important;
//   ul {
//     display: flex;
//     align-items: flex-end;
//     li {
//       margin: 0 !important;
//     }
//   }
//   li span,
//   li a {
//     background: none !important;
//     padding: 0 !important;
//     color: #221f1f !important;
//   }
//   li a {
//     color: #2461ee !important;
//   }
// }
#park-data {
  background: #fff !important;
  border-radius: 8px 8px 0 0;
  margin-top: 16px !important;
  padding-bottom: 0 !important;

  @include r.sp {
    padding-top: 0 !important;
  }
}

#park-data-in {
  padding: 0 34px 0 16px !important;
  margin: 0 !important;
  width: auto !important;

  @include r.sp {
    padding: 16px 8px 0 8px !important;
    margin: 0 !important;
  }
}

#box-park-course {
  margin: 0;
  padding: 0;
  width: 416px !important;
  float: right;

  @include r.sp {
    width: 100% !important;
  }

  .mgt-16 {
    margin-top: 16px;
  }

  h3 {
    font-weight: 700 !important;
    font-size: 1.6rem;
    margin-bottom: 16px;
    color: #221f1f;
    background-color: transparent !important;

    @include r.sp {
      font-size: 1.4rem;
    }
  }

  .course-table {
    margin: 16px 0;
    width: 100%;
  }

  .tb-course-info {
    width: 100% !important;
    border: 1px solid #c7c6c6;
    font-size: 1.4rem;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    color: #221f1f !important;

    @include r.sp {
      font-size: 1.3rem;
    }

    & + .tb-course-info {
      margin-top: 16px;
    }

    tr {
      th {
        font-weight: 700;
        text-align: left;
        padding: 8px 16px;
        width: 95px;
      }

      th,
      td {
        border-bottom: 1px solid #c9c9c9;
      }

      &:last-child {
        th,
        td {
          border-bottom: 0;
        }
      }
    }
  }

  p {
    font-size: 1.4rem;
    color: #221f1f;

    // margin-bottom: 0 !important;
    @include r.sp {
      font-size: 1.3rem;
    }
  }
}

.clr25,
.clr {
  @include r.clear;
}

#contents770a {
  background: #fff;
  padding: 0 16px 16px !important;
  border-radius: 0 0 8px 8px;
  width: 100% !important;

  @include r.sp {
    padding: 0 8px 32px;
  }

  p {
    margin-bottom: 16px;
  }

  a {
    color: #2461ee;

    @include r.hover {
      text-decoration: underline;
    }
  }
}

#contents730 {
  margin: 0 !important;
  width: auto !important;
  padding-top: 28px !important;

  h3 {
    font-weight: 700;
    @include r.clear;
    clear: both;
    margin-bottom: 16px;

    &.title2 {
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .nk23_c-block10__btn,
  .nk23_c-block10__contact {
    margin-top: 0;
  }

  .nk23_c-block10__btn {
    margin-bottom: 32px;

    .nk23_c-btn01__text {
      color: #221f1f;
    }
  }
}

.bl-left {
  float: left;
}

.bl-right {
  float: right;
}

.course-img {
  img {
    width: 100%;
    border-radius: 4px;
  }
}

.box360 {
  width: 361px;
  margin: 0 0 40px 0 !important;

  @include r.sp {
    width: 100% !important;
    margin: 0 0 16px 0 !important;
  }

  &--center {
    align-items: center;
    display: flex;
  }

  img {
    margin: 0 16px 0 0;
    padding: 0;
    float: left;
    border-radius: 4px;
  }

  .data {
    margin: 0;
    padding: 0;
    width: 245px;
    float: right;

    @include r.sp {
      width: calc(100% - 116px) !important;
    }
  }

  p {
    line-height: 22px;
    display: inline-block !important;
  }

  &.no-img {
    @include r.sp {
      margin-bottom: 32px !important;
    }

    .data {
      width: 100%;

      @include r.sp {
        width: calc(100% - 116px);
      }
    }
  }

  p {
    font-size: 1.3rem !important;

    a {
      font-size: 1.3rem;
      color: #2461ee;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#rc-gallery {
  // margin: 0;
  // padding: 0;
  // width: 280px;
  // float: left;
  background: transparent !important;

  @include r.sp {
    float: none !important;
    margin: auto !important;
    width: 100% !important;
  }
}

#rc-images {
  margin: 0;
  padding: 0;
  height: 185px;
  background: #000;
}

#rc-image {
  // margin: 0 0 5px 0;
  // padding: 0;
  // width: 278px;
  // height: 185px;
  overflow: hidden;
  position: relative;
  border: none !important;
  border-radius: 4px;

  // background: #fff url(p.$path + 'images/common/loadingAnimation.gif') center
  //   center no-repeat;
  @include r.sp {
    margin: 0 auto 5px !important;
  }
}

#rc-image img {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#rc-thumbwrapper {
  margin: 0;
  padding: 0;
  width: 278px;
  height: 68px;
  border: none !important;
  background-color: transparent !important;

  @include r.sp {
    margin: 0 auto !important;
  }
}

#rc-thumbarea {
  margin: 0;
  padding: 0;
  height: 68px;
  width: 278px;
  position: relative;
  overflow: hidden;
}

ul#rc-thumbs {
  margin: 0;
  padding: 0;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10000px;
  box-sizing: border-box;
}

ul#rc-thumbs li {
  margin: 0 4px 0 0;
  padding: 0;
  height: 68px;
  float: left;
  cursor: pointer;
  box-sizing: border-box;
  list-style-type: none;

  img {
    border-radius: 4px;
    border: 1px solid #c7c6c6;
  }
}

.content-table {
  padding: 0 16px;

  @include r.sp {
    padding: 0;
  }
}

.title-spat4-01 {
  padding: 16px;
  margin: 0;
  background: url(/info/spat4/images/bg_spat4_01.gif) 0 0 repeat;
  color: #fff;
  font-size: 2rem !important;
  font-weight: 700;
  border-radius: 8px;
  background-size: auto auto !important;
  background-color: rgba(0, 78, 162, 1) !important;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 4px,
    rgba(18, 90, 169, 1) 4px,
    rgba(18, 90, 169, 1) 6px
  ) !important;
}

.title-spat4 {
  background-repeat: no-repeat;

  &.netbank-w {
    background: url(/info/spat4/images/icon_white.png) 0 0 no-repeat;
  }
}

.box-about-service {
  table {
    background: none;
  }

  .list00 {
    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  // .box-about-service+.box-about-service .
  & + .box-about-service {
    margin-top: 20px;
  }
}

.tab-navigation.telephone {
  display: flex;
  justify-content: space-between;
  margin: 16px -4px;
  padding: 0 !important;
  background: transparent;
  border-bottom: none !important;

  li {
    width: 50%;

    a {
      display: flex;
      width: 100%;
      border: 1px solid #004ea2;
      font-size: 1.4rem;
      line-height: 1;
      text-align: center;
      background: #fff;
      border-radius: 24px;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 0 #004ea2;
      color: #004ea2;
      min-height: 35px;
      padding-bottom: 1px;
      transition: none;

      @include r.sp {
        font-size: 1.3rem;
      }

      @include r.hover {
        position: relative;
        top: 2px;
        box-shadow: none;
        background: #fff;
        border: 1px solid #004ea2;
        color: #004ea2;
        opacity: 1;
        transition: none;
      }

      @include r.sp {
        &:hover {
          background: #fff;
          color: #004ea2;
        }
      }
    }
  }
}

.title-spat4-02 {
  font-size: 2rem;
  line-height: 3.2rem !important;
  font-weight: 700;
  color: #004ea2;
  border-left: 5px solid #004ea2;
  padding-left: 10px !important;
  margin: 0 0 0 -10px !important;
  background: none !important;
  position: relative;

  &.title_telephone {
    &::before {
      background: none !important;
    }
  }

  &.title_netbank {
    margin-bottom: 20px !important;
  }
}

.title-spat4-03 {
  font-size: 1.8rem !important;
  font-weight: 700;
  color: #221f1f !important;
  line-height: 22px;
  border-bottom: 1px solid #c9c9c9 !important;
  padding-bottom: 4px;
  margin-bottom: 10px !important;
  margin-top: 16px;
}

.title-spat4-04 {
  font-size: 1.6rem !important;
  padding: 5px !important;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 10px !important;
  background: rgba($color: #004ea2, $alpha: 0.1) !important;
}

.tx-small {
  font-size: 1.1rem;
}

.tx-bold {
  font-weight: 700;
}

.box-freedial {
  width: 380px !important;
  margin: 16px auto 20px !important;
  font-size: 1.3rem;
  box-sizing: border-box;

  @include r.sp {
    width: 100% !important;
  }

  &__tel {
    margin-bottom: 13px;

    img {
      margin: auto;
      max-width: 288px;
    }
  }
}

.contact-txt {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include r.sp {
    margin-bottom: 4px;

    &__link {
      margin-top: 16px;
      width: 100%;
    }
  }

  &__link {
    color: #2461ee;
    margin-left: 16px;

    @include r.sp {
      display: block;
      margin-left: 0;
    }
  }

  & + .contact-txt {
    margin-top: 16px;
    margin-bottom: 0 !important;
  }
}

.is-tel {
  font-size: 2.4rem;
  font-weight: bold;
  margin-left: 8px;
  pointer-events: none;
}

#contents-nw {
  .nk23_l-container {
    @include r.sp {
      padding: 0;
    }
  }

  .twoColEq_L {
    float: left;
    width: 470px;
    margin: 8px 0;

    @include r.sp {
      width: 100%;
      border-radius: 0;
      float: none;
    }

    p {
      font-size: 1.3rem;
    }

    .nk23_c-btn02 {
      width: 175px;
      min-height: 18px;

      @include r.sp {
        border-radius: 50px;
        padding: 2px 5px 2px;
      }
    }
  }

  .twoColEq_R {
    float: right;
    width: 470px;
    margin: 8px 0;

    @include r.sp {
      width: 100%;
      float: none;
      margin: 16px 0;
    }
  }

  .nk23_c-btn02 {
    &__text {
      font-size: 1.2rem;
      font-weight: normal;
    }

    &__icon {
      width: 12px;
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

#boxProf-jockey {
  padding: 16px;
  background: #fff;
  border-radius: 8px;

  @include r.sp {
    padding: 8px;
    border-radius: 0;
  }
}

#jockey-img {
  width: 200px;

  @include r.sp {
    width: 150px;
  }

  img {
    width: 100%;
    border-radius: 4px;
  }
}

#jockey-prof {
  width: calc(100% - 200px);
  padding-left: 8px;

  @include r.sp {
    width: calc(100% - 150px);
  }

  #tl-prof {
    margin-bottom: 16px;
  }

  .tb04 {
    tr {
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .nk23_c-btn02 {
    margin-top: 8px;

    @include r.sp {
      margin-top: 16px;
    }
  }
}

#trainer-prof {
  .tb04 {
    tr {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

#tl-prof {
  font-size: 2.2rem;
  font-weight: 700;

  @include r.sp {
    font-size: 1.6rem;
  }
}

.tb04 {
  width: 100%;
  font-size: 1.3rem;

  tr {
    border-bottom: 1px solid #c9c9c9;

    td {
      padding: 4px 0;

      .val-middle {
        vertical-align: middle;
        display: inline-block;
      }

      .mL10 {
        margin-left: 4px;
      }
    }
  }
}

.bl-inline {
  display: inline-block;
}

.ico-01 {
  margin: 0;
  color: #2461ee;
  font-size: 1.4rem;
  line-height: 2rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 0;
  padding-right: 24px;

  &:hover {
    text-decoration: underline;
  }
}

.btn-style-reset {
  margin-bottom: 8px;
  margin-top: 8px;
  max-width: 175px;
}

h3.tl-h3 {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  background: #fff;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  position: relative;

  @include r.sp {
    border-radius: 0;
    font-size: 1.4rem;
    padding: 8px 16px;
  }

  span {
    &:after {
      @include r.sp {
        content: "";
        background: url(p.$path + "images/common/icon/icon_arrow_black.svg") no-repeat;
        position: absolute;
        width: 12px;
        height: 6px;
        background-size: 12px 6px;
        right: 18px;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &.mgt-16 {
    margin-top: 16px;
  }

  &.open + div {
    @include r.sp {
      display: block !important;
    }
  }

  &.close + div {
    @include r.sp {
      display: none !important;
    }
  }

  &.close {
    span {
      &:after {
        transform: rotate(0);
        margin-top: -3px;
      }
    }
  }
}

.table-wrapper {
  .tb01 {
    background: #fff;
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    @include r.sp {
      border-radius: 0;
    }

    th {
      &.w50px {
        width: 50px;
      }
    }
  }

  &.kanriba {
    #disp-chg-sp {
      .tb01 {
        @include r.sp {
          min-width: 100%;
        }

        thead {
          tr {
            &:first-child {
              th {
                &:first-child {
                  width: 140px;
                }

                &:nth-child(2) {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }

    #disp-chg-pc {
      .tb01 {
        thead {
          th {
            &:nth-child(4) {
              width: auto;
            }

            &:nth-child(5) {
              width: 290px;
            }
          }
        }
      }
    }

    .round-cnr {
      .bl-left {
        font-size: 1.4rem;
      }

      .tx-orange01 {
        font-size: 1.6rem;
      }

      .odds-pagination {
        font-size: 1.4rem;
      }
    }
  }
}

.tb01,
.tb02 {
  width: 100%;
  font-size: 1.4rem;
  background: #fff;

  @include r.sp {
    font-size: 1.3rem;
  }

  tr {
    border-top: 0 !important;

    &:last-child {
      td {
        border-bottom: 0;

        @include r.sp {
          border-bottom: 1px solid #c9c9c9;
        }
      }
    }

    &.bg-fcf.bg-f6f {
      background: #f7f7f7 !important;
    }

    th,
    td {
      border: 1px solid #c9c9c9;
      padding: 4px;
      text-align: center;

      @include r.sp {
        padding: 4px;
      }

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      .nk23_u-colorlink {
        white-space: normal;
      }
    }

    th {
      background: #404040;
      color: #fff;
      font-weight: 700;
      text-align: center;
    }

    td {
      a {
        color: #2461ee;

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }

    .is-bold {
      font-weight: bold;
    }
  }
}

.tb01 {
  input[type="radio"] {
    width: 13px !important;
    height: 13px !important;
    border-radius: 50%;
    border: 1px solid v.$color0;
    background-color: #fff;

    &:checked {
      background: v.$color0 url(p.$path + "images/common/icon/icon_checked-white.svg") no-repeat center / 7px auto;
    }
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: auto;
      width: 18px;
    }
  }

  .list_inline {
    margin-top: 16px;
    max-width: 580px;
  }

  .tx-red01 {
    color: v.$color57;
  }

  .tx_red01 {
    color: v.$color57;
  }

  tr {
    &.is-active {
      td {
        background-color: v.$color28;
      }
    }
  }

  label {
    margin-bottom: -4px;
  }

  input {
    &[type="text"],
    &[type="number"] {
      padding: 4px;
      height: 24px;
      max-width: 100%;
      font-size: 1.3rem;
      border: 1px solid v.$color19;
      border-radius: 4px;
    }

    &[type="number"] {
      width: 64px;
      margin-right: 4px;
    }

    &:not([type="number"]) {
      width: 386px;
    }

    // .tb01 input[type=checkbox] .
    &[type="checkbox"] {
      border-radius: 4px;
      border: 1px solid v.$color0 !important;
      width: 13px;
      height: 13px;
      background-color: #fff;
      margin-right: 2px;

      @include r.pc {
        position: relative;
        top: 1px;
      }

      &:checked {
        background: v.$color0 url(p.$path + "images/common/icon/icon_checked-white.svg") no-repeat center / 7px auto;
      }
    }
  }

  .field {
    border: 1px solid v.$color19;
    border-radius: 4px;
  }

  select {
    &.field {
      padding-left: 4px;
      font-size: 1.3rem;
      width: 77px;
      min-width: max-content;
      height: 23px;
      background: #fff url(p.$path + "images/common/icon/icon_down.svg") no-repeat center right 7px / 10px auto;

      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }

  .al-center {
    width: auto !important;
  }

  &.detail_search_cdn {
    color: #000 !important;
  }

  em {
    &.tx-red01 {
      font-size: 1.3rem;
      line-height: 1.9rem;
      font-style: normal;
      color: v.$color13;
    }
  }
}

#disp-chg-pc {
  .tb01 {
    thead {
      th {
        &:nth-child(4) {
          width: 254px;
        }

        &:nth-child(5) {
          width: 144px;
        }
      }
    }

    tbody {
      td {
        &:nth-child(4),
        &:nth-child(5) {
          text-align: left;
        }
      }
    }
  }
}

#disp-chg-sp {
  .tb01 {
    @include r.sp {
      table-layout: fixed;
      line-height: 1.3;
    }

    thead {
      tr {
        &:first-child {
          th {
            &:first-child {
              width: 40px;
            }

            &:nth-child(2) {
              width: 48px;
            }

            &:nth-child(3) {
              width: 70px;
            }

            &:nth-child(4) {
              width: 85px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            &:nth-child(4),
            &:nth-child(5) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.bg-3chaku {
  background: #fff9e6;
}

.bg-1chaku {
  background: #f2d1ce;
}

.bg-2chaku {
  background: #dfe8f7;
}

.al-right {
  text-align: right !important;
}

.al-left {
  text-align: left !important;
}

.al-center {
  text-align: center !important;
}

.val-bottom {
  vertical-align: bottom;
  margin-top: 16px;
}

#boxProf-horse {
  margin-bottom: 16px;
  background: #fff;
  padding: 8px;

  @include r.pc {
    border-radius: 8px;
  }

  #boxHorseName {
    margin: 0 0 24px 0;
    padding: 0 0 0 0;
    border-radius: 0;
    border-bottom: 1px solid #c9c9c9;
  }
}

#horse-img {
  width: 96px;
  margin: 8px 0 0;

  @include r.sp {
    width: 100px;
  }

  p {
    a {
      color: #2461ee;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#horse-blood {
  width: 48%;

  @include r.sp {
    width: calc(100% - 100px);
    padding-left: 24px;
  }

  .tb04 {
    td {
      padding: 4px;

      &:nth-child(1) {
        width: 36px;
      }

      &:nth-child(2) {
        width: 45px;
      }
    }
  }
}

.clearfix {
  clear: both;
}

.horse-info {
  width: 100%;
}

#horse-info {
  margin-bottom: 8px;

  @include r.sp {
    width: 100%;
    border-bottom: 1px solid #c9c9c9;
  }

  .info1,
  .info2 {
    @include r.sp {
      border-top: 1px solid #c9c9c9;
    }
  }

  .info3 {
    @include r.sp {
      display: none;
    }
  }

  tr {
    @include r.sp {
      border-bottom: 0;
    }

    &:nth-child(1) {
      .info1 {
        @include r.sp {
          border-top: 0;
        }
      }
    }
  }

  .w20pr {
    @include r.sp {
      width: 93px;
      display: inline-block;
    }
  }

  .w30pr {
    @include r.sp {
      width: calc(100% - 93px);
      display: inline-block;
      padding-left: 8px;
    }
  }
}

#tab-select {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    border-bottom: 1px solid v.$color0;

    @include r.sp {
      margin: 0 -8px 10px;
    }

    li {
      max-width: 150px;
      width: calc((100% - 28px) / 5);
      background: #ffffff;
      border-radius: 8px 8px 0 0;

      @include r.sp {
        width: calc((100% - 2px) / 2);
      }

      &:not(:last-child) {
        margin-right: 7px;

        @include r.sp {
          margin-right: 2px;
        }
      }

      a {
        display: block;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.85;
        color: v.$color0;

        @include r.sp {
          font-size: 1.4rem;
          line-height: 1.45;
          padding: 12px 5px;
        }
      }

      &#crt-tab {
        background: v.$color0;

        a {
          color: #fff;
          font-weight: bold;

          @include r.sp {
            padding: 16px 5px;
          }
        }
      }
    }
  }
}

.list_btn_round {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid v.$color0;
  margin: 0 -8px 8px;
  padding: 0 8px;

  li {
    background: #ffffff;
    width: calc(50% - 2px);
    text-align: center;
    border-radius: 8px 8px 0 0;

    a {
      display: block;
      font-size: 1.4rem;
      line-height: 1.45;
      padding: 12px 5px;
    }

    &.crt {
      background: v.$color0;

      a {
        color: #fff;
        font-weight: bold;
        padding: 16px 5px;
      }
    }
  }
}

.title01 {
  font-size: 1.8rem;
  color: v.$color0;
  font-weight: bold;
  padding: 0 8px;
}

.title02 {
  font-size: 1.6rem;
  line-height: 1.45;
  color: #000000;
  font-weight: bold;
}

.button02 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  max-width: 100%;
  width: 145px;
  min-height: 27px;
  border: 1px solid #c9c9c9;
  box-shadow: 0 2px 0 #c9c9c9;
  background-color: #fff;
  position: relative;
  font-size: 1.4rem;
  padding: 0 5px;
}

.scroll_div {
  overflow: auto;
  border-radius: 0 0 0 8px;

  .tb01 {
    @include r.sp {
      min-width: 420px;
    }
  }
}

.sp-login-modal-content {
  display: none;
}

#modal {
  &-wrap {
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    z-index: 20000;
  }

  &-content {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #c2deef;
    border-radius: 8px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20001;
    display: none;

    @include r.sp {
      height: 100%;
      max-width: 100%;
      border-radius: 0;
    }

    &.open {
      display: block;
    }
  }

  &-close {
    position: absolute;
    top: 0;
    display: block;
    right: 0;
    width: 24px;
    cursor: pointer;
  }
}

//--for login modal----
.login-modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    z-index: 20000;
  }

  &-content {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #c2deef;
    border-radius: 8px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20001;
    display: none;

    @include r.sp {
      height: 100%;
      max-width: 100%;
      border-radius: 0;
    }

    &.open {
      display: block;
    }
  }

  &-close {
    position: absolute;
    top: -5px;
    display: block;
    right: 0;
    width: 24px;
    cursor: pointer;
  }
}

.login {
  &-box {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #c2deef;
    border-radius: 8px;
    text-align: center;

    p {
      text-align: center;
      font-size: 1.3rem;
      line-height: 1.9;
      color: v.$color0;
      margin-bottom: 12px;

      img {
        margin: 0 auto;
      }
    }

    a {
      color: v.$color-link;

      @include r.hover {
        text-decoration: underline;
      }
    }

    form {
      font-size: 1.3rem;
      color: v.$color0;

      input[type="checkbox"] {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #fff;
        border: 1px solid v.$color0;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 2px;
        color: v.$color0;
        vertical-align: middle;
        position: relative;
        top: -1px;
        transition: all 0.3s ease-out;

        &::after {
          content: "";
          width: 5px;
          height: 8px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          margin-top: -1px;
          opacity: 0;
          transition: all 0.3s ease-out;
        }

        &:checked {
          background: v.$color0;

          &::after {
            opacity: 1;
          }
        }
      }

      input[type="image"] {
        width: auto;
        background: transparent;
      }
    }

    .tx-red01 {
      color: red;
    }

    .mB10 {
      margin-bottom: 8px;
    }

    //btn margin
    .ten_key_wrapper + p + p {
      margin-bottom: 0;
    }

    //bottom bnr margin
    #loginFrm + p + p {
      margin-bottom: 0;
      line-height: 1;
    }

    //エラーチェック
    .bg-error {
      background-color: #ffebed;
    }
  }

  &-text {
    display: inline-block;
    width: 204px;
    height: 30px;
    background: #fff;
    border: 1px solid v.$color19;
    border-radius: 8px;
    font-size: 1.4rem;
    line-height: 28px;
    padding: 0 8px;
    margin-left: 28px;

    @include r.pc {
      &:focus {
        border: 2px solid #221f1f;
      }
    }
  }

  &-icon-calc {
    display: inline-block;
    margin: 0 0 2px 8px;
    vertical-align: bottom;
  }

  &-btn {
    margin: 12px 0;

    input {
      width: auto;
      background: transparent;
    }
  }

  &-logo {
    margin-top: 12px;
  }
}

.tenkey-box {
  position: absolute;
  display: none;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 14px 10px 10px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 4px 0.01px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 3;

  &.open {
    display: block;
  }

  &.mT5 {
    margin-top: -1px;
  }
}

#user-pass + a + .tenkey-box {
  margin-top: 0;
}

.list-tenkey {
  width: 244px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    float: left;
    width: 36px;
    padding-right: 4px;
    padding-bottom: 5px;
    list-style: none;

    &:nth-child(6n) {
      clear: right;
      margin-left: 4px;
      padding-right: 0;
    }
  }
}

.btn-tenkey {
  display: block;
  width: 36px;
  height: 0;
  padding-top: 36px;
  overflow: hidden;
  background-image: url(/shared/images/btn/btn-tenkey.png);
  background-repeat: no-repeat;
  transition-duration: 0s;
  cursor: pointer;

  &.btn-tenkey-1 {
    background-position: 0 0;

    &:active {
      background-position: 0 -80px;
    }
  }

  &.btn-tenkey-2 {
    background-position: -40px 0;

    &:active {
      background-position: -40px -80px;
    }
  }

  &.btn-tenkey-3 {
    background-position: -80px 0;

    &:active {
      background-position: -80px -80px;
    }
  }

  &.btn-tenkey-4 {
    background-position: -120px 0;

    &:active {
      background-position: -120px -80px;
    }
  }

  &.btn-tenkey-5 {
    background-position: -160px 0;

    &:active {
      background-position: -160px -80px;
    }
  }

  &.btn-tenkey-del {
    background-position: -200px 0;

    &:active {
      background-position: -200px -80px;
    }
  }

  &.btn-tenkey-clear {
    background-position: -240px 0;

    &:active {
      background-position: -240px -80px;
    }
  }

  &.btn-tenkey-6 {
    background-position: 0 -40px;

    &:active {
      background-position: 0 -120px;
    }
  }

  &.btn-tenkey-7 {
    background-position: -40px -40px;

    &:active {
      background-position: -40px -120px;
    }
  }

  &.btn-tenkey-8 {
    background-position: -80px -40px;

    &:active {
      background-position: -80px -120px;
    }
  }

  &.btn-tenkey-9 {
    background-position: -120px -40px;

    &:active {
      background-position: -120px -120px;
    }
  }

  &.btn-tenkey-0 {
    background-position: -160px -40px;

    &:active {
      background-position: -160px -120px;
    }
  }

  &.btn-tenkey-ent {
    background-position: -200px -40px;

    &:active {
      background-position: -200px -120px;
    }
  }
}

@include r.sp {
  //--sp tenkey
  /* モーダルコンテンツ指定 */
  .login-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    min-height: 100vh;
    /* Fallback */
    min-height: calc(var(--modalVh, 1vh) * 100);
    max-width: 100%;
    background-color: #fff;
    border-radius: 0;
    display: none;

    &.open {
      display: block;
    }

    .tx_red01 {
      color: #ff0000 !important;
      margin-bottom: 35px !important;
    }

    p.mB20 {
      margin-bottom: 0;
    }

    .ten_key_wrapper:nth-child(2) {
      margin-bottom: 15px;
    }
  }

  /* ログイン用モーダル背景指定 */
  .login-modal-content.login-box {
    text-align: center;
    background-color: #c2deef;
  }

  .login-modal-content .modal-header {
    img {
      margin: 0 auto 13px;
    }
  }

  /* モーダルウィンドウ×（閉じる）ボタン */
  .sp-login-btn-modal-close {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 34px;
    height: 34px;
    line-height: 30px;
    margin: 5px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #221f1f;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
  }

  /* ログイン SPAT4加入者番号・SPAT4利用者ID入力用 */
  .login-text {
    display: inline-block;
    width: 200px;
    height: 30px;
  }

  input#user-id {
    margin-bottom: 10px;
  }

  input#user-pass {
    margin-bottom: 10px;
  }

  /* テンキーアイコンボタン */
  .login-icon-calc {
    display: inline-block;
    margin: 0 0 4px 5px;
    vertical-align: middle;
  }

  .login-icon-calc img {
    width: 20px;
  }

  /* 次回から自動ログインするcheckbox縦位置調整用 */
  .login-box input[type="checkbox"] {
    display: inline-block;
    margin-right: 2px;
    vertical-align: middle;
  }

  button#do_login {
    margin: 10px auto 10px;
    width: 153px;
    background-color: #004ea2;
    color: #004ea2;
    border-radius: 30px;
    font-size: 1.4rem;
    background-color: #fff;
    border-radius: 999px;
    min-height: 21px;
    line-height: 21px;
    border: 1px solid #0d3180;
    box-shadow: 0 2px 0 #0d3180;
    background-color: #fff;
  }

  #loginFrm + p {
    margin-bottom: 10px;
  }

  /* テンキー表示Box */
  .tenkey-box {
    position: absolute;
    display: none;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    padding: 10px 5px 0 5px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 1px 4px 0.01px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 3;
    text-align: center;
  }

  .tenkey-box.open {
    display: block;
  }

  /* テンキーボタン配置用リスト */
  .list-tenkey {
    width: 306px;
    margin: 0 auto;
  }

  .list-tenkey li {
    float: left;
    width: 50px;
    padding-right: 4px;
    padding-bottom: 5px;
    list-style: none;
  }

  .list-tenkey li:nth-child(6n) {
    clear: right;
    margin-left: 4px;
    padding-right: 0;
  }

  /* テンキーボタン */
  .ten_key_wrapper {
    position: relative;
  }

  .btn-tenkey {
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-bottom: 10px;
    padding-top: 0;
    text-align: center;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 0px #c9c9c9;
    background: #fff;
    color: #000000 !important;
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
  }

  .btn-tenkey.btn-tenkey-del {
    background: #646464;
    color: #fff !important;
    border-color: #3f3f3f;
    box-shadow: 0px 2px 0px #3f3f3f;
    font-weight: 400;
  }

  .btn-tenkey.btn-tenkey-ent {
    text-indent: 100%;
    overflow: hidden;
    background-image: url(/shared/sp/images/btn-tenkey-ent.png);
    background-repeat: no-repeat;
    background-position: top center;
    border: 1px solid #0d3180;
    box-shadow: 0px 2px 0px #0d3180;
  }

  .btn-tenkey:active,
  .btn-tenkey:focus {
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) inset;
    background: -webkit-linear-gradient(top, #eaeaea 0%, #fff 100%);
    background: linear-gradient(to bottom, #eaeaea 0%, #fff 100%);
  }

  .btn-tenkey.btn-tenkey-del:active,
  .btn-tenkey.btn-tenkey-del:focus {
    background: #646464;
  }

  .btn-tenkey.btn-tenkey-ent:active,
  .btn-tenkey.btn-tenkey-ent:focus {
    background: url(../images/btn-tenkey-ent.png) no-repeat center center;
    background-image: url(/shared/sp/images/btn-tenkey-ent.png) !important;
    background-repeat: no-repeat;
    background-position: top center;
    background: #004ea2;
  }
}

.position-relative {
  position: relative;
}

.nk23_tl-mv {
  &__img {
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
}

.mawarilabel {
  // width: 100px;
  // height: 24px;
  // padding: 2px 5px 2px 5px;
  // border-radius: 6px;
  // border: #221f1f solid 1px;
  // text-align: center;
  // font-weight: bold;
  // margin-bottom: 16px !important;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-size: 1.3rem;
}

.contents750 {
  @include r.sp {
    width: 100% !important;
  }
}

#contents770b {
  background: none !important;
  padding-top: 0 !important;

  @include r.sp {
    width: 100% !important;
  }
}

#breadcrumbs {
  margin: 0 0 16px 0 !important;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      margin: 0 8px 0 0;
      list-style-type: none;
      float: none;
      font-size: 1.2rem;
      color: v.$color0;

      a,
      span {
        background-position-y: 2px;
        padding: 0 16px 0 0;
        color: #2461ee;
        margin: 0;
        background: url(/info/shared/images/divide-subnav.gif) no-repeat right;
        text-decoration: none;
        display: block;
        line-height: 1;
      }

      span {
        color: v.$color0;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

#contents770 {
  #breadcrumbs {
    @media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
      margin: 0 0 11px 0 !important;
    }
  }
}

#race-header550-2 {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0 16px;

  @include r.sp {
    padding: 16px 8px;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #221f1f;
    display: flex;
    align-items: center;

    .tx-small {
      font-weight: 400;
      margin-left: 16px;
      display: flex;
      align-items: center;

      @include r.sp {
        padding: 0;
      }

      // #race-header550-2 h3 .tx-small img .
      img {
        margin-left: 8px;
        width: 20px;
        display: inline-block;
      }
    }
  }
}

#contents-nw-550 {
  .tx-small {
    font-size: 1.2rem;
  }

  .nk23_c-title01 {
    margin-bottom: 8px;
  }

  .tl-h3 {
    font-size: 1.4rem;

    &:after {
      display: none;
    }
  }

  .tb01 {
    background: #fff;
    border-radius: 0 0 8px 8px;

    @include r.sp {
      border-radius: 0;
    }

    td {
      &[class*="bg-"] {
        font-weight: bold;
      }

      &.bd-bottom {
        border-bottom: 0;
      }
    }

    tr {
      td {
        &:nth-child(4) {
          text-align: left;
        }
      }

      th {
        &:nth-child(5),
        &:nth-child(6) {
          width: 56px;

          @include r.sp {
            width: 37px;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.boxNews {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  padding-bottom: 8px;

  .tl-topics {
    border-bottom: 1px solid v.$color19;
    padding: 2px 8px 2px 4px;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color0;
    font-weight: bold;

    img {
      display: inline-block;
      width: 16px;
    }
  }

  a {
    font-size: 1.3rem;
    line-height: 1.5;
    color: v.$color-link;

    @include r.hover {
      text-decoration: underline;
    }
  }

  .pR10 {
    padding-right: 8px;
  }

  > p {
    margin-bottom: 8px;
  }
}

.tl-main {
  text-align: center;
  margin-bottom: 16px;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;

  img {
    margin: 0 auto;
  }
}

.nk23-c-course_info {
  @include r.sp {
    padding: 8px 0 40px;
  }

  #contents {
    max-width: 960px;
    margin: 0 auto;
    font-size: 1.3rem;
    line-height: 1.6;
    color: v.$color0;

    @include r.sp {
      font-size: 1.6rem;
      padding: 0 8px !important;
    }
  }

  a {
    @include r.hover {
      opacity: 0.7;
    }
  }

  .tl-h3 {
    background: transparent;
    font-size: 1.4rem;
    line-height: 1.6;
    color: v.$color0;
    font-weight: bold;
    padding: 0;
    margin: 24px 0 8px;
  }

  #tab-select {
    margin-top: 8px;
  }

  #box-course-info {
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    padding: 16px;

    @include r.sp {
      border-radius: 8px;
      padding: 0;
    }

    h3 {
      font-size: 1.4rem;
      line-height: 1.72;
      color: v.$color0;
      font-weight: bold;
      margin-bottom: 12px;

      @include r.sp {
        display: none;
      }
    }
  }

  #box-park-course {
    background: #ffffff;
    width: 100% !important;
    float: none;
    @include r.clear;

    @include r.sp {
      padding: 8px 4px;
      border-radius: 0 0 8px 8px;
    }

    .course-table {
      width: 54.1%;
      margin: 0;
      float: right;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding-right: 32px;

      @include r.sp {
        width: 100%;
        float: none;
        padding-right: 0;
      }
    }

    .tb-course-info {
      max-width: calc(50% - 5px);
      margin: 0;

      @include r.sp {
        max-width: 100%;
        font-size: 1.6rem;
        border-radius: 8px;
      }

      &:not(:last-child) {
        @include r.sp {
          margin: 0 0 8px !important;
        }
      }

      th {
        padding: 2px 4px;

        @include r.sp {
          width: 130px;
          text-align: center;
          font-weight: normal;
        }
      }
    }

    h3 {
      @include r.sp {
        display: none;
      }
    }

    p {
      font-size: 1.3rem;
      line-height: 1.6;
      color: v.$color0;
      float: left;

      @include r.sp {
        font-size: 1.6rem;
      }
    }

    .clearfix {
      .clr15 {
        + p {
          margin-top: 16px;

          @include r.sp {
            margin-top: 0;
          }
        }
      }
    }

    + .title02 {
      @include r.sp {
        margin-top: 16px;
      }
    }

    .mawarilabel {
      width: 112px;
      margin-bottom: 16px !important;
      float: none;

      @include r.sp {
        width: 100px;
        height: 32px;
        line-height: 32px !important;
        margin-bottom: 8px !important;
      }
    }
  }

  .course-img {
    width: 44.8%;
    float: left;

    @include r.sp {
      width: 100%;
      float: none;
      margin-bottom: 8px;
    }

    img {
      border-radius: 4px;
    }
  }

  .tb01 {
    border-radius: 8px;
    overflow: hidden;
    font-size: 1.3rem;
    color: v.$color0;

    @include r.sp {
      border-radius: 0 0 8px 8px;
    }

    td {
      background: #fff;

      @include r.sp {
        min-width: 30px;
        font-size: 1.4rem;
      }

      &:first-child {
        @include r.sp {
          border-right: none;
          text-align: left;
        }
      }

      a {
        color: v.$color-link;
      }
    }

    .w190px {
      width: 190px;
    }

    .w160px {
      width: 160px;
    }

    .w140px {
      width: 140px;
    }

    .w130px {
      width: 130px;
    }

    .w80px {
      width: 80px;
    }

    .w60px {
      width: 60px;
    }

    .w40px {
      width: 40px;
    }

    .al_right {
      text-align: right !important;
    }

    .al_left {
      text-align: left !important;
    }

    .nowrap {
      white-space: nowrap;
    }

    tr {
      th {
        background: v.$color29;
        color: #fff;
        font-weight: 700;
        text-align: center;
      }

      &:first-child {
        th {
          min-width: 63px;
          box-sizing: border-box;

          @include r.sp {
            width: 80px;
            min-width: auto;
          }
        }
      }

      &:nth-child(2) {
        th {
          background: v.$color34;
          color: v.$color0;
        }
      }

      &:last-child {
        td {
          @include r.sp {
            border-bottom: none !important;
          }
        }
      }
    }
  }

  article {
    position: relative;

    .btn_title01 {
      position: absolute;
      top: 0;
      right: 8px;
      width: 143px;
    }
  }

  .title01 {
    @include r.sp {
      margin-bottom: 8px;
    }
  }

  .title02 {
    @include r.sp {
      background: #ffffff;
      border-radius: 8px 8px 0 0;
      padding: 4px;
      border-bottom: 1px solid v.$color19;
    }
  }
}

// page kijyoba_list,kanriba_list,kis_grade,cho_grade
.nk23_c-kba_list {
  width: 640px;
  margin: auto;

  @include r.sp {
    width: 100%;
  }

  .tl-main {
    margin-bottom: 16px;

    img {
      margin: auto;
      height: 23px;
    }
  }

  p {
    &:nth-child(2) {
      &.tx-small {
        .bl-right {
          @include r.sp {
            display: block;
            float: none;
          }
        }
      }
    }

    &:nth-child(4) {
      @include r.sp {
        padding: 0 8px 8px;
      }
    }
  }

  &.is-style1 {
    .tb01 {
      background: transparent !important;

      @include r.sp {
        border-radius: 0 0 8px 8px;
      }

      tr {
        &:first-child {
          th {
            border-top: 0;

            &:first-child {
              border-radius: 8px 0 0 0;
            }

            &:last-child {
              border-radius: 0 8px 0 0;
            }
          }
        }

        &:last-child {
          td {
            border-top: 0;

            &:first-child {
              border-radius: 0 0 0 8px;
            }

            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }
        }

        td {
          background: #fff;

          @include r.sp {
            padding: 16px 4px;
          }
        }

        th {
          &:nth-child(2) {
            @include r.sp {
              width: 50px;
            }
          }

          &:nth-child(4) {
            @include r.sp {
              width: 125px;
            }
          }
        }
      }
    }
  }
}

.bg-1 {
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-2 {
  background: #221f1f;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-3 {
  background: #e63434;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-4 {
  background: #3570e6;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-5 {
  background: #ffd83e;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-6 {
  background: #4ba642;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-7 {
  background: #f59331;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.bg-8 {
  background: #eb6ad5;
  color: #fff;
  text-shadow: 0 0 4px rgba(34, 31, 31, 0.25);
}

.tb01c {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.3rem;
  margin-top: 14px;

  tr {
    border-top: 1px #c9c9c9 solid;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }

    th {
      padding: 4px;
      background: #404040;
      color: #fff;
      font-weight: 700;
      line-height: 1.2;

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }

    td {
      background: #fff;
      padding: 4px;

      &:nth-child(1) {
        img {
          margin: auto;
          width: 36px;

          &.fnb {
            width: 28px;
          }
        }
      }

      &:nth-child(2) {
        padding: 4px 24px;
      }

      a {
        color: #2461ee;

        &:hover {
          text-decoration: underline;
        }

        img {
          display: inline-block;
        }
      }
    }
  }
}

.mR5 {
  margin-right: 5px;
}

.ls-news-blog {
  padding: 4px 8px;

  li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 4px 0;
    list-style-type: none;
    font-size: 1.3rem;
    line-height: 1;
    color: v.$color0;

    @include r.sp {
      display: block;
      position: relative;
    }

    &:not(:last-child) {
      border-bottom: 1px solid v.$color19;
    }

    em {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 125px;
      font-style: normal;
      min-height: 18px;

      img {
        width: 30px;
        margin: 2px 8px 0 0;
        padding: 0;
        vertical-align: middle;
      }
    }

    span {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex: 1;

      @include r.sp {
        display: block;
        margin-top: 6px;
      }

      img {
        width: 38px;
        margin: 2px 10px 0 0;
        padding: 0;
        vertical-align: middle;

        @include r.sp {
          position: absolute;
          top: 5px;
          margin: 0;
          left: 125px;
        }
      }

      a {
        flex: 1;
        color: v.$color-link;

        @include r.hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include r.sp {
  #disp-chg-pc {
    display: none;
  }
}

#disp-chg-sp {
  display: none;

  @include r.sp {
    display: block;
    overflow: auto;
    width: 100%;
    position: relative;
  }

  .tb01 {
    @include r.sp {
      min-width: 767px;
    }
  }
}

// css new page kis_info,uma_info
.nk23_c-kis-info {
  padding: 16px 16px 40px;

  @include r.sp {
    padding: 16px 0 60px;
  }

  p {
    &.bl-left.mL10 {
      font-size: 1.3rem;
      line-height: 1.9rem;

      @include r.sp {
        padding: 0 8px;
      }
    }

    &.tx-small {
      padding-left: 10px;
      text-indent: -10px;
      font-size: 1.2rem;
      line-height: 1.9rem;

      @include r.sp {
        text-indent: -23px;
        padding: 8px 16px 0 30px;
      }
    }
  }

  .tl-h3 {
    max-width: 1200px;
    margin: auto;
  }

  .table-wrapper {
    max-width: 1200px;
    margin: auto;
  }

  .tx-small.tx-gray01 {
    max-width: 1200px;
    margin: auto;
  }

  .stripe {
    thead {
      tr {
        th {
          word-break: keep-all;
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td {
          word-break: keep-all;
          white-space: nowrap;

          &:nth-child(4) {
            word-break: break-all;
            white-space: normal;
          }
        }
      }
    }
  }
}

.round-cnr {
  border-radius: 8px !important;
  padding: 4px 8px;
  font-size: 1.3rem;
  @include r.clear;
  margin: 8px auto !important;

  @include r.sp {
    margin: 0 auto !important;
  }

  .odds-pagination ul li.current {
    font-weight: normal;
  }

  &.resp {
    background: #fff;
    border-top: 1px solid #c9c9c9;
    padding: 16px;
    border-radius: 0 !important;

    &:nth-child(2) {
      @include r.sp {
        border-top: 0;
      }
    }
  }
}

.odds-pagination {
  margin: 0;
  padding: 0;
  color: #221f1f;

  ul {
    @include r.clear;

    li {
      margin: 0 5px 0 0;
      padding: 0;
      float: left;
      list-style-type: none;

      &.crt-page a {
        color: #221f1f;
        text-decoration: none;
      }

      &.current {
        font-weight: 700;
      }

      a {
        color: #3570e6;
        padding-right: 5px;

        &:focus-visible {
          border: none;
          outline: none;
          box-shadow: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &.next {
        a {
          position: relative;
          padding-right: 15px;

          &:after {
            content: "";
            width: 6px;
            height: 10px;
            font-size: 0;
            background: url(p.$path + "images/common/icon/icon_arrow_right_blue.svg") no-repeat center / 6px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
        }
      }

      &.prev {
        a {
          position: relative;
          padding-left: 15px;

          &:after {
            content: "";
            width: 6px;
            height: 10px;
            font-size: 0;
            background: url(p.$path + "images/common/icon/icon_arrow_left_blue.svg") no-repeat center / 6px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
}

.twoColEq_L {
  width: calc(50% - 10px);
  float: left;

  @include r.sp {
    width: 100%;
    float: none;
  }
}

.twoColEq_R {
  width: calc(50% - 10px);
  float: right;

  @include r.sp {
    width: 100%;
    float: none;
  }
}

.boxNews380 {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 12px;
  margin-bottom: 16px;

  @include r.sp {
    padding-bottom: 8px;
  }

  h3 {
    border-bottom: 1px solid v.$color19;
    padding: 2px 10px 2px 4px;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color0;
    font-weight: bold;

    img {
      display: inline-block;
    }

    &.tl-news-urawa {
      border-color: v.$urawa-color;
    }

    &.tl-news-funabashi {
      border-color: v.$funabashi-color;
    }

    &.tl-news-ooi {
      border-color: v.$ooi-color;
    }

    &.tl-news-kawasaki {
      border-color: v.$kawasaki-color;
    }
  }

  a {
    font-size: 1.3rem;
    line-height: 1.6;
    color: v.$color-link;

    @include r.hover {
      text-decoration: underline;
    }
  }

  .pR10 {
    padding-right: 8px;
  }

  ul {
    padding: 4px 8px;

    li {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 4px 0;
      list-style-type: none;
      font-size: 1.3rem;
      line-height: 1.6;
      color: v.$color0;

      @include r.sp {
        display: block;
        position: relative;
      }

      &:not(:last-child) {
        border-bottom: 1px solid v.$color19;
      }

      em {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        width: 80px;
        font-style: normal;
        position: relative;
        top: 2px;

        @include r.sp {
          top: 0;
        }
      }

      span {
        flex: 1;

        @include r.sp {
          display: block;
          margin-top: 6px;
        }

        img {
          display: inline-block;
          width: 38px;
          margin: 0 10px 0 0;
          padding: 0;
          vertical-align: middle;

          @include r.sp {
            position: absolute;
            top: 5px;
            margin: 0;
            left: 80px;
          }
        }

        a {
          display: inline;
          color: v.$color-link;

          @include r.hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.ls-news {
  background: #ffffff;
  border-radius: 8px;
  padding: 0 8px;

  li {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.3rem;
    color: v.$color0;
    padding: 4px 0;

    &:not(:last-child) {
      border-bottom: 1px solid v.$color19;
    }

    em {
      width: 90px;
      font-style: normal;
    }

    span {
      flex: 1;
    }

    a {
      color: v.$color-link;

      @include r.hover {
        text-decoration: underline;
      }
    }
  }
}

.bg-fff {
  background: #fff;
}

.tx-gray01 {
  color: v.$color0;
}

.list-nom {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      &:not(:last-child) {
        margin-right: 12px;
      }

      a {
        color: v.$color-link;

        @include r.hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.nk23_c-list_news {
  @include r.sp {
    padding: 8px 8px 0;
  }

  #contents {
    max-width: 960px;
    margin: 0 auto;
  }

  #contents770 {
    width: 100%;
    margin: 0 !important;
  }

  .tl-news {
    background: #fff;
    border-radius: 8px;
    font-size: 1.4rem;
    color: v.$color0;
    font-weight: bold;
    padding: 6px 4px;
    margin-top: 8px;

    img {
      display: inline-block;
      margin-right: 4px;
      margin-top: -2px;
    }
  }

  .tl-news-pst {
    border-radius: 8px 8px 0 0;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 6px 4px;
    background: #fff;
    border-bottom: 1px solid v.$color19;

    img {
      display: inline-block;
      margin-right: 4px;
      margin-top: -2px;
    }
  }

  .ls-news {
    margin-top: 16px;
  }

  .filter {
    margin-bottom: 8px;
  }

  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .title02 {
    @include r.sp {
      background: #fff;
      border-radius: 8px 8px 0 0;
      border-bottom: 1px solid v.$color19;
      padding: 4px;
    }
  }
}

.bg-ffc {
  background: #ffffcc;
}

.bg-cff {
  background: #ccffff;
}

.mT5 {
  margin-top: 5px;
}

.tb-sell-fcl {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  width: 100%;
  font-size: 1.3rem;
  border: none !important;
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    border-bottom: 1px solid v.$color19;

    th {
      font-weight: 700;
      line-height: 1.49;
      background: v.$color29;
      color: #fff;
      border-top: 1px solid v.$color19;
      border-bottom: 1px solid v.$color19;

      &:first-child {
        border-left: 1px solid v.$color19;
      }

      &:last-child {
        border-right: 1px solid v.$color19;
      }

      &:nth-child(1) {
        width: 1%;
        min-width: 330px;
      }

      &:nth-child(2) {
        width: 1%;
        min-width: 110px;
      }

      &:nth-child(3) {
        width: 1%;
        min-width: 145px;
      }
    }

    td {
      background-color: #fff;
      border-bottom: 1px solid v.$color19;

      &:first-child {
        border-left: 1px solid v.$color19;
      }

      &:last-child {
        border-right: 1px solid v.$color19;
      }
    }
  }

  th,
  td {
    padding: 4px 5px;

    &:not(:last-child) {
      border-right: 1px solid v.$color19;
    }
  }

  tr {
    &:last-child {
      td {
        &:first-child {
          @include r.pc {
            border-bottom-left-radius: 8px;
          }
        }

        &:last-child {
          @include r.pc {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }

  a {
    @include r.hover {
      text-decoration: underline;
    }
  }
}

#noneMsg {
  p {
    margin-bottom: 0;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-weight: 700;
    text-align: center;
    display: block;
  }
}

#box-search {
  padding: 16px 8px;
  border-radius: 8px;
  display: flex;
  background-color: #fff;
  font-size: 1.3rem;
  line-height: 2.2rem;
  margin-top: 16px;

  #jyuSelect {
    margin-right: 4px;
    width: 110px;
    height: 23px;
    display: block;

    @include r.hover {
      // transform: translateY(2px);
      // box-shadow: none;
    }
  }

  .bl-left {
    white-space: nowrap;
    display: flex;
    float: none;
    width: max-content;
    max-width: 50%;
    align-items: center;

    img {
      display: block;
      height: 14px;

      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }

  // #box-search select .
  select {
    min-width: 53px;
    padding: 0 16px 0 4px;
    margin: 0 4px;
    border-radius: 4px;
    line-height: 17px;
    border: 1px solid v.$color19;
    background: url(p.$path + "images/common/icon/icon_down.svg") no-repeat center right 4px / 10px auto;

    &#keibajo {
      min-width: 63px;
    }

    &#order {
      min-width: 76px;
    }
  }

  input[type="button"] {
    padding: 0 6px;
    margin-left: 8px;
    height: 23px;
    display: inline-block;
    border-radius: 24px;
    border: 1px solid v.$color19;
    box-shadow: 0 2px v.$color19;
    width: max-content;
    cursor: pointer;

    @include r.hover {
      transform: translateY(2px);
      box-shadow: none;
    }
  }
}

.tl-main-txt {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
}

.checkBoxes {
  border-radius: 2px;
  border: 1px solid v.$color0 !important;
  width: 13px;
  height: 13px;
  background-color: #fff;

  &:checked {
    background: v.$color0 url(p.$path + "images/common/icon/icon_checked-white.svg") no-repeat center / 7px auto;
  }
}

.twoCol250_R {
  tr {
    &:last-child {
      td {
        padding-bottom: 16px;
      }
    }
  }
}

.clr5 {
  margin: 0;
  padding: 0;
  clear: both;
  font-size: 0;
  line-height: 5px;
  height: 5px;
}

.clr10 {
  margin: 0;
  padding: 0;
  clear: both;
  font-size: 0;
  line-height: 10px;
  height: 10px;
}

.line-dot2 {
  border-top: 1px solid v.$color19;
}

.lst-schedule {
  background: #fff;
  padding: 8px;
  border-radius: 0 0 8px 8px;

  .list-nom {
    margin-top: 8px;
  }
}

.navLink {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;

  li {
    width: calc(20% - 8px);
    background: #fff;
    border-radius: 8px;
    border: 1px solid v.$color19;
    box-shadow: 0 2px 0 v.$color19;
    box-sizing: border-box;
    text-align: center;

    a {
      display: block;
      font-size: 1.2rem;
      line-height: 1.5;
      color: v.$color0;
      font-weight: bold;
      padding: 4px 0 2px;

      @include r.max-w(374px) {
        font-size: 1rem;
      }

      &::before {
        content: "";
        width: 39px;
        height: 30px;
        display: block;
        margin: 0 auto;
        border-radius: 4px;
        overflow: hidden;
      }

      span {
        display: block;
      }
    }

    &.crt {
      box-shadow: none;

      a {
        color: #fff;
        padding: 4px 0;
      }
    }

    &.nav00 {
      a {
        &::before {
          background: url(p.$path + "images/common/newsNav00.png") no-repeat center / cover;
        }
      }

      &.crt {
        background: v.$color0;
      }
    }

    &.nav18 {
      a {
        &::before {
          background: url(p.$path + "images/common/ico-urw-outline.svg") no-repeat center / contain;
        }
      }

      &.crt {
        background: v.$urawa-color;
        border-color: v.$color2;
      }
    }

    &.nav19 {
      a {
        &::before {
          background: url(p.$path + "images/common/ico-fnb-outline.svg") no-repeat center / contain;
        }
      }

      &.crt {
        background: v.$funabashi-color;
        border-color: v.$color5;
      }
    }

    &.nav20 {
      a {
        &::before {
          background: url(p.$path + "images/common/ico-tck-outline.svg") no-repeat center / contain;
        }
      }

      &.crt {
        background: v.$ooi-color;
        border-color: v.$color8;
      }
    }

    &.nav21 {
      a {
        &::before {
          background: url(p.$path + "images/common/ico-kws-outline.svg") no-repeat center / contain;
        }
      }

      &.crt {
        background: v.$kawasaki-color;
        border-color: v.$color11;
      }
    }
  }
}

.filter {
  font-size: 1.6rem;
  line-height: 1.5;
  color: v.$color0;

  select {
    background:
      url(p.$path + "images/common/icon/icon_arrow_black.svg") no-repeat center right 8px / 8px 4px,
      #fff;
    border: 1px solid v.$color19;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px 24px 8px 4px;

    &::placeholder {
      color: inherit;
    }

    &[name="month"] {
      min-width: 57px;
    }

    &[name="year"] {
      min-width: 78px;
    }
  }

  .btn_disp {
    width: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid v.$color19;
    border-radius: 24px;
    box-shadow: 0 2px 0 v.$color19;
    box-sizing: border-box;
    padding: 10px 4px;
    margin-left: 4px;
  }
}

.list_news {
  background: #fff;
  border-radius: 0 0 8px 8px;

  li {
    &:not(:last-child) {
      border-bottom: 1px solid v.$color19;
    }

    a {
      display: block;
      font-size: 1.4rem;
      line-height: 1.5;
      color: v.$color-link;
      padding: 4px 16px 4px 4px;
      position: relative;

      &::after {
        content: "";
        width: 5px;
        height: 9px;
        background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center / cover;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translate(0, -50%);
      }

      span {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1.5;
        color: v.$color0;
        margin-bottom: 4px;
      }

      em {
        font-style: normal;
      }
    }

    .ico_news {
      &::before {
        content: "NEWS";
        display: block;
        text-align: center;
        width: 48px;
        border: 1px solid v.$color0;
        border-radius: 4px;
        font-size: 8px;
        line-height: 1.5;
        color: v.$color0;
        font-weight: bold;
        padding: 2px;
        margin-right: 4px;
      }
    }
  }
}

.nk23-c_kis_detail_search {
  @include r.sp {
    padding: 8px 0 0;
  }

  #contents {
    max-width: 960px;
    margin: 0 auto;

    @include r.sp {
      padding: 8px 8px 40px;
    }
  }

  .tb01 {
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    td {
      text-align: left;
    }

    input[type="text"],
    input[type="number"] {
      border-radius: 8px;
    }

    input[type="checkbox"] {
      border-radius: 4px;
      position: relative;
      top: 1px;
    }

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        th {
          border-bottom: none;
        }
      }
    }
  }

  .al-center {
    width: 100%;
    margin-top: 16px;

    input {
      width: 110px;
      background: transparent;
      margin: 0 2px;
    }
  }

  p {
    font-size: 1.3rem;
    line-height: 1.7;
    color: v.$color0;
  }

  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .title02 {
    background: #fff;
    border-radius: 8px 8px 0 0;
    padding: 4px;
    border-bottom: 1px solid v.$color19;
  }

  form {
    background: #fff;
    border-radius: 0 0 8px 8px;

    @include r.sp {
      padding: 8px 4px 32px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.68;
      color: v.$color0;
      font-weight: bold;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .list_inline {
    li {
      width: auto;
      margin-right: 12px;
    }
  }

  .al_center {
    .list_inline {
      justify-content: center;
    }
  }

  .searchform_area input[type="checkbox"] {
    @include r.sp {
      position: relative;
      top: 4px;
      margin-right: 2px;
    }
  }

  .detail_search_note {
    @include r.sp {
      font-size: 1.3rem;
      line-height: 1.7;
      margin-top: 8px;
    }
  }

  .detail_search {
    &_box {
      margin-bottom: 8px;
    }

    &_text {
      margin-top: 12px;
    }
  }
}

.nk23-c_kis_detail_search_result {
  @include r.sp {
    padding: 8px 0 0;
  }

  #contents {
    max-width: 1200px;
    margin: 0 auto;

    @include r.sp {
      padding: 16px 8px 40px;
    }
  }

  .tl-main,
  #tab-select,
  .al-center,
  form,
  p,
  .round-cnr {
    max-width: 960px;
    margin: 0 auto;
  }

  .round-cnr {
    border-radius: 8px !important;
    padding: 4px 8px;
    font-size: 1.3rem;
    @include r.clear;
    margin: 8px auto;
  }

  .tl-main {
    margin-bottom: 16px;
  }

  .tb01 {
    @include r.pc {
      overflow: hidden;
      border-radius: 8px;
    }

    & + .round-cnr {
      margin-top: 24px;
    }

    th {
      &:nth-child(2) {
        @include r.sp {
          min-width: 40px;
        }
      }

      &:nth-child(3) {
        width: 1%;
        min-width: 70px;

        @include r.sp {
          min-width: 40px;
        }
      }

      &:nth-child(4) {
        width: 1%;
        min-width: 70px;

        @include r.sp {
          min-width: 50px;
        }
      }
    }

    td {
      text-align: left;

      @include r.sp {
        text-align: center;
      }

      &:first-child {
        @include r.sp {
          text-align: left;
        }
      }
    }

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        th {
          border-bottom: none;
        }
      }
    }

    .btn-style-reset {
      margin: 0 auto;
      display: block;
    }
  }

  .al-center {
    width: 100%;
    margin-top: 16px;

    input {
      width: 110px;
      background: transparent;
      margin: 0 2px;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 1.58;
    color: v.$color0;
    font-weight: 700;
  }

  .bg-2line-btm {
    margin-top: 16px;

    span {
      font-size: 1.8rem;
    }
  }

  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .title02 {
    background: #fff;
    border-radius: 8px 8px 0 0;
    padding: 8px 4px 4px 4px;
    border-bottom: 1px solid v.$color19;

    @include r.pc {
      margin-top: 8px;
    }

    span {
      @include r.sp {
        font-size: 1.3rem;
        font-weight: normal;
      }

      &.all {
        @include r.sp {
          font-weight: bold;
        }
      }
    }
  }

  form {
    background: #fff;
    border-radius: 0 0 8px 8px;

    @include r.sp {
      padding: 8px 4px 32px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.68;
      color: v.$color0;
      font-weight: bold;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .tb01 {
      border-radius: 0 0 8px 8px;
    }
  }

  .list_inline {
    li {
      width: auto;
      margin-right: 12px;
    }
  }

  .al_center {
    .list_inline {
      justify-content: center;

      @include r.sp {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .searchform_area input[type="checkbox"] {
    @include r.sp {
      position: relative;
      top: 4px;
      margin-right: 2px;
    }
  }

  .detail_search_note {
    @include r.sp {
      font-size: 1.3rem;
      line-height: 1.7;
      margin-top: 8px;
    }
  }

  .detail_search {
    &_box {
      margin-bottom: 8px;
    }

    &_text {
      margin-top: 12px;
    }
  }

  .scroll_div {
    border-radius: 0;

    .tb01 {
      @include r.sp {
        min-width: auto;
      }
    }
  }
}

.list_inline {
  display: flex;
  flex-wrap: wrap;
  max-width: 572px;

  li {
    width: 60px;
    margin-right: 4px;
  }

  label {
    @include r.sp {
      font-size: 1.2rem;
    }
  }
}

.detail_search {
  $root: &;

  &_box {
    background: #fff;
    border-radius: 8px;

    .title02 {
      margin-top: 0 !important;
      padding: 4px;
      border-bottom: 1px solid #c9c9c9;
      border-radius: 8px 8px 0 0;
    }

    #{$root}_text {
      margin: 0;
    }
  }

  &_content {
    padding: 4px;
  }

  &_text {
    font-size: 1.6rem !important;
    line-height: 1.5 !important;
    color: v.$color0;
    font-weight: bold;

    @include r.sp {
      line-height: 1.25 !important;
    }
  }

  &_note {
    @include r.sp {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}

.searchform_area {
  padding: 16px 8px 32px;

  h3 {
    @include r.sp {
      margin-bottom: 4px;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1;
    }

    & + .list_inline {
      @include r.sp {
        margin-top: 8px;
      }
    }
  }

  .uma,
  .term,
  .range,
  .distance {
    @include r.sp {
      margin-bottom: 16px;
    }
  }

  .distance {
    @include r.sp {
      margin-top: 24px;
    }
  }

  .baba,
  .grade,
  .class,
  .keibajyou {
    @include r.sp {
      margin-bottom: 8px;
    }
  }

  .range {
    @include r.sp {
      margin-bottom: 2px;
    }
  }

  input {
    &[type="text"] {
      @include r.sp {
        padding: 4px;
        height: 33px;
        max-width: 100%;
        font-size: 1.3rem;
      }
    }

    &[type="checkbox"] {
      @include r.sp {
        border-radius: 4px;
        border: 1px solid v.$color0 !important;
        width: 20px;
        height: 20px;
        background-color: #fff;
      }

      &:checked {
        @include r.sp {
          background: v.$color0 url(p.$path + "images/common/icon/icon_checked-white.svg") no-repeat center / 12px auto;
        }
      }
    }

    // .searchform_area input[type=button] .
    &[type="button"] {
      border-radius: 999px;
      height: 33px;
      width: 138px;
      border: 1px solid v.$color19;
      box-shadow: 0 2px v.$color19;
      background-color: #fff;
      font-size: 1.2rem;
      line-height: 1;

      @include r.w374 {
        width: 120px;
      }
    }
  }

  .list_inline {
    input[type="button"] {
      &:not(:last-child) {
        @include r.sp {
          margin-right: 8px;
        }
      }
    }
  }

  input[type="text"],
  input[type="tel"],
  select {
    border: 1px solid v.$color19;
    border-radius: 8px;

    @include r.sp {
      height: 33px;
      border-radius: 4px;
    }
  }

  input[type="text"],
  input[type="tel"] {
    padding: 4px;
  }

  input[type="tel"] {
    @include r.sp {
      width: 68px;
    }

    &.w80px {
      @include r.sp {
        width: 80px;
      }
    }
  }

  select {
    @include r.sp {
      padding-left: 4px;
      font-size: 1.3rem;
      max-width: 145px;
      width: 100%;
      height: 34px;
      background: #fff url(p.$path + "images/common/icon/icon_down.svg") no-repeat center right 7px / 10px auto;
    }

    &:not(:first-child) {
      @include r.sp {
        margin-left: 4px;
      }
    }
  }
}

.list_btn_square {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-end;
  gap: 0 2px;
  border-bottom: 1px solid v.$color0;
  padding: 0 1px;

  li {
    a {
      background: #fff;
      display: block;
      border-radius: 8px 8px 0 0;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.45;
      color: v.$color0;
      padding: 4px;
    }

    &.crt {
      a {
        background: v.$color0;
        color: #fff;
        font-weight: bold;
        padding: 8px 4px;
      }
    }
  }
}

.pagination {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 4px;
  border-radius: 0 0 8px 8px;

  li {
    margin: 0 7px;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      position: relative;
      display: inline-block;
      width: 1px;
      height: 13px;
      margin-left: 12px;
      background: #c9c9c9;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    a {
      display: block;
      font-size: 1.4rem;
      line-height: 1.5;
      color: v.$color-link;

      &:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &.active {
        font-weight: bold;
        color: v.$color0;
      }
    }

    span {
      &.active {
        font-weight: bold;
        color: v.$color0;
        display: block;
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }

    &.next {
      background: #ffffff;
      margin-left: -10px;

      a {
        width: 54px;
        height: 20px;
        font-size: 0;
        position: relative;
        padding-left: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::before {
          content: "次へ";
          position: relative;
          display: inline;
          color: v.$color-link;
          font-size: 1.4rem;
        }

        &::after {
          content: "";
          position: relative;
          display: inline;
          width: 16px;
          height: 26px;
          background: url(p.$path + "images/common/icon/icon_arrow_right_blue.svg") no-repeat center center / 6px;
        }
      }
    }

    &.prev {
      a {
        width: 20px;
        height: 20px;
        font-size: 0;
        background: url(p.$path + "images/common/icon/icon_left1.svg") no-repeat center top 7px / 5px;
      }
    }
  }
}

.nk23-c_cho_detail_search {
  @include r.sp {
    padding: 8px 0 0;
  }

  #contents {
    max-width: 960px;
    margin: 0 auto;

    @include r.sp {
      padding: 8px 8px 40px;
    }
  }

  .tb01 {
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    td {
      text-align: left;
    }

    input[type="text"],
    input[type="number"] {
      border-radius: 8px;
    }

    input[type="checkbox"] {
      border-radius: 4px;
      position: relative;
      top: 1px;
    }

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        th {
          border-bottom: none;
        }
      }
    }
  }

  .al-center {
    width: 100%;
    margin-top: 16px;

    input {
      width: 110px;
      background: transparent;
      margin: 0 2px;
    }
  }

  p {
    font-size: 1.3rem;
    line-height: 1.7;
    color: v.$color0;
  }

  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .title02 {
    background: #fff;
    border-radius: 8px 8px 0 0;
    padding: 4px;
    border-bottom: 1px solid v.$color19;
  }

  form {
    background: #fff;
    border-radius: 0 0 8px 8px;

    @include r.sp {
      padding: 8px 4px 32px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.68;
      color: v.$color0;
      font-weight: bold;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .list_inline {
    li {
      width: auto;
      margin-right: 12px;
    }
  }

  .al_center {
    .list_inline {
      justify-content: center;
    }
  }

  .searchform_area input[type="checkbox"] {
    @include r.sp {
      position: relative;
      top: 4px;
      margin-right: 2px;
    }
  }

  .detail_search_note {
    @include r.sp {
      font-size: 1.3rem;
      line-height: 1.7;
      margin-top: 8px;
    }
  }

  .detail_search {
    &_box {
      margin-bottom: 8px;
    }

    &_text {
      margin-top: 12px;
      font-size: 1.8rem !important;
      line-height: 1.35 !important;

      @include r.sp {
        margin-top: 0;
      }
    }
  }
}

.nk23-c_cho_detail_search_result {
  @include r.sp {
    padding: 8px 0 0;

    .title02 {
      margin-top: 16px;
    }
  }

  #contents {
    max-width: 1200px;
    margin: 0 auto;

    @include r.sp {
      padding: 16px 8px 40px;
    }
  }

  .tl-main,
  #tab-select,
  .al-center,
  form,
  p,
  .round-cnr {
    max-width: 960px;
    margin: 0 auto;
  }

  .round-cnr {
    border-radius: 8px !important;
    padding: 4px 8px;
    font-size: 1.3rem;
    @include r.clear;
    margin: 8px auto;
  }

  .tl-main {
    margin-bottom: 16px;
  }

  .tb01 {
    @include r.pc {
      overflow: hidden;
      border-radius: 8px;
    }

    & + .round-cnr {
      margin-top: 24px;
    }

    th {
      &:nth-child(2) {
        @include r.sp {
          min-width: 40px;
        }
      }

      &:nth-child(3) {
        width: 1%;
        min-width: 70px;

        @include r.sp {
          min-width: 40px;
        }
      }

      &:nth-child(4) {
        width: 1%;
        min-width: 70px;

        @include r.sp {
          min-width: 100px;
        }
      }
    }

    td {
      text-align: left;

      @include r.sp {
        text-align: center;
      }

      &:first-child {
        @include r.sp {
          text-align: left;
        }
      }
    }

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        th {
          border-bottom: none;
        }
      }
    }

    .btn-style-reset {
      margin: 0 auto;
      display: block;
    }
  }

  .al-center {
    width: 100%;
    margin-top: 16px;

    input {
      width: 110px;
      background: transparent;
      margin: 0 2px;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 1.58;
    color: v.$color0;
    font-weight: 700;
  }

  .bg-2line-btm {
    margin-top: 24px;

    span {
      font-size: 1.8rem;
    }
  }

  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .title02 {
    background: #fff;
    border-radius: 8px 8px 0 0;
    padding: 8px 4px 4px 4px;
    border-bottom: 1px solid v.$color19;

    @include r.pc {
      margin-top: 8px;
    }

    span {
      @include r.sp {
        font-size: 1.3rem;
        font-weight: normal;
      }

      &.all {
        @include r.sp {
          font-weight: bold;
        }
      }
    }
  }

  form {
    background: #fff;
    border-radius: 0 0 8px 8px;

    @include r.sp {
      padding: 8px 4px 32px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.68;
      color: v.$color0;
      font-weight: bold;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .tb01 {
      border-radius: 0 0 8px 8px;
    }
  }

  .list_inline {
    li {
      width: auto;
      margin-right: 12px;

      &.w40pr {
        margin: 0 6px;
      }
    }
  }

  .al_center {
    .list_inline {
      justify-content: center;
    }
  }

  .searchform_area input[type="checkbox"] {
    @include r.sp {
      position: relative;
      top: 4px;
      margin-right: 2px;
    }
  }

  .detail_search_note {
    @include r.sp {
      font-size: 1.3rem;
      line-height: 1.7;
      margin-top: 8px;
    }
  }

  .detail_search {
    &_box {
      margin-bottom: 8px;
    }

    &_text {
      margin-top: 12px;
    }
  }

  .scroll_div {
    border-radius: 0;

    .tb01 {
      @include r.sp {
        min-width: auto;
      }
    }
  }
}

// page uma_detail_search,uma_detail_search_result
.nk23-c_uma_detail_search_result {
  @include r.sp {
    padding: 8px 0 0;

    form {
      .other {
        .list_inline {
          li {
            margin-right: 8px;
            margin-bottom: 4px;

            label {
              white-space: nowrap;
              word-break: keep-all;
              margin-right: -4px;
            }
          }
        }
      }
    }
  }

  #contents {
    max-width: 1200px;
    margin: 0 auto;

    @include r.sp {
      padding: 16px 8px 40px;
    }
  }

  .tl-main,
  #tab-select,
  .al-center,
  form,
  p,
  .round-cnr {
    max-width: 960px;
    margin: 0 auto;
  }

  .tl-main {
    margin-bottom: 16px;
  }

  .tb01 {
    @include r.pc {
      overflow: hidden;
      border-radius: 8px;

      &.detail_search_cdn {
        border-radius: 0 0 8px 8px;
      }
    }

    & + .round-cnr {
      margin-top: 24px;
    }

    td {
      text-align: left;

      @include r.sp {
        text-align: center;
      }

      &:first-child {
        @include r.sp {
          text-align: left;
        }
      }

      &.b2Left {
        @include r.sp {
          text-align: left;
          padding: 8px 16px;
        }
      }

      label {
        margin-right: 12px;
      }
    }

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        th {
          border-bottom: none;

          @include r.sp {
            border-bottom: 1px solid #c9c9c9;
          }
        }
      }
    }

    .btn-style-reset {
      margin: 0 auto;
      display: block;
    }

    select {
      display: inline-block;
      width: 78px;
      background:
        url(p.$path + "images/common/icon/icon_down.svg") no-repeat center right 8px / 10px,
        #fff;
      border: 1px solid v.$color19;
      border-radius: 4px;
      font-size: 1.3rem;
      line-height: 1.5;
      color: v.$color0;
      padding: 1px 16px 1px 4px;
    }

    input[type="number"] {
      &[size="10"] {
        width: 144px;
      }
    }

    &.stripe {
      &.stripe {
        th {
          &:nth-child(2) {
            width: 79px;

            @include r.sp {
              min-width: 40px;
            }
          }

          &:nth-child(3) {
            width: 68px;

            @include r.sp {
              min-width: 40px;
            }
          }

          &:nth-child(4) {
            // width: 1%;
            @include r.sp {
              min-width: 100px;
            }
          }

          &:nth-child(5) {
            width: 160px;
          }
        }

        .w80px {
          width: 80px;

          a {
            display: block;
            width: 60px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .al-center {
    width: 100%;
    margin-top: 16px;

    input {
      width: 110px;
      background: transparent;
      margin: 0 2px;
    }
  }

  p {
    font-size: 1.1rem;
    line-height: 1.58;
    color: v.$color0;
    font-weight: 700;
  }

  .bg-2line-btm {
    font-size: 1.3rem;
    margin-top: 8px;
    color: #333333;

    span {
      font-size: 1.8rem;
    }
  }

  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .title02 {
    background: #fff;
    border-radius: 8px 8px 0 0;
    padding: 8px 4px 4px 4px;
    border-bottom: 1px solid v.$color19;
    margin-top: 8px;

    span {
      @include r.sp {
        font-size: 1.3rem;
        font-weight: normal;
      }

      &.all {
        @include r.sp {
          font-weight: bold;
        }
      }
    }
  }

  .detail_search_result_title.title02 {
    @include r.sp {
      border-bottom: 0;
    }
  }

  form {
    background: #fff;
    border-radius: 8px;

    @include r.sp {
      padding: 8px 4px 24px;
      border-radius: 0 0 8px 8px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.68;
      color: v.$color0;
      font-weight: bold;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .tb01 {
      // border-radius: 8px;
    }
  }

  .list_inline {
    @include r.sp {
      align-items: center;
    }

    li {
      width: auto;
      margin-right: 12px;

      @include r.sp {
        margin-bottom: 16px;
        font-size: 1.2rem;
      }
    }

    &.range2 {
      li {
        @include r.sp {
          flex: 1;
          margin: 0;
        }

        &:nth-child(2) {
          @include r.sp {
            flex: none;
            margin: 0 4px;
          }
        }
      }
    }

    &.range {
      li {
        @include r.sp {
          margin-right: 8px;
        }

        .money_from,
        .money_to {
          @include r.sp {
            width: 118px;
          }

          @include r.max-w(360px) {
            width: 90px;
          }
        }
      }
    }
  }

  .al_center {
    .list_inline {
      justify-content: center;
    }
  }

  .searchform_area {
    input[type="checkbox"] {
      @include r.sp {
        position: relative;
        top: 4px;
        margin-right: 2px;
      }
    }

    h3 {
      @include r.sp {
        margin-bottom: 0;
      }
    }

    select {
      @include r.sp {
        max-width: 100%;
      }
    }
  }

  .detail_search_note {
    @include r.sp {
      font-size: 1.2rem;
      line-height: 1.75;
      margin-top: 8px;
    }
  }

  .detail_search {
    &_box {
      margin-bottom: 8px;
    }

    &_text {
      margin-top: 12px;

      @include r.sp {
        margin-top: 0;
        font-weight: 700;
      }
    }
  }

  .scroll_div {
    border-radius: 0;

    .tb01 {
      @include r.sp {
        min-width: auto;
      }
    }
  }
}

button.btn_spat4ltd {
  @include r.sp {
    border-radius: 999px;
    width: 100%;
    height: 23px;
    border: 1px solid #c9c9c9;
    box-shadow: 0 2px 0 #c9c9c9;
    background-color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(p.$path + "images/common/icon/icon_horse-blue.svg") no-repeat center / 100%;
      margin-left: 16px;
    }
  }
}

.tb01 {
  &.detail_search_cdn {
    tr {
      td {
        label {
          margin-right: 8px;
        }
      }
    }

    select.field {
      margin-right: 4px;
    }
  }

  &.favo {
    font-size: 1.3rem;

    tr {
      td {
        input[type="checkbox"] {
          border-radius: 2px;
        }
      }
    }
  }
}

.al_center {
  .list_inline {
    justify-content: center;

    @include r.sp {
      max-width: 100%;
      width: 100%;
    }
  }
}

.detail_search_msg {
  padding: 5px 8px;
  margin-top: 8px;
  background-color: #fff;
}

#disp-chg-pc {
  @include r.pc {
    overflow: auto;
  }
}

.tb-over {
  background-color: #f2f2f2;
}

img.btn-bk-prev {
  max-width: 154px;
}

.nk23_c-guide {
  .box360 {
    img {
      width: 100px;
    }
  }

  .contact-txt {
    img {
      width: 175px;
    }
  }

  .tel {
    img {
      width: 250px;
    }
  }

  &__ttl1 {
    img {
      @include r.sp {
        width: 224px;
      }
    }
  }

  &__ttl2 {
    img {
      @include r.sp {
        width: 212px;
      }
    }
  }

  &__contact {
    img {
      @include r.sp {
        width: 96px;
      }
    }
  }
}

html.is-newWindow {
  body {
    min-width: auto !important;
  }
}

#newsNav.navLink li span {
  font-weight: bold;
}

.p-pc-only {
  @media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
    #contents {
      display: block !important;
    }

    #contents170 {
      float: left;
    }

    #contents770 {
      float: right;
    }
  }

  @include r.sp {
    min-width: 1248px;

    #contents170 {
      width: 180px !important;
      margin-top: 0 !important;
    }

    #contents770 {
      width: 770px !important;
      margin-left: 8px !important;
    }

    #submenu tr td ul li a,
    #submenu2 tr td ul li a {
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding: 8px 24px 8px 0 !important;
    }

    #submenu tr th a,
    #submenu2 tr th a {
      padding: 8px 8px;
    }

    #submenu tr td ul li.title,
    #submenu2 tr td ul li.title {
      padding: 8px 0 !important;
    }

    #submenu tr td ul li.subtitle h4,
    #submenu2 tr td ul li.subtitle h4 {
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding: 8px 8px 8px 0;
    }

    .p-leading_sokuhou_kis,
		.p-leading_sokuhou_cho {
      #tab-select ul {
        margin: 0;
      }

      #tab-select ul li {
        width: calc((100% - 28px) / 5);
      }

      #tab-select ul li#crt-tab a,
      #tab-select ul li a {
        font-size: 1.2rem;
        line-height: 1.85;
        padding: 0;
      }

      #tab-select ul li:not(:last-child) {
        margin-right: 7px;
      }

      .round-cnr {
        margin: 8px auto !important;
      }
    }
  }
}

.tab-select-txt2 {
  margin: 0 0 8px 0;
  padding: 0;
  width: 100%;
  line-height: 1;
  border-bottom: 1px solid #ccc;
  text-align: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2px;

    li {
      width: auto;
      margin: 0 2px;

      // border-top-left-radius: 4px;
      // border-top-right-radius: 4px;

      a {
        font-size: 1.3rem;
        line-height: 2.2rem;
        border-top: 1px solid #c9c9c9;
        border-left: 1px solid #c9c9c9;
        border-right: 1px solid #c9c9c9;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: block;
        word-break: keep-all;
        white-space: nowrap;
        padding: 0 12px;

        &:hover {
          background: #221f1f;
          color: #fff;
          border-top: 1px solid #221f1f;
          border-left: 1px solid #221f1f;
          border-right: 1px solid #221f1f;
        }
      }

      &#crt-tab-txt2 {
        a {
          background: #221f1f;
          color: #fff;
          font-weight: 700;
          border-top: 1px solid #221f1f;
          border-left: 1px solid #221f1f;
          border-right: 1px solid #221f1f;
        }
      }
    }
  }
}

.box-rireki {
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  margin-top: 13px;
  max-width: 400px;
  padding-bottom: 5px;

  h2.tl-koshinrireki {
    background: transparent;
    line-height: 2.3rem;
    font-size: 1.3rem;
    color: #221f1f;
    border-bottom: 1px solid #c9c9c9;
    text-indent: inherit;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .koshinrireki_in {
    font-size: 1.1rem;
    padding: 4px 8px;
    height: 135px;
    overflow: auto;
    position: relative;

    li {
      line-height: 1.6rem;
    }
  }
}

.ico-night {
  padding: 0px 0 0px 18px;
  background: url("/shared/images/icon/ico-k-night.svg") no-repeat left;
  vertical-align: middle;
  display: inline-block;
  background-size: 12px 12px;
}

.c-release {
  #contents999,
  p {
    font-size: 1.3rem;
    line-height: 2.1rem;

    a {
      color: #2461ee;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #contents770b {
    background: #fff !important;
    border-radius: 8px;
    padding: 15px 20px 20px !important;

    #contents730 {
      padding: 0 !important;

      .bl-right {
        margin-bottom: 15px;

        p {
          margin-bottom: 0;
        }
      }

      .tx-gray01 {
        display: block;
        font-size: 1.3rem;
        line-height: 2.1rem;
        margin-bottom: 15px;
      }
    }

    p {
      display: block;
      font-size: 1.3rem;
      line-height: 2.1rem;
      margin-bottom: 8px;

      .ico-night {
        margin-top: 1px;
      }
    }

    h2 {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    .odds-pagination {
      ul {
        li {
          margin: 0 0 0 16px;
          line-height: 1.1rem;

          &:first-child {
            margin: 0;
          }

          a {
            color: #2461ee;
            font-size: 1.3rem;
            line-height: 1.9rem;
            padding-right: 0;
          }
        }
      }
    }
  }
}

.tb-cal2 {
  margin: 0 0 25px 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  border-collapse: separate;
  width: 100%;
  color: #221f1f;
  font-size: 1.1rem;
  border-spacing: 0;

  .t-name {
    padding-left: 5px;
    text-align: left;
  }

  th {
    padding: 2px 1px 1px 1px;
    font-weight: normal;
    border-top: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;
    background: #f7f7f7;
    font-weight: 700;

    &:not(:last-child) {
      border-left: 1px solid #c9c9c9;
    }

    &:first-child {
      border-right: 1px solid #c9c9c9;
    }

    .tx-mid {
      font-size: 1.3rem;
    }

    &.bg-blue02 {
      background: #0d3180;
      border-right: 0;
      border-left: 0;
      border-top: 0;
    }
  }

  td {
    padding: 2px 0;
    text-align: center;
    border-left: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;

    &.tx-red01 {
      color: #e63434;
    }

    &.tx-blue01 {
      color: #3570e6;
    }

    a {
      display: block;
    }
  }

  .disc {
    font-size: 6px;
    vertical-align: middle;
  }

  tr {
    &:first-child {
      th {
        &:last-child {
          border-top-right-radius: 8px;
          border-left: 1px solid #c9c9c9;
          border-right: 1px solid #c9c9c9;
        }
      }
    }

    &:last-child {
      td {
        &:last-child {
          border-bottom-right-radius: 8px;
        }

        &:first-child {
          border-bottom-left-radius: 8px;
        }
      }
    }
  }

  tbody {
    td {
      &:last-child {
        border-right: 1px solid #c9c9c9;
      }
    }
  }
}

.bg-spat4-01 {
  padding: 16px 10px 10px;
  background-image: none;
  background-color: v.$color32;
  background-image: none !important;
  border-radius: 8px;

  .contents750 {
    padding: 0;
    width: 100% !important;
  }

  h2 {
    margin-bottom: 16px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #fff;
  }

  .clearfix {
    margin: 0 -5px;
    display: flex;
  }

  // .bg-spat4-01 a .
  a {
    margin: 0 5px 10px;
    float: none;
    width: calc(50% - 5px);
    display: flex;
    background-color: transparent !important;

    @include r.hover {
      border: none !important;
      background-color: transparent !important;
    }

    img {
      border-radius: 8px;
      width: 100%;
    }
  }
}

.box-about-service.netbank,
.box-about-service.telephone {
  padding: 20px 10px;
  border-radius: 8px;
  background-color: v.$color51;
  border: 1px solid v.$color32;

  .title-spat4 {
    margin-bottom: 24px;
    padding-left: 42px;
    padding-bottom: 0;
    font-size: 2.8rem;
    font-weight: 700;
    color: v.$color0;
    border-bottom: none;
    background-size: 36px auto;
    background-position: top 5px left;
  }

  // .box-about-service.netbank .list00, .box-about-service.telephone .list00 .
  .list00 {
    margin-top: 16px;

    li {
      margin-left: 0;
    }
  }

  a {
    &.button {
      background: none;
      border: none;

      @include r.hover {
        border: none;
      }
    }
  }

  p {
    font-size: 1.4rem;
    color: v.$color0;
  }

  table {
    margin-top: 16px;
    margin-left: 0;
    width: max-content;

    a {
      border-radius: 2px;
      overflow: hidden;
      display: flex;
      border: 1px solid v.$color19;
    }

    td {
      padding-bottom: 0;
      padding-right: 4px;
      padding-left: 0;
    }
  }
}

.box-about-service.telephone {
  .title-spat4 {
    background-image: url("/info/spat4/images/icon_white2.png");
  }

  ul {
    padding-top: 0;
  }

  p {
    &.mB105 {
      margin-bottom: 0 !important;
    }

    // .box-about-service.telephone p+p .
    & + p {
      margin-top: 16px;
    }
  }
}

// .box-about-service.netbank .
.box-about-service.netbank {
  margin-bottom: 16px;
}

.tb00 {
  max-width: 710px;
  width: 100%;
  margin: auto;

  td {
    padding: 7px 5px;
    border: none;
    line-height: 1.2;

    img {
      vertical-align: middle;
    }
  }
}

ul.tab-navigation {
  &.netbank {
    margin: 16px -4px 20px;
    padding: 0;

    a {
      margin-bottom: 0;
      width: auto;
      line-height: 3.3rem;
      font-size: 1.4rem;

      &:hover {
        box-shadow: none;
        transform: translateY(2px);
        opacity: 1;
        color: #004ea2;
      }
    }

    li {
      a {
        &.active {
          background-color: #004ea2 !important;
          border: 1px solid #0d3180 !important;
          box-shadow: none;
          font-weight: bold;
          background: linear-gradient(to bottom, #004ea2 0, #004ea2 100%) !important;
          color: #fff;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  &.telephone {
    li {
      a {
        &.active {
          background-color: #004ea2 !important;
          border: 1px solid #0d3180 !important;
          box-shadow: none;
          background: linear-gradient(to bottom, #004ea2 0, #004ea2 100%) !important;

          &:hover {
            color: #fff;
            top: 0;
          }
        }
      }
    }
  }

  li {
    a {
      display: block;

      &:link {
        text-decoration: none;
      }
    }
  }
}

.mark-pdf {
  min-height: 30px;
  background: url(/info/spat4/images/icon_pdf.svg) 0 0 no-repeat;

  a {
    margin-top: 4px;
    font-size: 1.3rem;
    color: v.$color-link;

    @include r.hover {
      text-decoration: underline;
    }
  }
}

.list-faq {
  margin-left: 48px;

  dt,
  dd {
    border-radius: 8px;

    &:before {
      left: -48px;
      background-size: 225px auto;
    }
  }

  // .list-faq dt .
  dt {
    margin-bottom: 5px;
    padding: 7px 8px;
    background-color: rgba($color: v.$color58, $alpha: 0.5);
    border: none;
  }

  dd {
    margin-bottom: 30px;
    padding: 5px 10px 16px;
    border-color: v.$color19;
    font-size: 1.3rem;
    line-height: 1.9rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: v.$color-link;
      text-decoration: none;

      @include r.hover {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }
}

.list00 {
  .button {
    img {
      width: 165px;
    }
  }

  li {
    float: left;
    list-style: none;

    &:first-child {
      margin-left: 0;
    }
  }
}

.box-A,
.box-Q {
  > img {
    &:first-child {
      min-width: 37px;
      max-width: 37px;
    }

    &.w-auto {
      min-width: auto;
      max-width: 100%;
      margin-top: 20px;
    }
  }
}

.box-A {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  & + .box-A {
    margin-top: 15px;
  }

  table {
    margin-top: 32px;
    width: 100%;

    // .box-A table+.tx-small .
    & + .tx-small {
      margin-top: 9px;
    }
  }

  ul {
    margin: 16px 0 16px 26px;
  }

  li {
    list-style: disc outside;
  }

  .tx-red01 {
    color: #f00;
  }

  // .box-A p .
  p {
    padding-left: 13px;
    width: calc(100% - 37px);
    font-size: 1.3rem !important;
    line-height: 2.1rem;
    display: block !important;

    a {
      color: v.$color-link;

      @include r.hover {
        text-decoration: underline;
      }
    }
  }

  .tx-small {
    font-size: 1.1rem !important;
    line-height: 1.8rem;
  }

  & + .al-center {
    img {
      display: inline-block;
    }
  }

  .ml32 {
    margin-left: 32px;
  }

  .ml37 {
    margin-left: 37px;
  }

  .ml50 {
    margin-left: 50px;
  }

  .mt23 {
    margin-top: 23px !important;
  }

  .mt4 {
    margin-top: 4px;
  }

  .mt28 {
    margin-top: 28px;
  }

  .mt8 {
    margin-top: 8px;
  }

  .mL50 {
    margin-left: 50px;
  }

  .mL8 {
    margin-left: 8px;
  }

  .text-block {
    display: block;
  }

  .text-inline {
    display: inline-block;
  }

  .tb01b {
    margin-top: 5px !important;
    font-size: 1.3rem;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    &.w600px {
      width: 600px;
    }

    tr {
      &:first-child {
        td {
          text-align: center;
          color: #fff;
          font-weight: 700;
          background-color: v.$color29;
          border-left: 1px solid v.$color19;
          border-top: 1px solid v.$color19;
          border-bottom: 1px solid v.$color19;

          &:last-child {
            border-left-color: v.$color19;
            border-right: 1px solid v.$color19;
          }
        }
      }

      &:not(:first-child) {
        td {
          border-left: 1px solid v.$color19;

          &:last-child {
            border-right: 1px solid v.$color19;
          }
        }
      }
    }

    td {
      border-bottom: 1px solid v.$color19;
      width: 373px;

      &:first-child {
        width: 227px;
      }

      a {
        display: flex;
        color: v.$color-link;
        align-items: flex-start;

        @include r.hover {
          text-decoration: underline;
        }

        img {
          margin: 2px 0 0;
          min-width: 16px;
          max-width: 16px;
        }
      }
    }
  }

  .box690 {
    img {
      display: inline-block;
    }
  }
}

.box-Q {
  padding: 3.5px 16px 3.5px 0;
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 8px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 37px;
    background-color: v.$color0;
    border-radius: 8px 0 0 8px;
  }

  // .box-Q img .
  img {
    margin-top: 2px;
    height: 19px;
    position: relative;
    z-index: 2;
  }

  .txt-q {
    padding-left: 13px;
    font-size: 1.3rem;
    line-height: 2.1rem;
    font-weight: 700;
  }
}

#ls-qa-other,
#ls-qa-website,
#ls-qa-race,
#ls-qa-program,
#ls-qa-racecourse,
.ls-qa {
  a {
    padding-left: 25px;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: v.$color-link;
    background: url(/info/qanda/images/ico-q-other.jpg) no-repeat center left / 21px auto;

    @include r.hover {
      text-decoration: underline;
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: 6.5px;
    }
  }
}

.link-pagetop {
  padding-left: 16px;
  padding-right: 20px;
  background: url(p.$path + "images/common/icon/ico-page-top.svg") no-repeat center left / 11px auto;
  color: v.$color-link;
  font-size: 1.1rem;
  line-height: 1.8rem;

  @include r.hover {
    text-decoration: underline;
  }
}

#contents .mB105 {
  margin-bottom: 16px;
}

#box-fcl-data {
  margin: 16px 0 0;
  padding: 0;
  float: right;
  width: 335px;
  background: #fff;
  z-index: 5;
  font-size: 1.3rem;
  line-height: 2.1rem;

  // #box-fcl-data h3 .
  h3 {
    margin-bottom: 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid v.$color19;
  }

  .val-bottom {
    margin-top: 0;

    & + .tx-small {
      margin-top: 7px;
    }
  }

  // #box-fcl-data .box-fcl .
  .box-fcl {
    padding: 15px 14px 10px;
    background-color: v.$color34;
    border: 1px solid v.$color19;
    border-radius: 8px;

    &-in {
      a {
        color: v.$color-link;

        @include r.hover {
          text-decoration: underline;
        }
      }
    }
  }

  // #box-fcl-data .only .
  .only {
    margin-top: 31px;
    padding-bottom: 40px;
  }

  .red {
    color: #f00;
  }

  p {
    display: block;
    font-size: 1.3rem;
    line-height: 2.1rem;
  }

  .bl-left {
    img {
      border-radius: 4px;
    }
  }

  .bl-right {
    a {
      img {
        width: 105px;
      }
    }
  }

  .tx-small {
    font-size: 1.1rem;
    line-height: 1.8rem;
    padding-left: 20px;

    & + h3 {
      margin-top: 10px;
    }
  }

  .tx-large,
  .tx-mid {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

#box-fcl-map {
  top: 6px;
  margin: 0;
  padding: 0;
  float: left;
  width: 390px;
  position: relative;
}

.box-fcl-in {
  h3 {
    img {
      height: 12px;
    }
  }
}

.ico-popup {
  margin: 0;
  padding: 2px 0 2px 22px;
  background: url(/shared/images/icon/ico-popup.gif) no-repeat;
  background-position-y: center;
  background-size: 20px 11px;
}

#navi {
  border-bottom: 1px solid #004ea2;
  display: flex;
  justify-content: space-between;

  li {
    display: flex;
    margin-bottom: -1px;
  }

  a {
    position: relative;
    //
    //@include r.hover {
    //  img {
    //    opacity: 0;
    //
    //    &.hover {
    //      opacity: 1;
    //    }
    //  }
    //}
  }

  img {
    height: 42px;

    &.hover {
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      z-index: 1;
    }
  }
}

.mawarilabel {
  height: 24px !important;
  padding: 0 5px !important;
  border-radius: 6px;
  border: #221f1f solid 1px !important;
  margin-bottom: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem !important;
  line-height: 24px !important;
}
