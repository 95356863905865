@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
navi
------------------------------------------------------------*/
.nk23_c-navi1 {
  padding: 16px;
  box-shadow: 0 2px 3px rgba(34, 31, 31, 0.1);
  background-color: #ffffff;
  position: relative;
  @include r.sp {
    padding: 16px 8px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    position: relative;
    margin: 0 16px;
  }

  &__item.is-active &__link {
    opacity: 1;
    font-weight: bold;

    &.is-color1 {
      color: v.$urawa-color;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 1px;
      background-color: #000000;
    }
  }

  &__link {
    position: relative;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: center;
    display: block;
    color: #221f1f;
    opacity: 0.7;
    @include r.hover {
      opacity: 0.4;
    }
  }
}
